import { ReactNode } from 'react';
import styled from 'styled-components';

const OuterWrapper = styled.div`
  position: relative;
  max-width: 260px;
  width: 100%;
`;

interface IEditorSidebarOuterWrapper {
  id?: string;
  children: ReactNode;
  onFocus?: () => void;
}

const EditorSidebarOuterWrapper = ({ id, children, onFocus }: IEditorSidebarOuterWrapper): JSX.Element => {
  return (
    <OuterWrapper id={id} onFocus={onFocus}>
      {children}
    </OuterWrapper>
  );
};

export default EditorSidebarOuterWrapper;
