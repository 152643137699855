import { memo } from 'react';

const ArrowDownCircle = (): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 14.5C12.0376 14.5 14.5 12.0376 14.5 9C14.5 5.96243 12.0376 3.5 9 3.5C5.96243 3.5 3.5 5.96243 3.5 9C3.5 12.0376 5.96243 14.5 9 14.5ZM9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z"
        fill="currentColor"
      />
      <path
        d="M9.42 11.8405L11.82 9.71392C12.06 9.50126 12.06 9.18227 11.82 8.96961C11.58 8.75695 11.22 8.75695 10.98 8.96961L9.6 10.1924L9.6 6.53164C9.6 6.21265 9.36 5.99999 9 5.99999C8.64 5.99999 8.4 6.21265 8.4 6.53164L8.4 10.1924L7.02 8.96961C6.9 8.86328 6.78 8.81012 6.6 8.81012C6.24 8.81012 6 9.02278 6 9.34176C6 9.50126 6.06 9.60759 6.18 9.71392L8.58 11.8405C8.82 12.0532 9.18 12.0532 9.42 11.8405Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ArrowDownCircle);
