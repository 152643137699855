import { memo } from 'react';

const CogWheel = (): JSX.Element => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8026 15.843C24.2199 16.0647 24.5419 16.4147 24.7684 16.7647C25.2095 17.488 25.1738 18.3747 24.7446 19.1564L23.9099 20.5564C23.4688 21.3031 22.6461 21.7697 21.7996 21.7697C21.3823 21.7697 20.9173 21.6531 20.5358 21.4197C20.2258 21.2214 19.8681 21.1514 19.4866 21.1514C18.3062 21.1514 17.3166 22.1197 17.2808 23.2747C17.2808 24.6164 16.1839 25.6664 14.8128 25.6664H13.1913C11.8082 25.6664 10.7113 24.6164 10.7113 23.2747C10.6875 22.1197 9.69787 21.1514 8.51752 21.1514C8.12406 21.1514 7.76637 21.2214 7.4683 21.4197C7.08677 21.6531 6.60985 21.7697 6.20448 21.7697C5.34603 21.7697 4.52336 21.3031 4.0822 20.5564L3.25952 19.1564C2.81838 18.398 2.79454 17.488 3.23568 16.7647C3.42645 16.4147 3.78413 16.0647 4.18951 15.843C4.52335 15.6797 4.73796 15.4114 4.94065 15.0964C5.53679 14.093 5.17911 12.7747 4.16567 12.1797C2.9853 11.5147 2.60377 10.033 3.28337 8.87803L4.0822 7.50136C4.77373 6.34635 6.25217 5.93802 7.44445 6.61469C8.48175 7.17469 9.82903 6.80135 10.4371 5.80968C10.6279 5.48302 10.7352 5.13302 10.7113 4.78302C10.6875 4.32801 10.8186 3.89635 11.0452 3.54634C11.4863 2.82301 12.2851 2.35634 13.1556 2.33301H14.8366C15.719 2.33301 16.5178 2.82301 16.9589 3.54634C17.1736 3.89635 17.3166 4.32801 17.2808 4.78302C17.257 5.13302 17.3643 5.48302 17.5551 5.80968C18.1631 6.80135 19.5104 7.17469 20.5596 6.61469C21.74 5.93802 23.2303 6.34635 23.9099 7.50136L24.7087 8.87803C25.4003 10.033 25.0187 11.5147 23.8265 12.1797C22.813 12.7747 22.4553 14.093 23.0634 15.0964C23.2541 15.4114 23.4688 15.6797 23.8026 15.843ZM10.6279 14.0114C10.6279 15.843 12.1421 17.3014 14.014 17.3014C15.8858 17.3014 17.3643 15.843 17.3643 14.0114C17.3643 12.1797 15.8858 10.7097 14.014 10.7097C12.1421 10.7097 10.6279 12.1797 10.6279 14.0114Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(CogWheel);
