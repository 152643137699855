import { memo } from 'react';

interface IShapes {
  className?: string;
}

const Shapes = ({ className }: IShapes): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0008 10.9091C30.0008 15.9925 26.5238 20.2639 21.8183 21.4747C21.8189 21.187 21.8189 20.8921 21.8189 20.5903V20.5871V20.5871V20.3222V20.3221V20.3221C21.819 18.5201 21.819 16.9661 21.69 15.6975C21.5531 14.3523 21.249 13.0288 20.44 11.8181C19.8431 10.9248 19.076 10.1577 18.1827 9.56078C16.972 8.75182 15.6485 8.44765 14.3033 8.3108C13.0347 8.18175 11.4806 8.18181 9.6786 8.18189L9.54617 8.18189L9.41374 8.18189H9.41331C9.11053 8.18188 8.81474 8.18186 8.52613 8.18246C9.73694 3.47697 14.0083 0 19.0917 0C25.1166 0 30.0008 4.88417 30.0008 10.9091ZM1.17157 12.0807C0 13.2523 0 15.1379 0 18.9091V22C0 25.7713 0 27.6569 1.17157 28.8285C2.34315 30 4.22877 30 8 30H11.0909C14.8621 30 16.7478 30 17.9193 28.8285C19.0909 27.6569 19.0909 25.7713 19.0909 22V18.9091C19.0909 15.1379 19.0909 13.2523 17.9193 12.0807C16.7478 10.9091 14.8621 10.9091 11.0909 10.9091H8C4.22876 10.9091 2.34315 10.9091 1.17157 12.0807Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Shapes);
