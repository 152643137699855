import React, { useEffect } from 'react';
import ReactCodeInput from 'react-code-input';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoginStyled from 'components/auth/shared/login-styled';
import zazuLogo1x from 'assets/images/zazu-logo1x.png';
import zazuLogo2x from 'assets/images/zazu-logo2x.png';
import zazuLogo3x from 'assets/images/zazu-logo3x.png';
import zazuBg1x from 'assets/images/auth-backgroundx1.webp';
import zazuBg2x from 'assets/images/auth-backgroundx2.webp';
import zazuBg3x from 'assets/images/auth-backgroundx3.webp';

import { forgotPasswordCode } from '../../../redux/features/user/userSlice';
import Title from '../shared/title';
import TextButton from '../shared/form-text-button';
import FormButton from '../shared/form-button';

import { RootState } from '../../../redux/rootReducer';

import styled from 'styled-components';
import { clearErrors } from '../../../redux/features/user/userSlice';

const StyledPageContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  & * {
    box-sizing: border-box;
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 367px;
  background-color: var(--shade-900);
  border-radius: 12px;
  align-items: center;
  box-shadow: 0px 4px 30px 20px rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 69px 36px 69px;
  align-items: left;
`;

const StyledInformation = styled.p`
  margin-bottom: 26px;
  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--shade-100);
`;

const StyledTitle = styled(Title)`
  margin-bottom: 9px;
`;

const StyledTextButton = styled(TextButton)`
  align-self: flex-start;
  line-height: 18px;
  padding-top: 15px;
  padding-bottom: 4px;
`;

const CodeInputContainer = styled.div`
  max-width: 100%;
  margin-bottom: 27px;
`;

const StyledReactCodeInput = styled(ReactCodeInput)`
  & input {
    max-height: 40px;
    width: 33px;
    border-radius: 6px;
    background: var(--shade-700);
    margin-right: 4px;
    max-width: 100%;
    outline: none;
    border: 1px solid transparent;
    font-family: Heebo;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.01em;
    text-align: center;
    color: var(--white);
    &:focus {
      border-color: var(--primary);
    }
  }

  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledCodeError = styled.span`
  max-width: 100%;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--white);
  background: var(--warning);
  border-radius: 6px;
  padding: 5.5px 6px;
  margin-bottom: 22.5px;
`;

type IToken = { token: string };
const ForgotPasswordCode: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({ mode: 'all' });
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const onPasswordReset = ({ token }: IToken) => {
    dispatch(forgotPasswordCode(token, history));
  };

  return (
    <StyledPageContainer>
      <LoginStyled.AuthBackgroundContainer>
        <LoginStyled.AuthBackground srcSet={`${zazuBg3x} 3x, ${zazuBg2x} 2x, ${zazuBg1x} 1x`} />
        <LoginStyled.Overlay />
      </LoginStyled.AuthBackgroundContainer>
      <LoginStyled.LogoLink href={'//zazuapp.co'} target={'_blank'} rel="noopener noreferrer">
        <LoginStyled.Logo srcSet={`${zazuLogo3x} 3x,${zazuLogo2x} 2x, ${zazuLogo1x} 1x`} />
      </LoginStyled.LogoLink>
      <StyledFormWrapper>
        <StyledForm onSubmit={handleSubmit(onPasswordReset)}>
          <StyledTitle text="Insert Code" />
          <StyledInformation>Please insert the 6-digit code we have sent to your indicated email.</StyledInformation>
          {user.authErrors && <StyledCodeError>Invalid code</StyledCodeError>}
          <Controller
            control={control}
            name="token"
            rules={{ required: true, maxLength: 6, minLength: 6 }}
            render={({ onChange, value }) => (
              <CodeInputContainer>
                <StyledReactCodeInput
                  inputMode="numeric"
                  type="number"
                  name="token"
                  onChange={onChange}
                  value={value}
                  fields={6}
                />
              </CodeInputContainer>
            )}
          />
          <FormButton type="submit" text="Verify" disabled={!isValid} />
          <StyledTextButton
            color="var(--primary)"
            onClick={() => history.push('/login')}
            text="Go Back"
            name="back-button"
          />
        </StyledForm>
      </StyledFormWrapper>
    </StyledPageContainer>
  );
};

export default ForgotPasswordCode;
