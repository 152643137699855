import styled from 'styled-components';
import EditorStyles from 'components/editor/editor-styled';

const EditorContainerWrapper = styled.div<{ maxWidth?: string | number }>`
  position: relative;
  margin-right: 64px;
  width: ${({ maxWidth }) => (maxWidth ? `calc(${maxWidth} + 64px)` : '700px')};
`;

const EditorPlaceholder = styled(EditorStyles.EditorPlaceholder)`
  bottom: 4.5%;
  height: calc(95% - var(--header-height));
`;

const EditorInnerPlaceholder = styled(EditorStyles.EditorInnerPlaceholder)`
  margin: auto 0;
`;

const EditorSecondLine = styled.div`
  padding: 0 24px 0 64px;
  display: flex;
  width: 100%;
  min-width: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: auto;
  position: relative;
  z-index: 3;
  pointer-events: none;
`;

export default {
  EditorContainerWrapper,
  EditorSecondLine,
  EditorPlaceholder,
  EditorInnerPlaceholder,
};
