interface ILockIcon {
  onClick?: (params?: any) => void;
}

const LockIcon = ({ onClick }: ILockIcon): JSX.Element => {
  return (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 5.00046C3.65889 5 3.82541 5 4 5H6C6.17459 5 6.34111 5 6.5 5.00046V3C6.5 2.17157 5.82843 1.5 5 1.5C4.17157 1.5 3.5 2.17157 3.5 3V5.00046ZM2 5.05075C1.34994 5.11469 0.912377 5.2592 0.585786 5.58579C0 6.17157 0 7.11438 0 9C0 10.8856 0 11.8284 0.585786 12.4142C1.17157 13 2.11438 13 4 13H6C7.88562 13 8.82843 13 9.41421 12.4142C10 11.8284 10 10.8856 10 9C10 7.11438 10 6.17157 9.41421 5.58579C9.08762 5.2592 8.65006 5.11469 8 5.05075V3C8 1.34315 6.65685 0 5 0C3.34315 0 2 1.34315 2 3V5.05075Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LockIcon;
