import React from 'react';
import { RoundPencil } from './../../../icons';
import styled from 'styled-components';

interface IEditPasswordButton {
  onClick: () => void;
}

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
`;

const ButtonText = styled.span`
  display: inline-block;
  padding-left: 6px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0;
`;

const EditPasswordButton = ({ onClick }: IEditPasswordButton): JSX.Element => {
  return (
    <Button onClick={onClick}>
      <RoundPencil />

      <ButtonText>Set new password</ButtonText>
    </Button>
  );
};

export default EditPasswordButton;
