import { memo } from 'react';

interface IInteractive {
  className?: string;
}

const Interactive = ({ className }: IInteractive): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM23.75 15C23.75 19.8325 19.8325 23.75 15 23.75C10.1675 23.75 6.25 19.8325 6.25 15C6.25 10.1675 10.1675 6.25 15 6.25C19.8325 6.25 23.75 10.1675 23.75 15ZM15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Interactive);
