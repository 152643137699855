import { memo } from 'react';

interface IPreview {
  className?: string;
}

const Preview = ({ className }: IPreview): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9662 19.9668C26.808 20.1292 26.2105 20.8236 25.6542 21.3948C22.3919 24.9872 13.8813 30.8672 9.42704 32.662C8.75056 32.9504 7.04024 33.5608 6.12646 33.6C5.25084 33.6 4.41616 33.3984 3.61967 32.9896C2.62679 32.4184 1.8303 31.5196 1.39387 30.4584C1.11292 29.722 0.67648 27.5184 0.67648 27.4792C0.240044 25.0684 0 21.1512 0 16.8224C0 12.698 0.240044 8.9404 0.59738 6.4932C0.638287 6.454 1.07472 3.7156 1.55207 2.7776C2.42766 1.064 4.13795 0 5.96823 0H6.12646C7.31847 0.042 9.8252 1.106 9.8252 1.1452C14.0395 2.9428 22.3535 8.5344 25.695 12.25C25.695 12.25 26.6361 13.2048 27.0452 13.8012C27.6836 14.658 28 15.7192 28 16.7804C28 17.9648 27.6427 19.068 26.9662 19.9668Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Preview);
