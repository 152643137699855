import qs from 'qs';
import React, { useMemo } from 'react';
import StoriesSection from 'components/stories/shared/elements/stories-section/stories-section';
import TeamTitle from './shared/team-title';
import { EmptyStoryList, NoStoriesFound } from 'components/stories/shared/elements';
import isEmpty from 'lodash/isEmpty';
import { StoriesWrapper } from './../stories/shared/structure';
import { useAppSelector, useSpinner } from '../../hooks';
import { useLocation } from 'react-router-dom';
import { useStoryListQuery } from 'redux/services/stories/stories';

const sectionTitleLink = {
  text: 'Team',
  url: '/teams',
};

const TeamsAllStories = (): JSX.Element => {
  const location = useLocation();
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const teamId = useAppSelector((state) => state.auth.user?.selectedTeamId);

  const searchParams = useMemo(() => {
    return qs.parse(location.search.slice(1));
  }, [location]);

  const query = useMemo(() => {
    let query = '';
    if (searchParams) {
      Object.keys(searchParams).forEach((key: string) => (query += `search[${key}]=${searchParams?.[key] ?? ''}&`));
    }

    return query;
  }, [searchParams]);

  const { data, isLoading } = useStoryListQuery(
    { query, workspaceId: selectedWorkspaceId ?? '', teamId },
    { skip: !selectedWorkspaceId || !teamId },
  );

  const { Spinner, spinnerProps } = useSpinner();

  if (isLoading) {
    return <Spinner {...spinnerProps} isVisible={true} />;
  }

  return (
    <StoriesWrapper>
      <TeamTitle />

      {data?.stories?.length === 0 && isEmpty(searchParams) && (
        <EmptyStoryList
          text={'Start collaborating.\nCreate a Story and move it \nto the Teams section.'}
          hasButton={false}
        />
      )}

      {data?.stories?.length === 0 && !isEmpty(searchParams) && <NoStoriesFound />}

      {data?.stories && data?.stories?.length > 0 && (
        <StoriesSection stories={data?.stories} linkHeader={sectionTitleLink} header="All stories" withStoryAuthor />
      )}
    </StoriesWrapper>
  );
};

export default TeamsAllStories;
