import { ReactNode, memo } from 'react';
import styled, { css } from 'styled-components';
import { columnType } from '../../../config/constants';

interface IEditorSidebarColumn {
  flexGrow?: '1' | '0';
  className?: string;
  flexBasis?: string;
  maxWidth?: string;
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  flexShrink?: '1' | '0';
  alignItems?: string;
  minWidth?: string;
  padding?: string;
  children: ReactNode;
}

const StyledSettingsColumn = styled.div<{
  flexGrow?: string;
  flexBasis?: string;
  maxWidth?: string;
  justifyContent?: string;
  alignItems?: string;
  flexShrink?: '1' | '0';
  minWidth?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  flex-basis: ${({ flexBasis }) => flexBasis};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
  flex-grow: ${({ flexGrow }) => flexGrow};
  ${({ flexShrink }) =>
    flexShrink &&
    css`
      flex-shrink: ${flexShrink};
    `}
  justify-self: ${({ justifyContent }) => justifyContent};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  min-width: ${({ minWidth }) => minWidth ?? '300px'};
  padding: ${({ padding }) => padding ?? '12px'};
`;

const SettingsColumn = ({
  className,
  flexGrow,
  flexBasis,
  maxWidth,
  justifyContent,
  alignItems,
  flexShrink,
  minWidth,
  padding,
  children,
}: IEditorSidebarColumn) => {
  return (
    <StyledSettingsColumn
      className={className}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      flexBasis={flexBasis}
      maxWidth={maxWidth}
      justifyContent={justifyContent!}
      minWidth={minWidth}
      padding={padding}
      alignItems={alignItems}
    >
      {children}
    </StyledSettingsColumn>
  );
};

SettingsColumn.defaultProps = {
  flexGrow: '0',
  justifyContent: 'flex-start',
  flexBasis: columnType.WHOLE,
  alignItems: 'flex-start',
};

export default memo(SettingsColumn);
