import { memo } from 'react';

const UnlockSM = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 11.0005V8C13.5 7.17157 12.8284 6.5 12 6.5C11.1716 6.5 10.5 7.17157 10.5 8V8.25C10.5 8.66421 10.1642 9 9.75 9C9.33579 9 9 8.66421 9 8.25V8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8V11.0507C15.6501 11.1147 16.0876 11.2592 16.4142 11.5858C17 12.1716 17 13.1144 17 15C17 16.8856 17 17.8284 16.4142 18.4142C15.8284 19 14.8856 19 13 19H11C9.11438 19 8.17157 19 7.58579 18.4142C7 17.8284 7 16.8856 7 15C7 13.1144 7 12.1716 7.58579 11.5858C8.17157 11 9.11438 11 11 11H13C13.1746 11 13.3411 11 13.5 11.0005Z"
      fill="#ABABBA"
    />
  </svg>
);

export default memo(UnlockSM);
