import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InaccessibleModal, { InaccessibleModalType } from './inaccessible-modal';

interface IWidgetInaccessibleModal {
  isOpen: boolean;
  user: string;
  onClose: () => void;
  setWidgetLockIgnored: React.Dispatch<React.SetStateAction<boolean>>;
}

const WidgetInaccessibleModal = ({
  isOpen,
  user,
  onClose,
  setWidgetLockIgnored,
}: IWidgetInaccessibleModal): JSX.Element => {
  const history = useHistory();

  const onOpenAnywayHandler = useCallback(() => {
    setWidgetLockIgnored(true);
    onClose();
  }, [onClose, setWidgetLockIgnored]);

  const onCloseClick = useCallback(() => {
    history.push('/my-carousels');
  }, [history]);

  return (
    <InaccessibleModal
      isOpen={isOpen}
      user={user}
      type={InaccessibleModalType.WIDGET}
      onClose={onCloseClick}
      onAccept={onOpenAnywayHandler}
    />
  );
};

export default memo(WidgetInaccessibleModal);
