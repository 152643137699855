export const pushCrisp = (
  method: string,
  parameters: string | { [key: string]: string | number } | { [key: string]: string[] | number[] | boolean[] },
) => {
  if (typeof parameters === 'string') {
    return (window as any).$crisp.push([method, parameters]);
  }

  if (Object.keys(parameters).length > 0) {
    const items = Object.entries(parameters);
    items.map((item) => {
      const [key, value] = item;
      return (window as any).$crisp.push([method, key, value]);
    });
  }
};
