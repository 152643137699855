import { memo } from 'react';

interface IConversionIcon {
  className?: string;
}

const ConversionIcon = ({ className }: IConversionIcon): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73459 3.5H22.2654C23.5 3.5 24.5 4.53011 24.5 5.80187V7.40994C24.5 8.02689 24.2589 8.61934 23.8308 9.05143L17.0005 15.9604C16.8827 16.0806 16.7227 16.1475 16.5573 16.1463L10.8384 16.1285C10.6643 16.1285 10.4989 16.0539 10.38 15.9236L4.10324 9.02025C3.71514 8.59373 3.5 8.03023 3.5 7.44558V5.80298C3.5 4.53122 4.5 3.5 5.73459 3.5ZM11.144 18.0154L16.2413 18.0309C16.5592 18.0321 16.8165 18.2982 16.8165 18.6245V21.4921C16.8165 21.8195 16.6305 22.1157 16.3408 22.2527L11.7137 24.4254C11.6056 24.4755 11.491 24.5 11.3765 24.5C11.224 24.5 11.0716 24.4555 10.9386 24.3675C10.7062 24.2138 10.5656 23.9488 10.5656 23.6648V18.6089C10.5656 18.2804 10.8251 18.0142 11.144 18.0154Z"
        fill="none"
      />
    </svg>
  );
};

export default memo(ConversionIcon);
