interface IProps {
  fillColor: string;
}

const SoundMuted = ({ fillColor }: IProps): JSX.Element => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7527 0.863732C14.0369 0.5796 14.4899 0.577934 14.7849 0.862895C15.0716 1.14952 15.0716 1.61248 14.7858 1.89828L1.24727 15.436C1.11271 15.5705 0.918814 15.6499 0.729932 15.6499C0.545226 15.6499 0.363031 15.5747 0.215936 15.4385C-0.0715601 15.1401 -0.0715601 14.6863 0.213433 14.4014L2.44492 12.1702H2.42235C1.30995 12.1702 0.5001 11.3847 0.349657 10.1646C0.181672 8.94458 0.215107 6.85539 0.349657 5.74397C0.507624 4.59076 1.36094 3.79771 2.42235 3.79771H3.86738L6.62536 1.54142C6.96058 1.2573 7.55395 0.989053 8.01279 0.981529C8.84853 0.981529 9.6174 1.56566 9.89324 2.5016C10.0019 2.8952 10.0445 3.28712 10.0779 3.66401L10.144 4.19799C10.1548 4.27989 10.1648 4.35845 10.1732 4.44201L13.7528 0.863708L13.7527 0.863732ZM9.37967 9.12211C9.49248 9.01182 9.74488 8.9324 9.85848 8.96169C10.1653 9.03938 10.2254 9.47975 10.2204 9.82577C10.2062 10.8302 10.1728 11.5288 10.1192 11.9608L10.0817 12.3169L10.081 12.3234C10.0453 12.6815 10.0083 13.0521 9.90198 13.4475C9.62369 14.3818 8.87732 14.9885 8.02903 14.9885C8.00148 14.9885 7.97385 14.9885 7.94542 14.9876C7.47663 14.9876 6.96845 14.7069 6.68013 14.462L5.65294 13.6673C5.26266 13.3773 5.378 12.9144 5.59697 12.6461C5.76047 12.4464 7.72579 10.6422 8.75803 9.69464C9.10809 9.37332 9.35085 9.15046 9.37967 9.12211Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SoundMuted;
