import api from 'utils/api';
import { AppThunk } from 'redux/store';

import { combineStoryAndTemplate } from 'utils/templateUtils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadStory } from '../amp-story/ampStorySlice';
import pick from 'lodash/pick';
import { replaceTemporaryMediaInStory } from '../media/mediaSlice';
import { storyUrls } from 'config/urls';
import { TemplateDetails, TemplateType } from 'redux/services/templates/templates';

interface ITemplatesSlice {
  isSaveTemplateModalOpen: boolean;
  templateType: TemplateType | null;
}

const initialState = {
  isSaveTemplateModalOpen: false,
} as ITemplatesSlice;

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    selectTemplateType: (state, action: PayloadAction<TemplateType | null>) => {
      state.templateType = action.payload;
    },
    toggleSaveTemplateModal: (state, action: PayloadAction<boolean>) => {
      state.isSaveTemplateModalOpen = action.payload;
    },
  },
});

export const { selectTemplateType, toggleSaveTemplateModal } = templatesSlice.actions;
export default templatesSlice.reducer;

export const applyTemplate = (template: TemplateDetails, selectedSlides?: number[]): AppThunk => async (
  dispatch,
  getState,
) => {
  dispatch(replaceTemporaryMediaInStory());

  const state = getState();
  const currentAmpStory = state.ampStory.present;
  const activeSlidePosition = state.ampStory.present.activeSlidePosition as number;

  if (!template) {
    return;
  }

  if (!selectedSlides || selectedSlides?.length === 0) {
    selectedSlides = [...Array(template.cuts?.length).keys()];
  }
  // Get only required fields from template
  const currentTemplate = pick(template, ['cuts', 'fonts', 'storyFonts', 'googleFonts', 'initialHeight']);
  const updatedAmpStory = combineStoryAndTemplate(
    currentAmpStory,
    currentTemplate,
    activeSlidePosition,
    selectedSlides,
  );

  await api.put(storyUrls.updateStoryConfig, {
    ampStory: updatedAmpStory,
  });

  const scale = Number(currentAmpStory.initialHeight) / Number(currentTemplate.initialHeight);
  const zoomPercent = state.helpers.zoomPercent;
  const scaleIndexes = updatedAmpStory?.cuts?.filter((cut) => cut?.isTemplateSlide).map((cut) => cut?.position);

  dispatch(
    loadStory(
      { storyId: currentAmpStory._id, activeSlidePosition, scale: zoomPercent, slideScaling: scale, scaleIndexes },
      false,
      false,
      false,
      true,
    ),
  );
};
