import React from 'react';
import styled, { css } from 'styled-components';

interface IEditorModalLabel {
  text: string;
  isActive?: boolean;
  onClick?: any;
}

const defaultProps = {
  isActive: false,
};

const Label = styled.h4<{ isActive: boolean | undefined }>`
  display: inline-block;
  letter-spacing: 0.01em;
  font-size: 14px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0 4px 0;
  cursor: pointer;
  color: #ababba;
  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--white);
    `};
`;

const EditorModalLabel: React.FC<IEditorModalLabel> = ({ text, isActive, onClick }) => {
  return (
    <Label isActive={isActive} onClick={onClick}>
      {text}
    </Label>
  );
};

EditorModalLabel.defaultProps = defaultProps;

export default EditorModalLabel;
