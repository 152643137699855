import ChevronDownIcon from '../icons/chevron-down';
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu';
import styled, { css } from 'styled-components';
import { memo } from 'react';
import { useAppSelector } from '../../hooks';
import { useCreateWorkspaceMutation, useWorkspacesListQuery } from 'redux/services/workspaces/workspaces';
import { useMemo } from 'react';
import { useSelectWorkspaceMutation } from 'redux/services/auth/auth';

const StyledLogo = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  backdrop-filter: blur(50px);
  border: 1px solid var(--shade-700-85);
  margin-right: 12px;
  flex: 0 0 40px;
`;

const StyledMenu = styled(Menu)`
  margin: 0;
  background: none;
  border: none;
  ~ div {
    .workspaces-menu {
      .rc-menu {
        background: var(--shade-700-85);
        border: 1px solid var(--white-10);
        box-shadow: 24px 32px 72px var(--black-18);
        backdrop-filter: blur(50px);
        border-radius: 6px;
        padding: 8px;
        width: calc(100% - 94px);
        max-height: 400px;
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          width: 3px;
          border-radius: 12px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 20px;
          transition: background 0.12s ease;
        }
        &:hover {
          scrollbar-width: thin;
          scrollbar-color: var(--shade-300-85) transparent;
        }
        &:hover::-webkit-scrollbar {
          width: 3px;
          border-radius: 2px;
        }

        &:hover::-webkit-scrollbar-thumb {
          background: var(--shade-300-85);
        }
      }
    }
  }
`;

const StyledSubMenu = styled(SubMenu)`
  &&& {
    border: none;
    border-radius: 6px;
    transition: background-color 225ms ease;
    width: 100%;
    &.rc-menu-submenu-selected {
      background-color: unset;
    }
    &.rc-menu-submenu-active {
      background-color: var(--shade-500-85);
      backdrop-filter: blur(50px);
    }
    &.rc-menu-submenu-open {
      background-color: var(--shade-500-85);
      backdrop-filter: blur(50px);
      // TODO ask Gaetan
      /* svg,
      svg > * {
        fill: var(--primary);
      } */
    }
    > div {
      background: none;
      padding: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&&& {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    background: none;
    padding: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: background-color 200ms ease, color 200ms ease;
    &.rc-menu-item-active {
      background-color: var(--primary);
      h2 {
        color: var(--shade-900);
      }
      h4 {
        color: var(--shade-700);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Container = styled.div`
  &&& {
    cursor: pointer;
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    padding: 6px 10px;
    border-radius: 6px;
  }
`;

const InnerContainer = styled(Container)`
  &&& {
    padding: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Members = styled.h4`
  margin: 0;
  padding: 0;
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  transition: color 225ms ease;
`;

const Title = styled.h2<{ secondary?: boolean }>`
  margin: 0;
  padding: 0 20px 0 0;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${({ secondary }) => (secondary ? 'var(--shade-100)' : '#ffffff')};
  transition: color 225ms ease;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MenuTitle = styled(Title)`
  font-size: 16px;
  line-height: 22px;
`;

const LogoPlaceholder = styled.div<{ secondary?: boolean }>`
  width: 40px;
  height: 40px;
  border: 1px solid var(--shade-700-85);
  background: var(--shade-100);
  backdrop-filter: blur(50px);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-right: 12px;
  flex: 0 0 40px;
  ${({ secondary }) =>
    secondary &&
    css`
      background: var(--shade-500-85);
      border: 1px solid var(--shade-500-85);
      position: relative;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      span {
        color: var(--shade-100);
        font-size: 46px;
        line-height: 24px;
        font-weight: 100;
        padding-top: 4px;
        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: var(--shade-100);
          transition: background-color 225ms ease;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 8px;
        }
        &:before {
          height: 24px;
          width: 1.5px;
        }
        &:after {
          height: 1.5px;
          width: 24px;
        }
      }
    `};
`;

const DropdownIcon = styled(ChevronDownIcon)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%) scale(1.4);
  color: var(--shade-100);
`;

const WorkspacesMenu = () => {
  const { data: workspaces } = useWorkspacesListQuery();
  const [createWorkspace] = useCreateWorkspaceMutation();
  const [selectWorkspace] = useSelectWorkspaceMutation();

  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const selectedWorkspace = useMemo(() => workspaces?.find(({ _id }) => _id === selectedWorkspaceId), [
    selectedWorkspaceId,
    workspaces,
  ]);

  const onSelectWorkspace = (id: string) => () => selectWorkspace(id);
  const onCreateWorkspace = async () => {
    try {
      const { _id } = await createWorkspace({}).unwrap();
      selectWorkspace(_id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <StyledMenu
      triggerSubMenuAction="click"
      mode="horizontal"
      openAnimation="slide-up"
      className="workspaces-selector-root"
      getPopupContainer={(test) => {
        const parent = test.closest('.workspaces-selector-root')?.parentElement ?? document.body;
        return parent;
      }}
    >
      <StyledSubMenu
        popupClassName={'workspaces-menu'}
        popupOffset={[0, 10]}
        title={
          <Container>
            {selectedWorkspace?.logoUrl ? (
              <StyledLogo src={selectedWorkspace?.logoUrl} />
            ) : (
              <LogoPlaceholder>{selectedWorkspace?.title?.[0]?.toUpperCase()}</LogoPlaceholder>
            )}
            <Title>{selectedWorkspace?.title}</Title>
            <DropdownIcon />
          </Container>
        }
        key="1"
      >
        {workspaces
          ?.filter(({ _id }) => _id !== selectedWorkspaceId)
          ?.map((w) => (
            <StyledMenuItem onClick={onSelectWorkspace(w._id)} key={w._id}>
              <InnerContainer>
                {w?.logoUrl ? (
                  <StyledLogo src={w?.logoUrl} />
                ) : (
                  <LogoPlaceholder>{w?.title?.[0]?.toUpperCase()}</LogoPlaceholder>
                )}
                <Content>
                  <MenuTitle>{w?.title}</MenuTitle>
                  <Members>
                    {w?.users?.length} member{w?.users?.length > 1 ? 's' : ''}
                  </Members>
                </Content>
              </InnerContainer>
            </StyledMenuItem>
          ))}
        <StyledMenuItem onClick={onCreateWorkspace}>
          <InnerContainer>
            <LogoPlaceholder secondary>
              <span></span>
            </LogoPlaceholder>
            <Content>
              <MenuTitle secondary>Create Workspace</MenuTitle>
            </Content>
          </InnerContainer>
        </StyledMenuItem>
      </StyledSubMenu>
    </StyledMenu>
  );
};

export default memo(WorkspacesMenu);
