import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ILoaderWrapper {
  children: ReactNode;
}

const StyledLoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LoaderWrapper = ({ children }: ILoaderWrapper): JSX.Element => {
  return <StyledLoaderWrapper>{children}</StyledLoaderWrapper>;
};

export default LoaderWrapper;
