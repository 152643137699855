import styled from 'styled-components';

interface IProfileUsername {
  className?: string;
  username?: string;
}

const UserName = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: var(--white);
  margin: 0;
`;

const ProfileUsername = ({ className, username }: IProfileUsername): JSX.Element => {
  return <UserName className={className}>{username}</UserName>;
};

export default ProfileUsername;
