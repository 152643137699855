interface IEditorSidebarFilter {
  src: string;
  title: string;
  isSelected?: boolean;
  onSelect: () => void;
  figureCss?: string;
  beforeCss?: string;
}

import styled from 'styled-components';

const Container = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  padding: 4px 5px;

  background: transparent;
  color: var(--white);
  cursor: pointer;
  transition: background 225ms ease, color 225ms ease;

  &:hover {
    background: var(--shade-500-85);
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background: var(--primary-20);
    color: var(--primary);
        &:hover {
            background: var(--primary-20);
        }
    `}
`;

const Title = styled.p`
  color: inherit;
  text-align: center;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 4px 0 0 0;
`;

const ImageContainer = styled.figure<{ figureCss?: string; beforeCss?: string }>`
  margin: 0;
  padding: 0;
  width: 58px;
  height: 58px;
  position: relative;

  ${({ figureCss }) => figureCss};

  ${({ beforeCss }) =>
    beforeCss &&
    `&:before {
            ${beforeCss};
        }
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 6px;
  backdrop-filter: blur(25px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const EditorSiderbarFilter = ({
  src,
  title,
  isSelected,
  figureCss,
  beforeCss,
  onSelect,
}: IEditorSidebarFilter): JSX.Element => {
  return (
    <Container isSelected={isSelected} onClick={onSelect}>
      <ImageContainer figureCss={figureCss} beforeCss={beforeCss}>
        <Image src={src} />
      </ImageContainer>
      <Title>{title}</Title>
    </Container>
  );
};

export default EditorSiderbarFilter;
