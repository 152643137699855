import { memo } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/buttons';
import { useToggle } from '../../hooks';
import InviteMembersModal from '../invite-members-modal/invite-members-modal';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--white);
  margin: 0;
`;

interface IWorkspaceMembersHeader {
  length?: number;
  isDisabled?: boolean;
}

const WorkspaceMembersHeader = ({ length, isDisabled }: IWorkspaceMembersHeader): JSX.Element => {
  const { isShown: isInviteModalShown, toggle: toggleInviteModal, setHide: setHideInviteModal } = useToggle(false);
  const onClickHandler = () => {
    if (!isDisabled) {
      toggleInviteModal();
    }
  };
  return (
    <HeaderWrapper>
      <StyledTitle>Members ({length})</StyledTitle>
      <PrimaryButton text="Invite Member" onClick={onClickHandler} isDisabled={isDisabled} />
      <InviteMembersModal isOpen={isInviteModalShown} onCancel={setHideInviteModal} />
    </HeaderWrapper>
  );
};

export default memo(WorkspaceMembersHeader);
