import { memo } from 'react';

const StepForward = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.7001 11.3L16.7 7.30001C16.3 6.90001 15.7 6.90001 15.3 7.30001C14.9 7.70001 14.9 8.30001 15.3 8.70001L17.6 11H8.00001C7.40001 11 7.00001 11.4 7.00001 12C7.00001 12.6 7.40001 13 8.00001 13H17.6L15.3 15.3C15.1 15.5 15 15.7 15 16C15 16.6 15.4 17 16 17C16.3 17 16.5 16.9 16.7 16.7L20.7001 12.7C21.1001 12.3 21.1001 11.7 20.7001 11.3ZM3 6C2.4 6 2 6.4 2 7V17C2 17.6 2.4 18 3 18C3.6 18 4.00001 17.6 4.00001 17V7C4.00001 6.4 3.6 6 3 6Z"
      fill="#ABABBA"
    />
  </svg>
);

export default memo(StepForward);
