import { memo } from 'react';

interface IPublish {
  className?: string;
}

const Publish = ({ className }: IPublish): JSX.Element => {
  return (
    <svg width="14" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        d="M3.6665 3.00016C3.6665 3.32233 3.92767 3.5835 4.24984 3.5835L7.74984 3.5835C8.072 3.5835 8.33317 3.32233 8.33317 3.00016C8.33317 2.678 8.072 2.41683 7.74984 2.41683L4.24984 2.41683C3.92767 2.41683 3.6665 2.678 3.6665 3.00016ZM4.83317 4.75016H3.08317C2.11667 4.75016 1.33317 3.96666 1.33317 3.00016C1.33317 2.03367 2.11667 1.25016 3.08317 1.25016L4.83317 1.25016C5.15534 1.25016 5.4165 0.988996 5.4165 0.666829C5.4165 0.344663 5.15534 0.0834961 4.83317 0.0834961L3.08317 0.0834961C1.47234 0.0834962 0.166504 1.38933 0.166504 3.00016C0.166504 4.61099 1.47234 5.91683 3.08317 5.91683H4.83317C5.15534 5.91683 5.4165 5.65566 5.4165 5.3335C5.4165 5.01133 5.15534 4.75016 4.83317 4.75016ZM8.9165 0.0834961L7.1665 0.0834961C6.84434 0.0834961 6.58317 0.344663 6.58317 0.666829C6.58317 0.988996 6.84434 1.25016 7.1665 1.25016L8.9165 1.25016C9.883 1.25016 10.6665 2.03367 10.6665 3.00016C10.6665 3.96666 9.883 4.75016 8.9165 4.75016L7.1665 4.75016C6.84434 4.75016 6.58317 5.01133 6.58317 5.3335C6.58317 5.65566 6.84434 5.91683 7.1665 5.91683L8.9165 5.91683C10.5273 5.91683 11.8332 4.61099 11.8332 3.00016C11.8332 1.38933 10.5273 0.0834961 8.9165 0.0834961Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default memo(Publish);
