import { useCallback } from 'react';
import { BottomBarButtonWrapper } from '../shared';
import { IconButton } from '../../buttons';
import { Delete } from '../../icons';
import { removeSlide } from '../../../redux/features/amp-story/ampStorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { stopPropagation } from '../../../utils/common';

const EditorSlideDelete = (): JSX.Element => {
  const dispatch = useDispatch();
  const activeSlidePosition = useSelector((state: RootState) => state.ampStory.present.activeSlidePosition);

  const onDeleteSlide = useCallback(() => {
    dispatch(removeSlide(activeSlidePosition || 0));
  }, [dispatch, activeSlidePosition]);

  return (
    <BottomBarButtonWrapper onClick={stopPropagation}>
      <IconButton onClick={onDeleteSlide}>
        <Delete />
      </IconButton>
    </BottomBarButtonWrapper>
  );
};

export default EditorSlideDelete;
