import Section from './../layouts/shared/section-title';
import { EmptyWidgetList, WidgetItem } from './shared/elements';
import { memo, useCallback, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SliderArrowButton } from '../stories/shared/elements';
import { SliderArrowButtonsWrapper, StoriesWrapper, SwiperWrapper } from '../stories/shared/structure';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import type { Swiper as SwiperType } from 'swiper/types';
import { useAppSelector } from '../../hooks';
import {
  useDeleteWidgetMutation,
  useDuplicateWidgetMutation,
  usePrefetch,
  useWidgetsListQuery,
} from 'redux/services/widgets/widgets';

const params = {
  spaceBetween: 24,
  slidesPerView: 'auto' as const,
  freeMode: true,
};

const MyWidgetsList = (): JSX.Element => {
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const { data: paginatedWidgets, isSuccess, isLoading } = useWidgetsListQuery(selectedWorkspaceId ?? skipToken);
  const [deleteWidget] = useDeleteWidgetMutation();
  const [duplicateWidget] = useDuplicateWidgetMutation();
  const prefetchWidget = usePrefetch('getWidget');

  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [_, setActiveIndex] = useState(0);

  const goNextSlide = useCallback(() => swiper?.slideNext(), [swiper]);
  const goPrevSlide = useCallback(() => swiper?.slidePrev(), [swiper]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key;
      if (key === 'ArrowRight') {
        goNextSlide();
      }
      if (key == 'ArrowLeft') {
        goPrevSlide();
      }
    },
    [goNextSlide, goPrevSlide],
  );

  // We need this function to re-render the react component and reflect the active index
  const handleSlideChange = useCallback((swiperRef: SwiperType) => {
    setActiveIndex(swiperRef.activeIndex);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <StoriesWrapper hiddenOverflow={false}>
      {paginatedWidgets?.data?.length === 0 ? (
        <EmptyWidgetList />
      ) : (
        <>
          {isSuccess && !isLoading && (
            <>
              <Section.Wrapper>
                <Section.Title>
                  <Section.Text>All Carousels</Section.Text>
                </Section.Title>

                <SliderArrowButtonsWrapper>
                  <SliderArrowButton.Left isDisabled={swiper?.isBeginning} onClick={goPrevSlide} />
                  <SliderArrowButton.Right isDisabled={swiper?.isEnd} onClick={goNextSlide} />
                </SliderArrowButtonsWrapper>
              </Section.Wrapper>
              <SwiperWrapper>
                <Swiper className="story-container" {...params} onSwiper={setSwiper} onSlideChange={handleSlideChange}>
                  {paginatedWidgets?.data?.map((widget) => {
                    return (
                      <SwiperSlide key={widget.key} className={'swiper-slide-styles'}>
                        <WidgetItem
                          widget={widget}
                          deleteWidget={deleteWidget}
                          duplicateWidget={duplicateWidget}
                          prefetchWidget={prefetchWidget}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </SwiperWrapper>
            </>
          )}
        </>
      )}
    </StoriesWrapper>
  );
};

export default memo(MyWidgetsList);
