import ColorPicker from '../../../color-picker';
import EditorSidebarColumn from '../../../editor-sidebar/shared/structure/editor-sidebar-column';
import EditorSidebarFontSelect from '../../../../components/editor-sidebar/editor-sidebar-settings/editor-sidebar-text-settings/editor-sidebar-font-select';
import EditorSidebarHalfColumn from '../../../editor-sidebar/shared/structure/editor-sidebar-half-column';
import EditorSidebarLabel from '../../../editor-sidebar/shared/elements/editor-sidebar-label';
import EditorSidebarRowWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-row-wrapper';
import EditorSidebarSectionTitle from '../../../editor-sidebar/shared/elements/editor-sidebar-section-title';
import EditorSidebarSectionTitleWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-section-title-wrapper';
import EditorSidebarSectionWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-section-wrapper';
import EditorSidebarValuesWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-values-wrapper';
import Select from '../../../shared/select';
import ToggleSwitch from '../../../shared/toggle-switch';
import {
  defaultTextFontColor,
  FONT_TYPE,
  gradientDefault,
  textSpacings,
  TEXT_BLOCK_STYLES,
} from '../../../../config/constants';
import { GoogleFont } from 'redux/services/fonts/interface';
import { IColorType } from '../../../../interfaces/colors';
import { IFont } from '../../../../interfaces/fonts';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector, useDebounce, useDidUpdateEffect } from 'hooks';
import { useGoogleFontsListQuery, useWorkspaceFontsListQuery } from 'redux/services/fonts/fonts';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

import {
  getDefaultFontWeight,
  getFontOptionName,
  getFontWeights,
  loadFontFamily,
} from '../../../../utils/textEditorUtils';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';
import EditorSidebarButtonWrapper from 'components/editor-sidebar/shared/structure/editor-sidebar-button-wrapper';
import EditorSidebarButtonWithIcon from 'components/editor-sidebar/shared/elements/editor-sidebar-button-with-icon';
import { CenterAlign, JustifyAlign, LeftAlign, RightAlign } from 'components/icons';

const fontSizes = [...Array(100).keys()].map((i) => ({
  name: i + 1,
  value: i + 1,
}));

const textSpacingsOptions = textSpacings.map((textSpacing: number) => ({
  name: textSpacing,
  value: textSpacing,
}));

const descriptionPositionOptions = [
  { name: 'Outside', value: 1 },
  { name: 'Inside', value: 0 },
];

const WidgetEditorSidebarTextDescriptionSettings = (): JSX.Element => {
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);

  // Fetch workspace specific fonts;
  const { data: workspaceFonts } = useWorkspaceFontsListQuery(selectedWorkspaceId ?? skipToken);
  const { data: googleFonts } = useGoogleFontsListQuery();
  const storyFonts = useAppSelector((state) => state.ampStory.present.fonts);

  const { widget, setWidget } = useContext(WidgetContext) as WidgetContextType;
  const { setChangesCount } = useContext(ChangesContext) as ChangesContextType;

  const {
    fontsize: descriptionFontSize,
    lineheight: descriptionLineHeight,
    align: descriptionAlign,
    gradientcolor: defaultGradientColor,
    fontcolor: defaultDescriptionFontColor,
    titlebelow: descriptionPosition,
    hasdescriptionshadow: hasDescriptionShadow,
    fontfamily: descriptionFontFamily,
    descriptionenabled: isDescrptionEnabled,
  } = widget.editorDetails.style;

  const [searchFont, setSearchFont] = useState('');
  const [descriptionFontColor, setDescriptionFontColor] = useState(defaultDescriptionFontColor);
  const [gradientColor, setGradientColor] = useState(defaultGradientColor ?? 'rgba(0,0,0,1)');
  const debouncedFontColor = useDebounce<string>(descriptionFontColor, 50);
  const debouncedGradientColor = useDebounce<string>(gradientColor, 50);

  const filteredFonts = useMemo(() => {
    const filteredUserFonts = workspaceFonts
      ?.filter((font) => font.family.toLowerCase().includes(searchFont.toLowerCase()))
      ?.map((font) => ({ name: font.family, value: getFontOptionName(font.family, false), fontData: font }));

    const filteredGoogleFonts = googleFonts
      ?.filter((font) => font.family.toLowerCase().includes(searchFont.toLowerCase()))
      ?.map((font) => ({ name: font.family, value: getFontOptionName(font.family, false), fontData: font }));

    return {
      user: {
        title: 'Brand fonts',
        options: filteredUserFonts,
      },
      google: {
        options: filteredGoogleFonts,
      },
    };
  }, [searchFont, googleFonts, workspaceFonts]);

  const onFontSizeChange = useCallback(
    (option: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            fontsize: option,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onSpacingChange = useCallback(
    (option: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            lineheight: option,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onAlignChange = useCallback(
    (align: string) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            align,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onFontColorChange = useCallback((_: unknown, value: any) => {
    setDescriptionFontColor(value);
  }, []);

  const onGradientColorChange = useCallback((_: unknown, value: any) => {
    setGradientColor(value);
  }, []);

  const gradientColorReset = useCallback(() => setGradientColor('rgba(0, 0, 0, 1)'), []);
  const onFontColorReset = useCallback(() => setDescriptionFontColor('rgba(255, 255, 255, 1)'), []);

  const onHasDescriptionShadowChange = useCallback(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          hasdescriptionshadow: prev.editorDetails.style.hasdescriptionshadow ? 0 : 1,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [setWidget, setChangesCount]);

  const onDescriptionPositionChange = useCallback(
    (value: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          titlebelow: value,
          style: {
            ...prev.editorDetails.style,
            titlebelow: value,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onFontFamilyChange = useCallback(
    (option: string) => {
      const fontFamily = option.replaceAll('_', ' ');
      const font = [...(workspaceFonts ?? []), ...(googleFonts ?? [])].find(
        (storyFont) => storyFont.family === fontFamily,
      );
      const existingFont: IFont = storyFonts.find((storyFont: IFont) => storyFont.family === font?.family);

      if (font) {
        if (!existingFont) {
          loadFontFamily(font as any);
        }
        const fontWeight = getDefaultFontWeight(getFontWeights(font.weight)).value;
        let url = '';

        if (font.fontType === FONT_TYPE.USER_FONT) {
          url = font?.paths?.[fontWeight] ?? '';
        } else if (font.fontType === FONT_TYPE.GOOGLE_FONT) {
          url = (font as GoogleFont).file ?? '';
        }

        setWidget((prev) => ({
          ...prev,
          editorDetails: {
            ...prev.editorDetails,
            style: {
              ...prev.editorDetails.style,
              fontfamily: option.replaceAll('_', ' '),
              fontfamilyurl: url,
            },
          },
        }));
        setChangesCount((prev) => prev + 1);
      }
    },
    [setWidget, setChangesCount, workspaceFonts, googleFonts, storyFonts],
  );

  const onToggleDescription = () => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          descriptionenabled: prev.editorDetails.style.descriptionenabled ? 0 : 1,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  };

  useDidUpdateEffect(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          fontcolor: debouncedFontColor,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [debouncedFontColor, setWidget, setChangesCount]);

  useDidUpdateEffect(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          gradientcolor: debouncedGradientColor,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [debouncedGradientColor, setWidget, setChangesCount]);

  return (
    <EditorSidebarSectionWrapper>
      <EditorSidebarSectionTitleWrapper>
        <EditorSidebarSectionTitle text={'Description'} />
        <ToggleSwitch isOn={isDescrptionEnabled} onClick={onToggleDescription} />
      </EditorSidebarSectionTitleWrapper>
      {isDescrptionEnabled ? (
        <>
          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Font'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarColumn>
                <EditorSidebarFontSelect
                  widgetScrollIdentifier={'widget-description'}
                  isDisabled={false}
                  searchValue={searchFont}
                  selectOption={descriptionFontFamily ? descriptionFontFamily.replaceAll('_', ' ') : 'Heebo'}
                  optionsObject={filteredFonts as any}
                  onSearchChange={setSearchFont}
                  onSelect={onFontFamilyChange}
                />
              </EditorSidebarColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Size'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <Select
                  isDisabled={false}
                  selectOption={descriptionFontSize || 24}
                  options={fontSizes}
                  onSelect={onFontSizeChange}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Spacing'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <Select
                  isDisabled={false}
                  selectOption={descriptionLineHeight || 1.5}
                  options={textSpacingsOptions}
                  onSelect={onSpacingChange}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Align'} />

            <EditorSidebarValuesWrapper justifyContent={'flex-end'}>
              <EditorSidebarButtonWrapper>
                <EditorSidebarButtonWithIcon
                  isDisabled={false}
                  isActive={descriptionAlign === TEXT_BLOCK_STYLES.ALIGN_LEFT}
                  onClick={() => onAlignChange(TEXT_BLOCK_STYLES.ALIGN_LEFT)}
                >
                  <LeftAlign />
                </EditorSidebarButtonWithIcon>
              </EditorSidebarButtonWrapper>

              <EditorSidebarButtonWrapper>
                <EditorSidebarButtonWithIcon
                  isDisabled={false}
                  isActive={(descriptionAlign ?? 'center') === TEXT_BLOCK_STYLES.ALIGN_CENTER}
                  onClick={() => onAlignChange(TEXT_BLOCK_STYLES.ALIGN_CENTER)}
                >
                  <CenterAlign />
                </EditorSidebarButtonWithIcon>
              </EditorSidebarButtonWrapper>

              <EditorSidebarButtonWrapper>
                <EditorSidebarButtonWithIcon
                  isDisabled={false}
                  isActive={descriptionAlign === TEXT_BLOCK_STYLES.ALIGN_RIGHT}
                  onClick={() => onAlignChange(TEXT_BLOCK_STYLES.ALIGN_RIGHT)}
                >
                  <RightAlign />
                </EditorSidebarButtonWithIcon>
              </EditorSidebarButtonWrapper>

              <EditorSidebarButtonWrapper>
                <EditorSidebarButtonWithIcon
                  isDisabled={false}
                  isActive={descriptionAlign === TEXT_BLOCK_STYLES.ALIGN_JUSTIFY}
                  onClick={() => onAlignChange(TEXT_BLOCK_STYLES.ALIGN_JUSTIFY)}
                >
                  <JustifyAlign />
                </EditorSidebarButtonWithIcon>
              </EditorSidebarButtonWrapper>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Color'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <ColorPicker
                  colorType={IColorType.FillColor}
                  isDisabled={false}
                  defaultLeftColor={defaultTextFontColor}
                  leftColor={descriptionFontColor}
                  handleColorChange={onFontColorChange}
                  // handleColorReset={onFontColorReset}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Position'} />
            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <Select
                  isDisabled={false}
                  selectOption={descriptionPosition ? 'Outside' : 'Inside'}
                  options={descriptionPositionOptions}
                  onSelect={onDescriptionPositionChange}
                  dropdownWidth={'90px'}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Shadow'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <ToggleSwitch isOn={hasDescriptionShadow} onClick={onHasDescriptionShadowChange} />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          {descriptionPosition === 0 && (
            <EditorSidebarRowWrapper>
              <EditorSidebarLabel text={'Gradient color'} />

              <EditorSidebarValuesWrapper>
                <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                  <ColorPicker
                    colorType={IColorType.FillColor}
                    isDisabled={false}
                    defaultLeftColor={gradientDefault}
                    leftColor={gradientColor}
                    handleColorChange={onGradientColorChange}
                    // handleColorReset={gradientColorReset}
                    hideAlpha
                  />
                </EditorSidebarHalfColumn>
              </EditorSidebarValuesWrapper>
            </EditorSidebarRowWrapper>
          )}
        </>
      ) : (
        <></>
      )}
    </EditorSidebarSectionWrapper>
  );
};

export default memo(WidgetEditorSidebarTextDescriptionSettings);
