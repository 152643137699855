import { useState } from 'react';

type UseMoveableTooltip = () => {
  tooltip: { show: boolean; text: string };
  onMouseEnter: (title?: string) => () => void;
  onMouseLeave: (title?: string) => () => void;
};

const useMoveableTooltip: UseMoveableTooltip = () => {
  const [tooltip, setTooltip] = useState({
    show: false,
    text: '',
  });

  const onMouseEnter = (title?: string) => () => {
    if (title) {
      setTooltip({
        show: true,
        text: title,
      });
    }
  };

  const onMouseLeave = (title?: string) => () => {
    if (title) {
      setTooltip((t) => ({
        ...t,
        show: false,
      }));
    }
  };

  return {
    tooltip,
    onMouseEnter,
    onMouseLeave,
  };
};

export default useMoveableTooltip;
