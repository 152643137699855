import { AxiosResponse } from 'axios';
import uniq from 'lodash/uniq';
import { batch } from 'react-redux';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { imageUrls, mediaUrls, uploadedMediaUrls, videoUrls } from '../../../config/urls';
import { ICut } from '../../../interfaces/cut';
import { layerTypes } from '../../../interfaces/layer-types';
import api from '../../../utils/api';
import {
  concatMediaArrays,
  getPendingMediaContent,
  getTemporaryVideoUrls,
  onGetVideoDuration,
} from '../../../utils/mediaUtils';
import { AppThunk } from '../../store';
import { setActiveLayerPropsInStore, updateStoryConfig } from '../amp-story/ampStorySlice';
import { IUploadedMedia } from '../../../interfaces/media/common';
import { IVideoProcessing } from '../../../interfaces/video-processing';
import {
  setOriginalVideo,
  setVideoProcessing,
  toggleVideoProcessingLoader,
} from '../video-processing/videoProcessingSlice';
import { addPendingMedia, toggleIsMediaUploading } from '../editor/helpers/helpersSlice';
import generateId from '../../../utils/generateId';
import { isMediaLayer } from '../../../utils/editorUtils';
import { ILayer } from '../../../interfaces/layer';

interface IUploadedMediaListResponse {
  media: IUploadedMedia[];
  hasNext: boolean;
  totalCount: number;
}

interface IUploadedMediaResponse {
  videoProcessing: IVideoProcessing;
  video: IUploadedMedia;
}
interface IMediaState {
  allMediaFiles: string[];
  uploadedMedia: Partial<IUploadedMedia>[];
  offset: number;
  limit: number;
  isDeleteModalOpen: boolean;
  showSplitVideoModal: boolean;
  artboardsCount: number;
  videoDuration: number;
  shouldDiscardLayout: boolean;
  videoFile: null | File;
}

const initialState = {
  allMediaFiles: [],
  uploadedMedia: [],
  offset: 0,
  limit: 20,
  isDeleteModalOpen: false,
  showSplitVideoModal: false,
  artboardsCount: 1,
  videoDuration: -1,
  shouldDiscardLayout: false,
  videoFile: null,
} as IMediaState;

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    addMediaName(state, action: PayloadAction<string>) {
      if (!state.allMediaFiles.includes(action.payload)) {
        state.allMediaFiles.push(action.payload);
      }
    },
    addMedia(state, action: PayloadAction<IUploadedMedia[]>) {
      state.uploadedMedia = concatMediaArrays(state.uploadedMedia, action.payload);
    },
    addUploadedMedia(state, action: PayloadAction<Partial<IUploadedMedia>>) {
      state.uploadedMedia = [action.payload].concat(state.uploadedMedia);
    },
    clearLoadedMedia(state) {
      state.uploadedMedia = [];
    },
    setOffset(state) {
      state.offset = state.offset + state.limit;
    },
    clearOffset(state) {
      state.offset = 0;
    },
    saveAllMediaFiles(state, action: PayloadAction<string[]>) {
      state.allMediaFiles = action.payload;
    },
    filterDeletedMedia(state, action) {
      state.uploadedMedia = state.uploadedMedia.filter((media) => media.id !== action.payload);
    },
    replaceWithUploadedImage(state, action) {
      state.uploadedMedia[0].id = action.payload.id;
      state.uploadedMedia[0].originalName = action.payload.originalName;
    },
    toggleDeleteModal(state, action: PayloadAction<boolean>) {
      state.isDeleteModalOpen = action.payload;
    },
    toggleSplitVideoModal(state) {
      state.showSplitVideoModal = !state.showSplitVideoModal;
    },
    setArtboardsCount(state, action: PayloadAction<number>) {
      state.artboardsCount = action.payload;
    },
    setShouldDiscardLayout(state, action: PayloadAction<boolean>) {
      state.shouldDiscardLayout = action.payload;
    },
    setVideoDuration(state, action: PayloadAction<number>) {
      state.videoDuration = action.payload;
    },
    setVideoFile(state, action: PayloadAction<File | null>) {
      state.videoFile = action.payload;
    },
  },
});

export const {
  addMedia,
  addMediaName,
  saveAllMediaFiles,
  addUploadedMedia,
  setOffset,
  clearLoadedMedia,
  clearOffset,
  filterDeletedMedia,
  replaceWithUploadedImage,
  toggleDeleteModal,
  toggleSplitVideoModal,
  setShouldDiscardLayout,
  setArtboardsCount,
  setVideoDuration,
  setVideoFile,
} = mediaSlice.actions;
export default mediaSlice.reducer;

export const deleteMedia = (id: string): AppThunk => async (dispatch) => {
  try {
    await api.delete(mediaUrls.deleteMedia(id));
    dispatch(filterDeletedMedia(id));
  } catch (err) {
    console.error(err);
  }
};
export const getAllMediaFromStory = (cuts: ICut[], saveToAllMedias: boolean): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const allMediaFiles = state.media.allMediaFiles;

  const fileNames: string[] = [];
  cuts.forEach((cut) => {
    cut.layers.forEach((layer: any) => {
      const currentLayerType = layer.type === layerTypes.GIFS ? layerTypes.IMAGE : layer.type;
      if (
        [layerTypes.IMAGE, layerTypes.VIDEO, layerTypes.GIFS].includes(layer.type) &&
        layer.content &&
        layer.content[currentLayerType] &&
        layer.content[currentLayerType].name
      ) {
        if (layer.content.thumbnail && typeof layer.content.thumbnail === 'string') {
          fileNames.push(layer.content.thumbnail.split(/[/\\]/).pop());
        }
      }
    });
    fileNames.push(`${cut.position}.image.png`);
  });
  if (saveToAllMedias) {
    dispatch(saveAllMediaFiles(uniq(Array.from(allMediaFiles.concat(fileNames)))));
  }
  return uniq(Array.from(fileNames));
};

export const uploadImage = (fileToBeUploaded: any): AppThunk => async (dispatch, getState) => {
  if (!fileToBeUploaded) {
    return;
  }

  const state = getState();
  const user = state.auth.user;
  if (!user) {
    return;
  }
  const storyId = state.ampStory.present._id;
  let uploadImageResponse: AxiosResponse<IUploadedMedia>;

  try {
    const formData = new FormData();
    formData.append('file', fileToBeUploaded);

    dispatch(toggleIsMediaUploading(true));
    const id = generateId();
    const temporaryImage = {
      id,
      temporaryId: id,
      url: URL.createObjectURL(fileToBeUploaded),
      originalName: fileToBeUploaded.name,
    };

    dispatch(addUploadedMedia(temporaryImage));

    uploadImageResponse = await api.post(imageUrls.uploadImage(user?.selectedWorkspaceId, user?._id), formData);
    const image = { ...uploadImageResponse?.data, temporaryId: temporaryImage.id };
    const imageTitle = `${uploadImageResponse.data.name}` || '';
    const pendingMedia = getPendingMediaContent(layerTypes.IMAGE, image);

    batch(() => {
      dispatch(replaceWithUploadedImage(image));
      dispatch(addMediaName(imageTitle || ''));
      dispatch(addPendingMedia({ storyId, temporaryId: temporaryImage.id, pendingMedia }));
      dispatch(toggleIsMediaUploading(false));
    });
    dispatch(updateStoryConfig());
  } catch (e) {
    // TODO
    console.error(e);
    return;
  }
};

export const uploadVideo = (fileToBeUploaded: File): AppThunk => async (dispatch, getState) => {
  if (!fileToBeUploaded) {
    return;
  }
  const state = getState();
  const user = state.auth.user;
  if (!user) {
    return;
  }
  const storyId = state.ampStory.present._id;
  let uploadedVideoResponse: AxiosResponse<IUploadedMediaResponse>;
  try {
    dispatch(toggleIsMediaUploading(true));

    const temporaryVideo = await getTemporaryVideoUrls(fileToBeUploaded);
    dispatch(setOriginalVideo(temporaryVideo));

    onGetVideoDuration({
      mediaUrl: temporaryVideo.url,
      callback: async (duration) => {
        const formData = new FormData();
        formData.append('file', fileToBeUploaded);
        formData.append('duration', duration.toString());

        uploadedVideoResponse = await api.post(videoUrls.uploadVideo(user.selectedWorkspaceId, user._id), formData);
        const originalVideo = { ...uploadedVideoResponse?.data?.video, temporaryId: temporaryVideo.id };
        const pendingMedia = getPendingMediaContent(layerTypes.VIDEO, originalVideo);

        batch(() => {
          dispatch(toggleVideoProcessingLoader(true));
          dispatch(setOriginalVideo(originalVideo));
          dispatch(toggleIsMediaUploading(false));
          dispatch(setVideoProcessing(uploadedVideoResponse?.data?.videoProcessing));
          dispatch(addPendingMedia({ storyId, temporaryId: temporaryVideo.id, pendingMedia }));
        });
      },
    });
  } catch (err) {
    console.error(err);
    return;
  }
};

export const replaceTemporaryMediaInStory = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const pendingMediaQueue = state.helpers.pendingMediaQueue;
  const storyId = state.ampStory.present._id as string;

  if (Object.keys(pendingMediaQueue).length > 0) {
    batch(() => {
      state.ampStory.present.cuts.forEach((cut: ICut, cutIndex: number) => {
        cut.layers.forEach((layer: ILayer, layerIndex) => {
          const isMediaLayerType = isMediaLayer(layer?.type);
          const hasBlobIcon = (layer?.settings?.linkSettings?.icon ?? '')?.includes('blob');
          const hasUploadedMedia = state.media.uploadedMedia.find((m) => m.url === layer?.settings?.linkSettings?.icon);

          if (hasBlobIcon && hasUploadedMedia) {
            const pendingIcon = pendingMediaQueue?.[storyId]?.[hasUploadedMedia?.temporaryId ?? -1];
            dispatch(
              setActiveLayerPropsInStore({
                field: 'settings.linkSettings.icon',
                value: pendingIcon.content.image?.url,
                activeSlidePosition: cutIndex,
                activeLayerPosition: layerIndex,
              }),
            );
            return;
          }

          const pendingMedia = pendingMediaQueue?.[storyId]?.[layer?.temporaryId ?? -1];

          if (isMediaLayerType && pendingMedia) {
            const content = {
              ...layer?.content,
              ...pendingMedia.content,
            };
            dispatch(
              setActiveLayerPropsInStore({
                field: 'content',
                value: content,
                activeSlidePosition: cutIndex,
                activeLayerPosition: layerIndex,
              }),
            );
          }

          if (layer?.type === layerTypes.OUTLINK && pendingMedia) {
            dispatch(
              setActiveLayerPropsInStore({
                field: 'content.image',
                value: pendingMedia.content.image,
                activeSlidePosition: cutIndex,
                activeLayerPosition: layerIndex,
              }),
            );
          }
        });
      });
    });
  }
};

export const loadUploadedMedia = (type: string): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const user = state.auth.user;
  if (!user) {
    return;
  }
  let loadedMediaResponse: AxiosResponse<IUploadedMediaListResponse> | null = null;
  try {
    loadedMediaResponse = await api.get(
      uploadedMediaUrls.getMedia(user.selectedWorkspaceId, user._id, type, state.media.limit, state.media.offset),
    );
  } catch (err) {
    console.error(err);
  }
  if (loadedMediaResponse?.data?.media) {
    dispatch(addMedia(loadedMediaResponse?.data?.media));
  }
};
