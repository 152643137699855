import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { batch } from 'react-redux';
import React from 'react';

interface IEditorModalState {
  modalType: string | null;
  offsetTop: number;
  isOpen: boolean;
}

const initialState = {
  modalType: null,
  offsetTop: 0,
  isOpen: false,
} as IEditorModalState;

const editorModalSlice = createSlice({
  name: 'editorModal',
  initialState,
  reducers: {
    setModalType(state, action) {
      state.modalType = action.payload;
    },
    setOffset(state, action) {
      state.offsetTop = action.payload;
    },
    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
  },
});

export const { setModalType, setOffset, setIsOpen } = editorModalSlice.actions;

export default editorModalSlice.reducer;

export const openModal = (modalType: string | null, offsetTop?: number): AppThunk => (dispatch) => {
  batch(() => {
    dispatch(setModalType(modalType));
    dispatch(setOffset(offsetTop));
    dispatch(setIsOpen(true));
  });
};

export const closeModal = (): AppThunk => (dispatch) => {
  batch(() => {
    dispatch(setModalType(null));
    dispatch(setOffset(0));
    dispatch(setIsOpen(false));
  });
};

interface ITargetNode extends ChildNode {
  id: string;
}

interface ITargetHTMLElement extends Element {
  childNodes: NodeListOf<ITargetNode>;
  title: string;
}

export const onOutsideClickModal = (event: React.MouseEvent<Element, MouseEvent>, title: string): AppThunk => (
  dispatch,
) => {
  let isContained = false;
  const node = event?.target as ITargetHTMLElement;
  if (node.childNodes) {
    node.childNodes.forEach((child) => {
      if (child.id === title) {
        isContained = true;
      }
    });
  }
  if (node.id === title && !isContained) {
    isContained = true;
  }
  if (!isContained) {
    dispatch(closeModal());
  }
};
