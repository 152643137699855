import { DPAIcon } from 'components/icons';
import styled from 'styled-components';

const Button = styled.button`
  background-color: var(--white);
  color: var(--dpa-color);
  border-radius: 6px;
  padding: 7px 10px;
  cursor: pointer;
  border: none;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-right: 12px;
  }
`;

type DPAButton = {
  text: string;
  onClick?: () => void;
};

const DPAIdButton: React.VFC<DPAButton> = ({ text, onClick }) => (
  <Button onClick={onClick}>
    <DPAIcon /> {text}
  </Button>
);

export default DPAIdButton;
