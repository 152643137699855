import OutsideClickHandler from 'react-outside-click-handler';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { ModalExportOuterWrapper } from 'components/shared/modal';
import { PrimaryButton } from 'components/buttons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from 'hooks';
import { useTagsListQuery } from 'redux/services/tags/tags';
import { useUpdateTagsMutation } from 'redux/services/widgets/widgets';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

const Divider = styled.div`
  width: 46px;
  height: 1px;
  background-color: var(--white-10);
  margin: 10px 0;
`;

const OverrideOuterWrapper = styled(ModalExportOuterWrapper)`
  > * {
    max-width: 458px;
    align-items: center;
    justify-content: center;
  }
`;

const ModalWrapper = styled.div`
  background: rgb(20, 20, 31);
  border: 1px solid rgba(33, 33, 44, 0.85);
  box-shadow: 24px 32px 72px rgba(0, 0, 0, 0.18);
  back-dropfilter: blur(50px);
  border-radius: 12px;
  color: #fff;
  padding: 18px 0;
  max-width: 458px;
  max-height: 300px;
  z-index: 20;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 20px 0;
`;

const TitleWrapper = styled.div`
  padding: 0 20px;
`;

const HeadTagWrapper = styled.div`
  padding: 0 20px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  gap: 8px;
  padding: 0 0 0 20px;
  max-height: 80px;

  ::-webkit-scrollbar {
    width: 2px;
    height: 3px;
    scrollbar-color: var(--shade-300-85) transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--shade-300-85);
    border-radius: 20px;
    border: transparent;
  }
`;

const Pill = styled.span<{ isActive?: boolean }>`
  background-color: rgba(80, 80, 98, 0.85);
  border-radius: 4px;
  padding: 1px 8px;
  font-family: Heebo;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;

  ${({ isActive }) =>
    isActive &&
    `
  background-color: var(--primary);
  color: var(--shade-900);
  `}
  &:hover {
    cursor: pointer;
  }
`;

const Label = styled.p`
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const Content = styled.p`
  ${Label};
  font-size: 12px;
  font-family: Heebo;
`;

interface Props {
  setWidgetTagsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WidgetEditorTagsModal: React.VFC<Props> = ({ setWidgetTagsModalOpen }): JSX.Element | null => {
  const { widget } = useContext(WidgetContext) as WidgetContextType;
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const { data: workspaceTags } = useTagsListQuery(selectedWorkspaceId ?? skipToken);
  const [updateTags, { isLoading }] = useUpdateTagsMutation();

  const [tags, setTags] = useState<string[]>(widget.tags);

  const handleCloseModal = () => setWidgetTagsModalOpen(false);

  const handleSaveTags = async () => {
    await updateTags({ widgetId: widget._id, tags }).unwrap();
    setWidgetTagsModalOpen(false);
  };

  const handleTagsChange = useCallback((tag: string, clearTags?: boolean) => {
    if (clearTags) {
      setTags([]);
      return;
    }
    setTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      }
      return [...prev, tag];
    });
  }, []);

  return (
    <OverrideOuterWrapper>
      <OutsideClickHandler display="flex" onOutsideClick={handleCloseModal}>
        <ModalWrapper onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <Label>Tags</Label>
            <PrimaryButton
              isLoading={isLoading}
              isDisabled={isLoading}
              onClick={handleSaveTags}
              text={'Save Tags'}
              loader
            />
          </ModalHeader>
          <TitleWrapper>
            <Content>Stories containing the selected Tags will be automatically added in this Carousel.</Content>
          </TitleWrapper>
          <HeadTagWrapper>
            <Pill onClick={() => handleTagsChange('', true)} isActive={!tags?.length}>
              All Stories
            </Pill>
            <Divider />
          </HeadTagWrapper>
          <TagsWrapper>
            {workspaceTags?.map((wsTag) => (
              <Pill isActive={tags.includes(wsTag._id)} key={wsTag._id} onClick={() => handleTagsChange(wsTag._id)}>
                {wsTag.name}
              </Pill>
            ))}
          </TagsWrapper>
        </ModalWrapper>
      </OutsideClickHandler>
    </OverrideOuterWrapper>
  );
};

export default WidgetEditorTagsModal;
