import DotsIcon from '../../../icons/dots';
import MessageModal from '../../../message-modal';
import OutsideClickHandler from 'react-outside-click-handler';
import sinceTime from '../../../../utils/sinceTime';
import styled from 'styled-components';
import { memo, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WidgetDetails } from 'redux/services/widgets/interface';

interface IWidgetItem {
  widget: WidgetDetails;
  deleteWidget: (id: string) => void;
  duplicateWidget: (id: string) => void;
  prefetchWidget: (id: string) => void;
}

const MenuOuterWrapper = styled.div`
  position: absolute;
  top: 23px;
  right: 15px;
  pointer-events: none;
  visibility: visible;
  opacity: 1;
  transition: 0.12s ease;
  z-index: 1;
  cursor: pointer;
`;

const WidgetHeaderWrapper = styled.div`
  position: relative;
`;

const WidgetImageWrapper = styled.div`
  position: relative;
  height: 149px;
  background-color: var(--shade-700);
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: 25px 20px 0;
  box-shadow: 24px 32px 72px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(50px);
`;

const WidgetPreviewImage = styled.div<{ width?: string; height?: string }>`
  width: ${(props) => props.width || '50px'};
  height: ${(props) => props.height || '50px'};
  display: inline-block;
  margin-right: 7px;
  margin-top: 15px;
  position: relative;
  font-family: Heebo;
`;

interface IWidgetImage {
  borderRadius?: string;
}

const WidgetImage = styled.img.attrs<IWidgetImage>(({ borderRadius }) => ({
  style: {
    borderRadius,
  },
}))<IWidgetImage>`
  margin: auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border: 3px solid var(--shade-500);
  box-sizing: border-box;
  font-size: 24px;
`;

const WidgetImagePlus = styled.div<{ radius?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.radius || '25px'};
  border: 3px solid #2e2e38;
  box-sizing: border-box;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const WidgetBodyWrapper = styled.div`
  padding: 12px 0 0;
`;

const WidgetTitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--white);
  margin: 0 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 20px 0 0;
`;

const StoriesWrapper = styled.div`
  display: flex;
`;

const WidgetDate = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0;
`;

const MenuWrapper = styled.div`
  position: relative;
`;

const MenuTrigger = styled.button<{ isFocused: boolean; isMenuVisible?: boolean }>`
  display: inline-block;
  padding: 5px 2px;
  background: ${({ isFocused }) => (isFocused ? 'var(--white)' : 'transparent')};
  border: none;
  border-radius: 4px;
  outline: none;
  color: ${({ isFocused }) => (isFocused ? 'var(--shade-700)' : 'var(--white)')};
  line-height: 0;
  cursor: pointer;
  opacity: ${({ isMenuVisible }) => (isMenuVisible ? `1` : '0')};
  transition: 0.12s ease;
  &:hover {
    background: var(--white);
    color: var(--shade-700-85);
    border-radius: 4px;
  }
`;

const WidgetWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 311px;
  &:hover {
    ${MenuOuterWrapper} {
      pointer-events: all;
      opacity: 1;
      visibility: visible;
    }
    ${MenuTrigger} {
      opacity: 1;
    }
  }
`;

const DropdownWrapper = styled.div`
  background: var(--shade-700-85);
  border: 1px solid var(--white-10);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 12px;
  padding: 16px 12px;
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  min-width: 124px;
`;

const DropdownItem = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  border-radius: 6px;
  padding: 4px 8px;
  transition: 0.12s ease;
  cursor: pointer;
  &:hover {
    color: var(--shade-900);
    background: var(--primary);
  }
`;

const OverrideIcon = styled(DotsIcon)`
  pointer-events: none !important;
`;

const deleteMessage = `Are you sure you want to delete the selected Carousel?`;

const WidgetItem = ({ widget, deleteWidget, duplicateWidget, prefetchWidget }: IWidgetItem): JSX.Element => {
  const { push } = useHistory();

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isDeleteWidgetModalOpen, setDeleteWidgetModalOpen] = useState(false);
  const [selectedWidgetId, setSelectedWidgetId] = useState('');

  const { newWidth, newRadius, numCanFit, newHeight } = useMemo(() => {
    const widthScale = parseFloat(widget.editorDetails.style.width) / parseFloat(widget.editorDetails.style.height);
    const newWidth = widthScale * 45;
    const radiusScale = newWidth / parseFloat(widget.editorDetails.style.width);
    const newRadius = radiusScale * parseFloat(widget.editorDetails.style.radius);
    const numCanFit = Math.trunc(260 / (newWidth + 15)) - 1;

    return {
      widthScale,
      newWidth: newWidth + 'px',
      newHeight: '45px',
      radiusScale,
      newRadius: newRadius + 'px',
      numCanFit,
    };
  }, [widget]);

  const filteredStories = useMemo(() => {
    return widget.editorDetails.stories.filter((story) => story?.image?.length > 0 || story?.title?.length > 0);
  }, [widget.editorDetails.stories]);

  const stories = useMemo(() => {
    const slides: JSX.Element[] = [];

    for (const [index, story] of filteredStories.entries()) {
      slides.push(
        <WidgetPreviewImage key={index} width={newWidth} height={newHeight}>
          <WidgetImage src={story.image} borderRadius={newRadius} />
        </WidgetPreviewImage>,
      );

      if (index >= numCanFit) {
        break;
      }
    }

    return slides;
  }, [numCanFit, newWidth, newHeight, filteredStories, newRadius]);

  const onDeleteWidgetClick = useCallback(
    (widgetId: string) => (event: React.MouseEvent) => {
      event.stopPropagation();
      setSelectedWidgetId(widgetId);
      setDeleteWidgetModalOpen(true);
    },
    [],
  );

  const onDuplicateWidgetClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      duplicateWidget(widget._id);
      setIsMenuVisible(false);
    },
    [duplicateWidget, widget._id],
  );

  const onCancelDeleteWidget = useCallback(() => {
    setDeleteWidgetModalOpen(false);
  }, []);

  const onAcceptDeleteWidget = useCallback(() => {
    deleteWidget(selectedWidgetId);
    setDeleteWidgetModalOpen(false);
  }, [deleteWidget, selectedWidgetId]);

  const selectWidget = useCallback(() => {
    prefetchWidget(widget._id);
    push(`/carousel/${widget._id}`);
  }, [prefetchWidget, push, widget._id]);

  const onCloseMenu = useCallback(() => {
    setIsMenuVisible(false);
  }, []);

  const onDropdownTriggerClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsMenuVisible(!isMenuVisible);
    },
    [isMenuVisible],
  );

  return (
    <>
      <WidgetWrapper onClick={selectWidget}>
        <WidgetHeaderWrapper>
          <MenuOuterWrapper>
            <MenuWrapper>
              <OutsideClickHandler onOutsideClick={onCloseMenu}>
                <MenuTrigger isMenuVisible={isMenuVisible} isFocused={isMenuVisible} onClick={onDropdownTriggerClick}>
                  <OverrideIcon />
                </MenuTrigger>
                {isMenuVisible && (
                  <DropdownWrapper>
                    <DropdownItem onClick={onDuplicateWidgetClick}>Duplicate</DropdownItem>
                    <DropdownItem onClick={onDeleteWidgetClick(widget._id)}>Delete</DropdownItem>
                  </DropdownWrapper>
                )}
              </OutsideClickHandler>
            </MenuWrapper>
          </MenuOuterWrapper>

          <WidgetImageWrapper>
            <WidgetTitle>{widget.name}</WidgetTitle>
            <StoriesWrapper>
              {stories}

              {filteredStories.length - stories.length > 0 && (
                <WidgetPreviewImage width={newWidth} height={newHeight}>
                  <WidgetImagePlus radius={newRadius}>+{filteredStories.length - stories.length}</WidgetImagePlus>
                </WidgetPreviewImage>
              )}
            </StoriesWrapper>
          </WidgetImageWrapper>
        </WidgetHeaderWrapper>

        <WidgetBodyWrapper>
          <WidgetDate>{sinceTime(widget.updatedAt)}</WidgetDate>
        </WidgetBodyWrapper>
      </WidgetWrapper>

      {isDeleteWidgetModalOpen && (
        <MessageModal
          isOpen
          message={deleteMessage}
          shouldCloseOnOverlayClick
          onCancel={onCancelDeleteWidget}
          onAccept={onAcceptDeleteWidget}
        />
      )}
    </>
  );
};

export default memo(WidgetItem);
