import api from '../api';

import type { GradientsInputType, GradientsResponse } from './interface';

export const gradientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGradients: builder.query<GradientsResponse, GradientsInputType>({
      query: ({ offset, limit }) => `gradients?offset=${offset}&limit=${limit}`,
      keepUnusedDataFor: 43200,
    }),
  }),
});

export const { useGetGradientsQuery, useLazyGetGradientsQuery } = gradientsApi;
