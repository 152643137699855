import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface WidgetsState {
  isCreateWidgetModalOpen: boolean;
}

const initialState = {
  isCreateWidgetModalOpen: false,
} as WidgetsState;

const widgetSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    toggleCreateWidgetModal: (state, action: PayloadAction<boolean>) => {
      state.isCreateWidgetModalOpen = action.payload;
    },
  },
});

export const { toggleCreateWidgetModal } = widgetSlice.actions;
export default widgetSlice.reducer;
