import { lazy, Suspense } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 20% 50px;
`;

const InnerWrapper = styled.div`
  margin-top: -13%;
  text-align: center;
`;

const TrialExpiredIcon = lazy(() => import('components/icons/trial-expired'));

const StyledTrialExpiredIcon = styled(TrialExpiredIcon)`
  margin: 0 0 12px;
`;

const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--white);
  text-align: center;
  max-width: 365px;
  margin: 0;
  &:first-of-type {
    margin: 0 0 12px;
  }
`;

const TrialExpired = (): JSX.Element => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Suspense fallback={<></>}>
          <StyledTrialExpiredIcon />
        </Suspense>

        <Text>Your Free Trial has ended.</Text>
        <Text>
          If you wish to extend your testing period or subscribe to one of our plans, please get in touch via our live
          chat!
        </Text>
      </InnerWrapper>
    </Wrapper>
  );
};

export default TrialExpired;
