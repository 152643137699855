import Checkbox from '../shared/checkbox';
import DatePickerWrapper from '../shared/date-picker-wrapper';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import WidgetEditorIframeSmallSmartphone from '../widget-editor-iframe/widget-editor-iframe-small-smartphone';
import { BackButton, HeaderTitle, InputLabel, SectionTitle } from '../export-modal/shared/elements';
import { datesAreOnSameDay, generateDate, revertToJSDate } from './helpers/widget-export-modal-helpers';
import { DeleteSM } from '../icons';
import { FormColumn, WidgetColumn } from './shared/structure';
import { FormSection, PublishButtonContainer, SectionColumn, SectionRow } from '../export-modal/shared/structure';
import { ModalBodyWrapper, ModalExportOuterWrapper, ModalHeaderWrapper, ModalWrapper } from './../shared/modal';
import { PrimaryButton } from '../buttons';
import { useAppSelector } from 'hooks';
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePublishWidgetMutation } from 'redux/services/widgets/widgets';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';
import InputWithSelect from '../shared/input-with-select';
import EditorSidebarLabel from '../editor-sidebar/shared/elements/editor-sidebar-label';

const DatePicker = lazy(() => import('react-datepicker'));

const DeleteButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 6px;
  position: absolute;
  right: -34px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.12s ease;
  &:hover {
    background: var(--shade-500-85);
  }
`;

const PaddingBox = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 72px;
  gap: 4px;
`;

const LIMIT_VALUES = Array.from({ length: 19 }, (x, i) => ({
  name: (i + 1) * 5,
  value: (i + 1) * 5,
}));

interface Props {
  setExportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPublishedModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WidgetExportModal: React.VFC<Props> = ({ setExportModalOpen, setPublishedModalOpen }): JSX.Element => {
  const { widget } = useContext(WidgetContext) as WidgetContextType;
  const userId = useAppSelector((state) => state.auth.user?._id);
  // const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);

  // const { wsDomains, customCDN } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
  //   selectFromResult: ({ data: workspace }) => ({
  //     wsDomains: workspace?.domainSettings.domainList,
  //     customCDN: workspace?.customCDN,
  //   }),
  //   skip: !selectedWorkspaceId,
  // });
  const [handlePublish] = usePublishWidgetMutation();

  // const carouselDomain = widget?.customDomain;

  // const domainOptions = useMemo(() => {
  //   if (customCDN?.isEnabled && customCDN?.cdns?.length) {
  //     return customCDN?.cdns?.map((cdn) => ({ name: cdn, value: cdn }));
  //   }

  //   const domains = wsDomains?.map((domain) => {
  //     return {
  //       name: domain.domainName,
  //       value: domain.domainName,
  //     };
  //   });

  //   return [...(domains ?? []), { name: defaultDomain, value: defaultDomain }];
  // }, [customCDN?.cdns, customCDN?.isEnabled, wsDomains]);

  // const [url, setUrl] = useState(carouselDomain ?? domainOptions[0].value);
  const [schedule, setSchedule] = useState({
    isActive: widget?.startAt ? true : false,
    hasEndDate: widget?.endAt ? true : false,
    startAt: revertToJSDate(widget?.startAt),
    endAt: widget?.endAt ? revertToJSDate(widget?.endAt) : new Date(new Date().setDate(new Date().getDate() + 7)),
  });
  const [limit, setLimit] = useState<number>(widget?.limit ?? 10);
  const [isLimitEnabled, setLimitEnabled] = useState<boolean>(widget?.isLimitEnabled ?? false);

  const endAtAdditionProps = useMemo(
    () => ({
      minTime: new Date(
        new Date(schedule.startAt).setHours(schedule.startAt.getHours(), schedule.startAt.getMinutes() + 1),
      ),
      maxTime: new Date(new Date(schedule.endAt).setHours(23, 59, 59)),
    }),
    [schedule.startAt, schedule.endAt],
  );

  const onCloseExportModal = useCallback(() => {
    if (!document.querySelector('.amp-story-layer')) {
      setExportModalOpen(false);
    }
  }, [setExportModalOpen]);

  // const onUrlChange = useCallback((url: string) => {
  //   setUrl(url);
  // }, []);

  const toggleSchedule = useCallback(() => {
    setSchedule((prevState) => ({ ...prevState, isActive: !prevState.isActive }));
  }, []);

  const toggleLimit = useCallback(() => {
    setLimitEnabled((prev) => !prev);
  }, []);

  const setScheduleDate = useCallback(
    (type: 'startAt' | 'endAt') => (date: Date) => {
      setSchedule((prevState) => ({ ...prevState, [type]: date }));
    },
    [],
  );

  const onLimitSelect = useCallback(
    (option?: number | BaseSyntheticEvent) => {
      const currentValue = limit;
      const newLimit = currentValue < 1 ? 1 : currentValue > 99 ? 99 : currentValue;
      const selectedLimit = Math.round(typeof option === 'number' ? option : newLimit);

      setLimit(selectedLimit);
    },
    [limit],
  );

  const onLimitChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    let valueString = event.target.value;

    if (valueString.length > 1 && valueString[0] === '0') {
      valueString = valueString.substr(1, valueString.length - 1);
    }

    if (/^\d*\.?\d*$/.test(valueString) && event) {
      setLimit(+valueString);
    }
  }, []);

  const onLimitKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation();
      let value = parseFloat((event.target as HTMLInputElement)?.value);
      value = Number.isNaN(value) ? limit ?? 5 : Math.round(value);

      if (event.code === 'ArrowUp') {
        const stylesValue = value + 1 > 99 ? 99 : value + 1;
        setLimit(stylesValue);
      }

      if (event.code === 'ArrowDown') {
        const stylesValue = value - 1 < 1 ? 1 : value - 1;
        setLimit(stylesValue);
      }
    },
    [limit],
  );

  const onPublishCarousel = useCallback(async () => {
    // const customDomain = url !== defaultDomain ? url : null;
    const payload = {
      id: widget?._id,
      modifiedBy: userId,
      // customDomain,
      ...(schedule.isActive && { startAt: generateDate(schedule.startAt) }),
      ...(schedule.isActive && schedule.hasEndDate && { endAt: generateDate(schedule.endAt) }),
      limit,
      isLimitEnabled,
    };
    let isSuccess = false;
    try {
      await handlePublish(payload).unwrap();
      isSuccess = true;
    } catch (error) {
      console.error(error);
    } finally {
      setExportModalOpen(false);
      if (isSuccess) {
        setPublishedModalOpen(true);
      }
    }
  }, [
    handlePublish,
    isLimitEnabled,
    limit,
    schedule.endAt,
    schedule.hasEndDate,
    schedule.isActive,
    schedule.startAt,
    setExportModalOpen,
    setPublishedModalOpen,
    userId,
    widget?._id,
  ]);

  const toggleHasEndDate = useCallback(() => {
    setSchedule((prevState) => ({ ...prevState, hasEndDate: !prevState.hasEndDate }));
  }, []);

  useEffect(() => {
    if (schedule.startAt.getTime() >= schedule.endAt.getTime()) {
      setScheduleDate('endAt')(new Date(new Date(schedule.startAt).setDate(schedule.startAt.getDate() + 1)));
    }
  }, [schedule.startAt, schedule.endAt, setScheduleDate]);

  return (
    <ModalExportOuterWrapper>
      <OutsideClickHandler display="flex" onOutsideClick={onCloseExportModal}>
        <ModalWrapper>
          <ModalHeaderWrapper>
            <BackButton onClick={onCloseExportModal} />

            <HeaderTitle text="Publish Story Carousel" />

            <PublishButtonContainer>
              <PrimaryButton onClick={onPublishCarousel} text={'Publish Carousel'} loader isLoading={false} />
            </PublishButtonContainer>
          </ModalHeaderWrapper>

          <ModalBodyWrapper>
            <FormColumn>
              <FormSection padding="0 98px 0 32px">
                <SectionColumn>
                  <SectionTitle text="Publishing settings" />
                </SectionColumn>
                {/* 
                <SectionRow>
                  <SectionColumn width={'50%'}>
                    <InputLabel htmlFor="url" text="Stories URL" />

                    <Select
                      selectOption={url}
                      optionEllipsis={true}
                      options={domainOptions}
                      onSelect={onUrlChange}
                      dropdownZIndex={4}
                    />
                  </SectionColumn>
                </SectionRow> */}

                <SectionRow>
                  <PaddingBox>
                    <Checkbox
                      isChecked={isLimitEnabled}
                      label="Limit number of Stories displayed in the Carousel"
                      onClick={toggleLimit}
                    />
                    {isLimitEnabled && (
                      <SelectContainer>
                        <EditorSidebarLabel text={'Maximum'} />
                        <InputWithSelect
                          value={limit}
                          isDisabled={false}
                          options={LIMIT_VALUES}
                          onBlur={onLimitSelect}
                          onSelect={onLimitSelect}
                          onChange={onLimitChange}
                          onKeyDown={onLimitKeyDown}
                        />
                      </SelectContainer>
                    )}
                  </PaddingBox>
                </SectionRow>

                <SectionRow>
                  <PaddingBox>
                    <Checkbox
                      isChecked={schedule.isActive}
                      label="Schedule Story Carousel (only available with JSON Feed)"
                      onClick={toggleSchedule}
                    />
                  </PaddingBox>
                </SectionRow>

                {schedule.isActive && (
                  <SectionRow>
                    <SectionColumn width={'50%'}>
                      <InputLabel htmlFor="url" text="Online from" />

                      <DatePickerWrapper>
                        <Suspense fallback={<></>}>
                          <DatePicker
                            selected={schedule.startAt}
                            {...(schedule.hasEndDate && {
                              selectStart: true,
                              endDate: schedule.endAt,
                            })}
                            onChange={setScheduleDate('startAt')}
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy H:mm"
                            timeFormat="H:mm"
                            showTimeSelect
                          />
                        </Suspense>
                      </DatePickerWrapper>
                    </SectionColumn>

                    <SectionColumn width={'50%'}>
                      <InputLabel htmlFor="url" text="To" />

                      <DatePickerWrapper
                        isDisabled={!schedule.hasEndDate}
                        {...(!schedule.hasEndDate && { onClick: toggleHasEndDate })}
                      >
                        <DatePicker
                          disabled={!schedule.hasEndDate}
                          selected={schedule.endAt}
                          selectsEnd
                          {...(datesAreOnSameDay(schedule.startAt, schedule.endAt) && endAtAdditionProps)}
                          minDate={schedule.startAt}
                          onChange={setScheduleDate('endAt')}
                          timeInputLabel="Time:"
                          dateFormat="dd.MM.yyyy H:mm"
                          timeFormat="H:mm"
                          showTimeSelect
                        />

                        {schedule.hasEndDate && (
                          <DeleteButtonWrapper onClick={toggleHasEndDate}>
                            <DeleteSM />
                          </DeleteButtonWrapper>
                        )}
                      </DatePickerWrapper>
                    </SectionColumn>
                  </SectionRow>
                )}
              </FormSection>
            </FormColumn>

            <WidgetColumn>
              <WidgetEditorIframeSmallSmartphone />
            </WidgetColumn>
          </ModalBodyWrapper>
        </ModalWrapper>
      </OutsideClickHandler>
    </ModalExportOuterWrapper>
  );
};

export default WidgetExportModal;
