import { memo } from 'react';

const CloseModal = (): JSX.Element => {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      width="13"
      height="13"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82843 5.76739L5.06066 3.99963L6.82843 2.23186C7.14663 1.91366 7.14662 1.4894 6.82843 1.1712C6.51023 0.853002 6.08596 0.853002 5.76777 1.1712L4 2.93897L2.23223 1.1712C1.91404 0.853002 1.48977 0.853002 1.17157 1.1712C0.853375 1.4894 0.853375 1.91366 1.17157 2.23186L2.93934 3.99963L1.17157 5.76739C0.853375 6.08559 0.853375 6.50986 1.17157 6.82805C1.48977 7.14625 1.91404 7.14625 2.23223 6.82805L4 5.06029L5.76777 6.82805C6.08596 7.14625 6.51023 7.14625 6.82843 6.82805C7.14663 6.50986 7.14662 6.08559 6.82843 5.76739Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(CloseModal);
