import React from 'react';
import styled from 'styled-components';
import InputDynamicWidth from './../../../shared/input-dynamic-width';

interface IColorInputElement {
  className?: string;
  isDisabled?: boolean;
  value: string | number;
  type?: string;
  placeholder?: string;
  onKeyDown?: any;
  onChange: any;
}

const ColorInputElement = styled(InputDynamicWidth)`
  padding: 7px 12px;
`;

const ColorInputDynamicWidth = ({
  className,
  isDisabled,
  value,
  type,
  placeholder,
  onKeyDown,
  onChange,
}: IColorInputElement): JSX.Element => {
  return (
    <ColorInputElement
      className={className}
      isDisabled={isDisabled}
      value={value}
      type={type}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onChange={onChange}
    />
  );
};

export default ColorInputDynamicWidth;
