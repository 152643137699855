import OutsideClickHandler from 'react-outside-click-handler';
import styled, { css, keyframes } from 'styled-components';
import { environment } from '../../config/environment';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ModalPublishedOuterWrapper, SuccessModalInnerWrapper } from './../export-modal/shared/structure';
import { ModalWrapper } from './../shared/modal';
import { SuccessImage, SuccessText } from '../export-modal/shared/elements';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

const HeaderWrapper = styled.div`
  display: flex;
  padding: 32px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Button = styled.button`
  padding: 8px 20px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0px 4px 12px var(--black-16);
  width: 100%;
`;

const CopyLinkButton = styled(Button)`
  background: var(--primary);
  color: var(--shade-900);
  border-color: var(--primary);
`;

const CopyEmbedCodeButton = styled(Button)`
  background: transparent;
  color: var(--primary);
  border-color: var(--primary);
`;

const BackButton = styled(Button)`
  background: var(--shade-100);
  color: var(--shade-900);
  border-color: var(--shade-100);
`;

const ButtonWrapper = styled.div`
  position: relative;
  &:not(:last-of-type) {
    margin: 0 0 12px;
  }
`;

const appearing = keyframes`
  0% {
    opacity: 1;
    z-index: 1;
    transform: translate(-50%, 0%);
  }

  20%{
    opacity: 1;
    transform: translate(-50%, -200%);
  }

  80%{
    opacity: 1;
  }

  100%{
    opacity: 0;
    z-index: -1;
  }
`;

const Tooltip = styled.div<{ isVisible: boolean }>`
  z-index: -1;
  font-family: Heebo;
  font-size: 12px;
  position: absolute;
  background-color: var(--shade-900);
  border-radius: 22px;
  color: var(--white);
  line-height: 1;
  padding: 5px 12px;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  left: 50%;
  transform: translate(-50%, -180%);
  ${({ isVisible }) =>
    isVisible &&
    css`
      z-index: -1;
      animation-name: ${appearing};
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    `}
`;

const ButtonsWrapper = styled.div`
  margin: 13px 0 0;
`;

interface Props {
  setPublishedModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuccessfullyPublishedModal: React.VFC<Props> = ({ setPublishedModalOpen }): JSX.Element => {
  const { widget } = useContext(WidgetContext) as WidgetContextType;
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const widgetJSONUrl = useMemo(() => `${window.location.origin}/api/v3/widget/${widget._id}/json`, [widget._id]);
  const widgetEmbedCode = useMemo(
    () =>
      `<div class="cutnutwidget" id='${widget.key}' style="min-height: 250px; padding: 10px 0 35px 0; position: relative;"></div><script async defer src='${environment.widgetHost}'></script>`,
    [widget.key],
  );

  const copyToClipboard = useCallback(
    (code: string) => () => {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(code);
        setIsLinkCopied(true);
        return;
      }

      const textArea = document.createElement('textarea');
      textArea.value = code;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
      setIsLinkCopied(true);
    },
    [],
  );

  useEffect(() => {
    if (isLinkCopied) {
      setTimeout(() => setIsLinkCopied(false), 1200);
    }
  }, [isLinkCopied]);

  const onClosePublishedModal = useCallback(() => setPublishedModalOpen(false), [setPublishedModalOpen]);

  return (
    <ModalPublishedOuterWrapper>
      <OutsideClickHandler display="flex" onOutsideClick={onClosePublishedModal}>
        <ModalWrapper>
          <SuccessModalInnerWrapper>
            <HeaderWrapper>
              <SuccessImage />
              <SuccessText text="Your Carousel was successfully published" />
            </HeaderWrapper>

            <ButtonsWrapper>
              <ButtonWrapper>
                <Tooltip isVisible={isLinkCopied}>Copied</Tooltip>
                <CopyLinkButton onClick={copyToClipboard(widgetEmbedCode)}>Copy HTML code</CopyLinkButton>
              </ButtonWrapper>

              <ButtonWrapper>
                <CopyEmbedCodeButton onClick={copyToClipboard(widgetJSONUrl)}>Copy JSON Feed</CopyEmbedCodeButton>
              </ButtonWrapper>

              <ButtonWrapper>
                <BackButton onClick={onClosePublishedModal}>Back to editor</BackButton>
              </ButtonWrapper>
            </ButtonsWrapper>
          </SuccessModalInnerWrapper>
        </ModalWrapper>
      </OutsideClickHandler>
    </ModalPublishedOuterWrapper>
  );
};

export default memo(SuccessfullyPublishedModal);
