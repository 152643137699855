import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IFormSection {
  padding?: string;
  children: ReactNode;
}

const Section = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => (padding ? padding : '0 32px')};
  &:not(:last-of-type) {
    margin: 0 0 40px;
  }
`;

const FormSection = ({ padding, children }: IFormSection): JSX.Element => {
  return <Section padding={padding}>{children}</Section>;
};

export default FormSection;
