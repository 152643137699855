import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../../redux/features/user/userSlice';
import { useForm } from 'react-hook-form';
import Title from '../shared/title';
import FormInput from '../shared/form-input';
import TextButton from '../shared/form-text-button';
import FormButton from '../shared/form-button';
import LoginStyled from 'components/auth/shared/login-styled';
import zazuBg1x from 'assets/images/auth-backgroundx1.webp';
import zazuBg2x from 'assets/images/auth-backgroundx2.webp';
import zazuBg3x from 'assets/images/auth-backgroundx3.webp';
import zazuLogo1x from 'assets/images/zazu-logo1x.png';
import zazuLogo2x from 'assets/images/zazu-logo2x.png';
import zazuLogo3x from 'assets/images/zazu-logo3x.png';

import { RootState } from '../../../redux/rootReducer';

import styled from 'styled-components';
import { clearErrors } from '../../../redux/features/user/userSlice';
import ExclamationMark from '../../icons/exclamation-mark';
import FormStyles from './../shared/form-styles';

const StyledPageContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  & * {
    box-sizing: border-box;
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 367px;
  border-radius: 12px;
  align-items: center;
  background-color: var(--shade-900);
  box-shadow: 0px 4px 30px 20px rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 69px 36px 69px;
  align-items: left;
`;

const StyledTextButton = styled(TextButton)`
  align-self: flex-start;
  line-height: 18px;
  padding-top: 15px;
  padding-bottom: 4px;
  font-size: 12px;
`;

const StyledFormButton = styled(FormButton)`
  margin-top: 24px;
`;

const StyledInformation = styled.p`
  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #ababba;
  margin-bottom: 26px;
`;

const StyledTitle = styled(Title)`
  line-height: 48px;
  max-width: 100%;
`;

const StyledFormError = styled.span`
  max-width: 100%;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--white);
  background: var(--warning);
  border-radius: 6px;
  padding: 5.5px 6px;
  margin-bottom: 22.5px;
`;

type IEmail = {
  email: string;
};
const ForgotPassword = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const {
    handleSubmit,
    register,
    formState: { isValid },
    errors,
  } = useForm({ mode: 'all' });

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const onForgotPassword = ({ email }: IEmail) => {
    dispatch(forgotPassword(email, history));
  };

  return (
    <StyledPageContainer>
      <LoginStyled.AuthBackgroundContainer>
        <LoginStyled.AuthBackground srcSet={`${zazuBg3x} 3x, ${zazuBg2x} 2x, ${zazuBg1x} 1x`} />
        <LoginStyled.Overlay />
      </LoginStyled.AuthBackgroundContainer>
      <LoginStyled.LogoLink href={'//zazuapp.co'} target={'_blank'} rel="noopener noreferrer">
        <LoginStyled.Logo srcSet={`${zazuLogo3x} 3x,${zazuLogo2x} 2x, ${zazuLogo1x} 1x`} />
      </LoginStyled.LogoLink>
      <StyledFormWrapper>
        <StyledForm onSubmit={handleSubmit(onForgotPassword)}>
          <StyledTitle text="Forgot your password?" />
          <StyledInformation>
            Happens to the best of us. We will sort this out in no time. Please enter your email to proceed.
          </StyledInformation>
          {user.authErrors && <StyledFormError>{user?.authErrors?.message}</StyledFormError>}
          <FormInput placeHolder="Enter your email" type="email" name="email" autoComplete="off" register={register} />
          {errors.email && (
            <FormStyles.StyledInputError>
              <ExclamationMark />
              <FormStyles.StyledErrorText> Please enter a valid email </FormStyles.StyledErrorText>
            </FormStyles.StyledInputError>
          )}
          <StyledFormButton type="submit" text="Send Email" disabled={!isValid} />
          <StyledTextButton
            color="var(--primary)"
            onClick={() => history.push('/login')}
            text="Go Back"
            name="back-button"
          />
        </StyledForm>
      </StyledFormWrapper>
    </StyledPageContainer>
  );
};
export default ForgotPassword;
