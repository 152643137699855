import { ReactNode } from 'react';
import styled from 'styled-components';

interface IEditorSidebarRow {
  className?: string;
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  onClick?: () => void;
  children: ReactNode;
  margin?: string;
  gap?: string;
  disableCursor?: boolean;
}

const StyledSettingsRow = styled.div<{
  justifyContent?: string;
  alignItems?: string;
  margin?: string;
  disableCursor?: boolean;
  gap?: string;
}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ disableCursor }) =>
    disableCursor &&
    `
    &&& * {
      cursor: default;
    }
  `}
`;

const SettingsRow = ({
  className,
  children,
  alignItems,
  justifyContent,
  onClick,
  margin,
  gap,
  disableCursor,
}: IEditorSidebarRow): JSX.Element => {
  return (
    <StyledSettingsRow
      className={className}
      justifyContent={justifyContent}
      alignItems={alignItems}
      margin={margin}
      gap={gap}
      onClick={onClick}
      disableCursor={disableCursor}
    >
      {children}
    </StyledSettingsRow>
  );
};

SettingsRow.defaultProps = {
  justifyContent: 'flex-start',
};

export default SettingsRow;
