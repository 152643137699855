import OutsideClickHandler from 'react-outside-click-handler';
import { TutorialOuterWrapper } from '../shared';
import { environment } from '../../../config/environment';
import styled from 'styled-components';
import { CSSProperties } from 'react';

interface ITutorialDashboardVideoModal {
  onClose: () => void;
  style?: CSSProperties;
}

const StyledTutorialOuterWrapper = styled(TutorialOuterWrapper)`
  z-index: 114;
  background: var(--shade-700-85);
`;

const VideoWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;
  width: 100%;
`;

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const TutorialDashboardVideoModal = ({ onClose, style }: ITutorialDashboardVideoModal): JSX.Element => {
  return (
    <StyledTutorialOuterWrapper maxWidth={'1200px'} style={style}>
      <OutsideClickHandler display="flex" onOutsideClick={onClose}>
        <VideoWrapper>
          <Video controls>
            <source src={`${environment.mediaEndpoint}/Tutorial/zazu-product-tour.mp4`} />
          </Video>
        </VideoWrapper>
      </OutsideClickHandler>
    </StyledTutorialOuterWrapper>
  );
};

export default TutorialDashboardVideoModal;
