import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ITutorialModalBody {
  className?: string;
  children: ReactNode;
}

const Wrapper = styled.section`
  padding: 0 50px;
  width: 100%;
`;

const TutorialModalBody = ({ className, children }: ITutorialModalBody): JSX.Element => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default TutorialModalBody;
