import PageTitle from '../../shared/page-title/page-title';
import PageTitleWrapper from '../../shared/page-title/page-title-wrapper';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import { useAppSelector } from 'hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetWorkspaceQuery, useGetTeamQuery } from 'redux/services/workspaces/workspaces';

const UsersWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 12px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const User = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--shade-300);
  font-size: 12px;
  font-family: Heebo;
  background-color: var(--shade-900);
  color: var(--white);
  overflow: hidden;
  &:not(:first-child) {
    margin-left: -10px;
  }
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const TeamTitle = (): JSX.Element => {
  const selectedTeamId = useAppSelector((state) => state.auth.user?.selectedTeamId);

  const { teamMembers } = useGetTeamQuery(selectedTeamId ?? skipToken, {
    selectFromResult: ({ data: team }) => ({
      teamMembers: team?.users,
    }),
    skip: !selectedTeamId,
  });

  return (
    <PageTitleWrapper>
      <PageTitle text="Team Stories" />

      {teamMembers && (
        <UsersWrapper to={'/settings/members'}>
          {teamMembers?.slice(0, 4).map((user, index) => {
            if (index === 3) {
              return <User key={`team-user-avatar-${index}`}>+{teamMembers?.length - 3}</User>;
            }

            return (
              <User key={`team-user-avatar-${index}`}>
                {user?.avatar ? (
                  <Avatar src={user?.avatar} alt={user.username} />
                ) : (
                  user?.username?.charAt(0)?.toUpperCase()
                )}
              </User>
            );
          })}
        </UsersWrapper>
      )}
    </PageTitleWrapper>
  );
};

export default memo(TeamTitle);
