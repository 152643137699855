import { v4, validate } from 'uuid';

export default (): string => v4();

export const convertId = (input: any): void => {
  if (validate(input?._id)) {
    return;
  }
  input._id = validate(input?.id) ? input.id : v4();
};
