import Folder from './navigation-folder';
import React, { memo } from 'react';
import styled from 'styled-components';
import { IFolder } from 'redux/services/folders/interfaces';

interface IProps {
  folders: IFolder[];
  folderId?: string | null;
  teamId?: string | null;
}

const Container = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const NavigationFoldersTreeRecursive: React.FC<IProps> = ({ folders, teamId }) => {
  return (
    <Container>
      {folders.map((folder) => (
        <Folder key={folder._id} folder={folder} teamId={teamId} />
      ))}
    </Container>
  );
};

export default memo(NavigationFoldersTreeRecursive);
