import Styled from './widget-editor-device-styled';
import useMutationObservable from '../../hooks/useMutationObservable';
import { environment } from '../../config/environment';
import { loadScript, removeScript } from '../../utils/common';
import { previewMutationListener } from '../../utils/widgetUtils';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

interface IWidgetEditorIframeSmartphone {
  updateScrollbars: () => void;
}

const WidgetEditorIframeSmartphone = ({ updateScrollbars }: IWidgetEditorIframeSmartphone): JSX.Element => {
  const { widget } = useContext(WidgetContext) as WidgetContextType;
  const widgetKey = widget.key;

  const cutnutwidgetRef = useRef<HTMLDivElement | null>(null);
  const widgetWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // local - process.env.PUBLIC_URL | other - environment.widgetHost
    loadScript(environment.widgetHost as string);

    return () => {
      removeScript(environment.widgetHost as string);
    };
  }, []);

  const lightboxMutation = useCallback((mutationList: MutationRecord[] | undefined) => {
    if (mutationList) {
      previewMutationListener(cutnutwidgetRef.current)(mutationList);
    }
  }, []);

  useMutationObservable(widgetWrapperRef.current, lightboxMutation);

  useMutationObservable(cutnutwidgetRef.current, updateScrollbars);

  return (
    <Styled.OuterWrapper>
      <Styled.SmartphoneWrapper>
        <Styled.SmartphoneHeader>
          <Styled.HamburgerWrapper>
            <Styled.HamburgerLine />
            <Styled.HamburgerLine />
            <Styled.HamburgerLine />
          </Styled.HamburgerWrapper>

          <Styled.HeaderUser />
        </Styled.SmartphoneHeader>

        <Styled.ExternalWidgetWrapper ref={widgetWrapperRef}>
          <div className="cutnutwidget" id={widgetKey} ref={cutnutwidgetRef} />
        </Styled.ExternalWidgetWrapper>

        <Styled.FirstSection>
          <Styled.FirstSectionTitle />

          <Styled.FirstSectionText />
          <Styled.FirstSectionText />
          <Styled.FirstSectionText />
        </Styled.FirstSection>

        <Styled.SecondSection>
          <Styled.SecondSectionItem>
            <Styled.SecondSectionItemImage />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
          </Styled.SecondSectionItem>

          <Styled.SecondSectionItem>
            <Styled.SecondSectionItemImage />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
          </Styled.SecondSectionItem>
        </Styled.SecondSection>
      </Styled.SmartphoneWrapper>
    </Styled.OuterWrapper>
  );
};

export default WidgetEditorIframeSmartphone;
