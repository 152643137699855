import CreateWidgetModal from 'components/create-widget-modal/create-widget-modal';
import DashboardLayout from 'components/layouts';
import MyWidgets from 'components/widgets-list/mywidgets';
import TutorialCarousel from 'components/tutorial-modal/tutorial-carousel/tutorial-carousel';
import { useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { usePrefetch as usePrefetchPresets } from 'redux/services/widgets/widgets';
import { usePrefetch as usePrefetchTags } from 'redux/services/tags/tags';

const MyWidgetsPage = (): JSX.Element => {
  const tutorialCarouselPassed = useAppSelector((state) => state.auth.user?.tutorialCarouselPassed);
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const isCreateWidgetModalOpen = useAppSelector((state) => state.widget.isCreateWidgetModalOpen);

  const prefetchPresets = usePrefetchPresets('getPresets');
  const prefetchTags = usePrefetchTags('tagsList');

  useEffect(() => {
    if (selectedWorkspaceId) {
      prefetchPresets(selectedWorkspaceId);
      prefetchTags(selectedWorkspaceId);
    }
  }, [prefetchPresets, prefetchTags, selectedWorkspaceId]);

  return (
    <DashboardLayout carouselVersion>
      <MyWidgets />

      {!tutorialCarouselPassed && <TutorialCarousel />}
      {tutorialCarouselPassed && isCreateWidgetModalOpen && <CreateWidgetModal />}
    </DashboardLayout>
  );
};

export default MyWidgetsPage;
