import ApiSettings from 'components/settings/api-settings/api-settings';
import DashboardLayout from 'components/layouts';
import SettingsTabs from 'components/settings/settings-tabs';
import SettingsWrapper from 'components/settings/shared/settings-wrapper';
import { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from 'hooks';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';

const SettingsApiPage = (): JSX.Element => {
  // Temp redirect if conversion is not enabled
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const { isStoryConversionEnabled, isSuccess } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace, isSuccess }) => ({
      isStoryConversionEnabled: workspace?.isStoryConversionEnabled,
      isSuccess,
    }),
  });

  if (isSuccess && !isStoryConversionEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <DashboardLayout>
      <SettingsWrapper>
        <SettingsTabs />

        <ApiSettings />
      </SettingsWrapper>
    </DashboardLayout>
  );
};

export default memo(SettingsApiPage);
