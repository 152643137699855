import { memo } from 'react';

const Teams = ({
  className,
  fillColor = 'var(--shade-100)',
}: {
  className?: string;
  fillColor?: string;
}): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5811 9.05616C16.5811 12.1406 14.057 14.6123 10.9075 14.6123C7.75908 14.6123 5.23396 12.1406 5.23396 9.05616C5.23396 5.97177 7.75908 3.5 10.9075 3.5C14.057 3.5 16.5811 5.97177 16.5811 9.05616ZM2.33325 20.9037C2.33325 18.0484 6.28305 17.334 10.9075 17.334C15.5571 17.334 19.4818 18.073 19.4818 20.9305C19.4818 23.7857 15.5319 24.5001 10.9075 24.5001C6.25793 24.5001 2.33325 23.7611 2.33325 20.9037ZM18.8689 9.15684C18.8689 10.7275 18.3872 12.1932 17.5424 13.4106C17.4546 13.5358 17.5322 13.7046 17.6851 13.7314C17.8974 13.7661 18.1155 13.7873 18.3381 13.7918C20.5528 13.8488 22.5402 12.4525 23.0892 10.3497C23.9032 7.22952 21.5151 4.42796 18.4728 4.42796C18.1429 4.42796 17.8267 4.4615 17.5185 4.52298C17.4763 4.53192 17.4305 4.55204 17.4078 4.58783C17.378 4.63365 17.3997 4.69291 17.4295 4.73204C18.3438 5.98748 18.8689 7.5157 18.8689 9.15684ZM22.5368 15.986C24.0254 16.271 25.0036 16.8513 25.4089 17.6976C25.7525 18.3907 25.7525 19.1956 25.4089 19.8888C24.7891 21.1989 22.7891 21.6204 22.0117 21.7288C21.8507 21.7512 21.7217 21.6171 21.7388 21.4583C22.1361 17.8272 18.9774 16.1056 18.1601 15.7099C18.1258 15.6908 18.1178 15.664 18.1212 15.6462C18.1235 15.635 18.1384 15.6171 18.1646 15.6137C19.9329 15.5802 21.8347 15.8184 22.5368 15.986Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default memo(Teams);
