import { memo } from 'react';

interface IUploads {
  className?: string;
}

const Uploads = ({ className }: IUploads): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4289 3.97918H15.8191C15.1973 3.97918 14.6171 3.68905 14.2302 3.20549L13.0145 1.58905C12.227 0.580498 11.0264 -0.01358 9.74012 0.000235781H7.55723C2.5421 0.000235781 0 2.90155 0 8.62127V18.9969C0 24.3436 3.30197 27.6318 8.62104 27.6318H21.3651C26.7118 27.6318 29.9999 24.3436 29.9999 19.0107V12.2825C30.0138 6.70088 27.5269 3.97918 22.4289 3.97918ZM17.7232 15.8288C18.1969 16.3025 18.9074 16.3025 19.3811 15.8288C19.8548 15.3551 19.8548 14.6446 19.3811 14.1709L15.8285 10.6183C15.3548 10.1446 14.6443 10.1446 14.1706 10.6183L10.618 14.1709C10.1443 14.6446 10.1443 15.3551 10.618 15.8288C11.0916 16.3025 11.8022 16.3025 12.2758 15.8288L13.8153 14.2894V18.5525C13.8153 19.263 14.289 19.7367 14.9995 19.7367C15.71 19.7367 16.1837 19.263 16.1837 18.5525V14.2894L17.7232 15.8288Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Uploads);
