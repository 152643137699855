export const storyUrls = {
  createStory: '/story/create',
  getStory: (id: string): string => `/story/${id}`,
  updateStoryConfig: `/story/update`,
  updateMetadata: '/story/metadata/update',
  saveStoryChanges: `/story/save`,
  publishWebStory: '/story/publish',
  checkChanges: (id: string): string => `/story/${id}/export/check`,
  uploadPosters: (id: string): string => `/upload/story/${id}/poster`,
  socialExport: (id: string): string => `/story/${id}/social-export`,
  updateStoryTags: `/story/tags/update`,
};

export const widgetUrls = {
  getWidgets: '/widget/list',
  createWidget: '/widget/create',
  getWidgetConfig: (id: string): string => `/widget/${id}/config`,
  updateMetadata: '/widget/metadata/update',
  duplicateWidget: `/widget/duplicate`,
  deleteWidget: (id: string): string => `/widget/${id}`,
  saveWidgetChanges: (id: string): string => `/save/widget/${id}/changes`,
  updatePublishedData: (): string => `/widget/publisher/data`,
  getJSONData: (id: string): string => `/widget/${id}/json`,
  getLastUserModified: (userId: string): string => `workspace/user/${userId}`,
  updateStory: `/widget/update/story`,
};

export const gifsUrls = {
  getGifs: (searchType: string, offset: number, title?: string): string =>
    `${title ? '/gifs' : '/gifs/trending'}?searchType=${searchType}&offset=${offset}${title ? `&search=${title}` : ''}`,
  uploadGif: '/upload/remote/gif',
};

export const imageUrls = {
  images: (page: number, title?: string): string => `/images?&page=${page}${title ? `&search=${title}` : ''}`,
  uploadRemoteImage: '/upload/remote/image',
  /** Set flag userUploadedMedia query param when user upload image from his computer  */
  uploadImage: (workspaceId: string, userId: string) =>
    `/file/upload/image/${workspaceId}/${userId}?userUploadedMedia=true`,
};

export const videoUrls = {
  uploadVideo: (workspaceId: string, userId: string) => `/file/upload/video/${workspaceId}/${userId}`,
  splitVideo: '/file/video/split',
};

export const mediaUrls = {
  deleteMedia: (mediaId: string) => `/file/media/${mediaId}`,
};

export const uploadedMediaUrls = {
  getMedia: (workspaceId: string, userId: string, mediaType: string, limit: number, offset: number): string =>
    `/file/workspace/${workspaceId}/user/${userId}/media?filter[type]=${mediaType}&sort[createdAt]=DESC&limit=${limit}&offset=${offset}`,
};

export const userUrls = {
  login: '/user/login',
  auth: `/user/authenticate`,
  logout: '/user/logout',
  signUp: '/user/register',
  forgotPassword: '/user/forgot/password',
  forgotPasswordToken: (token: string): string => `/user/forgot/password/${token}`,
  resetPassword: '/user/reset/password',
  changePassword: '/user/change/password',
  addFavouriteColor: '/user/add/favourite-color',
  updateUser: '/user/update',
  addBugReport: '/user/report/log',
  deleteCustomSnippet: (id?: string): string => `/snippet/${id}`,
  verifyEmail: '/user/verify-email',
  sendVerificationEmail: '/user/send-verification-email',
  updateTutorialPassed: (userId: string): string => `/user/tutorial-passed/${userId}`,
  refreshToken: '/user/token/refresh',
};

export const domainsUrls = {
  createDomain: '/domain/create',
  removeDomain: (id?: string): string => `/domain/remove/${id}`,
};

export const editorUrls = {
  updateAmp: '/amp',
};

export const templatesUrls = {
  getTemplates: (type: 'global' | 'personal' | 'team'): string => `/template/${type}`,
  deleteUserTemplate: (id: string): string => `/template/${id}`,
  getTemplate: (templateId: string): string => `/template/${templateId}`,
  saveTemplate: (type: 'global' | 'personal' | 'team'): string => `/template/save/${type}`,
  deleteGlobalTemplate: (id: string): string => `/template/global/${id}`,
};

export const videoProcessingUrls = {
  getVideoProcessing: (id: string): string => `/file/video-processing/${id}`,
};

export const workspacesUrls = {
  getWorkspaces: '/workspace',
  getWorkspace: (id: string): string => `/workspace/${id}`,
  createWorkspace: '/workspace/create',
  selectWorkspace: '/user/update-workspace',
  updateSEO: '/workspace/update/seo',
  updateGeneralSettings: '/workspace/update/general',
  deleteSeoImg: '/workspace/seo/image',
  addAnalytics: '/workspace/google-analytics/save',
  getAnalytics: '/workspace/google-analytics',
  addCustomSnippet: '/snippet/create',
  getCustomSnippets: '/snippet/list',
  workspaceUsersList: (offset?: number, limit?: number, sortOptions?: string): string =>
    `/workspace/users/list?offset=${offset}&limit=${limit}&${sortOptions}`,
  selectTeam: '/user/update-team',
  addWorkspaceWUser: 'workspace/users/add',
  removeWorkspaceMember: (userId: string): string => `/workspace/user/${userId}`,
  acceptInvitation: '/workspace/users/accept',
  updateMemberRole: '/workspace/users/update-role',
  getTeamUsers: (): string => `/workspace/team`,
};

export const folderUrls = {
  getUserFolders: (folderId?: string, teamId?: string): string =>
    `/folder${
      teamId && folderId
        ? `?teamId=${teamId}&root=${folderId}`
        : teamId
        ? `?teamId=${teamId}`
        : folderId
        ? `?root=${folderId}`
        : ''
    }`,
  getFoldersAndStories: (folderId?: string | null, query?: string, limit?: number): string => {
    return `/shared/contents?contentType=["STORIES", "FOLDERS"]${folderId ? `&root=${folderId}` : ''}${
      query ? '&' + query : ''
    }${limit ? '&limit=' + limit : ''}`;
  },
  creareFolder: `/folder/create`,
  renameFolder: '/folder/rename',
  moveFolder: 'folder/move',
  deleteFolder: (folderId: string): string => `/folder/${folderId}`,
};
