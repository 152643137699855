import { IImage } from './image';
import { layerTypes } from './layer-types';
import { IGif } from './media/gif';
import presets from 'components/editor-modals/constants/editor-modal-custom-presets';
import swipeups from 'components/editor-modals/constants/editor-modal-swipe-up-presets';
import { IUploadedMedia } from './media/common';
import { StylePreset } from 'redux/services/workspaces/interface';

export interface DragabbleLayerItem {
  _id: string | number;
  type: layerTypes;
  subType?: string;
  index?: number;
  image?: string;
  width: number;
  height: number;
  previewWidth?: number;
  imageContent?: IImage;
  gifContent?: IGif;
  presetContent?: typeof presets[number];
  swipeUpContent?: typeof swipeups[number];
  uploadedMedia?: Partial<IUploadedMedia>;
  preset?: StylePreset;
}

export enum DraggableTypes {
  Layer = 'Layer',
}
