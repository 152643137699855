import Styled from './cropped-layer-styles';
import { ILayer } from '../../../../../interfaces/layer';
import { layerTypes } from 'interfaces/layer-types';
import { Suspense, lazy, memo, useMemo } from 'react';
import { setCroppedElementStyles } from '../../../../../utils/croppingUtils';
import { useAppSelector } from 'hooks';
import 'react-image-crop-fork/dist/ReactCrop.css';

const Cropper = lazy(() => import('components/cropper'));

interface IImageLayerProps {
  layer: ILayer;
  width: number;
  height: number;
  slidePosition: any;
  isActive: boolean;
  inheritVisibility?: boolean;
}

const ImageLayer = ({ isActive, width, height, layer, inheritVisibility }: IImageLayerProps): JSX.Element => {
  const isCroppingModeActive = useAppSelector((state) => state.helpers.isCroppingMode);
  const canRenderCropper = useMemo(() => isActive && isCroppingModeActive, [isActive, isCroppingModeActive]);

  const hasDropShadow = useMemo(() => {
    return (
      !layer.settings.layerSettings.fullscreen &&
      layer.type !== layerTypes.GIFS &&
      +layer.settings.generalSettings.shadow !== 0
    );
  }, [layer.settings.layerSettings.fullscreen, layer.type, layer.settings.generalSettings.shadow]);

  if (canRenderCropper) {
    return (
      <Suspense fallback={<></>}>
        <Cropper layer={layer} />
      </Suspense>
    );
  }

  return (
    <Styled.BackgroundContainer
      className="image-wrapper"
      width={width}
      height={height}
      filter={
        hasDropShadow ? `drop-shadow(1px 1px ${+layer.settings.generalSettings.shadow}px rgba(0,0,0,0.75))` : 'none'
      }
      borderRadius={`${+layer.settings.generalSettings.round * 2}px`}
    >
      <Styled.MediaWrapper
        isActive={!canRenderCropper || layer.settings.layerSettings.fullscreen}
        inheritVisibility={inheritVisibility}
        figureCss={layer?.settings?.layerSettings?.cssFilter?.figure}
        beforeCss={layer?.settings?.layerSettings?.cssFilter?.before}
      >
        <img
          style={setCroppedElementStyles(
            layer.settings?.layerSettings,
            layer.settings?.cropSettings,
            width,
            height,
            canRenderCropper,
          )}
          src={layer?.content?.value || layer?.content?.image?.url}
          alt="user-asset"
        />
      </Styled.MediaWrapper>
    </Styled.BackgroundContainer>
  );
};

export default memo(ImageLayer);
