import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCustomColorsWrapper = styled.div<{ type?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 4px -2px 0;
  min-width: 100%;
`;

interface ICustomColorsWrapper {
  children: ReactNode;
}

const CustomColorsWrapper = ({ children }: ICustomColorsWrapper): JSX.Element => {
  return <StyledCustomColorsWrapper>{children}</StyledCustomColorsWrapper>;
};

export default CustomColorsWrapper;
