import Styled from './widget-editor-device-styled';
import useMutationObservable from '../../hooks/useMutationObservable';
import { environment } from '../../config/environment';
import { loadScript, removeScript } from '../../utils/common';
import { useContext, useEffect, useRef } from 'react';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

interface IWidgetEditorIframeSmartphone {
  updateScrollbars?: () => void;
}

const WidgetEditorIframeSmallSmartphone = ({ updateScrollbars }: IWidgetEditorIframeSmartphone): JSX.Element => {
  const { widget } = useContext(WidgetContext) as WidgetContextType;
  const widgetKey = widget.key;

  const cutnutwidgetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // local - process.env.PUBLIC_URL | other - environment.widgetHost
    loadScript(environment.widgetHost as string);

    return () => {
      removeScript(environment.widgetHost as string);
    };
  }, []);

  useMutationObservable(cutnutwidgetRef.current, updateScrollbars);

  return (
    <Styled.SmartphoneOuterWrapper>
      <Styled.SmartphoneSmallWrapper>
        <Styled.SmartphoneSmallHeader>
          <Styled.HamburgerSmallWrapper>
            <Styled.HamburgerSmallLine />
            <Styled.HamburgerSmallLine />
            <Styled.HamburgerSmallLine />
          </Styled.HamburgerSmallWrapper>

          <Styled.HeaderSmallUser />
        </Styled.SmartphoneSmallHeader>

        <Styled.ExternalWidgetWrapper>
          <div className="cutnutwidget" id={widgetKey} ref={cutnutwidgetRef} />
        </Styled.ExternalWidgetWrapper>

        <Styled.FirstSmallSection>
          <Styled.FirstSmallSectionTitle />

          <div style={{ lineHeight: 0 }}>
            <Styled.FirstSmallSectionText />
            <Styled.FirstSmallSectionText />
            <Styled.FirstSmallSectionText />
          </div>
        </Styled.FirstSmallSection>

        <Styled.SecondSmallSection>
          <Styled.SecondSmallSectionItem>
            <Styled.SecondSmallSectionItemImage />

            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />

            <div style={{ padding: '0 0 4px' }} />

            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
          </Styled.SecondSmallSectionItem>

          <Styled.SecondSmallSectionItem>
            <Styled.SecondSmallSectionItemImage />

            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />

            <div style={{ padding: '0 0 4px' }} />

            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
            <Styled.SecondSmallSectionItemText />
          </Styled.SecondSmallSectionItem>
        </Styled.SecondSmallSection>
      </Styled.SmartphoneSmallWrapper>
    </Styled.SmartphoneOuterWrapper>
  );
};

export default WidgetEditorIframeSmallSmartphone;
