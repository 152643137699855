import { memo } from 'react';

const StoryViews = (): JSX.Element => {
  return (
    <svg
      style={{ background: 'rgba(246, 82, 43, 0.2)', borderRadius: '4px' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4443 9.33312C10.9332 9.33312 9.77764 10.4887 9.77764 11.9998C9.77764 13.5109 10.9332 14.6665 12.4443 14.6665C13.9554 14.6665 15.111 13.5109 15.111 11.9998C15.111 10.4887 13.9554 9.33312 12.4443 9.33312ZM21.2443 11.6442C19.4665 7.46645 16.0888 4.88867 12.4443 4.88867C8.79986 4.88867 5.42208 7.46645 3.6443 11.6442C3.55541 11.9109 3.55541 12.0887 3.6443 12.3553C5.42208 16.5331 8.79986 19.1109 12.4443 19.1109C16.0888 19.1109 19.4665 16.5331 21.2443 12.3553C21.3332 12.0887 21.3332 11.9109 21.2443 11.6442ZM12.4443 16.4442C9.95542 16.4442 7.99986 14.4887 7.99986 11.9998C7.99986 9.5109 9.95542 7.55534 12.4443 7.55534C14.9332 7.55534 16.8888 9.5109 16.8888 11.9998C16.8888 14.4887 14.9332 16.4442 12.4443 16.4442Z"
        fill="#F6522B"
      />
    </svg>
  );
};
export default memo(StoryViews);
