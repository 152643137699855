const VideoIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 8.00437C0.5 3.8647 3.86632 0.5 8.00002 0.5C12.1337 0.5 15.5 3.8647 15.5 8.00437C15.5 12.1353 12.1337 15.5 8.00002 15.5C3.86632 15.5 0.5 12.1353 0.5 8.00437ZM10.751 8.76338C10.8306 8.6838 10.932 8.5608 10.9537 8.53185C11.0696 8.3799 11.1275 8.1918 11.1275 8.00437C11.1275 7.79377 11.0623 7.5984 10.9392 7.43925C10.9292 7.42912 10.9094 7.40767 10.8836 7.37962C10.8355 7.32727 10.7664 7.25197 10.7003 7.18597C10.1067 6.54922 8.55742 5.50725 7.74667 5.18887C7.6236 5.13894 7.31227 5.02968 7.14577 5.02245C6.98647 5.02245 6.83444 5.05863 6.68969 5.13099C6.50872 5.23229 6.36391 5.39148 6.28429 5.57961C6.23361 5.70985 6.15397 6.1006 6.15397 6.10783C6.07434 6.53474 6.03091 7.2294 6.03091 7.99642C6.03091 8.72797 6.07434 9.39293 6.1395 9.8271C6.14128 9.8289 6.14752 9.85995 6.15719 9.90818C6.1866 10.0548 6.24787 10.3604 6.31324 10.4855C6.47251 10.7894 6.78382 10.9776 7.11682 10.9776H7.14577C7.36297 10.9703 7.81905 10.7822 7.81905 10.775C8.58645 10.4566 10.0994 9.4653 10.7075 8.8068L10.751 8.76338Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VideoIcon;
