import ColorPicker from '../../../color-picker';
import EditorSidebarColumn from '../../../editor-sidebar/shared/structure/editor-sidebar-column';
import EditorSidebarFontSelect from '../../../../components/editor-sidebar/editor-sidebar-settings/editor-sidebar-text-settings/editor-sidebar-font-select';
import EditorSidebarHalfColumn from '../../../editor-sidebar/shared/structure/editor-sidebar-half-column';
import EditorSidebarLabel from '../../../editor-sidebar/shared/elements/editor-sidebar-label';
import EditorSidebarRowWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-row-wrapper';
import EditorSidebarSectionTitle from '../../../editor-sidebar/shared/elements/editor-sidebar-section-title';
import EditorSidebarSectionTitleWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-section-title-wrapper';
import EditorSidebarSectionWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-section-wrapper';
import EditorSidebarValuesWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-values-wrapper';
import Select from '../../../shared/select';
import ToggleSwitch from '../../../shared/toggle-switch';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';
import { defaultTextFontColor, FONT_TYPE } from '../../../../config/constants';
import { GoogleFont } from 'redux/services/fonts/interface';
import { IColorType } from '../../../../interfaces/colors';
import { IFont } from '../../../../interfaces/fonts';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector, useDebounce, useDidUpdateEffect } from 'hooks';
import { useGoogleFontsListQuery, useWorkspaceFontsListQuery } from 'redux/services/fonts/fonts';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

import {
  getDefaultFontWeight,
  getFontOptionName,
  getFontWeights,
  loadFontFamily,
} from '../../../../utils/textEditorUtils';

const fontSizes = [...Array(100).keys()].map((i) => ({
  name: i + 1,
  value: i + 1,
}));

const WidgetEditorSidebarTextTitleSettings = (): JSX.Element => {
  const storyFonts = useAppSelector((state) => state.ampStory.present.fonts);
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  // Fetch workspace specific fonts;
  const { data: googleFonts } = useGoogleFontsListQuery();
  const { data: workspaceFonts } = useWorkspaceFontsListQuery(selectedWorkspaceId ?? skipToken);

  const { widget, setWidget } = useContext(WidgetContext) as WidgetContextType;
  const { setChangesCount } = useContext(ChangesContext) as ChangesContextType;

  const {
    titlefontsize: titleFontSize,
    titlefontcolor: defaultTitleFontColor,
    hastitleshadow: hasTitleShadow,
    titlefontfamily: titleFontFamily,
    titleenabled: isTitleEnabled,
  } = widget.editorDetails.style;

  const [searchFont, setSearchFont] = useState('');
  const [titleFontColor, setTitleFontColor] = useState(defaultTitleFontColor);

  const debouncedTitleFontColor = useDebounce<string>(titleFontColor, 50);

  const filteredFonts = useMemo(() => {
    const filteredUserFonts = workspaceFonts
      ?.filter((font) => font.family.toLowerCase().includes(searchFont.toLowerCase()))
      ?.map((font) => ({ name: font.family, value: getFontOptionName(font.family, false), fontData: font }));

    const filteredGoogleFonts = googleFonts
      ?.filter((font) => font.family.toLowerCase().includes(searchFont.toLowerCase()))
      ?.map((font) => ({ name: font.family, value: getFontOptionName(font.family, false), fontData: font }));

    return {
      user: {
        title: 'Brand fonts',
        options: filteredUserFonts,
      },
      google: {
        options: filteredGoogleFonts,
      },
    };
  }, [searchFont, googleFonts, workspaceFonts]);

  const onFontSizeChange = useCallback(
    (option: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            titlefontsize: option,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onFontColorChange = useCallback((_: unknown, value: any) => {
    setTitleFontColor(value);
  }, []);

  const onFontColorReset = useCallback(() => setTitleFontColor('rgba(255, 255, 255, 1)'), []);

  const onHasTitleShadowChange = useCallback(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          hastitleshadow: prev.editorDetails.style.hastitleshadow ? 0 : 1,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [setWidget, setChangesCount]);

  const onFontFamilyChange = useCallback(
    (option: string) => {
      const fontFamily = option.replaceAll('_', ' ');
      const font = [...(workspaceFonts ?? []), ...(googleFonts ?? [])].find(
        (storyFont) => storyFont.family === fontFamily,
      );
      const existingFont: IFont = storyFonts.find((storyFont: IFont) => storyFont.family === font?.family);

      if (font) {
        if (!existingFont) {
          loadFontFamily(font as any);
        }
        const fontWeight = getDefaultFontWeight(getFontWeights(font.weight)).value;

        let url = '';

        if (font.fontType === FONT_TYPE.USER_FONT) {
          url = font?.paths?.[fontWeight] ?? '';
        } else if (font.fontType === FONT_TYPE.GOOGLE_FONT) {
          url = (font as GoogleFont).file ?? '';
        }

        setWidget((prev) => ({
          ...prev,
          editorDetails: {
            ...prev.editorDetails,
            style: {
              ...prev.editorDetails.style,
              titlefontfamily: option.replaceAll('_', ' '),
              titlefontfamilyurl: url,
            },
          },
        }));
        setChangesCount((prev) => prev + 1);
      }
    },
    [setWidget, setChangesCount, googleFonts, workspaceFonts, storyFonts],
  );

  const onToggleTitle = () => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          titleenabled: prev.editorDetails.style.titleenabled ? 0 : 1,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  };

  useDidUpdateEffect(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          titlefontcolor: debouncedTitleFontColor,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [debouncedTitleFontColor, setWidget, setChangesCount]);

  return (
    <EditorSidebarSectionWrapper>
      <EditorSidebarSectionTitleWrapper>
        <EditorSidebarSectionTitle text={'Title'} />
        <ToggleSwitch isOn={isTitleEnabled} onClick={onToggleTitle} />
      </EditorSidebarSectionTitleWrapper>
      {isTitleEnabled ? (
        <>
          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Font'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarColumn>
                <EditorSidebarFontSelect
                  widgetScrollIdentifier={'widget-title'}
                  isDisabled={false}
                  searchValue={searchFont}
                  selectOption={titleFontFamily ? titleFontFamily.replaceAll('_', ' ') : 'Heebo'}
                  optionsObject={filteredFonts as any}
                  onSearchChange={setSearchFont}
                  onSelect={onFontFamilyChange}
                />
              </EditorSidebarColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Size'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <Select
                  isDisabled={false}
                  selectOption={titleFontSize || 24}
                  options={fontSizes}
                  onSelect={onFontSizeChange}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Color'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <ColorPicker
                  colorType={IColorType.FillColor}
                  isDisabled={false}
                  defaultLeftColor={defaultTextFontColor}
                  leftColor={titleFontColor}
                  handleColorChange={onFontColorChange}
                  // handleColorReset={onFontColorReset}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Shadow'} />

            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <ToggleSwitch isOn={hasTitleShadow} onClick={onHasTitleShadowChange} />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>
        </>
      ) : (
        <></>
      )}
    </EditorSidebarSectionWrapper>
  );
};

export default memo(WidgetEditorSidebarTextTitleSettings);
