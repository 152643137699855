import { memo, useMemo, lazy, Suspense } from 'react';
import Modal from 'react-modal';
import { Times } from '../../icons';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../buttons';

export enum InaccessibleModalType {
  STORY = 'STORY',
  WIDGET = 'WIDGET',
}

interface IInaccessibleModal {
  isOpen: boolean;
  user: string;
  type?: InaccessibleModalType;
  onClose: () => void;
  onAccept: () => void;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px;
`;

const Title = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
  margin: 0 15px 0 0;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 0 0;
  color: var(--white);
  background: none;
  outline: none;
  border: none;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.12s ease;
  margin: 1px 0 0;
  &:hover {
    border: none;
    outline: none;
    background: var(--shade-700);
  }
`;

const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0 0 18px;
`;

const StoryInaccessible = lazy(() => import('components/icons/story-inaccessible'));

const InaccessibleIcon = styled(StoryInaccessible)`
  margin: auto;
  max-width: 100%;
`;

const customStyles = {
  overlay: {
    backgroundColor: 'var(--shade-500-85)',
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '32px 24px',
    maxWidth: '390px',
    borderRadius: '8px',
    border: 'none',
    boxShadow: '24px 32px 72px var(--black-18)',
    display: 'flex',
    background: 'var(--shade-900-85)',
  },
};

const InaccessibleModal = ({ isOpen, user, type, onClose, onAccept }: IInaccessibleModal): JSX.Element => {
  const inaccessibleType = useMemo(() => {
    return type === InaccessibleModalType.STORY ? 'Story' : 'Carousel';
  }, [type]);

  return (
    <Modal
      closeTimeoutMS={300}
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
    >
      <ContentWrapper>
        <TitleWrapper>
          <Title>
            {user} is working on this {inaccessibleType}
          </Title>

          <CloseButton onClick={onClose}>
            <Times />
          </CloseButton>
        </TitleWrapper>

        <Text>
          One of your team member is currently working on this {inaccessibleType}. You will be able to open it again as
          soon as they close it.
        </Text>
        <Suspense fallback={<></>}>
          <InaccessibleIcon />
        </Suspense>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <PrimaryButton text="Back to Dashboard" onClick={onClose} />
          <SecondaryButton text={`Open ${inaccessibleType} anyway`} onClick={onAccept} />
        </div>
      </ContentWrapper>
    </Modal>
  );
};

export default memo(InaccessibleModal);
