import { memo } from 'react';

interface IPartyPopper {
  className?: string;
}

const EmojiPartyPopper = ({ className }: IPartyPopper): JSX.Element => {
  return (
    <svg
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3989 22.228L2.90795 31.2958C1.50707 31.8364 0.114879 30.498 0.660217 29.135L10.0516 7.96729"
        fill="#FFB636"
      />
      <path
        d="M0.660065 29.135L1.40643 27.4528C1.47043 27.3582 1.53424 27.2634 1.59831 27.1688C5.31062 21.6848 9.07732 16.2343 12.9727 10.8708L17.3403 15.2121C14.0449 17.406 3.34434 28.1289 1.10299 30.9732C0.615077 30.538 0.377954 29.8402 0.660065 29.135Z"
        fill="#FFD469"
      />
      <path
        d="M19.6179 12.7772C23.6046 16.6431 25.7851 20.7965 24.4883 22.0541C23.1914 23.3117 18.9082 21.1972 14.9215 17.3313C10.9347 13.4655 8.75428 9.31209 10.0511 8.05447C11.348 6.79684 15.6312 8.91128 19.6179 12.7772Z"
        fill="#A06C33"
      />
      <path
        d="M10.0291 20.4733C10.0291 20.849 9.71498 21.1536 9.32755 21.1536C8.94012 21.1536 8.62598 20.849 8.62598 20.4733C8.62598 20.0975 8.94006 19.793 9.32755 19.793C9.71504 19.793 10.0291 20.0975 10.0291 20.4733Z"
        fill="#F7F9AA"
      />
      <path
        d="M32.3549 13.3226C32.3549 13.8618 31.9041 14.2989 31.348 14.2989C30.7919 14.2989 30.3411 13.8618 30.3411 13.3226C30.3411 12.7833 30.7919 12.3462 31.348 12.3462C31.9041 12.3462 32.3549 12.7833 32.3549 13.3226ZM25.6309 26.3239C25.0748 26.3239 24.624 26.7611 24.624 27.3003C24.624 27.8396 25.0748 28.2767 25.6309 28.2767C26.187 28.2767 26.6378 27.8396 26.6378 27.3003C26.6378 26.7611 26.1869 26.3239 25.6309 26.3239Z"
        fill="#FFB636"
      />
      <path
        d="M22.9417 4.9702C22.8553 5.53676 22.4778 6.0372 21.9059 6.3432C21.5111 6.55445 21.0467 6.66426 20.5648 6.66426C20.4113 6.66419 20.258 6.6532 20.1061 6.63139C19.7653 6.58251 19.4241 6.63239 19.1702 6.7682C19.0564 6.82907 18.8609 6.96226 18.8291 7.17057C18.7974 7.37889 18.9449 7.56126 19.0355 7.65145C19.2326 7.84739 19.5334 7.98776 19.8637 8.0402C19.8728 8.04114 19.8818 8.04145 19.8909 8.04276C21.2843 8.24251 22.2796 9.31276 22.1094 10.4286C22.0231 10.9952 21.6456 11.4956 21.0736 11.8016C20.6788 12.0129 20.2145 12.1227 19.7326 12.1227C19.5791 12.1226 19.4258 12.1116 19.2739 12.0898C18.9331 12.0409 18.5918 12.0908 18.338 12.2266C18.2241 12.2875 18.0286 12.4207 17.9968 12.629C17.9459 12.9633 18.3692 13.4047 19.0584 13.5035C19.4333 13.5572 19.6923 13.8955 19.6369 14.2591C19.5865 14.5898 19.2935 14.8274 18.9588 14.8274C18.9254 14.8274 18.8916 14.825 18.8576 14.8201C17.4642 14.6204 16.469 13.5501 16.639 12.4343C16.7254 11.8677 17.1029 11.3673 17.6749 11.0613C18.194 10.7835 18.8332 10.6811 19.4747 10.7731C19.8155 10.8219 20.1566 10.7721 20.4105 10.6363C20.5243 10.5754 20.7199 10.4422 20.7517 10.2339C20.8017 9.90501 20.3922 9.4732 19.7228 9.36526C19.7118 9.3642 19.7008 9.36332 19.6898 9.36176C19.0484 9.26976 18.4671 8.99251 18.053 8.58095C17.5969 8.12757 17.3848 7.54257 17.4713 6.97589C17.5576 6.40932 17.9351 5.90889 18.507 5.60289C19.0262 5.32514 19.6654 5.22276 20.3068 5.3147C20.6475 5.36345 20.9888 5.3137 21.2427 5.17789C21.3565 5.11701 21.552 4.98382 21.5838 4.77551C21.6155 4.5672 21.4681 4.38482 21.3774 4.29463C21.1749 4.09338 20.8632 3.94988 20.5223 3.90107C20.1474 3.84732 19.8883 3.50901 19.9437 3.14538C19.9991 2.78176 20.3481 2.53088 20.7231 2.58432C21.3644 2.67632 21.9458 2.95357 22.3598 3.36513C22.816 3.81857 23.0281 4.40357 22.9417 4.9702Z"
        fill="#BEA4FF"
      />
      <path
        d="M5.26738 9.98046C5.26738 10.5915 4.75652 11.0869 4.12637 11.0869C3.49621 11.0869 2.98535 10.5915 2.98535 9.98046C2.98535 9.3694 3.49621 8.87402 4.12637 8.87402C4.75652 8.87402 5.26738 9.36934 5.26738 9.98046ZM25.6632 8.70215C24.9352 8.70215 24.345 9.27446 24.345 9.9804C24.345 10.6863 24.9352 11.2586 25.6632 11.2586C26.3912 11.2586 26.9814 10.6863 26.9814 9.9804C26.9814 9.27446 26.3913 8.70215 25.6632 8.70215ZM13.8911 24.7436C13.163 24.7436 12.5729 25.3159 12.5729 26.0218C12.5729 26.7278 13.1631 27.3001 13.8911 27.3001C14.6191 27.3001 15.2093 26.7278 15.2093 26.0218C15.2093 25.3159 14.6191 24.7436 13.8911 24.7436ZM31.3774 18.8907C31.5768 18.5164 31.4254 18.0562 31.0394 17.8629C30.8855 17.7859 30.7018 17.6836 30.4891 17.5653C28.6204 16.5258 24.7322 14.3627 20.1539 17.9471C19.8159 18.2118 19.7631 18.6921 20.036 19.0198C20.3089 19.3476 20.8041 19.3989 21.1422 19.1341C24.8828 16.2056 27.9022 17.8851 29.7062 18.8886C29.9255 19.0106 30.1327 19.1259 30.3174 19.2184C30.4092 19.2644 30.5094 19.2924 30.6124 19.3008C30.7153 19.3092 30.819 19.2979 30.9174 19.2675C31.0158 19.237 31.1071 19.1881 31.186 19.1234C31.265 19.0588 31.33 18.9797 31.3774 18.8907Z"
        fill="#FF6E83"
      />
      <path
        d="M28.0264 3.92357C28.0264 4.29932 27.7123 4.60389 27.3249 4.60389C26.9374 4.60389 26.6233 4.29932 26.6233 3.92357C26.6233 3.54782 26.9374 3.24326 27.3249 3.24326C27.7124 3.2432 28.0264 3.54782 28.0264 3.92357ZM2.98575 0.743451C2.59826 0.743451 2.28418 1.04801 2.28418 1.42376C2.28418 1.79951 2.59826 2.10408 2.98575 2.10408C3.37324 2.10408 3.68739 1.79951 3.68739 1.42376C3.68739 1.04801 3.37324 0.743451 2.98575 0.743451ZM13.9867 9.62201C14.066 9.47114 14.1639 9.29932 14.2674 9.11745C14.793 8.19426 15.5127 6.93001 15.556 5.45751C15.608 3.68851 14.6579 2.08239 12.7322 0.683763C12.3978 0.440951 11.924 0.507013 11.6738 0.831075C11.4234 1.1552 11.4914 1.6147 11.8257 1.85739C13.3565 2.96914 14.0822 4.13307 14.0445 5.4157C14.0116 6.5327 13.4195 7.57295 12.9436 8.40876C12.834 8.60145 12.7304 8.78339 12.6399 8.95545C12.45 9.31614 12.5977 9.7577 12.9696 9.94176C13.0758 9.99452 13.1934 10.022 13.3127 10.0221C13.4522 10.0222 13.5889 9.98483 13.7079 9.91423C13.8268 9.84362 13.9233 9.74249 13.9867 9.62201Z"
        fill="#59CAFC"
      />
    </svg>
  );
};

export default memo(EmojiPartyPopper);
