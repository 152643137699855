import { memo } from 'react';

interface ITemplates {
  className?: string;
}

const Templates = ({ className }: ITemplates): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C24.714 0 27.0711 0 28.5355 1.46447C29.5339 2.46283 29.8517 3.876 29.9528 6.16797C29.9939 7.09904 30.0144 7.56458 29.7191 7.87323C29.4237 8.18188 28.9433 8.18188 27.9825 8.18188L12.2734 8.18188C12.2733 8.18188 12.2733 8.18188 12.2733 8.18187V8.18187C12.2733 8.18187 12.2733 8.18187 12.2733 8.18187H8.18244C8.18244 8.18187 8.18243 8.18187 8.18243 8.18187V8.18187C8.18243 8.18188 8.18243 8.18188 8.18242 8.18188L2.01746 8.18188C1.05666 8.18188 0.576261 8.18188 0.280926 7.87323C-0.0144092 7.56457 0.0061327 7.09904 0.0472164 6.16797C0.14835 3.876 0.466106 2.46283 1.46447 1.46446C2.92893 0 5.28596 0 10 0H20ZM8.18243 14.2728C8.18243 13.33 8.18243 12.8586 7.88954 12.5657C7.59665 12.2728 7.12524 12.2728 6.18243 12.2728L2 12.2728C1.05719 12.2728 0.585787 12.2728 0.292893 12.5657C0 12.8586 0 13.33 0 14.2728V22.7273C0 27.4413 0 29.7983 1.46447 31.2628C2.46291 32.2612 3.87623 32.579 6.16853 32.6801C7.0996 32.7211 7.56514 32.7417 7.87378 32.4463C8.18243 32.151 8.18243 31.6706 8.18243 30.7098L8.18243 14.2728ZM12.2733 30.7273C12.2733 31.6701 12.2733 32.1415 12.5662 32.4344C12.8591 32.7273 13.3305 32.7273 14.2733 32.7273H20C24.714 32.7273 27.0711 32.7273 28.5355 31.2628C30 29.7983 30 27.4413 30 22.7273L30 14.2728C30 13.33 30 12.8586 29.7071 12.5657C29.4142 12.2728 28.9428 12.2728 28 12.2728L14.2733 12.2728C13.3305 12.2728 12.8591 12.2728 12.5662 12.5657C12.2733 12.8586 12.2733 13.33 12.2733 14.2728L12.2733 30.7273Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Templates);
