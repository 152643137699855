import * as Sentry from '@sentry/react';
import App from './app';
import React from 'react';
import ReactDOM from 'react-dom';
import store from './redux/store';
import { attachInterceptors } from './utils/api';
import { DndProvider } from 'react-dnd';
import { ErrorView } from './components/error';
import { environment } from 'config/environment';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import './css/index.css';
import 'rc-slider/assets/index.css';
import 'rc-menu/assets/index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

// Init axios interceptors
attachInterceptors(store);

Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.sentryENV,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorView}>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
