import CloseModal from '../../components/icons/close-modal';
import InputBasic from '../shared/input-basic';
import Modal from 'react-modal';
import Select from '../../components/shared/select';
import styled from 'styled-components';
import { AddButton, PrimaryButton } from '../../components/buttons';
import { ChangeEvent, memo, useRef, useState } from 'react';
import { DEFAULT_TOAST_CONFIG, USER_ROLE_OPTIONS } from '../../config/constants';
import { IInvitedUsers, USER_ROLES_NAMES, USER_ROLES_VALUES } from '../../interfaces/workspaces';
import { toast } from 'react-toastify';
import { useAppSelector } from 'hooks';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useInviteWorkspaceMembersMutation } from 'redux/services/workspaces/workspaces';
import { useMemo } from 'react';
import { validateEmail } from '../../utils/commonUtils';

type IProps = {
  isOpen: boolean;
  onCancel?: () => void;
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  box-shadow: 24px 32px 72px rgba(0, 0, 0, 0.18);
  background: var(--shade-85-900);
  backdrop-filter: blur(50px);
  border-radius: 12px;
  max-height: 100%;
`;

const MessageContainer = styled.div`
  flex-grow: 10;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const ScrollContainer = styled.div`
  overflow-y: overlay;
  scrollbar-width: none;
  padding-right: 3px;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--shade-300-85);
  }
`;

const Message = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  letter-spacing: 0.01em;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-left: 5px;
  button {
    cursor: pointer;
  }
`;

const AcceptButton = styled(PrimaryButton)`
  padding: 7px 19px;
  min-width: 105px;
`;

interface IRoleOptions {
  [key: string]: {
    name: USER_ROLES_NAMES;
    value: USER_ROLES_VALUES;
  };
}

const USER_ROLES: IRoleOptions = {
  user: { name: USER_ROLES_NAMES.CREATOR, value: USER_ROLES_VALUES.CREATOR },
  admin: { name: USER_ROLES_NAMES.ADMIN, value: USER_ROLES_VALUES.ADMIN },
};

const initalModalInputs = [
  { email: '', role: { name: USER_ROLES_NAMES.CREATOR, value: USER_ROLES_VALUES.CREATOR } },
  { email: '', role: { name: USER_ROLES_NAMES.CREATOR, value: USER_ROLES_VALUES.CREATOR } },
  { email: '', role: { name: USER_ROLES_NAMES.CREATOR, value: USER_ROLES_VALUES.CREATOR } },
];

const customStyles = {
  overlay: {
    backgroundColor: 'var(--shade-500-85)',
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    maxWidth: '390px',
    overflow: 'hidden',
    maxHeight: '496px',
    borderRadius: '12px',
    border: 'none',
    boxShadow: '24px 32px 72px var(--black-18)',
    display: 'flex',
    background: 'var(--shade-900-95)',
  },
};

const InviteMembersModal = ({ isOpen, onCancel }: IProps): JSX.Element => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [inputList, setInputList] = useState(initalModalInputs);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const inviteModalRoles = useMemo(() => USER_ROLE_OPTIONS.slice(0, USER_ROLE_OPTIONS.length - 1), []);
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const [inviteMembers] = useInviteWorkspaceMembersMutation();

  const handleEmailChange = useCallback(
    (index: number) => (e: ChangeEvent<HTMLInputElement>) =>
      setInputList((list) =>
        list.map((listElement, listElementIndex) =>
          listElementIndex === index ? { ...listElement, email: e?.target?.value } : listElement,
        ),
      ),
    [],
  );

  const handleRoleChange = useCallback(
    (option: USER_ROLES_VALUES, index: number) =>
      setInputList((list) =>
        list.map((listElement, listElementIndex) =>
          listElementIndex === index ? { ...listElement, role: USER_ROLES[option] } : listElement,
        ),
      ),
    [],
  );

  const handleAddClick = useCallback(
    () =>
      setInputList((list) => [
        ...list,
        { email: '', role: { value: USER_ROLES_VALUES.CREATOR, name: USER_ROLES_NAMES.CREATOR } },
      ]),
    [],
  );

  const onModalCloseHandler = useCallback(() => {
    setTimeout(() => {
      setInputList(initalModalInputs);
    }, 400);
    onCancel && onCancel();
  }, [onCancel]);

  const handleSendInvites = useCallback(async () => {
    const users: IInvitedUsers = inputList.flatMap((el) => {
      if (el.email !== '') {
        return [{ email: el.email, role: el.role.value }];
      }
      return [];
    });

    if (selectedWorkspaceId) {
      await inviteMembers({ users, workspaceId: selectedWorkspaceId }).unwrap();
    }

    toast.info('Invitation has been sent successfully', { ...DEFAULT_TOAST_CONFIG, autoClose: 3000 });
    onModalCloseHandler();
  }, [inputList, inviteMembers, onModalCloseHandler, selectedWorkspaceId]);

  useEffect(() => {
    setIsDisabled(inputList.some((el) => validateEmail(el.email)));
    scrollContainerRef?.current?.scrollTo({ top: scrollContainerRef?.current?.scrollHeight, behavior: 'smooth' });
  }, [inputList]);

  return (
    <>
      <Modal
        closeTimeoutMS={300}
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={onModalCloseHandler}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
      >
        <ModalContainer>
          <MessageContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 5px 20px 15px' }}>
              <Message>Invite team members</Message>
              <div onClick={onModalCloseHandler}>
                <CloseModal />
              </div>
            </div>
            <ScrollContainer style={{ overflow: 'overlay' }} ref={scrollContainerRef}>
              {inputList.map((inp, i) => (
                <div key={i} style={{ display: 'flex', margin: '15px 0' }}>
                  <div style={{ flex: '0 0 70%', margin: '0px 6px' }}>
                    <InputBasic
                      name="email"
                      type="email"
                      onChange={handleEmailChange(i)}
                      placeholder={'Enter an email address'}
                      value={inp.email}
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <Select
                      onSelect={(e) => handleRoleChange(e as USER_ROLES_VALUES, i)}
                      selectOption={inp.role.name}
                      options={inviteModalRoles}
                    />
                  </div>
                </div>
              ))}
            </ScrollContainer>
            <ButtonContainer>
              <AddButton onClick={handleAddClick} text="Add another" />
              <AcceptButton text={'Send Invites'} onClick={handleSendInvites} isDisabled={!isDisabled} />
            </ButtonContainer>
          </MessageContainer>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default memo(InviteMembersModal);
