import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { Store } from 'redux';
import { toast } from 'react-toastify';
import { environment } from '../config/environment';
import { catchErrors } from '../redux/features/error/errorSlice';
import { DEFAULT_TOAST_CONFIG } from '../config/constants';
import { userUrls } from 'config/urls';
import { refreshTokens } from 'redux/features/auth/authSlice';
import { mutex } from 'redux/base-query';

const api = axios.create({
  baseURL: environment.host,
});

const attachInterceptors = (store: Store): void => {
  // Request interceptor for API calls
  api.interceptors.request.use(
    (config) => {
      // Get token from local storage
      const value = localStorage.getItem('accessToken');
      config.headers = {
        Authorization: `Bearer ${value}`,
        ...config.headers,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  api.interceptors.request.use(
    async (config) => {
      await mutex.waitForUnlock();
      /* Disable pending requests */

      // removePending(config); // Check previous requests to cancel before the request starts
      // addPending(config); // Add current request to pending
      // other code before request
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Response interceptor for API calls
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();

          originalRequest._retry = true;
          try {
            const { data } = await api.post(userUrls.refreshToken, {
              token: localStorage.getItem('refreshToken'),
            });

            if (data) {
              // Set tokens
              localStorage.setItem('accessToken', data?.accessToken?.token);
              localStorage.setItem('accessToken:exp', data?.accessToken?.exp.toString());
              localStorage.setItem('accessToken:iat', data?.accessToken?.iat.toString());

              localStorage.setItem('refreshToken', data?.refreshToken?.token);
              localStorage.setItem('refreshToken:exp', data?.refreshToken?.exp.toString());
              localStorage.setItem('refreshToken:iat', data?.refreshToken?.iat.toString());

              axios.defaults.headers.common['Authorization'] = `Bearer ${data?.accessToken?.token}`;
              store.dispatch(refreshTokens(data));
              originalRequest.headers = {
                ...originalRequest?.headers,
                Authorization: `Bearer ${data?.accessToken?.token}`,
              };
              return api(originalRequest);
            }
          } catch (err) {
            console.error(err);
          } finally {
            release();
          }

          // Set tokens
          localStorage.removeItem('accessToken');
          localStorage.removeItem('accessToken:exp');
          localStorage.removeItem('accessToken:iat');

          localStorage.removeItem('refreshToken');
          localStorage.removeItem('refreshToken:exp');
          localStorage.removeItem('refreshToken:iat');
        } else {
          await mutex.waitForUnlock();
          return api(originalRequest);
        }
      }
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => {
      /* Disable pending requests */
      // removePending(response.config); // Remove this request at the end of the request
      return response;
    },
    (error) => {
      if (axios.isCancel(error)) {
        // Repeated request handle
        console.info('repeated request: ' + error.message);
      } else {
        const errorMsg = getErrorMessage(error);

        toast.info(errorMsg, DEFAULT_TOAST_CONFIG);
        // Do we need reducer ?
        store.dispatch(catchErrors(error));
      }
      return Promise.reject(error);
    },
  );
};

// Declare a Map to store the identification and cancellation functions for each request
const pending = new Map();
/**
 * Add Request
 * @param {Object} config
 */
const addPending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url, qs.stringify(config.params)].join('&');
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        // If the current request does not exist in pending, add it
        pending.set(url, cancel);
      }
    });
};

/**
 * Remove Request
 * @param {Object} config
 */
const removePending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url, qs.stringify(config.params)].join('&');

  if (pending.has(url)) {
    // If the current request identity exists in pending, you need to cancel the current request and remove it
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};

/**
 * Empty requests in pending (called on route jumps)
 */
export const clearPending = (): void => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

export const checkPending = ({
  method,
  url,
  params,
}: {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  params?: string | string[];
}): boolean => {
  const request = [method.toLowerCase(), url, qs.stringify(params)].join('&');

  return pending.has(request);
};

export const getErrorMessage = (error: AxiosError): string => {
  const errorType = error.response?.data?.errors?.[0]?.title;
  const errorMessage = error.response?.data?.errors?.[0]?.detail;

  if (process.env.NODE_ENV === 'development') {
    return errorMessage;
  }

  switch (errorType) {
    case 'InvalidCredentials':
      return 'Your session has ended. Please login again.';
    default:
      return 'Whoops. Something went wrong. We are working on it.';
  }
};

export { attachInterceptors };
export default api;
