import styled from 'styled-components';
import { memo } from 'react';
import { useAppSelector } from 'hooks';

const DefaultImageBorder = styled.div`
  border: 1.5px solid var(--shade-300);
  background: var(--shade-700-85);
  box-sizing: border-box;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DefaultImageFill = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--shade-300);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DefaultImageLetter = styled.div`
  color: white;
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
`;

const ProfileAvatar = (): JSX.Element => {
  const userFirstLetter = useAppSelector((state) => state.auth.user?.username.charAt(0).toUpperCase());

  return (
    <DefaultImageBorder>
      <DefaultImageFill>
        <DefaultImageLetter>{userFirstLetter}</DefaultImageLetter>
      </DefaultImageFill>
    </DefaultImageBorder>
  );
};

export default memo(ProfileAvatar);
