import deviceViewUrl from '../../../assets/images/tutorial/device-view.webp';
import TutorialSectionText from '../shared/tutorial-section-text';
import { features } from './constants';
import { TutorialTypesPassed } from 'redux/services/auth/interface';
import { useAppSelector } from '../../../hooks';
import { useUpdateTutorialFieldMutation } from 'redux/services/auth/auth';

import {
  TutorialModalHeader,
  TutorialOuterWrapper,
  TutorialModalWrapper,
  TutorialModalBody,
  TutorialModalFooter,
  SharedStyled,
  Feature,
  TutorialModalBodyWrapper,
} from '../shared';
import { useRef } from 'react';
import useResizeModalBodyHeight from '../hooks/useResizeModalBodyHeight';

const TutorialCarousel = (): JSX.Element => {
  const [updateTutorialField] = useUpdateTutorialFieldMutation();
  const headerRef = useRef<null | HTMLDivElement>(null);
  const footerRef = useRef<null | HTMLDivElement>(null);
  const outerWrapperRef = useRef<null | HTMLDivElement>(null);
  const { bodyHeight } = useResizeModalBodyHeight({
    header: headerRef.current,
    footer: footerRef.current,
    modalWrapper: outerWrapperRef.current,
  });
  const userId = useAppSelector((state) => state.auth.user?._id);

  const onMainButtonClick = () => {
    if (userId) {
      updateTutorialField({ type: TutorialTypesPassed.Carousel, userId });
    }
  };

  return (
    <TutorialOuterWrapper propRef={outerWrapperRef}>
      <TutorialModalWrapper>
        <TutorialModalHeader
          title={'Bring the Story Experience to your Website'}
          subTitle={'Keep your readers engaged using Zazu’s Story Carousel'}
          propRef={headerRef}
        />

        <TutorialModalBody>
          <TutorialModalBodyWrapper maxHeight={bodyHeight} padding={'0 15px'}>
            <SharedStyled.ContentRow $padding={'50px 5px 50px'} $alignItems="flex-center">
              <SharedStyled.ContentColumn $padding={'0 40px 0 0'}>
                <TutorialSectionText
                  text={'Create Instagram style carousels that can be easily embedded into your website.'}
                  maxWidth={'306px'}
                />

                <SharedStyled.Row>
                  {features.map((feature) => (
                    <SharedStyled.FeatureColumn key={feature.textInfo.text} style={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Feature textInfo={feature.textInfo} icon={feature.icon} />
                    </SharedStyled.FeatureColumn>
                  ))}
                </SharedStyled.Row>
              </SharedStyled.ContentColumn>

              <SharedStyled.ImageColumn
                $padding={'0'}
                $margin={'0 0 20px'}
                style={{
                  background: 'none',
                  order: 2,
                }}
              >
                <img src={deviceViewUrl} width={'290px'} height={'385px'} alt={'Device view'} />
              </SharedStyled.ImageColumn>
            </SharedStyled.ContentRow>
          </TutorialModalBodyWrapper>
        </TutorialModalBody>

        <TutorialModalFooter propRef={footerRef}>
          <SharedStyled.Link
            href="https://help.zazuapp.co/en/category/web-story-carousel-u8dgeq/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get more help
          </SharedStyled.Link>

          <SharedStyled.MainButton text={'Got it'} onClick={onMainButtonClick} />
        </TutorialModalFooter>
      </TutorialModalWrapper>
    </TutorialOuterWrapper>
  );
};

export default TutorialCarousel;
