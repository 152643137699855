import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import styled from 'styled-components';
import { storyIds } from '../../../../config/constants';
import { buildPreviewLink } from '../../../../utils/preview';
import { RefObject } from 'react';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  margin: 0 0 16px;
`;

const Iframe = styled.iframe`
  border-radius: 6px;
  &.bottom {
    z-index: 1;
  }
  &.middle {
    z-index: 1;
  }
  body {
    overflow: hidden;
  }
  .i-amphtml-story-share-pill {
    display: none;
  }
`;

interface IStoryIframe {
  size: {
    width: number;
    height: number;
  };
  propRef?: RefObject<HTMLDivElement>;
}

const StoryIframe = ({ size, propRef }: IStoryIframe): JSX.Element => {
  const storyId = useSelector((state: RootState) => state.ampStory.present._id);

  return (
    <Wrapper ref={propRef}>
      <Iframe
        src={buildPreviewLink(storyId)}
        className="top"
        id={storyIds.exportModal}
        frameBorder="0"
        width={`${size.width}px`}
        height={`${size.height}px`}
        allowFullScreen={true}
      />
    </Wrapper>
  );
};

export default StoryIframe;
