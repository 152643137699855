import styled from 'styled-components';
import { ReactNode } from 'react';

interface ICropperWrapper {
  children: ReactNode;
}

const Wrapper = styled.div`
  height: 360px;
  width: 100%;
  position: relative;
  margin: 0 0 32px;
`;

const CropperWrapper = ({ children }: ICropperWrapper) => {
  return <Wrapper>{children}</Wrapper>;
};

export default CropperWrapper;
