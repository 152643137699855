import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';

const activeClassName = 'nav-element-active';

interface IStyledNavLinkAttr {
  activeClassName: string;
}

interface IStyledNavLink {
  $isDisabled?: boolean;
  $removeExtraPadding?: boolean;
  $disableTopLevelActive?: boolean;
}

const StyledNavLink = styled(NavLink).attrs<IStyledNavLinkAttr>({ activeClassName })<IStyledNavLink>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 6px 14px 6px ${({ $removeExtraPadding }) => ($removeExtraPadding ? '0' : '38px')};
  margin-bottom: 8px;
  text-decoration: none;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
    `};
  svg > * {
    fill: ${({ $isDisabled }) => ($isDisabled ? 'var(--shade-300)' : 'var(--shade-100-85)')};
  }
  &:hover {
    background: var(--shade-500-85);
  }
  &.${activeClassName} {
    ${({ $disableTopLevelActive }) =>
      !$disableTopLevelActive &&
      css`
        background: var(--primary-10);
        svg > * {
          fill: var(--primary);
        }
        > div {
          color: var(--primary);
        }
      `}
  }
`;

const LinkElementTitle = styled.div<{ isDisabled?: boolean }>`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.333333;
  letter-spacing: 0.01em;
  margin-left: 14px;
  color: ${({ isDisabled }) => (isDisabled ? 'var(--shade-300)' : 'var(--shade-100)')};
`;

interface ILinkElementProps {
  isDisabled?: boolean;
  to: string;
  title: string;
  exact?: boolean;
  children: ReactNode;
  removePadding?: boolean;
  disableTopLevelActive?: boolean;
}

const LinkElement = ({
  isDisabled,
  removePadding,
  to,
  exact,
  title,
  children,
  disableTopLevelActive,
}: ILinkElementProps): JSX.Element => {
  return (
    <StyledNavLink
      $isDisabled={isDisabled}
      $removeExtraPadding={removePadding}
      to={to}
      exact={exact}
      $disableTopLevelActive={disableTopLevelActive}
    >
      {children}
      <LinkElementTitle isDisabled={isDisabled}>{title}</LinkElementTitle>
    </StyledNavLink>
  );
};

export default LinkElement;
