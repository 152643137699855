import { memo } from 'react';

interface IEmptyWidgetListIcon {
  className?: string;
}

const EmptyWidgetListIcon = ({ className }: IEmptyWidgetListIcon): JSX.Element => {
  return (
    <svg
      className={className}
      width="153"
      height="138"
      viewBox="0 0 153 138"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.872 68.8576C135.872 75.0808 140.982 80.1443 147.262 80.1443C150.43 80.1443 153 82.6912 153 85.8294V106.303C153 123.616 138.786 137.7 121.314 137.7H31.694C14.2214 137.7 0 123.616 0 106.303V85.8294C0 82.6912 2.5704 80.1443 5.7375 80.1443C12.0258 80.1443 17.136 75.0808 17.136 68.8576C17.136 62.7935 12.2324 58.2303 5.7375 58.2303C4.21515 58.2303 2.76165 57.6315 1.683 56.5627C0.60435 55.4939 0 54.0461 0 52.5452L0.0153 31.4043C0.0153 14.0914 14.229 0 31.7016 0H121.298C138.771 0 152.992 14.0914 152.992 31.4043L153 51.8857C153 53.3866 152.396 54.842 151.325 55.9032C150.246 56.972 148.792 57.5708 147.262 57.5708C140.982 57.5708 135.872 62.6343 135.872 68.8576ZM93.7283 73.8129L102.748 65.1109C104.316 63.6101 104.859 61.3967 104.178 59.3501C103.505 57.3035 101.746 55.8481 99.6188 55.5524L87.157 53.7484L81.5801 42.5602C80.6239 40.6348 78.6808 39.4372 76.5158 39.4296H76.5005C74.3432 39.4296 72.4001 40.6272 71.4286 42.5526L65.8517 53.7484L53.4128 55.5449C51.2632 55.8481 49.5037 57.3035 48.8228 59.3501C48.1496 61.3967 48.6928 63.6101 50.2534 65.1109L59.2727 73.8129L57.146 86.1154C56.7788 88.2378 57.6433 90.3451 59.4028 91.611C60.3973 92.3159 61.5524 92.6798 62.7229 92.6798C63.6179 92.6798 64.5206 92.4599 65.3468 92.0279L76.5005 86.2215L87.6313 92.0127C89.5591 93.036 91.8464 92.8768 93.5983 91.6034C95.3654 90.3451 96.2299 88.2378 95.8627 86.1154L93.7283 73.8129Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(EmptyWidgetListIcon);
