import toolbarUrl from '../../../assets/images/tutorial/toolbar.webp';
import { PreviewStory, CustomDomains, ShareToSocialMedia } from '../../icons';

import {
  Templates,
  Text,
  Uploads,
  Medias,
  Shapes,
  Interactive,
  Crop,
  Replace,
  Lock,
  Fullscreen,
  Animate,
  CustomFonts,
  VisualEffects,
  Mute,
  Preview,
  SEO,
  Share,
  Domains,
} from 'components/icons/tutorial';

import artboardUrl from '../../../assets/images/tutorial/artboard.webp';
import animationUrl from '../../../assets/images/tutorial/editor-animations.webp';
import menuUrl from '../../../assets/images/tutorial/menu.webp';

const firstSlide = {
  sectionText:
    'Choose from a variety of elements to build your Story. You can also use preset templates or create your own.',
  sectionTextMaxWidth: '423px',
  order: 1,
  columnSize: 'calc(33.33% - 10px)',
  padding: '107px 5px 0',
  imageSection: {
    imageText: '',
    url: toolbarUrl,
    padding: '34px 46px',
    alt: 'Toolbar',
    width: '64px',
    height: '304px',
    margin: '0 0 20px',
  },
  featuresPadding: '0 0 0 56px',
  features: [
    {
      icon: {
        component: <Templates />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Templates',
        maxWidth: '68px',
      },
    },
    {
      icon: {
        component: <Text />,
        width: '30px',
        height: '30px',
      },

      textInfo: {
        text: 'Text',
        maxWidth: '52px',
      },
    },
    {
      icon: {
        component: <Uploads />,
        width: '30px',
        height: '30px',
      },

      textInfo: {
        text: 'Uploads',
        maxWidth: '53px',
      },
    },
    {
      icon: {
        component: <Medias />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Medias',
        maxWidth: '52px',
      },
    },
    {
      icon: {
        component: <Shapes />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Shapes',
        maxWidth: '52px',
      },
    },
    {
      icon: {
        component: <Interactive />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Interactive elements',
        maxWidth: '81px',
      },
    },
  ],
};

const secondSlide = {
  sectionText: 'The Artboard is your canvas. Use it to modify and adjust all layers in real-time.',
  sectionTextMaxWidth: '376px',
  order: 1,
  columnSize: 'calc(50% - 10px)',
  padding: '107px 5px 0',
  featuresPadding: '0 0 0 18px',
  imageSection: {
    imageText: 'Artboard 1',
    url: artboardUrl,
    padding: '20px 40px',
    alt: 'Artboard',
    width: '171px',
    height: '306px',
    margin: '0 0 20px',
  },
  features: [
    {
      icon: {
        component: <Crop />,
        width: '36px',
        height: '36px',
      },
      textInfo: {
        text: 'Crop image and videos',
        maxWidth: '74px',
      },
    },
    {
      icon: {
        component: <Replace />,
        width: '30px',
        height: '30px',
      },

      textInfo: {
        text: 'Replace medias files',
        maxWidth: '106px',
      },
    },
    {
      icon: {
        component: <Lock />,
        width: '30px',
        height: '30px',
      },

      textInfo: {
        text: 'Lock layers for more control',
        maxWidth: '104px',
      },
    },
    {
      icon: {
        component: <Fullscreen />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Bring assets full-screen',
        maxWidth: '84px',
      },
    },
  ],
};

const thirdSlide = {
  sectionText: 'The Inspector Sidebar lets you customize your layers to make your Story unique.',
  sectionTextMaxWidth: '308px',
  order: 2,
  columnSize: 'calc(50% - 10px)',
  padding: '107px 5px 0',
  featuresPadding: '0 43px 0 0',
  imageSection: {
    imageText: '',
    url: animationUrl,
    padding: '28px',
    alt: 'Animation sidebar',
    width: '267px',
    height: '342px',
    margin: '0 0 5px',
  },
  features: [
    {
      icon: {
        component: <Animate />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Animate layers',
        maxWidth: '96px',
      },
    },
    {
      icon: {
        component: <CustomFonts />,
        width: '30px',
        height: '30px',
      },

      textInfo: {
        text: 'Apply custom fonts',
        maxWidth: '92px',
      },
    },
    {
      icon: {
        component: <VisualEffects />,
        width: '30px',
        height: '30px',
      },

      textInfo: {
        text: 'Add visual effects',
        maxWidth: '67px',
      },
    },
    {
      icon: {
        component: <Mute />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Mute videos',
        maxWidth: '79px',
      },
    },
  ],
};

const fourthSlide = {
  sectionText: 'Publish your Web Stories online and make them discoverable on Search Engines.',
  sectionTextMaxWidth: '308px',
  order: 2,
  columnSize: 'calc(50% - 10px)',
  padding: '107px 5px 0',
  featuresPadding: '0 46px 0 0',
  imageSection: {
    imageText: '',
    url: menuUrl,
    padding: '70px 15px 54px 35px',
    alt: 'Editor export menu',
    width: '264px',
    height: '147px',
    margin: '35px 0 0',
  },
  features: [
    {
      icon: {
        component: <Preview />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Preview Story',
        maxWidth: '96px',
      },
    },
    {
      icon: {
        component: <SEO />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Set SEO data',
        maxWidth: '92px',
      },
    },
    {
      icon: {
        component: <Share />,
        width: '30px',
        height: '30px',
      },

      textInfo: {
        text: 'Share to social media',
        maxWidth: '97px',
      },
    },
    {
      icon: {
        component: <Domains />,
        width: '30px',
        height: '30px',
      },
      textInfo: {
        text: 'Custom domains',
        maxWidth: '79px',
      },
    },
  ],
};

export const slides = [firstSlide, secondSlide, thirdSlide, fourthSlide];
