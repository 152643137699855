import { CSSProperties, ReactNode, RefObject } from 'react';
import styled from 'styled-components';

interface IModalOuterWrapper {
  className?: string;
  children: ReactNode;
  maxWidth?: string;
  style?: CSSProperties;
  propRef?: RefObject<HTMLDivElement>;
}

const Wrapper = styled.div<{ $maxWidth?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--shade-500-85);
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 20px min(5%, 80px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 113;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    transition: background 0.12s ease;
  }
  &:hover {
    scrollbar-width: thin;
    scrollbar-color: var(--shade-300-85) transparent;
  }
  &:hover::-webkit-scrollbar {
    width: 3px;
    border-radius: 2px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: var(--shade-300-85);
  }
  > * {
    max-width: ${({ $maxWidth }) => $maxWidth ?? '780px'};
    width: 100%;
    margin: auto;
  }
  * {
    box-sizing: border-box;
  }
`;

const TutorialOuterWrapper = ({ style, maxWidth, className, children, propRef }: IModalOuterWrapper): JSX.Element => {
  return (
    <Wrapper className={className} $maxWidth={maxWidth} style={style} ref={propRef}>
      {children}
    </Wrapper>
  );
};

export default TutorialOuterWrapper;
