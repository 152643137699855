import { useEffect, useRef, useState } from 'react';
// Components
import { BottomBarButtonWrapper } from '../shared';
import { IconButton } from '../../buttons';
import { Layers } from '../../icons';
import EditorLayersMenu from './editor-layers-menu';
// Utils
import { layerButtonResizeObserver } from '../../../utils/resizeObservers';
import { stopPropagation } from '../../../utils/common';

interface IEditorLayers {
  handleLayerClick: (position: number) => void;
}

const EditorLayers = ({ handleLayerClick }: IEditorLayers): JSX.Element => {
  const [isLayersMenuVisible, setIsLayersMenuVisible] = useState(false);
  const layerButtonRef = useRef<HTMLElement>(null);
  const onLayersMenuClick = () => setIsLayersMenuVisible(!isLayersMenuVisible);

  useEffect(() => {
    if (layerButtonRef?.current) {
      layerButtonResizeObserver.observe(layerButtonRef.current);
    }

    return () => {
      if (layerButtonRef?.current) {
        layerButtonResizeObserver.unobserve(layerButtonRef.current);
      }
    };
  }, []);

  return (
    <BottomBarButtonWrapper onClick={stopPropagation}>
      <IconButton onClick={onLayersMenuClick} buttonRef={layerButtonRef}>
        <Layers isActive={isLayersMenuVisible} />
      </IconButton>

      {isLayersMenuVisible && <EditorLayersMenu handleLayerClick={handleLayerClick} />}
    </BottomBarButtonWrapper>
  );
};

export default EditorLayers;
