import { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  width: 200%;
  flex-flow: row wrap;
  transition: transform 225ms linear;
  transform: ${({ isActive }) => (isActive ? 'translateX(-50%)' : 'translateX(0)')};
`;

interface IEditorModalHeaderWrapper {
  isActive?: boolean;
  children: ReactNode;
}

const EditorModalHeaderWrapper = ({ isActive, children }: IEditorModalHeaderWrapper): JSX.Element => {
  return <Wrapper isActive={isActive}>{children}</Wrapper>;
};

export default EditorModalHeaderWrapper;
