import { memo } from 'react';

interface IWidget {
  className?: string;
  mainColor?: string;
}

const Widget = ({ className, mainColor = 'var(--shade-100)' }: IWidget): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ padding: '2px' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.744 12.2152C20.744 13.0868 21.4597 13.796 22.3394 13.796C22.7829 13.796 23.1429 14.1527 23.1429 14.5922V17.4597C23.1429 19.8845 21.1522 21.857 18.7051 21.857H6.15328C3.70614 21.857 1.71436 19.8845 1.71436 17.4597V14.5922C1.71436 14.1527 2.07436 13.796 2.51793 13.796C3.39864 13.796 4.11436 13.0868 4.11436 12.2152C4.11436 11.3659 3.42757 10.7268 2.51793 10.7268C2.30471 10.7268 2.10114 10.6429 1.95007 10.4932C1.799 10.3435 1.71436 10.1408 1.71436 9.93056L1.7165 6.96966C1.7165 4.54487 3.70721 2.57129 6.15436 2.57129H18.7029C21.1501 2.57129 23.1419 4.54487 23.1419 6.96966L23.1429 9.8382C23.1429 10.0484 23.0583 10.2522 22.9083 10.4009C22.7572 10.5506 22.5536 10.6344 22.3394 10.6344C21.4597 10.6344 20.744 11.3436 20.744 12.2152ZM14.8416 12.9085L16.1048 11.6897C16.3244 11.4795 16.4005 11.1695 16.3052 10.8829C16.2109 10.5962 15.9644 10.3924 15.6666 10.351L13.9212 10.0983L13.1402 8.53132C13.0062 8.26167 12.7341 8.09393 12.4309 8.09287H12.4287C12.1266 8.09287 11.8544 8.26061 11.7184 8.53026L10.9373 10.0983L9.19515 10.3499C8.89408 10.3924 8.64765 10.5962 8.5523 10.8829C8.45801 11.1695 8.53408 11.4795 8.75265 11.6897L10.0159 12.9085L9.71801 14.6315C9.66658 14.9288 9.78765 15.2239 10.0341 15.4012C10.1734 15.4999 10.3352 15.5509 10.4991 15.5509C10.6244 15.5509 10.7509 15.5201 10.8666 15.4596L12.4287 14.6464L13.9877 15.4574C14.2577 15.6008 14.578 15.5785 14.8234 15.4001C15.0709 15.2239 15.1919 14.9288 15.1405 14.6315L14.8416 12.9085Z"
        fill={mainColor}
      />
    </svg>
  );
};

export default memo(Widget);
