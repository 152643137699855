import api from '../api';
import { CreateTagRequest, Tag } from './interface';

export const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    tagsList: builder.query<Tag[], string>({
      query: () => 'tag',
      providesTags: (result: any[] | undefined, __, id) => {
        if (result) {
          const tags = [...result.map(({ _id: id }) => ({ type: 'Tags' as const, id }))];
          return [...tags, { type: 'Tags', id }];
        }

        return [{ type: 'Tags', id }];
      },
    }),
    createTag: builder.mutation<Tag, CreateTagRequest>({
      query: ({ name }) => ({
        url: 'tag/create',
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: (_, __, { workspaceId: id }) => [{ type: 'Tags', id }],
    }),
  }),
});

export const { useTagsListQuery, useCreateTagMutation, usePrefetch } = tagsApi;
