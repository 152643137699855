import { memo } from 'react';

interface IReplaceSM {
  className?: string;
}

const BoldSM = ({ className }: IReplaceSM): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="-8 -7 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.375 3.05C7.375 1.625 6.175 0.425003 4.75 0.425003H1.375C0.925 0.425003 0.625 0.725003 0.625 1.175V8.675C0.625 9.125 0.925 9.425 1.375 9.425H4.75C6.175 9.425 7.375 8.225 7.375 6.8C7.375 6.125 7.075 5.375 6.55 4.925C7.075 4.475 7.375 3.8 7.375 3.05ZM4.75 7.925H2.125V5.675H4.75C5.35 5.675 5.875 6.2 5.875 6.8C5.875 7.4 5.35 7.925 4.75 7.925ZM4.75 4.175H2.125V1.925H4.75C5.35 1.925 5.875 2.45 5.875 3.05C5.875 3.65 5.35 4.175 4.75 4.175Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(BoldSM);
