import { memo } from 'react';

interface ICarousel {
  className?: string;
}

const Carousel = ({ className }: ICarousel): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6415 13.5015C26.6415 14.7217 27.6435 15.7146 28.875 15.7146C29.496 15.7146 30 16.214 30 16.8293V20.8438C30 24.2385 27.213 27 23.787 27H6.2145C2.7885 27 0 24.2385 0 20.8438V16.8293C0 16.214 0.504 15.7146 1.125 15.7146C2.358 15.7146 3.36 14.7217 3.36 13.5015C3.36 12.3125 2.3985 11.4177 1.125 11.4177C0.8265 11.4177 0.5415 11.3003 0.33 11.0907C0.1185 10.8812 0 10.5973 0 10.303L0.003 6.15771C0.003 2.76302 2.79 0 6.216 0H23.784C27.21 0 29.9985 2.76302 29.9985 6.15771L30 10.1737C30 10.468 29.8815 10.7533 29.6715 10.9614C29.46 11.171 29.175 11.2884 28.875 11.2884C27.6435 11.2884 26.6415 12.2812 26.6415 13.5015ZM18.3782 15.472L20.1467 13.7658C20.4542 13.4715 20.5607 13.0375 20.4272 12.6362C20.2952 12.2349 19.9502 11.9495 19.5332 11.8916L17.0897 11.5378L15.9962 9.34405C15.8087 8.96653 15.4277 8.7317 15.0032 8.73021H15.0002C14.5772 8.73021 14.1962 8.96505 14.0057 9.34257L12.9122 11.5378L10.4732 11.8901C10.0517 11.9495 9.70667 12.2349 9.57317 12.6362C9.44117 13.0375 9.54767 13.4715 9.85367 13.7658L11.6222 15.472L11.2052 17.8843C11.1332 18.3005 11.3027 18.7136 11.6477 18.9619C11.8427 19.1001 12.0692 19.1714 12.2987 19.1714C12.4742 19.1714 12.6512 19.1283 12.8132 19.0436L15.0002 17.9051L17.1827 19.0406C17.5607 19.2413 18.0092 19.2101 18.3527 18.9604C18.6992 18.7136 18.8687 18.3005 18.7967 17.8843L18.3782 15.472Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Carousel);
