import api from '../api';
import {
  AuthenticateRequest,
  LoginRequest,
  LogoutRequest,
  RefreshRequest,
  RefreshResponse,
  ResendVerificationEmailRequest,
  UpdateTutorialFieldRequest,
  User,
  UserResponse,
  VerifyEmailRequest,
  VerifyEmailResponse,
} from './interface';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'user/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    authenticate: builder.query<User, AuthenticateRequest>({
      query: (credentials) => ({
        url: 'user/authenticate',
        headers: {
          Authorization: `Bearer ${credentials.accessToken?.token}`,
        },
      }),
    }),
    refresh: builder.mutation<RefreshResponse, RefreshRequest>({
      query: (body) => ({
        url: 'user/token/refresh',
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation<void, LogoutRequest>({
      query: (body) => ({
        url: 'user/logout',
        method: 'POST',
        body,
      }),
    }),
    verifyEmail: builder.mutation<VerifyEmailResponse, VerifyEmailRequest>({
      query: (credentials) => ({
        url: 'user/verify-email',
        method: 'PUT',
        body: credentials,
      }),
    }),
    resendVerificationEmail: builder.mutation<void, ResendVerificationEmailRequest>({
      query: (credentials) => ({
        url: 'user/send-verification-email',
        method: 'POST',
        body: credentials,
      }),
    }),
    selectWorkspace: builder.mutation<void, string>({
      query: (workspaceId) => ({
        url: 'user/update-workspace',
        method: 'PUT',
        body: { workspaceId },
      }),
    }),
    selectTeam: builder.mutation<void, string | null>({
      query: (teamId) => ({
        url: 'user/update-team',
        method: 'PUT',
        body: { teamId },
      }),
    }),
    changePassword: builder.mutation<void, string>({
      query: (password) => ({
        url: 'user/change/password',
        method: 'PUT',
        body: { password },
      }),
    }),
    updateTutorialField: builder.mutation<void, UpdateTutorialFieldRequest>({
      query: ({ userId, type }) => ({
        url: `user/tutorial-passed/${userId}`,
        method: 'PUT',
        body: { type },
      }),
    }),
    addFavouriteColor: builder.mutation<string[], string>({
      query: (color) => ({
        url: 'user/add/favourite-color',
        method: 'POST',
        body: { color },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useAuthenticateQuery,
  useVerifyEmailMutation,
  useResendVerificationEmailMutation,
  useSelectWorkspaceMutation,
  useSelectTeamMutation,
  useChangePasswordMutation,
  useUpdateTutorialFieldMutation,
  useAddFavouriteColorMutation,
} = authApi;
