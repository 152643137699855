import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { PrimaryButton } from '../buttons';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  font-size: 46px;
  color: var(--white);
  font-family: 'Heebo';
  margin: 0;
`;

const Output = styled.pre`
  background: var(--shade-700-85);
  color: var(--white);
  border: 1px solid var(--primary);
  padding: 12px;
  border-radius: 8px;
`;

const ErrorFallbackComponent = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => (
  <Container>
    <Wrapper>
      <Title>Something went wrong</Title>
      <Output style={{ textAlign: process.env.NODE_ENV === 'production' ? 'center' : 'left' }}>{`${error.message}
      ${process.env.NODE_ENV !== 'production' ? error?.stack : ''}`}</Output>
      <PrimaryButton onClick={resetErrorBoundary} text={'Try Again'} />
    </Wrapper>
  </Container>
);

export default ErrorFallbackComponent;
