import api from '../api';
import {
  AddAnalyticsTrackingAccountRequest,
  CreateCustomDomainRequest,
  DeleteCustomDomainRequest,
  DeleteCustomSnippetRequest,
  DeleteSeoImageRequest,
  Domain,
  GeneralSettingsUpdateRequest,
  GetWorkspaceMembersRequest,
  InitializeSitemapRequest,
  InviteWorkspaceMembersRequest,
  memberSortOrder,
  PopulatedTeam,
  RemoveWorkspaceMemberRequest,
  SaveCustomSnippetRequest,
  SeoSettingsUpdateRequest,
  Snippet,
  UpdateWorkspaceFontStyles,
  UpdateRssMetaRequest,
  UpdateWorkspaceMemberRole,
  Workspace,
  WorkspaceDetails,
  WorkspaceUser,
  CreateWorkspaceColorPalette,
  DeleteWorkspaceColorPalette,
  UpdateWorkspaceColorPalette,
} from './interface';

export const workspacesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    workspacesList: builder.query<Workspace[], void>({
      query: () => 'workspace',
      providesTags: [{ type: 'Workspaces', id: 'LIST' }],
      keepUnusedDataFor: 86400,
      transformResponse: (response: { workspaces: Workspace[] }) => response?.workspaces,
    }),

    getWorkspace: builder.query<WorkspaceDetails, string>({
      query: (workspaceId) => `workspace/${workspaceId}`,
      providesTags: (_, __, id) => [{ type: 'Workspaces', id }],
      keepUnusedDataFor: 60,
      transformResponse: (response: { workspace: WorkspaceDetails }) => response?.workspace,
    }),
    updateWorkspaceGeneralSettings: builder.mutation<WorkspaceDetails, GeneralSettingsUpdateRequest | FormData>({
      query: (body) => ({
        url: 'workspace/update/general',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => [
        { type: 'Workspaces', id: result?._id },
        { type: 'Workspaces', id: 'LIST' },
      ],
    }),
    updateWorkspaceSeoSettings: builder.mutation<WorkspaceDetails, SeoSettingsUpdateRequest | FormData>({
      query: (body) => ({
        url: 'workspace/update/seo',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Workspaces', id: result?._id }],
    }),
    deleteWorkspaceSeoImage: builder.mutation<WorkspaceDetails, DeleteSeoImageRequest>({
      query: (body) => ({
        url: 'workspace/seo/image',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (result, _, { logo }) => {
        if (logo) {
          return [
            { type: 'Workspaces', id: result?._id },
            { type: 'Workspaces', id: 'LIST' },
          ];
        }
        return [{ type: 'Workspaces', id: result?._id }];
      },
    }),
    createWorkspace: builder.mutation<Workspace, Partial<Workspace>>({
      query: (body) => ({
        url: 'workspace/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Workspaces', id: 'LIST' }],
      transformResponse: (response: { workspace: WorkspaceDetails }) => response?.workspace,
    }),
    createCustomDomain: builder.mutation<Domain, CreateCustomDomainRequest>({
      query: ({ domainName }) => ({
        url: 'domain/create',
        method: 'POST',
        body: { domainName },
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Workspaces', id: workspaceId }],
    }),
    deleteCustomDomain: builder.mutation<void, DeleteCustomDomainRequest>({
      query: ({ domainId }) => ({
        url: `domain/remove/${domainId}`,
        method: 'PUT',
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Workspaces', id: workspaceId }],
    }),
    getAnalyticsTrackingAccountId: builder.query<string, string>({
      query: () => 'workspace/google-analytics',
      keepUnusedDataFor: 86400,
      providesTags: (_, __, id) => [{ type: 'GA', id }],
      transformResponse: (response: { googleAnalyticsData: { data: { trackingAccountId: string } } }) =>
        response?.googleAnalyticsData?.data?.trackingAccountId,
    }),
    addAnalyticsTrackingAccountId: builder.mutation<WorkspaceDetails, AddAnalyticsTrackingAccountRequest>({
      query: (body) => ({
        url: 'workspace/google-analytics/save',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [
        { type: 'GA', id: result?._id },
        { type: 'Workspaces', id: result?._id },
      ],
    }),
    getWorkspaceSnippets: builder.query<Snippet[], string>({
      query: () => 'snippet/list',
      keepUnusedDataFor: 86400,
      providesTags: (_, __, id) => [{ type: 'Snippets', id }],
    }),
    saveWorkspaceCustomSnippet: builder.mutation<Snippet, SaveCustomSnippetRequest>({
      query: (body) => ({
        url: 'snippet/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [
        { type: 'Snippets', id: result?.workspace },
        { type: 'Workspaces', id: result?.workspace },
      ],
    }),
    deleteWorkspaceCustomSnippet: builder.mutation<void, DeleteCustomSnippetRequest>({
      query: ({ snippetId }) => ({
        url: `snippet/${snippetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { workspaceId }) => [
        { type: 'Snippets', id: workspaceId },
        { type: 'Workspaces', id: workspaceId },
      ],
    }),
    getWorkspaceMembers: builder.query<WorkspaceUser[], GetWorkspaceMembersRequest>({
      query: ({ offset, limit, sortOptions }) => ({
        url: `workspace/users/list?offset=${offset}&limit=${limit}&${sortOptions}`,
      }),
      providesTags: (_, __, { workspaceId }) => [{ type: 'Members', id: workspaceId }],
      transformResponse: (response: { hasNext: boolean; totalCount: number; users: WorkspaceUser[] }) =>
        response?.users.sort((a, b) => memberSortOrder.indexOf(a.status) - memberSortOrder.indexOf(b.status)),
    }),
    inviteWorkspaceMembers: builder.mutation<void, InviteWorkspaceMembersRequest>({
      query: ({ users }) => ({
        url: 'workspace/users/add',
        method: 'POST',
        body: { users },
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Members', id: workspaceId }],
    }),
    updateWorkspaceMemberRole: builder.mutation<void, UpdateWorkspaceMemberRole>({
      query: ({ userId, role }) => ({
        url: 'workspace/users/update-role',
        method: 'PUT',
        body: { userId, role },
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Members', id: workspaceId }],
    }),
    removeWorkspaceMember: builder.mutation<void, RemoveWorkspaceMemberRequest>({
      query: ({ id }) => ({
        url: `workspace/user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Members', id: workspaceId }],
    }),
    acceptInvitation: builder.mutation<void, string>({
      query: (token) => ({
        url: 'workspace/users/accept',
        method: 'PUT',
        body: { token },
      }),
      invalidatesTags: [{ type: 'Workspaces', id: 'LIST' }],
    }),
    getTeam: builder.query<PopulatedTeam, string>({
      query: (teamId) => ({
        url: `workspace/team/${teamId}`,
      }),
      providesTags: [{ type: 'Team', id: 'LIST' }],
    }),
    updateGoogleSignIn: builder.mutation<WorkspaceDetails, string | null>({
      query: (googleSignInAppId) => ({
        url: 'workspace/update/google-sign-in',
        method: 'PUT',
        body: { googleSignInAppId },
      }),
      invalidatesTags: (result) => [{ type: 'Workspaces', id: result?._id }],
    }),
    generateApiKey: builder.mutation<WorkspaceDetails, void>({
      query: () => `workspace/api-key`,
      invalidatesTags: (result) => [{ type: 'Workspaces', id: result?._id }],
    }),
    initializeSitemap: builder.mutation<WorkspaceDetails, InitializeSitemapRequest>({
      query: (body) => ({
        url: `workspace/sitemap/init`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Workspaces', id: result?._id }],
    }),
    updateSitemap: builder.mutation<void, InitializeSitemapRequest>({
      query: (body) => ({
        url: `workspace/sitemap/update`,
        method: 'POST',
        body,
      }),
    }),
    updateRssMeta: builder.mutation<WorkspaceDetails, UpdateRssMetaRequest>({
      query: (body) => ({
        url: `workspace/rss/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Workspaces', id: result?._id }],
    }),
    getWorkspaceUser: builder.query<WorkspaceUser, string>({
      query: (id) => `workspace/user/${id}`,
      transformResponse: (response: { user: WorkspaceUser }) => response?.user,
    }),
    updateWorkspaceFontStyles: builder.mutation<void, UpdateWorkspaceFontStyles>({
      query: (body) => ({
        url: `workspace/styles/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Workspaces', id: workspaceId }],
    }),
    createWorkspaceColorPalette: builder.mutation<void, CreateWorkspaceColorPalette>({
      query: (body) => ({
        url: `workspace/color-palette/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Workspaces', id: workspaceId }],
    }),
    deleleWorkspaceColorPalette: builder.mutation<void, DeleteWorkspaceColorPalette>({
      query: ({ paletteId }) => ({
        url: `workspace/color-palette/delete/${paletteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Workspaces', id: workspaceId }],
    }),
    updateWorkspaceColorPalette: builder.mutation<void, UpdateWorkspaceColorPalette>({
      query: ({ paletteId, name, addColors, removeColors }) => ({
        url: `workspace/color-palette/update`,
        method: 'PUT',
        body: { paletteId, name, addColors, removeColors },
      }),
      invalidatesTags: (_, __, { workspaceId }) => [{ type: 'Workspaces', id: workspaceId }],
    }),
  }),
});

export const {
  useWorkspacesListQuery,
  useGetWorkspaceQuery,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceGeneralSettingsMutation,
  useUpdateWorkspaceSeoSettingsMutation,
  useDeleteWorkspaceSeoImageMutation,
  useCreateCustomDomainMutation,
  useDeleteCustomDomainMutation,
  useGetAnalyticsTrackingAccountIdQuery,
  useAddAnalyticsTrackingAccountIdMutation,
  useGetWorkspaceSnippetsQuery,
  useSaveWorkspaceCustomSnippetMutation,
  useDeleteWorkspaceCustomSnippetMutation,
  useGetWorkspaceMembersQuery,
  useInviteWorkspaceMembersMutation,
  useUpdateWorkspaceMemberRoleMutation,
  useRemoveWorkspaceMemberMutation,
  useAcceptInvitationMutation,
  useGetTeamQuery,
  useUpdateGoogleSignInMutation,
  useGenerateApiKeyMutation,
  useInitializeSitemapMutation,
  useUpdateSitemapMutation,
  useUpdateRssMetaMutation,
  useGetWorkspaceUserQuery,
  useLazyGetWorkspaceUserQuery,
  useUpdateWorkspaceFontStylesMutation,
  useCreateWorkspaceColorPaletteMutation,
  useDeleleWorkspaceColorPaletteMutation,
  useUpdateWorkspaceColorPaletteMutation,
} = workspacesApi;
