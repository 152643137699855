import * as Styled from './selected-stories-bar-styled';
import FolderSM from '../../../../icons/folder-sm';
import { Delete } from '../../../../icons';
import { memo } from 'react';

interface ISelectedStoriesBar {
  selectedStories: string[];
  setMoveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
}

const SelectedStoriesBar = ({
  selectedStories,
  setMoveModalOpen,
  setDeleteModalOpen,
  onCancel,
}: ISelectedStoriesBar): JSX.Element =>
  selectedStories.length > 0 ? (
    <Styled.BarWrapper>
      <Styled.SelectedItemsText>{selectedStories.length} selected</Styled.SelectedItemsText>
      <Styled.Row style={{ display: 'flex' }}>
        <Styled.IconButton onClick={() => setMoveModalOpen(true)}>
          <FolderSM />
        </Styled.IconButton>

        <Styled.IconButton onClick={() => setDeleteModalOpen(true)}>
          <Delete />
        </Styled.IconButton>
      </Styled.Row>

      <Styled.CancelButton onClick={onCancel}>Cancel</Styled.CancelButton>
    </Styled.BarWrapper>
  ) : (
    <></>
  );

export default memo(SelectedStoriesBar);
