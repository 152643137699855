import { memo } from 'react';

interface IUpdate {
  className?: string;
}

const Update = ({ className }: IUpdate): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="33"
      viewBox="0 0 28 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26089 7.728C7.74005 5.74165 10.8233 4.6616 14 4.66666C17.3056 4.66666 20.3436 5.81155 22.7391 7.728L24.9993 5.46777L27.1989 7.66733L24.9387 9.92755C26.584 11.9873 27.6143 14.4698 27.911 17.0893C28.2078 19.7088 27.7589 22.3588 26.616 24.7345C25.4731 27.1101 23.6827 29.1148 21.4508 30.5178C19.2189 31.9208 16.6362 32.6651 14 32.6651C11.3638 32.6651 8.78108 31.9208 6.54919 30.5178C4.3173 29.1148 2.52689 27.1101 1.38402 24.7345C0.241144 22.3588 -0.207756 19.7088 0.0889782 17.0893C0.385713 14.4698 1.41602 11.9873 3.06134 9.92755L0.801119 7.66888L3.00067 5.46933L5.26089 7.72955V7.728ZM15.5556 17.1111V10.1033L7.77778 20.2222H12.4444V27.2222L20.2222 17.1111H15.5556ZM7.77778 0H20.2222V3.11111H7.77778V0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Update);
