import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ITemplatesWrapper {
  children: ReactNode;
}

const StyledTemplatesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 6px 20px 18px;
  gap: 18px;
  min-width: 100%;
  align-items: flex-start;
`;

const TemplatesWrapper: React.FC<ITemplatesWrapper> = ({ children }) => {
  return <StyledTemplatesWrapper>{children}</StyledTemplatesWrapper>;
};

export default TemplatesWrapper;
