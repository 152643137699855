import { memo } from 'react';

interface IMedias {
  className?: string;
}

const Medias = ({ className }: IMedias): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10.3125C15 7.72367 17.0987 5.625 19.6875 5.625C22.2763 5.625 24.375 7.72367 24.375 10.3125C24.375 12.9013 22.2763 15 19.6875 15C17.0987 15 15 12.9013 15 10.3125Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.997 1.997C0 3.994 0 7.20812 0 13.6364V16.3636C0 22.7919 0 26.006 1.997 28.003C3.994 30 7.20812 30 13.6364 30H16.3636C22.7919 30 26.006 30 28.003 28.003C30 26.006 30 22.7919 30 16.3636V13.6364C30 7.20812 30 3.994 28.003 1.997C26.006 0 22.7919 0 16.3636 0H13.6364C7.20812 0 3.994 0 1.997 1.997ZM2.95258 6.51837C2.73306 8.15108 2.72727 10.3451 2.72727 13.6364V16.2922C4.96486 14.9078 6.95889 14.3219 8.95749 14.6059C11.5699 14.9771 13.9237 16.8099 16.6333 19.4706C19.1709 21.9624 21.4018 22.4796 23.2952 22.2484C24.7202 22.0743 26.0317 21.4704 27.2364 20.769C27.2711 19.5577 27.2727 18.1162 27.2727 16.3636V13.6364C27.2727 10.3451 27.2669 8.15108 27.0474 6.51837C26.8385 4.9641 26.4818 4.33278 26.0745 3.92547C25.6672 3.51816 25.0359 3.16154 23.4816 2.95258C21.8489 2.73306 19.6549 2.72727 16.3636 2.72727H13.6364C10.3451 2.72727 8.15108 2.73306 6.51837 2.95258C4.9641 3.16154 4.33278 3.51816 3.92547 3.92547C3.51816 4.33278 3.16154 4.9641 2.95258 6.51837Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Medias);
