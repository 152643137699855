import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: var(--white);
  border-radius: 3px;
  border: 1px solid var(--white);
  margin: 5px 5px 0 0;
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  &:checked + div {
    background: var(--primary);
  }
`;

interface ICheckbox {
  name: string;
  innerRef: React.Ref<HTMLInputElement>;
}

const Checkbox = ({ name, innerRef }: ICheckbox): JSX.Element => (
  <CheckboxContainer>
    <HiddenCheckbox type="checkbox" name={name} ref={innerRef} />
    <StyledCheckbox />
  </CheckboxContainer>
);

export default Checkbox;
