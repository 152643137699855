import { memo } from 'react';

interface ICollaborate {
  className?: string;
}

const Collaborate = ({ className }: ICollaborate): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3186 7.14362C18.3186 11.1093 15.0734 14.2872 11.024 14.2872C6.97606 14.2872 3.72947 11.1093 3.72947 7.14362C3.72947 3.17798 6.97606 0 11.024 0C15.0734 0 18.3186 3.17798 18.3186 7.14362ZM0 22.3761C0 18.705 5.0783 17.7866 11.024 17.7866C17.0021 17.7866 22.0481 18.7367 22.0481 22.4106C22.0481 26.0816 16.9697 27 11.024 27C5.04601 27 0 26.0499 0 22.3761ZM21.2601 7.27307C21.2601 9.29254 20.6408 11.1769 19.5546 12.7422C19.4417 12.9031 19.5414 13.1202 19.7381 13.1547C20.0111 13.1992 20.2914 13.2265 20.5776 13.2324C23.4251 13.3056 25.9803 11.5104 26.6862 8.80672C27.7328 4.79509 24.6623 1.19309 20.7509 1.19309C20.3267 1.19309 19.9202 1.23621 19.5239 1.31526C19.4696 1.32675 19.4108 1.35263 19.3815 1.39863C19.3433 1.45755 19.3712 1.53374 19.4094 1.58405C20.585 3.19818 21.2601 5.16304 21.2601 7.27307ZM25.976 16.0534C27.8898 16.4199 29.1476 17.166 29.6687 18.2541C30.1104 19.1452 30.1104 20.1801 29.6687 21.0712C28.8717 22.7557 26.3003 23.2977 25.3008 23.437C25.0938 23.4658 24.9279 23.2933 24.95 23.0892C25.4607 18.4207 21.3996 16.2072 20.3487 15.6984C20.3046 15.6739 20.2944 15.6394 20.2988 15.6165C20.3018 15.6021 20.3208 15.5791 20.3546 15.5748C22.6281 15.5317 25.0733 15.8379 25.976 16.0534Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Collaborate);
