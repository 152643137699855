import { ILayer } from 'interfaces/layer';
import { layerTypes } from 'interfaces/layer-types';

export const renderDirections = (type: layerTypes): string[] => {
  const isMedia =
    type === layerTypes.IMAGE || type === layerTypes.GIFS || type === layerTypes.STICKERS || type === layerTypes.VIDEO;

  const directions: string[] = [];
  if (isMedia) {
    directions.push('nw', 'ne', 'sw', 'se');
  }
  if (type === layerTypes.HTML) {
    directions.push('w', 'e');
  }
  if (!isMedia && type !== layerTypes.HTML) {
    directions.push('w', 'e', 'nw', 'ne', 'n', 's', 'sw', 'se');
  }

  return directions;
};

export const filterSnappedLayers = (ids: string[]) => (layer: ILayer): void => {
  if (layer.type === layerTypes.HTML && !ids.includes(layer._id)) {
    ids.push(layer._id);
  }
};
