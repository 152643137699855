import { memo } from 'react';
import styled from 'styled-components';
import { emailRegex } from '../../../config/constants';

const StyledInput = styled.input`
  border-radius: 6px;
  width: 100%;
  background-color: var(--shade-700);
  outline: none;
  border: 1px solid transparent;
  color: var(--white);
  align-items: center;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 7px 6.61px;
  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--white) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--shade-700) inset;
    border: 1px solid var(--shade-700) !important;
  }
  &:-webkit-autofill:focus {
    border: 1px solid var(--primary) !important;
  }
  @-moz-document url-prefix() {
    & {
      filter: none;
    }
  }
  &:hover {
    border-color: var(--shade-300);
  }
  &:focus {
    border-color: var(--primary);
  }
  &::placeholder {
    font-family: Heebo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--shade-300);
  }
`;

type IFormInput = {
  className?: string;
  placeHolder?: string;
  type: string;
  name: string;
  autoComplete?: string;
  register?: any;
  config?: any;
  defaultValue?: any;
};

const FormInput: React.VFC<IFormInput> = ({
  register,
  className,
  placeHolder,
  type,
  name,
  config,
  autoComplete,
  defaultValue,
}: IFormInput) => {
  const conf = config ?? {
    required: 'Required',
    pattern: type === 'email' ? emailRegex : '',
  };

  return (
    <StyledInput
      className={className}
      placeholder={placeHolder}
      ref={register(conf)}
      type={type}
      name={name}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
    />
  );
};
export default memo(FormInput);
