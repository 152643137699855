import DashboardLayout from '../components/layouts';
import TrialExpired from '../components/trial-expired/trial-expired';

const TrialExpiredPage = (): JSX.Element => {
  return (
    <DashboardLayout hasNoHeader>
      <TrialExpired />
    </DashboardLayout>
  );
};

export default TrialExpiredPage;
