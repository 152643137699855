import styled from 'styled-components';

interface IProps {
  outerMargin?: number;
}

const Container = styled.div<IProps>`
  height: 1px;
  width: ${({ outerMargin }) => `calc(100% + ${outerMargin}px)`};
  margin: ${({ outerMargin }) => `6px -${(outerMargin ?? 0) / 2}px 10px`};

  background: var(--shade-500-85);
`;

const Divider = ({ outerMargin = 0 }: IProps): JSX.Element => <Container outerMargin={outerMargin} />;

export default Divider;
