import styled from 'styled-components';
import { lazy, Suspense } from 'react';
import { QuaternaryButton } from '../buttons';

const ViewWrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const TextWrapper = styled.div`
  margin: 26px auto 19px;
  max-width: 340px;
`;

const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--white);
  text-align: center;
  margin: 0;
`;

const InternalError = lazy(() => import('components/icons/internal-error'));

const ErrorImage = styled(InternalError)`
  max-width: 100%;
  height: auto;
`;

const ErrorView = (): JSX.Element => {
  const onReloadButtonClick = () => location.reload();
  return (
    <ViewWrapper>
      <Suspense fallback={<></>}>
        <ErrorImage />
      </Suspense>

      <TextWrapper>
        <Text>Whoops..</Text>
        <Text> Something went wrong on our side.</Text>
        <Text>Don’t worry, we will sort it out.</Text>
      </TextWrapper>

      <QuaternaryButton text={'Reload page'} onClick={onReloadButtonClick} />
    </ViewWrapper>
  );
};

export default ErrorView;
