import LoginStyled from '../components/auth/shared/login-styled';
import zazuLogo3x from '../assets/images/zazu-logo3x.png';
import zazuLogo2x from '../assets/images/zazu-logo2x.png';
import zazuLogo1x from '../assets/images/zazu-logo1x.png';
import zazuBg1x from 'assets/images/auth-backgroundx1.webp';
import zazuBg2x from 'assets/images/auth-backgroundx2.webp';
import zazuBg3x from 'assets/images/auth-backgroundx3.webp';
import SignUp from '../components/auth/sign-up/sign-up';

const SignUpPage = (): JSX.Element => (
  <LoginStyled.StyledFormWrapper>
    <LoginStyled.AuthBackgroundContainer>
      <LoginStyled.AuthBackground srcSet={`${zazuBg3x} 3x, ${zazuBg2x} 2x, ${zazuBg1x} 1x`} />
      <LoginStyled.Overlay />
    </LoginStyled.AuthBackgroundContainer>
    <LoginStyled.LogoLink href={'//zazuapp.co'} target={'_blank'} rel="noopener noreferrer">
      <LoginStyled.Logo srcSet={`${zazuLogo3x} 3x,${zazuLogo2x} 2x, ${zazuLogo1x} 1x`} />
    </LoginStyled.LogoLink>

    <SignUp />
  </LoginStyled.StyledFormWrapper>
);

export default SignUpPage;
