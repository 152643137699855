import CustomColor from './custom-color';
import CustomColorsLabel from './custom-colors-label';
import CustomColorsWrapper from './custom-colors-wrapper';
import { IRGBColor } from 'interfaces/colors';
import { memo } from 'react';
import { toRGBObject, toRGBString } from 'utils/parseColors';
import { useAddFavouriteColorMutation } from 'redux/services/auth/auth';
import { useAppSelector } from 'hooks';

interface IFavouriteColorsProps {
  selectedColor?: string;
  color: IRGBColor;
  handleColorChange: (color: IRGBColor) => void;
}

const FavouriteColors: React.FC<IFavouriteColorsProps> = ({ selectedColor, color, handleColorChange }) => {
  const favouriteColors = useAppSelector((state) => state.auth.user?.favouriteColors);
  const [addFavouriteColor] = useAddFavouriteColorMutation();

  const handleFavouriteColorAdd = () => addFavouriteColor(toRGBString(color));

  return (
    <>
      <CustomColorsLabel title={'Favorites'} />
      <CustomColorsWrapper>
        <CustomColor withPlus={true} handleClick={handleFavouriteColorAdd} />

        {favouriteColors?.map((color, index) => (
          <CustomColor
            selectedColor={selectedColor}
            key={`favourite-color-${index}`}
            color={color}
            handleClick={() => handleColorChange(toRGBObject(color))}
          />
        ))}
      </CustomColorsWrapper>
    </>
  );
};

export default memo(FavouriteColors);
