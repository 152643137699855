export const HEX = 'Hex';
export const RGBA = 'RGBA';
export const RGB = 'RGB';
export const HSLA = 'HSLA';
export const HSL = 'HSL';

export const colorModeOptions = [
  { name: HEX, value: HEX },
  { name: HSL, value: HSLA },
  { name: RGB, value: RGBA },
];
