import styled from 'styled-components';
import { lazy, Suspense } from 'react';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const EmptyInsights = lazy(() => import('components/icons/empty-insights'));

const Empty = (): JSX.Element => {
  return (
    <Container>
      <Suspense fallback={<></>}>
        <EmptyInsights />
      </Suspense>

      <Title>
        Publish your first Story to start seeing
        <br />
        some results in the Insights section.
      </Title>
    </Container>
  );
};

export default Empty;
