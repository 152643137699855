import { memo } from 'react';

interface IPlay {
  className?: string;
}

const Play = ({ className }: IPlay): JSX.Element => {
  return (
    <svg
      className={className}
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8411 9.508C12.7657 9.58533 12.4812 9.916 12.2163 10.188C10.6628 11.8987 6.61013 14.6987 4.48907 15.5533C4.16693 15.6907 3.35249 15.9813 2.91736 16C2.5004 16 2.10293 15.904 1.72365 15.7093C1.25085 15.4373 0.871573 15.0093 0.663747 14.504C0.52996 14.1533 0.322133 13.104 0.322133 13.0853C0.114307 11.9373 0 10.072 0 8.01067C0 6.04667 0.114306 4.25733 0.284466 3.092C0.303946 3.07333 0.511773 1.76933 0.73908 1.32267C1.15603 0.506667 1.97045 0 2.84201 0H2.91736C3.48499 0.02 4.67867 0.526667 4.67867 0.545333C6.68547 1.40133 10.6445 4.064 12.2357 5.83333C12.2357 5.83333 12.6839 6.288 12.8787 6.572C13.1827 6.98 13.3333 7.48533 13.3333 7.99067C13.3333 8.55467 13.1632 9.08 12.8411 9.508Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Play);
