import { posterTypes } from '../../../config/constants';

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous'; // CORS :(
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', (error) => reject(error));
    img.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

interface IImage {
  width: number;
  height: number;
}

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: DOMRectReadOnly | null,
  rotation = 0,
): Promise<string> => {
  const image = (await createImage(imageSrc)) as IImage & CanvasImageSource;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop?.width || 0;
  canvas.height = pixelCrop?.height || 0;

  // paste generated rotate image with correct offsets for x,y crop values.
  const pixelCropX = pixelCrop?.x || 0;
  const pixelCropY = pixelCrop?.y || 0;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCropX),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCropY),
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      if (!file) {
        return;
      }
      resolve(window.URL.createObjectURL(file));
    }, 'image/jpeg');
  });
};

export async function getRotatedImage(imageSrc: string, rotation = 0): Promise<string> {
  const image = document.getElementById('helper-image') as HTMLImageElement;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasTransform & CanvasDrawImage;

  const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

export const getAspectRatio = (type: string): number | undefined => {
  switch (type) {
    case posterTypes.landscape:
      return 928 / 696;
    case posterTypes.portrait:
      return 696 / 928;
    case posterTypes.square:
      return 696 / 696;
  }
};
