interface IProps {
  fillColor: string;
}

const Sound = ({ fillColor }: IProps): JSX.Element => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1104 2.68377C10.0692 2.3055 10.0262 1.91305 9.92078 1.52142C9.63336 0.584523 8.83718 7.48236e-06 7.98349 7.48236e-06C7.50739 -0.00166474 6.90508 0.277231 6.56362 0.559471L3.73326 2.8132H2.25159C1.16115 2.8132 0.284327 3.61231 0.118743 4.76548C-0.0219586 5.87269 -0.0562764 7.96281 0.118743 9.18104C0.270598 10.3994 1.10795 11.1868 2.25159 11.1868H3.73326L6.61853 13.4739C6.91451 13.7186 7.43703 13.9991 7.91746 13.9991C7.94831 14 7.9758 14 8.00321 14C8.87318 14 9.63933 13.3938 9.92675 12.4594C10.035 12.0643 10.073 11.694 10.1097 11.3363L10.1104 11.3296L10.149 10.973C10.2965 9.81657 10.2965 4.17595 10.149 3.0278L10.1104 2.68377ZM13.4236 2.71801C13.1876 2.38733 12.72 2.30467 12.3777 2.5343C12.0388 2.7656 11.9548 3.22152 12.1907 3.55136C12.847 4.47071 13.2082 5.69489 13.2082 7C13.2082 8.30433 12.847 9.52926 12.1907 10.4487C11.9548 10.7784 12.0388 11.2344 12.3786 11.4657C12.5056 11.5509 12.654 11.596 12.8067 11.596C13.0529 11.596 13.2829 11.4782 13.4236 11.282C14.2523 10.1213 14.7096 8.60074 14.7096 7C14.7096 5.39926 14.2523 3.87868 13.4236 2.71801ZM14.9646 0.171935C15.3061 -0.0585281 15.7754 0.0258063 16.0096 0.355646C17.2931 2.15178 18 4.51239 18 6.99992C18 9.48912 17.2931 11.8489 16.0096 13.645C15.8697 13.8413 15.639 13.959 15.3927 13.959C15.24 13.959 15.0924 13.9139 14.9654 13.8287C14.6257 13.5974 14.5416 13.1423 14.7767 12.8117C15.8869 11.2569 16.4986 9.19271 16.4986 6.99992C16.4986 4.80798 15.8869 2.74381 14.7767 1.189C14.5416 0.859164 14.6257 0.403239 14.9646 0.171935Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default Sound;
