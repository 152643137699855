import { ReactNode, RefObject } from 'react';
import styled from 'styled-components';

interface ITutorialModalHeader {
  title: string | ReactNode;
  subTitle?: string;
  propRef?: RefObject<HTMLDivElement>;
}

const HeaderWrapper = styled.header`
  padding: 69px 50px 0;
  position: relative;
`;

const Title = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--white);
  margin: 0;
`;

const Subtitle = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--shade-100);
  margin: 14px 0 0;
  padding: 0 0 20px;
`;

const TutorialModalHeader = ({ title, subTitle, propRef }: ITutorialModalHeader): JSX.Element => {
  return (
    <HeaderWrapper ref={propRef}>
      <Title>{title}</Title>
      {subTitle && <Subtitle>{subTitle}</Subtitle>}
    </HeaderWrapper>
  );
};

export default TutorialModalHeader;
