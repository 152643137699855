import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledSettingsBoldInfoText = styled.span`
  font-weight: 800;
`;

interface ISettingsBoldInfoTextProps {
  children: ReactNode;
}

const SettingsBoldInfoText = ({ children }: ISettingsBoldInfoTextProps): JSX.Element => {
  return <StyledSettingsBoldInfoText>{children}</StyledSettingsBoldInfoText>;
};

export default SettingsBoldInfoText;
