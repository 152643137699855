import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ISectionRow {
  children: ReactNode;
}

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

const SectionRow = ({ children }: ISectionRow) => {
  return <Row>{children}</Row>;
};

export default SectionRow;
