import { useCallback, useRef, useState, SetStateAction, Dispatch } from 'react';

const isFunction = <S>(setStateAction: SetStateAction<S>): setStateAction is (prevState: S) => S =>
  typeof setStateAction === 'function';

type ReadOnlyRefObject<T> = {
  readonly current: T;
};

type UseStateSync = {
  <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, ReadOnlyRefObject<S>];
  <S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>, ReadOnlyRefObject<S | undefined>];
};

const useStateSync: UseStateSync = <S>(initialState?: S | (() => S)) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(state);

  const dispatch: Dispatch<SetStateAction<S | undefined>> = useCallback((setStateAction) => {
    ref.current = isFunction(setStateAction) ? setStateAction(ref.current) : setStateAction;

    setState(ref.current);
  }, []);

  const returnArray: [S | undefined, Dispatch<SetStateAction<S | undefined>>, ReadOnlyRefObject<S | undefined>] = [
    state,
    dispatch,
    ref,
  ];

  return returnArray;
};

export { useStateSync };
