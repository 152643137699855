import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface EditorSidebarValuesWrapper {
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  children: ReactNode;
}

const SidebarValuesWrapper = styled.div<{ justifyContent: string }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex: 1 1 100%;
  max-width: 74.6%;
`;

const EditorSidebarValuesWrapper = ({ justifyContent, children }: EditorSidebarValuesWrapper): JSX.Element => {
  return <SidebarValuesWrapper justifyContent={justifyContent!}>{children}</SidebarValuesWrapper>;
};

EditorSidebarValuesWrapper.defaultProps = {
  justifyContent: 'space-between',
};

export default EditorSidebarValuesWrapper;
