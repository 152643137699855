import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IBoxColumn {
  width: string;
  children: ReactNode;
}

const Wrapper = styled.div<{ $width?: string }>`
  width: 100%;
  padding: 0 5px;
  max-width: ${({ $width }) => $width};
`;

const BoxColumn = ({ width, children }: IBoxColumn): JSX.Element => {
  return <Wrapper $width={width}>{children}</Wrapper>;
};

export default BoxColumn;
