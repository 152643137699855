import { memo } from 'react';

interface IShow {
  className?: string;
}

const Show = ({ className }: IShow): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3336 6.99984C8.20026 6.99984 7.3336 7.86651 7.3336 8.99984C7.3336 10.1332 8.20026 10.9998 9.3336 10.9998C10.4669 10.9998 11.3336 10.1332 11.3336 8.99984C11.3336 7.86651 10.4669 6.99984 9.3336 6.99984ZM15.9336 8.73317C14.6003 5.59984 12.0669 3.6665 9.3336 3.6665C6.60026 3.6665 4.06693 5.59984 2.73359 8.73317C2.66693 8.93317 2.66693 9.06651 2.73359 9.26651C4.06693 12.3998 6.60026 14.3332 9.3336 14.3332C12.0669 14.3332 14.6003 12.3998 15.9336 9.26651C16.0003 9.06651 16.0003 8.93317 15.9336 8.73317ZM9.3336 12.3332C7.46693 12.3332 6.00026 10.8665 6.00026 8.99984C6.00026 7.13317 7.46693 5.6665 9.3336 5.6665C11.2003 5.6665 12.6669 7.13317 12.6669 8.99984C12.6669 10.8665 11.2003 12.3332 9.3336 12.3332Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Show);
