import DashboardLayout from '../components/layouts';
import TeamsAllStories from '../components/teams/teams-all-stories';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '../hooks';
import { useCallback, useEffect } from 'react';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { useSelectTeamMutation } from 'redux/services/auth/auth';

const AllMyStoriesPage = (): JSX.Element => {
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const { teamId } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace }) => ({
      teamId: workspace?.teams?.[0]?._id,
    }),
  });

  const [selectTeam, { isUninitialized, isLoading, isError, isSuccess }] = useSelectTeamMutation();

  const onTeamDeselect = useCallback(() => {
    selectTeam(null);
  }, [selectTeam]);

  useEffect(() => {
    if (teamId) {
      selectTeam(teamId);
      window.addEventListener('beforeunload', onTeamDeselect);
    }

    return () => {
      // onTeamDeselect();
      window.removeEventListener('beforeunload', onTeamDeselect);
    };
  }, [teamId, selectTeam, onTeamDeselect]);

  const canRenderStories = (teamId && isUninitialized) || (!isLoading && !isUninitialized && !isError && isSuccess);

  return <DashboardLayout withHeaderSearchStory>{canRenderStories && <TeamsAllStories />}</DashboardLayout>;
};

export default AllMyStoriesPage;
