import { memo } from 'react';

const JustifyAlign = (): JSX.Element => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 2H1C0.734783 2 0.480429 1.89464 0.292892 1.70711C0.105356 1.51957 0 1.26522 0 1C0 0.734784 0.105356 0.48043 0.292892 0.292893C0.480429 0.105357 0.734783 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1C16 1.26522 15.8946 1.51957 15.7071 1.70711C15.5196 1.89464 15.2652 2 15 2ZM1 5H15C15.2652 5 15.5196 5.10536 15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6C16 6.26522 15.8946 6.51957 15.7071 6.70711C15.5196 6.89464 15.2652 7 15 7H1C0.734783 7 0.480429 6.89464 0.292892 6.70711C0.105356 6.51957 0 6.26522 0 6C0 5.73478 0.105356 5.48043 0.292892 5.29289C0.480429 5.10536 0.734783 5 1 5ZM1 10H15C15.2652 10 15.5196 10.1054 15.7071 10.2929C15.8946 10.4804 16 10.7348 16 11C16 11.2652 15.8946 11.5196 15.7071 11.7071C15.5196 11.8946 15.2652 12 15 12H1C0.734783 12 0.480429 11.8946 0.292892 11.7071C0.105356 11.5196 0 11.2652 0 11C0 10.7348 0.105356 10.4804 0.292892 10.2929C0.480429 10.1054 0.734783 10 1 10Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default memo(JustifyAlign);
