import React, { useEffect, useState, useRef } from 'react';
import TutorialSectionText from '../shared/tutorial-section-text';
import { slides } from './constants';
import { TutorialTypesPassed } from 'redux/services/auth/interface';
import { useAppSelector } from '../../../hooks';
import { useUpdateTutorialFieldMutation } from 'redux/services/auth/auth';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import {
  TutorialModalHeader,
  TutorialOuterWrapper,
  TutorialModalWrapper,
  TutorialModalBody,
  TutorialModalFooter,
  TutorialSlideButtons,
  SharedStyled,
  Feature,
  TutorialModalBodyWrapper,
} from '../shared';
import useResizeModalBodyHeight from '../hooks/useResizeModalBodyHeight';
import 'swiper/swiper.min.css';

const params = {
  noSwiping: true,
  noSwipingClass: 'swiper-slide',
  onlyExternal: true,
};

const TutorialEditor = (): JSX.Element => {
  const [updateTutorialField] = useUpdateTutorialFieldMutation();
  const headerRef = useRef<null | HTMLDivElement>(null);
  const footerRef = useRef<null | HTMLDivElement>(null);
  const outerWrapperRef = useRef<null | HTMLDivElement>(null);
  const { bodyHeight } = useResizeModalBodyHeight({
    header: headerRef.current,
    footer: footerRef.current,
    modalWrapper: outerWrapperRef.current,
  });
  const userId = useAppSelector((state) => state.auth.user?._id);
  const [swiper, setSwiper] = useState<any>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const onSlideButtonClick = (index: number) => () => {
    setActiveSlide(index);
  };

  const onPreviousClick = () => {
    setActiveSlide((prev) => prev - 1);
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide);
    }
  }, [swiper, activeSlide]);

  const onNextClick = () => {
    if (activeSlide < slides.length - 1) {
      setActiveSlide((prev) => prev + 1);
      return;
    }
    if (userId) {
      updateTutorialField({ type: TutorialTypesPassed.StoryEditor, userId });
    }
  };

  return (
    <TutorialOuterWrapper propRef={outerWrapperRef}>
      <TutorialModalWrapper>
        <TutorialModalHeader title={'Get creative with Zazu’s Story Editor!'} propRef={headerRef} />
        <>
          <TutorialModalBody>
            <TutorialModalBodyWrapper maxHeight={bodyHeight} padding={'0px'}>
              <Swiper {...params} onSwiper={setSwiper}>
                {slides?.map((slide) => (
                  <SwiperSlide key={slide?.sectionText}>
                    <SharedStyled.ContentRow $padding={slide.padding} $minHeight="565px" $alignItems="flex-start">
                      <SharedStyled.ContentColumn $padding={slide.featuresPadding}>
                        <TutorialSectionText text={slide.sectionText} maxWidth={slide.sectionTextMaxWidth} />
                        <SharedStyled.Row $padding={'0 10px'}>
                          {slide.features.map((feature) => (
                            <SharedStyled.FeatureColumn
                              key={feature.textInfo.text}
                              style={{ flexBasis: slide.columnSize }}
                            >
                              <Feature textInfo={feature.textInfo} icon={feature.icon} />
                            </SharedStyled.FeatureColumn>
                          ))}
                        </SharedStyled.Row>
                      </SharedStyled.ContentColumn>

                      <SharedStyled.ImageColumn
                        $padding={slide?.imageSection?.padding}
                        $margin={slide?.imageSection?.margin}
                        style={{
                          order: slide?.order,
                        }}
                      >
                        {slide?.imageSection?.imageText && (
                          <SharedStyled.ImageText>{slide?.imageSection?.imageText}</SharedStyled.ImageText>
                        )}

                        <img
                          src={slide.imageSection?.url}
                          width={slide.imageSection?.width}
                          height={slide.imageSection?.height}
                          alt={slide.imageSection?.alt}
                        />
                      </SharedStyled.ImageColumn>
                    </SharedStyled.ContentRow>
                  </SwiperSlide>
                ))}
              </Swiper>
            </TutorialModalBodyWrapper>
          </TutorialModalBody>
          <TutorialModalFooter propRef={footerRef}>
            {activeSlide > 0 && (
              <SharedStyled.PrevButton
                text={
                  <>
                    <SharedStyled.PrevIcon />
                    Previous
                  </>
                }
                onClick={onPreviousClick}
              />
            )}

            <TutorialSlideButtons.Wrapper>
              <TutorialSlideButtons.Buttons
                activeIndex={activeSlide}
                buttonCount={slides?.length}
                onButtonClick={onSlideButtonClick}
              />
            </TutorialSlideButtons.Wrapper>

            <SharedStyled.Link
              href="https://help.zazuapp.co/en/category/design-stories-9qr9sw/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get more help
            </SharedStyled.Link>

            <SharedStyled.MainButton
              text={
                activeSlide < slides.length - 1 ? (
                  <>
                    Next
                    <SharedStyled.NextIcon />
                  </>
                ) : (
                  'Got it'
                )
              }
              onClick={onNextClick}
            />
          </TutorialModalFooter>
        </>
      </TutorialModalWrapper>
    </TutorialOuterWrapper>
  );
};

export default TutorialEditor;
