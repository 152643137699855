import { memo } from 'react';

interface IMute {
  className?: string;
}

const Mute = ({ className }: IMute): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5055 0.427659C28.0739 -0.140605 28.9797 -0.143937 29.5698 0.425985C30.1431 0.999239 30.1431 1.92515 29.5715 2.49675L2.49454 29.5721C2.22542 29.8412 1.83763 30 1.45986 30C1.09045 30 0.726062 29.8495 0.431871 29.5772C-0.14312 28.9805 -0.14312 28.0729 0.426866 27.503L4.88984 23.0406H4.8447C2.61991 23.0406 1.0002 21.4696 0.699314 19.0295C0.363345 16.5893 0.430213 12.411 0.699314 10.1881C1.01525 7.88171 2.72187 6.29562 4.8447 6.29562H7.73477L13.2507 1.78305C13.9212 1.2148 15.1079 0.678301 16.0256 0.663254C17.6971 0.663254 19.2348 1.83152 19.7865 3.7034C20.0038 4.4906 20.089 5.27444 20.1558 6.02821L20.288 7.09618C20.3096 7.25998 20.3296 7.41709 20.3464 7.58422L27.5055 0.427611L27.5055 0.427659ZM18.7593 16.9444C18.985 16.7238 19.4898 16.565 19.717 16.6236C20.3305 16.7789 20.4509 17.6597 20.4408 18.3517C20.4123 20.3606 20.3455 21.7579 20.2385 22.6219L20.1633 23.334L20.1621 23.3471C20.0905 24.0633 20.0167 24.8044 19.804 25.5952C19.2474 27.4637 17.7546 28.6771 16.0581 28.6771C16.003 28.6771 15.9477 28.6771 15.8908 28.6754C14.9533 28.6754 13.9369 28.1139 13.3603 27.6241L11.3059 26.0348C10.5253 25.4548 10.756 24.5289 11.1939 23.9924C11.5209 23.5931 15.4516 19.9847 17.5161 18.0895C18.2162 17.4468 18.7017 17.0011 18.7593 16.9444Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Mute);
