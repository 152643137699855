import WidgetEditorSidebarPreviewSettings from './widget-editor-sidebar-settings/widget-editor-sidebar-preview-settings/widget-editor-sidebar-preview-settings';
import WidgetEditorSidebarShapeSettings from './widget-editor-sidebar-settings/widget-editor-sidebar-shape-settings/widget-editor-sidebar-shape-settings';
import { EditorSidebarHeader } from './../editor-sidebar/shared/elements';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { PreviewContext, PreviewContextType } from 'context/widget/is-preview';
import {
  WidgetEditorSidebarTextTitleSettings,
  WidgetEditorSidebarTextDescriptionSettings,
  WidgetEditorSidebarCarouselSettings,
} from './widget-editor-sidebar-settings';
import {
  EditorSidebarWrapper,
  EditorSidebarBodyWrapper,
  EditorSidebarOuterWrapper,
} from './../editor-sidebar/shared/structure';

import {
  MAIN_SIDEBAR_TABS,
  SIDEBAR_TAB_DESIGN,
  SIDEBAR_TAB_PREVIEW,
  SIDEBAR_TAB_TEXT,
} from './constants/widget-editor-sidebar-header-tabs';

const WidgetEditorSidebar: React.VFC = (): JSX.Element => {
  const { isPreview } = useContext(PreviewContext) as PreviewContextType;

  const [activeTabName, setActiveTabName] = useState(SIDEBAR_TAB_DESIGN);
  const [tabs, setTabs] = useState(MAIN_SIDEBAR_TABS);

  const getTabs = useCallback(() => {
    if (isPreview) {
      return setTabs([SIDEBAR_TAB_PREVIEW]);
    }

    return setTabs(MAIN_SIDEBAR_TABS);
  }, [isPreview]);

  useEffect(() => {
    getTabs();
    setActiveTabName(isPreview ? SIDEBAR_TAB_PREVIEW : SIDEBAR_TAB_DESIGN);
  }, [isPreview, getTabs]);

  return (
    <EditorSidebarOuterWrapper id="widget-sidebar">
      <EditorSidebarWrapper>
        <EditorSidebarHeader activeTab={activeTabName} tabs={tabs} onTabClick={setActiveTabName} />
        {isPreview && <WidgetEditorSidebarPreviewSettings />}

        {activeTabName === SIDEBAR_TAB_DESIGN && (
          <EditorSidebarBodyWrapper>
            <WidgetEditorSidebarShapeSettings />
            <WidgetEditorSidebarCarouselSettings />
          </EditorSidebarBodyWrapper>
        )}

        {activeTabName === SIDEBAR_TAB_TEXT && (
          <EditorSidebarBodyWrapper>
            <WidgetEditorSidebarTextTitleSettings />
            <WidgetEditorSidebarTextDescriptionSettings />
          </EditorSidebarBodyWrapper>
        )}
      </EditorSidebarWrapper>
      <div id="color-picker-modal" />
    </EditorSidebarOuterWrapper>
  );
};

export default memo(WidgetEditorSidebar);
