import { ReactNode, RefObject } from 'react';
import styled from 'styled-components';

interface ITutorialModalFooter {
  className?: string;
  children: ReactNode;
  propRef?: RefObject<HTMLDivElement>;
}

const Wrapper = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  position: relative;
`;

const TutorialModalFooter = ({ className, children, propRef }: ITutorialModalFooter): JSX.Element => {
  return (
    <Wrapper className={className} ref={propRef}>
      {children}
    </Wrapper>
  );
};

export default TutorialModalFooter;
