import { useCallback, useEffect } from 'react';

interface IuseDragAndDrop {
  isDropDisabled?: boolean;
  fileWrapper: HTMLElement | null;
  onDrop: (event: any) => void;
}

const useDragAndDrop = ({ isDropDisabled, fileWrapper, onDrop }: IuseDragAndDrop): void => {
  const handleDrag = useCallback(
    (event: DragEvent) => {
      if (isDropDisabled) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
    },
    [isDropDisabled],
  );

  const handleDragIn = useCallback(
    (event: DragEvent) => {
      if (isDropDisabled) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
    },
    [isDropDisabled],
  );

  const handleDragOut = useCallback(
    (event: DragEvent) => {
      if (isDropDisabled) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
    },
    [isDropDisabled],
  );

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (isDropDisabled) {
        return;
      }

      if (event?.dataTransfer?.files && event.dataTransfer.files.length > 0) {
        onDrop(event);
        event.dataTransfer.clearData();
      }
    },
    [isDropDisabled, onDrop],
  );

  useEffect(() => {
    if (fileWrapper) {
      fileWrapper?.addEventListener('dragenter', handleDragIn);
      fileWrapper?.addEventListener('dragleave', handleDragOut);
      fileWrapper?.addEventListener('dragover', handleDrag);
      fileWrapper?.addEventListener('drop', handleDrop);
    }

    return () => {
      if (fileWrapper) {
        fileWrapper.removeEventListener('dragenter', handleDragIn);
        fileWrapper.removeEventListener('dragleave', handleDragOut);
        fileWrapper.removeEventListener('dragover', handleDrag);
        fileWrapper.removeEventListener('drop', handleDrop);
      }
    };
  }, [fileWrapper, handleDrag, handleDragIn, handleDragOut, handleDrop]);
};

export default useDragAndDrop;
