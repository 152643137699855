import { memo } from 'react';

interface IIntegration {
  className?: string;
}

const Integration = ({ className }: IIntegration): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="23"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 11.25L22.9288 18.3212L21.1613 16.5537L26.465 11.25L21.1613 5.94625L22.9288 4.17875L30 11.25ZM3.535 11.25L8.83875 16.5537L7.07125 18.3212L0 11.25L7.07125 4.17875L8.8375 5.94625L3.535 11.25ZM12.235 22.5H9.575L17.765 0H20.425L12.235 22.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Integration);
