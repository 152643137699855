enum UserRole {
  admin = 'APP_ADMIN',
  user = 'APP_USER',
}

export interface User {
  _id: string;
  avatar?: string;
  blocked: boolean;
  email: string;
  favouriteColors: string[];
  instagramExportAllowed: boolean;
  isVerified: boolean;
  paid: boolean;
  privacyPolicy: boolean;
  provider: string;
  role: {
    name: UserRole;
    _id: string;
  };
  selectedTeamId?: string | null;
  selectedWorkspaceId: string;
  tutorialCarouselPassed: boolean;
  tutorialDashboardPassed: boolean;
  tutorialStoryEditorPassed: boolean;
  username: string;
}

export interface UserToken {
  token: string;
  exp: number;
  iat: number;
}

export interface UserResponse {
  user: User;
  accessToken: UserToken;
  refreshToken: UserToken;
}

export interface LogoutRequest {
  token: string;
  userId: string;
}

export interface VerifyEmailResponse {
  email: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface RefreshRequest {
  token: string;
}

export interface RefreshResponse {
  accessToken: UserToken;
  refreshToken: UserToken;
}

export interface AuthenticateRequest {
  accessToken: UserToken;
  refreshToken?: UserToken;
}

export interface VerifyEmailRequest {
  verificationToken: string;
  email: string;
}

export interface ResendVerificationEmailRequest {
  verificationToken: string;
}

export enum TutorialTypesPassed {
  Carousel = 'tutorialCarouselPassed',
  StoryEditor = 'tutorialStoryEditorPassed',
  Dashboard = 'tutorialDashboardPassed',
}
export interface UpdateTutorialFieldRequest {
  userId: string;
  type: TutorialTypesPassed;
}
