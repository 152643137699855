import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IEditorSidebarLabelWrapper {
  className?: string;
  children: ReactNode;
}

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 25.4%;
  width: 25.4%;
`;

const EditorSidebarLabelWrapper = ({ className, children }: IEditorSidebarLabelWrapper): JSX.Element => {
  return <LabelWrapper className={className}>{children}</LabelWrapper>;
};

export default EditorSidebarLabelWrapper;
