import { memo } from 'react';

const Underline = (): JSX.Element => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11.4C8.8 11.4 11 9.19999 11 6.39999V1.39999C11 0.799994 10.6 0.399994 10 0.399994C9.4 0.399994 9 0.799994 9 1.39999V6.39999C9 8.09999 7.7 9.39999 6 9.39999C4.3 9.39999 3 8.09999 3 6.39999V1.39999C3 0.799994 2.6 0.399994 2 0.399994C1.4 0.399994 1 0.799994 1 1.39999V6.39999C1 9.19999 3.2 11.4 6 11.4ZM11 13.4H1C0.4 13.4 0 13.8 0 14.4C0 15 0.4 15.4 1 15.4H11C11.6 15.4 12 15 12 14.4C12 13.8 11.6 13.4 11 13.4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Underline);
