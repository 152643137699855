import { memo } from 'react';

interface ILock {
  className?: string;
}

const Lock = ({ className }: ILock): JSX.Element => {
  return (
    <svg
      className={className}
      width="26"
      height="34"
      viewBox="0 0 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19964 13H17.7996V7.8C17.7996 5.14903 15.6506 3 12.9996 3C10.3487 3 8.19964 5.14904 8.19964 7.80001V13ZM5.19964 13H4C2.11438 13 1.17157 13 0.585786 13.5858C0 14.1716 0 15.1144 0 17V29.8C0 31.6856 0 32.6284 0.585786 33.2142C1.17157 33.8 2.11438 33.8 4 33.8H22C23.8856 33.8 24.8284 33.8 25.4142 33.2142C26 32.6284 26 31.6856 26 29.8V17C26 15.1144 26 14.1716 25.4142 13.5858C24.8284 13 23.8856 13 22 13H20.7996V7.8C20.7996 3.49218 17.3075 0 12.9996 0C8.69182 0 5.19964 3.49219 5.19964 7.80001V13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Lock);
