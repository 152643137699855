/* Output */
export type ReportResult = {
  reports: Report[];
  queryCost: number;
  resourceQuotasRemaining: ResourceQuotasRemaining;
};

type Report = {
  columnHeader: ColumnHeader;
  data: ReportData;
  nextPageToken?: string;
};

type ColumnHeader = {
  dimensions: string[];
  metricHeader: MetricHeader;
};

type MetricHeader = {
  metricHeaderEntries: MetricHeaderEntry[];
  pivotHeaders: PivotHeader[];
};

type MetricHeaderEntry = {
  name: string;
  type: MetricType;
};

type PivotHeader = {
  pivotHeaderEntries: PivotHeaderEntry[];
  totalPivotGroupsCount: number;
};

type PivotHeaderEntry = {
  dimensionNames: string[];
  dimensionValues: string[];
  metric: MetricHeaderEntry;
};

type ReportData = {
  rows: ReportRow[];
  totals: DateRangeValues[];
  rowCount: number;
  minimums: DateRangeValues[];
  maximums: DateRangeValues[];
  samplesReadCounts?: string[];
  samplingSpaceSizes?: string[];
  isDataGolden?: boolean;
  dataLastRefresh?: string;
};

type ReportRow = {
  dimensions: string[];
  metrics: DateRangeValues[];
};

type DateRangeValues = {
  values: string[];
  pivotValueRegions?: PivotValueRegion[];
};

type PivotValueRegion = {
  values: string[];
};

type ResourceQuotasRemaining = {
  dailyQuotaTokensRemaining: number;
  hourlyQuotaTokensRemaining: number;
};

/* Input */
export interface ReportsInputType {
  inputReports: ReportInput[];
  workspaceId: string;
}

type ReportInput = {
  dateRanges?: DateRange[];
  samplingLevel?: SamplingLevel;
  dimensions?: Dimension[];
  dimensionFilterClauses?: DimensionFilterClause[];
  metrics: Metric[];
  metricFilterClauses?: MetricFilterClause[];
  filtersExpression?: string;
  orderBys?: OrderBy[];
  segments?: Segment[];
  pivots?: Pivot[];
  cohortGroup?: CohortGroup;
  pageToken?: string;
  pageSize?: number;
  includeEmptyRows?: boolean;
  hideTotals?: boolean;
  hideValueRanges?: boolean;
};

type DateRange = {
  startDate: string;
  endDate: string;
};

enum SamplingLevel {
  SAMPLING_UNSPECIFIED = 'SAMPLING_UNSPECIFIED',
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

type Dimension = {
  name: string;
  histogramBuckets?: string[];
};

type DimensionFilterClause = {
  operator?: FilterLogicalOperator;
  filters: DimensionFilter[];
};

export enum FilterLogicalOperator {
  OPERATOR_UNSPECIFIED = 'OPERATOR_UNSPECIFIED',
  OR = 'OR',
  AND = 'AND',
}

type DimensionFilter = {
  dimensionName: string;
  not?: boolean;
  operator: Operator;
  expressions: string[];
  caseSensitive?: boolean;
};

export enum Operator {
  OPERATOR_UNSPECIFIED = 'OPERATOR_UNSPECIFIED',
  REGEXP = 'REGEXP',
  BEGINS_WITH = 'BEGINS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  PARTIAL = 'PARTIAL',
  EXACT = 'EXACT',
  NUMERIC_EQUAL = 'NUMERIC_EQUAL',
  NUMERIC_GREATER_THAN = 'NUMERIC_GREATER_THAN',
  NUMERIC_LESS_THAN = 'NUMERIC_LESS_THAN',
  IN_LIST = 'IN_LIST',
}

type Metric = {
  expression: string;
  alias?: string;
  formattingType?: MetricType;
};

enum MetricType {
  METRIC_TYPE_UNSPECIFIED = 'METRIC_TYPE_UNSPECIFIED',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT',
  TIME = 'TIME',
}

type MetricFilterClause = {
  operator: FilterLogicalOperator;
  filters: MetricFilter[];
};

type MetricFilter = {
  metricName: string;
  not: boolean;
  operator: Operator;
  comparisonValue: string;
};

type OrderBy = {
  fieldName: string;
  orderType?: OrderType;
  sortOrder?: SortOrder;
};

enum OrderType {
  ORDER_TYPE_UNSPECIFIED = 'ORDER_TYPE_UNSPECIFIED',
  VALUE = 'VALUE',
  DELTA = 'DELTA',
  SMART = 'SMART',
  HISTOGRAM_BUCKET = 'HISTOGRAM_BUCKET',
  DIMENSION_AS_INTEGER = 'DIMENSION_AS_INTEGER',
}

enum SortOrder {
  SORT_ORDER_UNSPECIFIED = 'SORT_ORDER_UNSPECIFIED',
  ASCENDING = 'ASCENDING',
  DESCENDING = 'ASCENDING',
}

type Segment = {
  dynamicSegment: DynamicSegment;
  segmentId: string;
};

type DynamicSegment = {
  name: string;
  userSegment: SegmentDefinition;
  sessionSegment: SegmentDefinition;
};

type SegmentDefinition = {
  segmentFilters: SegmentFilter[];
};

type SegmentFilter = {
  not: boolean;
  simpleSegment: SimpleSegment;
  sequenceSegment: SequenceSegment;
};

type SimpleSegment = {
  orFiltersForSegment: OrFiltersForSegment[];
};

type SequenceSegment = {
  segmentSequenceSteps: SegmentSequenceStep[];
  firstStepShouldMatchFirstHit: boolean;
};

type OrFiltersForSegment = {
  segmentFilterClauses: SegmentFilterClause[];
};

type SegmentFilterClause = {
  not: boolean;
  dimensionFilter: SegmentDimensionFilter;
  metricFilter: SegmentMetricFilter;
};

type SegmentDimensionFilter = {
  dimensionName: string;
  operator: Operator;
  caseSensitive: boolean;
  expressions: string[];
  minComparisonValue: string;
  maxComparisonValue: string;
};

type SegmentMetricFilter = {
  scope: Scope;
  metricName: string;
  operator: Operator;
  comparisonValue: string;
  maxComparisonValue: string;
};

enum Scope {
  UNSPECIFIED_SCOPE = 'UNSPECIFIED_SCOPE',
  PRODUCT = 'PRODUCT',
  HIT = 'HIT',
  SESSION = 'SESSION',
  USER = 'USER',
}

type SegmentSequenceStep = {
  orFiltersForSegment: OrFiltersForSegment[];
  matchType: MatchType;
};

enum MatchType {
  UNSPECIFIED_MATCH_TYPE = 'UNSPECIFIED_MATCH_TYPE',
  PRECEDES = 'PRECEDES',
  IMMEDIATELY_PRECEDES = 'IMMEDIATELY_PRECEDES',
}

type Pivot = {
  dimensions: Dimension[];
  dimensionFilterClauses?: DimensionFilterClause[];
  metrics?: Metric[];
  startGroup?: number;
  maxGroupCount?: number;
};

type CohortGroup = {
  cohorts: Cohort[];
  lifetimeValue?: boolean;
};

type Cohort = {
  name: string;
  type?: CohortType;
  dateRange?: DateRange;
};

export enum CohortType {
  UNSPECIFIED_COHORT_TYPE = 'UNSPECIFIED_COHORT_TYPE',
  FIRST_VISIT_DATE = 'FIRST_VISIT_DATE',
}
