import Styled from './widget-editor-iframe-styled';
import styled from 'styled-components';
import { memo, useEffect, useMemo, useRef } from 'react';
import { scaleTextarea } from 'utils/widgetUtils';
import { buildGradientColor } from 'utils/parseColors';

interface ContainerProps {
  radius: string;
  borderWidth: number;
  borderColor: string;
  hasBorderPadding: boolean;
  width: number;
  height: number;
  src: string;
  shadow: number;
}

interface GradientContainerProps {
  radius: string;
  width: number;
  height: number;
  hasBorderPadding: boolean;
  borderWidth: number;
  borderColor: string;
}

const Container = styled.div<ContainerProps>`
  border-radius: ${({ radius }) => radius + 'px' || '25px'};
  border: ${({ borderWidth, borderColor }) => borderWidth + 'px solid ' + borderColor || '25px'};
  margin: auto;
  cursor: pointer;
  object-fit: cover;
  padding: ${({ hasBorderPadding }) => (hasBorderPadding ? '3px' : '0')};
  width: ${({ width }) => width + 'px' || '50px'};
  height: ${({ height }) => height + 'px' || '50px'};
  ${({ src }) => (src !== '' ? 'background-image: url(' + src + ');' : 'background: #444444;')}
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-clip: content-box;
  box-shadow: ${({ shadow }) => `rgb(0, 0, 0) 0 0 ${shadow}px 0;`};
`;

const GradientContainer = styled.div<GradientContainerProps>`
  border: ${({ borderWidth, borderColor }) => borderWidth + 'px solid ' + borderColor || '25px'};
  width: ${({ width }) => width + 'px' || '50px'};
  height: ${({ height }) => height + 'px' || '50px'};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: ${({ radius }) => radius + 'px' || '25px'};
  padding: ${({ hasBorderPadding }) => (hasBorderPadding ? '3px' : '0')};
`;

const ImageContainer = styled.div<{ src: string; hasBorderPadding: boolean; radius: string }>`
  border-top-left-radius: ${({ radius }) => +radius - 6 + 'px' || '25px'};
  border-top-right-radius: ${({ radius }) => +radius - 6 + 'px' || '25px'};
  height: ${({ hasBorderPadding }) => (hasBorderPadding ? 'calc(80% - 6px)' : '80%')};
  width: ${({ hasBorderPadding }) => (hasBorderPadding ? 'calc(100% - 6px)' : '100%')};
  top: ${({ hasBorderPadding }) => (hasBorderPadding ? '3px' : '0')};
  left: ${({ hasBorderPadding }) => (hasBorderPadding ? '3px' : '0')};
  flex: 0 0 80%;
  z-index: 1;
  position: absolute;
  right: 0;

  ${({ src }) => (src !== '' ? 'background-image: url(' + src + ');' : 'background: #444444;')}
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-clip: content-box;
`;

const Gradient = styled.div<{ hasBorderPadding: boolean; radius: string; gradient: string }>`
  border-bottom-left-radius: ${({ radius }) => +radius - 6 + 'px' || '25px'};
  border-bottom-right-radius: ${({ radius }) => +radius - 6 + 'px' || '25px'};

  z-index: 2;
  position: absolute;
  width: ${({ hasBorderPadding }) => (hasBorderPadding ? 'calc(100% - 6px)' : '100%')};

  bottom: ${({ hasBorderPadding }) => (hasBorderPadding ? '3px' : '0')};
  left: ${({ hasBorderPadding }) => (hasBorderPadding ? '3px' : '0')};
  right: 0;

  min-height: ${({ hasBorderPadding }) => (hasBorderPadding ? 'calc(20% + 6px)' : '20%')};
  max-height: 50%; */
  
  flex: 1 0 20%;
  background: ${({ gradient }) => gradient};
  > textarea { 
    bottom: 4px;
    margin: 0;
    max-height: 100%;
    background: transparent;
  }
`;

const Overlay = styled.div<{ height: number; gradient: string }>`
  top: 1px;
  transform: translateY(-100%);
  position: absolute;
  height: ${({ height }) => height * 0.8}px;
  width: 100%;
  ${({ gradient }) => gradient};
`;

interface Props {
  styles: Record<string, string | number>;
  className?: string;
  onClick: () => void;
  src: string;
  descriptionValue: string;
  onDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur: () => void;
  isPreview?: boolean;
}

const SlideImage: React.FC<Props> = ({
  styles,
  className,
  onClick,
  src,
  descriptionValue,
  onDescriptionChange,
  onBlur,
  isPreview,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const gradientRef = useRef<HTMLDivElement | null>(null);

  const gradient = useMemo(() => {
    const gradientString = buildGradientColor((styles.gradientcolor as string) ?? 'rgba(0, 0, 0, 1)');

    return gradientString;
  }, [styles.gradientcolor]);

  useEffect(() => {
    scaleTextarea(textAreaRef.current);
    if (gradientRef.current && textAreaRef.current) {
      gradientRef.current.style.height = '1px';
      gradientRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  }, [descriptionValue, styles.titlebelow, styles.fontsize, styles.lineheight]);

  if (styles.descriptionenabled && styles.titlebelow === 0) {
    return (
      <GradientContainer
        className={className}
        radius={styles.radius as string}
        width={styles.width as number}
        height={styles.height as number}
        hasBorderPadding={(styles.hasborderpadding as number) === 1}
        borderWidth={styles.border_width as number}
        borderColor={styles.border_color as string}
      >
        <ImageContainer
          radius={styles.radius as string}
          hasBorderPadding={(styles.hasborderpadding as number) === 1}
          src={src}
        />
        <Gradient
          ref={gradientRef}
          radius={styles.radius as string}
          hasBorderPadding={(styles.hasborderpadding as number) === 1}
          gradient={(styles.gradientcolor as string) ?? 'rgba(0, 0, 0, 1)'}
        >
          <Overlay height={styles.height as number} gradient={gradient} />
          <Styled.WidgetSlideTextarea
            width={styles.width as number}
            ref={textAreaRef}
            rows={1}
            disabled={isPreview}
            styles={styles.fontfamily ? { ...styles } : { ...styles, fontfamily: 'Heebo' }}
            value={descriptionValue}
            onChange={onDescriptionChange}
            onClick={onClick}
            onBlur={onBlur}
          />
        </Gradient>
      </GradientContainer>
    );
  }

  return (
    <Container
      className={className}
      onClick={onClick}
      src={src}
      radius={styles.radius as string}
      borderWidth={styles.border_width as number}
      borderColor={styles.border_color as string}
      hasBorderPadding={(styles.hasborderpadding as number) === 1}
      width={styles.width as number}
      height={styles.height as number}
      shadow={styles.shadow as number}
    />
  );
};

export default memo(SlideImage);
