import DashboardLayout from '../components/layouts';
import Folder from '../components/folders/folder';
import { useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useSelectTeamMutation } from 'redux/services/auth/auth';

const FolderPage: React.FC = () => {
  const teamId = useAppSelector((state) => state.auth.user?.selectedTeamId);

  const [selectTeam, { isUninitialized, isLoading, isError, isSuccess }] = useSelectTeamMutation();

  useEffect(() => {
    if (teamId) {
      selectTeam(null);
    }
  }, [selectTeam, teamId]);

  const canRenderStories = (!teamId && isUninitialized) || (!isLoading && !isUninitialized && !isError && isSuccess);

  return <DashboardLayout withHeaderSearchStory>{canRenderStories && <Folder />}</DashboardLayout>;
};

export default FolderPage;
