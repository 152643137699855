import { memo } from 'react';

interface ICirclePlus {
  className?: string;
}

const CirclePlus = ({ className }: ICirclePlus): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 9C14.5 12.0376 12.0376 14.5 9 14.5C5.96243 14.5 3.5 12.0376 3.5 9C3.5 5.96243 5.96243 3.5 9 3.5C12.0376 3.5 14.5 5.96243 14.5 9ZM16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM9.5625 8.4375H11.4375C11.775 8.4375 12 8.6625 12 9C12 9.3375 11.775 9.5625 11.4375 9.5625H9.5625V11.4375C9.5625 11.775 9.3375 12 9 12C8.6625 12 8.4375 11.775 8.4375 11.4375V9.5625H6.5625C6.225 9.5625 6 9.3375 6 9C6 8.6625 6.225 8.4375 6.5625 8.4375H8.4375V6.5625C8.4375 6.225 8.6625 6 9 6C9.3375 6 9.5625 6.225 9.5625 6.5625V8.4375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(CirclePlus);
