import api from '../api';
import { UserResponse } from '../auth/interface';

export const dpaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    claimToken: builder.mutation<UserResponse, string>({
      query: (code) => `dpa/claim-token/${code}`,
    }),
  }),
});

export const { useClaimTokenMutation } = dpaApi;
