import Styled from './page-title-styled';

interface IPageTitle {
  text: string;
}

const PageTitle = ({ text }: IPageTitle): JSX.Element => {
  return <Styled.Title>{text}</Styled.Title>;
};

export default PageTitle;
