import api from '../api';
import { Article, ConvertArticleRequest, ConvertArticleResponse } from './interface';

export const articlesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    articlesList: builder.query<Article[], string>({
      query: () => 'article',
      providesTags: (result: any[] | undefined, __, id) => {
        if (result) {
          const articles = [...result.map(({ _id: id }) => ({ type: 'Articles' as const, id }))];
          return [...articles, { type: 'Articles', id }];
        }

        return [{ type: 'Articles', id }];
      },
      transformResponse: (response: { articles: Article[] }) => response?.articles,
    }),
    convertArticle: builder.mutation<ConvertArticleResponse, ConvertArticleRequest>({
      query: (body) => ({
        url: `article/convert`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { articleId }) => [{ type: 'Articles', id: articleId }],
    }),
    deleteArticle: builder.mutation<void, string>({
      query: (articleId) => ({
        url: `article/${articleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, articleId) => [{ type: 'Articles', id: articleId }],
    }),
  }),
});

export const { useArticlesListQuery, useConvertArticleMutation, useDeleteArticleMutation } = articlesApi;
