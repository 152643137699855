import { ReactNode } from 'react';
import styled from 'styled-components';

interface IWrapperAttrs {
  maxHeight: string;
  padding?: string;
}

const Wrapper = styled.div.attrs<IWrapperAttrs>(({ maxHeight, padding }) => ({
  style: {
    maxHeight: maxHeight,
    padding: padding ?? 0,
  },
}))<IWrapperAttrs>`
  min-height: 120px;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    transition: background 0.12s ease;
  }
  &:hover {
    scrollbar-width: thin;
    scrollbar-color: var(--shade-300-85) transparent;
  }
  &:hover::-webkit-scrollbar {
    width: 3px;
    border-radius: 2px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: var(--shade-300-85);
  }
`;

interface IProps {
  children: ReactNode;
  maxHeight: string;
  padding?: string;
}

const TutorialModalBodyWrapper = ({ children, padding, maxHeight }: IProps): JSX.Element => {
  return (
    <Wrapper maxHeight={maxHeight} padding={padding}>
      {children}
    </Wrapper>
  );
};

export default TutorialModalBodyWrapper;
