import debounce from 'lodash/debounce';
import EditorSavePrompt from '../editor-save-prompt';
import EditorScrollbar from '../shared/editor-scrollbar';
import EditorStyled from './../editor/editor-styled';
import isNull from 'lodash/isNull';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Styled from './widget-editor-styled';
import SuccessfullyPublishedModal from '../widget-export-modal/successfully-published-modal';
import timeDiffFromNow from '../../utils/timeDiffFromNow';
import WidgetEditorBottomBar from '../widget-editor-bottom-bar';
import WidgetEditorFrame from '../widget-editor-iframe';
import WidgetEditorIframePC from '../widget-editor-iframe/widget-editor-iframe-pc';
import WidgetEditorIframeSmartphone from '../widget-editor-iframe/widget-editor-iframe-smartphone';
import WidgetEditorModal from '../widget-editor-modals/widget-editor-modal';
import WidgetEditorSidebar from '../widget-editor-sidebar/widget-editor-sidebar';
import WidgetEditorTagsModal from '../widget-editor-modals/widget-editor-tags-modal';
import WidgetExportModal from '../widget-export-modal/widget-export-modal';
import WidgetInaccessibleModal from '../teams/shared/widget-inaccessible-modal';
import { DeviceTypeContext } from 'context/widget/device-type';
import { Notification } from '../profile/shared/elements';
import { preventDefault, stopPropagation } from '../../utils/common';
import { PreviewContext, PreviewContextType } from 'context/widget/is-preview';
import { SCROLL_DIRECTION, STORY_LOCK_MODE_TIME } from '../../config/constants';
import { useAppSelector } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { useLazyGetWorkspaceUserQuery } from 'redux/services/workspaces/workspaces';
import { WIDGET_DEVICE_TYPE } from '../widget-editor-sidebar/constants/widget-editor-sidebar-devices';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';
import { WidgetActiveSlideContext } from 'context/widget/active-slide';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';

const initialWidth = 700;

const notificationConfig = {
  position: 'absolute',
  top: '0',
  zIndex: '100',
  left: 'calc(50%)',
  transform: 'translate(calc(-50% + 32px), -50%)',
};

interface Props {
  isSaveNotificationVisible: boolean;
  setSaveNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isExportModalOpen: boolean;
  setExportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  saveWidget: () => Promise<void>;
}

const WidgetEditor: React.VFC<Props> = ({
  isExportModalOpen,
  setExportModalOpen,
  saveWidget,
  isSaveNotificationVisible,
  setSaveNotificationVisible,
}): JSX.Element => {
  const history = useHistory();
  const currentUserId = useAppSelector((state) => state.auth.user?._id);

  const [fetchLastModifiedBy] = useLazyGetWorkspaceUserQuery();

  const { isPreview } = useContext(PreviewContext) as PreviewContextType;
  const { widget } = useContext(WidgetContext) as WidgetContextType;
  const { changesCount } = useContext(ChangesContext) as ChangesContextType;

  const [deviceType, setDeviceType] = useState(WIDGET_DEVICE_TYPE.SMARTPHONE);
  const [widgetActiveSlide, setWidgetActiveSlide] = useState(0);

  const [isPublishedModalOpen, setPublishedModalOpen] = useState(false);
  const [isInaccessibleModalOpen, setIsInaccessibleModalOpen] = useState(false);
  const [isWidgetTagsModalOpen, setWidgetTagsModalOpen] = useState(false);

  const [hasScrollbar, setHasScrollbar] = useState({ horizontal: false, vertical: false });
  const [verticalScrollBarRatio, setVerticalScrollBarRatio] = useState(1);
  const [horizontalScrollBarRatio, setHorizontalScrollBarRatio] = useState(1);

  const [isWidgetLockIgnored, setWidgetLockIgnored] = useState(false);
  const [lastUserModified, setLastUserModified] = useState<{ username?: string } | null>(null);

  const timeDiff = useMemo(() => timeDiffFromNow(widget?.updatedAt || ''), [widget?.updatedAt]);
  const isSameUser = useMemo(() => widget?.modifiedBy === currentUserId, [currentUserId, widget?.modifiedBy]);
  const widgetChanged = useMemo(() => !isNull(changesCount) && changesCount > 0, [changesCount]);

  const widgetHeight = widget?.editorDetails.style.height;
  const widgetShadow = widget?.editorDetails.style.shadow;

  const editorPlaceholderRef = useRef<HTMLDivElement>(null);
  const editorContainerRef = useRef<HTMLDivElement>(null);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const withMetaKey = event.ctrlKey || event.metaKey;
      const nodeName = document?.activeElement?.nodeName;

      if (nodeName !== 'INPUT' && nodeName !== 'TEXTAREA') {
        if (event.key === 's' && withMetaKey) {
          preventDefault(event);
          saveWidget();
        }
      }
    },
    [saveWidget],
  );

  const handleNavigate = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const setScrollbars = useCallback((element: HTMLElement | null) => {
    if (element) {
      setVerticalScrollBarRatio(element.clientHeight / (element.clientHeight - 72));
      setHorizontalScrollBarRatio(element.clientWidth / (element.clientWidth - 48));
      setHasScrollbar({
        horizontal: element.scrollWidth > element.clientWidth,
        vertical: element.scrollHeight > element.clientHeight,
      });
    }
  }, []);

  const updateScrollbars = useCallback(() => {
    setScrollbars(editorPlaceholderRef.current);
  }, [setScrollbars]);

  const debouncedUpdateScrollbars = useCallback(debounce(updateScrollbars, 300), []);

  const closeInaccessibleModal = useCallback(() => setIsInaccessibleModalOpen(false), []);

  useEffect(() => {
    if (widgetHeight) {
      setScrollbars(editorPlaceholderRef.current);
    }
  }, [widgetHeight, widgetShadow, isPreview, deviceType, setScrollbars]);

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateScrollbars);

    return () => window.removeEventListener('resize', debouncedUpdateScrollbars);
  }, [debouncedUpdateScrollbars, updateScrollbars]);

  useEffect(() => {
    if (isSaveNotificationVisible) {
      const timeout = setTimeout(() => {
        setSaveNotificationVisible(false);
      }, 3000);

      return () => {
        setSaveNotificationVisible(false);
        clearTimeout(timeout);
      };
    }
  }, [isSaveNotificationVisible, setSaveNotificationVisible]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    const fetchUser = async () => {
      if (widget?.modifiedBy) {
        try {
          const user = await fetchLastModifiedBy(widget?.modifiedBy).unwrap();
          setLastUserModified(user);
        } catch (err) {
          console.error(err);
        }
      }
    };

    if (!isSameUser) {
      fetchUser();
    }
  }, [fetchLastModifiedBy, isSameUser, widget?.modifiedBy]);

  useEffect(() => {
    if (!isSameUser && !isWidgetLockIgnored && timeDiff) {
      setIsInaccessibleModalOpen(timeDiff < STORY_LOCK_MODE_TIME);
    }
  }, [isWidgetLockIgnored, isSameUser, timeDiff]);

  if (widget?._id === '') {
    return <React.Fragment />;
  }

  return (
    <WidgetActiveSlideContext.Provider value={{ widgetActiveSlide, setWidgetActiveSlide }}>
      <EditorStyled.Container>
        {isPublishedModalOpen && <SuccessfullyPublishedModal setPublishedModalOpen={setPublishedModalOpen} />}
        {isExportModalOpen && (
          <WidgetExportModal setExportModalOpen={setExportModalOpen} setPublishedModalOpen={setPublishedModalOpen} />
        )}
        {isInaccessibleModalOpen && (
          <WidgetInaccessibleModal
            user={lastUserModified?.username || ''}
            onClose={closeInaccessibleModal}
            isOpen={isInaccessibleModalOpen}
            setWidgetLockIgnored={setWidgetLockIgnored}
          />
        )}

        <DeviceTypeContext.Provider value={{ deviceType, setDeviceType }}>
          <EditorStyled.EditorContainer ref={editorContainerRef}>
            <EditorStyled.EditorWrapper id="widget-content" alignContent={isPreview ? 'center' : 'space-between'}>
              {isSaveNotificationVisible && (
                <Notification
                  emphasisText={'Saved.'}
                  text={'Your Carousel has been saved successfully'}
                  config={notificationConfig}
                />
              )}

              {isPreview ? (
                <EditorStyled.LayersMenuSpacing />
              ) : (
                <EditorStyled.LayersContainer onClick={stopPropagation}>
                  <WidgetEditorModal />
                </EditorStyled.LayersContainer>
              )}

              {hasScrollbar.vertical && (
                <EditorScrollbar
                  element={editorPlaceholderRef.current}
                  direction={SCROLL_DIRECTION.VERTICAL}
                  ratio={verticalScrollBarRatio}
                  shouldCalculateRatio
                />
              )}
              {hasScrollbar.horizontal && (
                <EditorScrollbar
                  element={editorPlaceholderRef.current}
                  direction={SCROLL_DIRECTION.HORIZONTAL}
                  ratio={horizontalScrollBarRatio}
                />
              )}

              <Styled.EditorPlaceholder ref={editorPlaceholderRef}>
                <Styled.EditorInnerPlaceholder>
                  {isPreview && deviceType === WIDGET_DEVICE_TYPE.SMARTPHONE && (
                    <Styled.EditorContainerWrapper maxWidth="725px">
                      <WidgetEditorIframeSmartphone updateScrollbars={updateScrollbars} />
                    </Styled.EditorContainerWrapper>
                  )}
                  {isPreview && deviceType === WIDGET_DEVICE_TYPE.COMPUTER && (
                    <Styled.EditorContainerWrapper maxWidth="950px">
                      <WidgetEditorIframePC updateScrollbars={updateScrollbars} />
                    </Styled.EditorContainerWrapper>
                  )}
                  {!isPreview && (
                    <Styled.EditorContainerWrapper maxWidth={`${initialWidth}px`}>
                      <WidgetEditorFrame />
                    </Styled.EditorContainerWrapper>
                  )}
                </Styled.EditorInnerPlaceholder>
              </Styled.EditorPlaceholder>

              {!isPreview && (
                <Styled.EditorSecondLine>
                  <WidgetEditorBottomBar setWidgetTagsModalOpen={setWidgetTagsModalOpen} />
                </Styled.EditorSecondLine>
              )}
              {isWidgetTagsModalOpen && <WidgetEditorTagsModal setWidgetTagsModalOpen={setWidgetTagsModalOpen} />}
            </EditorStyled.EditorWrapper>
            <WidgetEditorSidebar />
          </EditorStyled.EditorContainer>
        </DeviceTypeContext.Provider>

        <EditorSavePrompt when={widgetChanged} navigate={handleNavigate} />
      </EditorStyled.Container>
    </WidgetActiveSlideContext.Provider>
  );
};

export default memo(WidgetEditor);
