import AllStories from '../components/stories/all-stories';
import DashboardLayout from '../components/layouts';
import { useEffect } from 'react';
import { useSelectTeamMutation } from 'redux/services/auth/auth';
import { useAppSelector } from 'hooks';

const AllMyStoriesPage = (): JSX.Element => {
  const teamId = useAppSelector((state) => state.auth.user?.selectedTeamId);

  const [selectTeam, { isUninitialized, isLoading, isError, isSuccess }] = useSelectTeamMutation();

  useEffect(() => {
    if (teamId) {
      selectTeam(null);
    }
  }, [selectTeam, teamId]);

  const canRenderStories = (!teamId && isUninitialized) || (!isLoading && !isUninitialized && !isError && isSuccess);

  return <DashboardLayout withHeaderSearchStory>{canRenderStories && <AllStories />}</DashboardLayout>;
};

export default AllMyStoriesPage;
