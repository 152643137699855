import { memo } from 'react';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { ModalPosterOuterWrapper, PosterSectionsWrapper, PosterSection } from './shared/structure';
import { ModalBodyWrapper, ModalHeaderWrapper, ModalWrapper } from './../shared/modal';
import OutsideClickHandler from 'react-outside-click-handler';
import { BackButton, HeaderTitle, SectionTitle, PosterType } from './shared/elements';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPosterModalOpen, setPosterType } from '../../redux/features/export/exportSlice';
import { posterTypes } from '../../config/constants';
import { PosterImageLandscape, PosterImagePortrait, PosterImageSquare } from './shared/elements/poster-image-preview';
import { RootState } from '../../redux/rootReducer';
import styled from 'styled-components';
import UploadButton from './shared/elements/upload-button';
import CropperWrapper from './shared/structure/cropper-wrapper';
import { getCroppedImg, getAspectRatio } from './helpers/cropperHelpers';
import { IImageSrcs } from './export-modal';
import { Point } from 'react-easy-crop/types';
import SavePosterButton from './shared/elements/save-poster-button';
import { setImageCrops } from '../../redux/features/editor/helpers/helpersSlice';

type ICrop = {
  x: number;
  y: number;
};

export type IImageCrops = {
  [key: string]: ICrop;
  portrait: ICrop;
  landscape: ICrop;
  square: ICrop;
};
interface IPosterModal {
  postersSrcs: IImageSrcs;
  originalPostersSrcs: IImageSrcs;
  updatePostersSrcs: (imageSrcs: IImageSrcs) => void;
  updateOriginalPostersSrcs: (imageSrcs: IImageSrcs) => void;
}

const PosterModalTitle = styled(HeaderTitle)`
  margin: auto;
`;

const readFile = (file: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

const PosterModal = ({
  postersSrcs,
  originalPostersSrcs,
  updatePostersSrcs,
  updateOriginalPostersSrcs,
}: IPosterModal) => {
  const dispatch = useDispatch();
  // Current type of selected poster (Portrait, Landscape, ...)
  const posterType = useSelector((state: RootState) => state.export.posterType);
  // Object containing the position of different poster crops.
  const imageCrops: IImageCrops = useSelector((state: RootState) => state.helpers.imageCrops);

  const [imageCropsLocal, setImageCropsLocal] = useState<IImageCrops>(imageCrops);

  const [isSaving, setIsSaving] = useState(false);
  const [croppedImageSrcs, setCroppedImageSrcs] = useState<IImageSrcs>(postersSrcs);

  const poster = originalPostersSrcs[posterType.toLowerCase()] as string;

  const crop = imageCropsLocal[posterType.toLowerCase()];

  const setCrop = (location: Point) =>
    setImageCropsLocal((prevState) => {
      return {
        ...prevState,
        [posterType.toLocaleLowerCase()]: location,
      };
    });

  const showCroppedImage = useCallback(async (croppedAreaPixels, poster, posterType) => {
    const img = await getCroppedImg(poster, croppedAreaPixels, 0);

    setCroppedImageSrcs((prevImageSrcs: IImageSrcs) => ({
      ...prevImageSrcs,
      [posterType.toLocaleLowerCase()]: img,
    }));
  }, []);

  // Cropping completed
  const onCropComplete = useCallback(
    (_, croppedAreaPixels) => {
      dispatch(setImageCrops(imageCropsLocal));
      showCroppedImage(croppedAreaPixels, poster, posterType);
    },
    [showCroppedImage, poster, posterType, dispatch, imageCropsLocal],
  );

  // Upload handler
  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = (await readFile(file)) as string;

      setCroppedImageSrcs({ portrait: imageDataUrl, landscape: imageDataUrl, square: imageDataUrl });
      updateOriginalPostersSrcs({ portrait: imageDataUrl, landscape: imageDataUrl, square: imageDataUrl });
    }
  };

  // Utils
  const onClosePosterModal = useCallback(() => {
    if (!isSaving) {
      dispatch(setIsPosterModalOpen(false));
    }
  }, [dispatch, isSaving]);
  const onPosterTypeSelect = useCallback((type: string) => () => dispatch(setPosterType(type)), [dispatch]);

  // Todo
  const onSavePoster = async () => {
    setIsSaving(true);
    await updatePostersSrcs(croppedImageSrcs);
    dispatch(setIsPosterModalOpen(false));
  };

  return (
    <ModalPosterOuterWrapper>
      <OutsideClickHandler display="flex" onOutsideClick={onClosePosterModal}>
        <ModalWrapper>
          <ModalHeaderWrapper>
            <BackButton onClick={onClosePosterModal} />

            <PosterModalTitle text="Poster images" />
          </ModalHeaderWrapper>

          <ModalBodyWrapper>
            <PosterSectionsWrapper>
              <PosterSection width={31}>
                <SectionTitle text="Format" />

                <PosterType
                  isSelected={posterType === posterTypes.portrait}
                  type={posterTypes.portrait}
                  onClick={onPosterTypeSelect(posterTypes.portrait)}
                />

                <PosterType
                  isSelected={posterType === posterTypes.landscape}
                  type={posterTypes.landscape}
                  onClick={onPosterTypeSelect(posterTypes.landscape)}
                />

                <PosterType
                  isSelected={posterType === posterTypes.square}
                  type={posterTypes.square}
                  onClick={onPosterTypeSelect(posterTypes.square)}
                />
              </PosterSection>

              <PosterSection width={38}>
                <SectionTitle text="Crop" />

                <CropperWrapper>
                  <Cropper
                    image={poster}
                    crop={crop}
                    aspect={getAspectRatio(posterType)}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                  />
                </CropperWrapper>

                <UploadButton value={''} onChange={onFileChange} />
              </PosterSection>

              <PosterSection width={31}>
                <SectionTitle text="Preview" />

                {/* Previews */}

                {posterType === posterTypes.portrait && <PosterImagePortrait image={croppedImageSrcs.portrait} />}

                {posterType === posterTypes.landscape && <PosterImageLandscape image={croppedImageSrcs.landscape} />}

                {posterType === posterTypes.square && <PosterImageSquare image={croppedImageSrcs.square} />}

                <SavePosterButton isSaving={isSaving} onClick={onSavePoster} />
              </PosterSection>
            </PosterSectionsWrapper>
          </ModalBodyWrapper>
        </ModalWrapper>
      </OutsideClickHandler>
    </ModalPosterOuterWrapper>
  );
};

export default memo(PosterModal);
