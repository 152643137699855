import Styled from './layer-menu-styled';
import { closeModal, openModal } from '../../redux/features/editor-modal/editorModalSlice';
import { createNewLayer } from '../../redux/features/amp-story/ampStorySlice';
import { ILayersMenuConfig } from '../../interfaces/layers-menu-config';
import { layerTypes } from '../../interfaces/layer-types';
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RootState } from '../../redux/rootReducer';
import { toolbarResizeObserver } from '../../utils/resizeObservers';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-disable @typescript-eslint/no-var-requires */

const LayerMenu = (): JSX.Element => {
  const dispatch = useDispatch();

  const [_, updateRef] = useState(0);
  const layersMenuConfig = useSelector((state: RootState) => state.helpers.layersMenuConfig);
  const isModalOpen = useSelector((state: RootState) => state.editorModal.isOpen);
  const modalType = useSelector((state: RootState) => state.editorModal.modalType);
  const layerItemRef = useRef<HTMLDivElement[] | null[]>([]);
  const layerMenuWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (layerMenuWrapperRef?.current) {
      toolbarResizeObserver.observe(layerMenuWrapperRef.current);
    }

    return () => {
      if (layerMenuWrapperRef?.current) {
        toolbarResizeObserver.unobserve(layerMenuWrapperRef.current);
      }
    };
  }, []);

  const onMenuItemClick = (
    title: layerTypes,
    itemModalType: string | undefined,
    offsetTop: number | undefined,
  ) => () => {
    if (isModalOpen && modalType === itemModalType) {
      return dispatch(closeModal());
    }

    if (itemModalType) {
      return dispatch(openModal(itemModalType, offsetTop));
    }

    return dispatch(createNewLayer({ type: title }));
  };

  // useEffect(() => {
  //   dispatch(setLayersMenuConfig({ condition: videoCutLimited, index: 2 }));
  // }, [dispatch, videoCutLimited]);

  const getMenuIcon = (currentItem: ILayersMenuConfig): string => {
    if (currentItem?.classes?.locked) {
      return currentItem.imageDisabledSrc;
    }

    return modalType?.toLocaleLowerCase().includes(currentItem.name)
      ? currentItem.imageActiveSrc
      : currentItem.imageSrc;
  };

  // Refresh offsets
  useLayoutEffect(() => {
    updateRef((v) => v + 1);
  }, []);

  return (
    // Check if the output is satisfying enough
    <Styled.NewLayerMenuWrapper>
      {layersMenuConfig.map((layerMenuItem, index) => {
        const offsetTop = layerItemRef?.current[index]?.offsetTop;
        return (
          <Styled.LayerItem
            key={layerMenuItem.name}
            isActive={modalType === layerMenuItem.modalType}
            isLocked={layerMenuItem?.classes?.locked}
            ref={(element) => (layerItemRef.current[index] = element)}
            onClick={onMenuItemClick(layerMenuItem.name, layerMenuItem?.modalType, offsetTop)}
          >
            <Styled.StyledDiv>
              <img
                id={layerMenuItem.name}
                src={require(`../../assets/images/toolbar_icons/${getMenuIcon(layerMenuItem)}`).default}
                alt="Toolbar-icon"
              />
              <span
                style={{
                  pointerEvents: 'none',
                  position: 'absolute',
                  top: '50%',
                  left: 40,
                  transform: 'translateY(-50%)',
                  fontFamily: 'Heebo',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: 'var(--shade-100)',
                  padding: '7px 12px',
                  background: 'var(--shade-900)',
                  borderRadius: '6px',
                  backdropFilter: 'blur(50px)',
                  boxShadow: '24px 32px 72px rgba(0, 0, 0, 0.18)',
                  zIndex: 999,
                }}
              >
                {layerMenuItem.matTooltip}
              </span>
            </Styled.StyledDiv>
          </Styled.LayerItem>
        );
      })}
    </Styled.NewLayerMenuWrapper>
  );
};

export default memo(LayerMenu);
