import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../buttons';

interface ISavePosterButton {
  isSaving: boolean;
  onClick: (params?: any) => void;
}

const Button = styled(PrimaryButton)`
  display: block;
  box-shadow: 0px 4px 12px var(--black-16);
  width: 100%;
  max-width: 100%;
  align-self: flex-end;
  margin: auto 0 0;
`;

const SavePosterButton = ({ isSaving, onClick }: ISavePosterButton): JSX.Element => {
  return <Button isDisabled={isSaving} isLoading={isSaving} loader text={'Save Poster Images'} onClick={onClick} />;
};

export default SavePosterButton;
