import { memo } from 'react';

interface IDelete {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

const Delete = ({ className, color = '#ABABBA', width = '24px', height = '24px' }: IDelete): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3663 5.91868C19.7121 5.91868 20 6.20937 20 6.57927V6.92127C20 7.28216 19.7121 7.58186 19.3663 7.58186H4.63454C4.28788 7.58186 4 7.28216 4 6.92127V6.57927C4 6.20937 4.28788 5.91868 4.63454 5.91868H7.22629C7.75277 5.91868 8.21095 5.53978 8.32938 5.00519L8.46511 4.39138C8.67605 3.55529 9.37024 3 10.1647 3H13.8353C14.6212 3 15.3231 3.55529 15.5263 4.34729L15.6715 5.00429C15.7891 5.53978 16.2472 5.91868 16.7746 5.91868H19.3664H19.3663ZM18.0496 18.4206C18.3202 15.8674 18.794 9.80139 18.794 9.7402C18.8112 9.5548 18.7516 9.3793 18.6332 9.238C18.506 9.1057 18.3452 9.0274 18.1681 9.0274H5.83863C5.66055 9.0274 5.49111 9.1057 5.37353 9.238C5.25423 9.3793 5.19544 9.5548 5.20409 9.7402C5.20568 9.75144 5.22268 9.96511 5.2511 10.3223C5.37735 11.9093 5.72898 16.3292 5.9562 18.4206C6.11699 19.9614 7.11548 20.9298 8.56178 20.9649C9.67785 20.991 10.8276 21 12.0033 21C13.1107 21 14.2354 20.991 15.3861 20.9649C16.8826 20.9388 17.8802 19.9875 18.0496 18.4206H18.0496Z"
      fill={color}
    />
  </svg>
);

export default memo(Delete);
