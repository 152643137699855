import { memo } from 'react';

interface ISEO {
  className?: string;
}

const SEO = ({ className }: ISEO): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.4012H8.25637C8.51432 21.1751 10.036 25.7948 12.6657 29.7874C9.37179 29.27 6.34219 27.6757 4.05058 25.2537C1.75896 22.8317 0.334533 19.7187 0 16.4012ZM0 13.3863C0.334533 10.0688 1.75896 6.9557 4.05058 4.53371C6.34219 2.11173 9.37179 0.517395 12.6657 0C10.036 3.99268 8.51432 8.61235 8.25637 13.3863H0ZM29.9985 13.3863H21.7421C21.4842 8.61235 19.9625 3.99268 17.3328 0C20.6267 0.517395 23.6563 2.11173 25.9479 4.53371C28.2395 6.9557 29.664 10.0688 29.9985 13.3863ZM29.9985 16.4012C29.664 19.7187 28.2395 22.8317 25.9479 25.2537C23.6563 27.6757 20.6267 29.27 17.3328 29.7874C19.9625 25.7948 21.4842 21.1751 21.7421 16.4012H30H29.9985ZM11.2758 16.4012H18.7227C18.4746 20.4706 17.1931 24.4095 14.9992 27.8458C12.8054 24.4095 11.5239 20.4706 11.2758 16.4012ZM11.2758 13.3863C11.5239 9.31689 12.8054 5.37796 14.9992 1.94161C17.1931 5.37796 18.4746 9.31689 18.7227 13.3863H11.2758Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(SEO);
