import styled from 'styled-components';

const StyledTitle = styled.p<{ margin?: string }>`
  color: var(--shade-100);
  font-family: Heebo;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.01em;
  margin: ${({ margin }) => margin ?? '12px 0px 20px 0px'};
  text-align: left;
  font-style: normal;
  max-width: 100%;
`;
type IProps = {
  className?: string;
  text: string;
  margin?: string;
};
const Subtitle: React.VFC<IProps> = ({ className, text, margin }) => (
  <StyledTitle margin={margin} className={className}>
    {text}
  </StyledTitle>
);
export default Subtitle;
