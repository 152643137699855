import styled from 'styled-components';

const StyledTextButton = styled.button<{ color: string }>`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 3px;
  font-family: Heebo;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ color }) => color};
  align-self: flex-end;
  &:focus {
    border-color: none;
    outline: none;
  }
`;

type IFormTextButton = {
  name: string;
  onClick: () => void;
  text: string;
  color: string;
  className?: string;
};

const FormTextButton = ({ name, onClick, text, color, className }: IFormTextButton): JSX.Element => (
  <StyledTextButton className={className} name={name} onClick={onClick} color={color}>
    {' '}
    {text}{' '}
  </StyledTextButton>
);

export default FormTextButton;
