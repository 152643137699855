import React, { ChangeEvent, useCallback, useEffect, useState, KeyboardEvent } from 'react';
import EditorSidebarHalfColumn from './structure/editor-sidebar-half-column';
import EditorSidebarSlider from './elements/editor-sidebar-slider';
import EditorSidebarSignInput from './elements/editor-sidebar-sign-input';
import EditorSidebarValuesWrapper from './structure/editor-sidebar-values-wrapper';

interface IEditorSidebarSliderWithInputDecimals {
  min: number;
  max: number;
  step: number;
  value: string | number;
  sign: string;
  isDisabled?: boolean;
  onChange: (params: number) => void;
  onInputArrowDown: (params: any) => void;
  sliderMinValue?: number;
}

const EditorSidebarSliderWithInputDecimals = ({
  min,
  max,
  step,
  value,
  sign,
  isDisabled,
  onChange,
  onInputArrowDown,
  sliderMinValue = min,
}: IEditorSidebarSliderWithInputDecimals): JSX.Element => {
  const [localValue, setLocalValue] = useState<string | number>(+value);

  useEffect(() => {
    setLocalValue((v) => (v === value ? v : value));
  }, [value]);

  const getValue = useCallback((value: number | string, min: number, max: number) => {
    if (typeof value === 'string' && value !== '.') {
      return min;
    }

    const newValue = +value;
    if (newValue > max) {
      return newValue % 10;
    }
    if (newValue < min) {
      return min;
    }

    return newValue;
  }, []);

  const shouldArrowsFire = useCallback(
    (event: KeyboardEvent<HTMLInputElement>, min: number, max: number) => {
      if (isDisabled) {
        return false;
      }

      const value = +(event.target as HTMLInputElement).value;
      if (isNaN(value)) {
        return false;
      }
      if (event.key === 'ArrowUp') {
        return !(value >= max);
      }
      if (event.key === 'ArrowDown') {
        return !(value <= min);
      }

      return true;
    },
    [isDisabled],
  );

  const onSliderValueChange = useCallback(
    (inputParam: number | ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) {
        return;
      }

      const newValue = getValue(+inputParam, min, max);

      setLocalValue(newValue);
      onChange(newValue);
    },
    [getValue, isDisabled, max, min, onChange],
  );

  const onValueChange = useCallback(
    (inputParam: ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) {
        return;
      }

      let value: string | number = inputParam?.target.value || '';
      if (/^\d*\.?\d{0,1}$/.test(value)) {
        value = +inputParam?.target?.value > max ? max : inputParam?.target?.value;
        setLocalValue(value);
        onChange(+value);
      }

      if (isNaN(+value) || +value < min || (typeof value === 'string' && value.length === 0)) {
        onChange(sliderMinValue);
      }

      return;
    },
    [isDisabled, max, min, onChange, sliderMinValue],
  );

  const onArrowDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (isDisabled) {
        return;
      }
      if (shouldArrowsFire(event, sliderMinValue, max)) {
        onInputArrowDown(event);
      }
    },
    [isDisabled, max, onInputArrowDown, shouldArrowsFire, sliderMinValue],
  );

  const onBlur = useCallback(() => {
    if (isNaN(+localValue) || +localValue < min || (typeof localValue === 'string' && localValue.length === 0)) {
      setLocalValue(sliderMinValue);
      onChange(sliderMinValue);
    }
  }, [localValue, min, onChange, sliderMinValue]);

  return (
    <EditorSidebarValuesWrapper>
      <EditorSidebarHalfColumn>
        <EditorSidebarSlider
          isDisabled={isDisabled}
          min={sliderMinValue}
          max={max}
          step={step}
          value={+localValue}
          onChange={onSliderValueChange}
        />
      </EditorSidebarHalfColumn>

      <EditorSidebarHalfColumn>
        <EditorSidebarSignInput
          isDisabled={isDisabled}
          value={localValue}
          sign={sign}
          onKeyDown={onArrowDown}
          onChange={onValueChange}
          onBlur={onBlur}
        />
      </EditorSidebarHalfColumn>
    </EditorSidebarValuesWrapper>
  );
};

export default EditorSidebarSliderWithInputDecimals;
