import { memo } from 'react';

interface ICirclePlus {
  className?: string;
}

const Replace = ({ className }: ICirclePlus): JSX.Element => {
  return (
    <svg className={className} width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 7.49999V4.875H4.625L3.41862 6.08249C3.83486 6.50812 4.40467 6.74863 5 6.74998C5.95229 6.74858 6.80057 6.14782 7.118 5.24999H7.12475C7.1677 5.12798 7.20019 5.00253 7.22188 4.875H7.97638C7.7874 6.37495 6.51182 7.49993 5 7.49999H4.99625C4.20079 7.50235 3.43744 7.18637 2.87637 6.62248L2 7.49999ZM2.77775 4.12501H2.02325C2.21216 2.62562 3.48687 1.50083 4.99813 1.50002H5C5.79561 1.49747 6.55914 1.81347 7.12025 2.37752L8 1.50002V4.12501H5.375L6.58325 2.91752C6.16658 2.49139 5.59599 2.25083 5 2.25002C4.04771 2.25143 3.19943 2.85219 2.882 3.75001H2.87525C2.83196 3.87192 2.79908 3.9974 2.77775 4.12501Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Replace);
