import { memo, useEffect, useState } from 'react';
import ColorInput from './color-input';
import ColorInputsWrapper from './../structure/color-inputs-wrapper';
import { IRGBColor } from '../../../../interfaces/colors';

interface IRgbaColorInputProps {
  color: IRGBColor;
  handleColorChange: (color: IRGBColor) => void;
}

const RgbaColorInput: React.FC<IRgbaColorInputProps> = ({ color, handleColorChange }) => {
  const [rgbaColor, setRgbaColor] = useState(color);

  useEffect(() => {
    setRgbaColor(color);
  }, [color]);

  const isValidInput = (value: number) => value >= 0 && value <= 255;

  const handleRgbaColorChange = (event: any, field: string) => {
    const { value } = event.target;
    const newValue = Math.round(value);

    const newColor = { ...rgbaColor, [field]: newValue };

    if (isValidInput(newValue)) {
      setRgbaColor(newColor);
      handleColorChange(newColor);
    }
  };

  return (
    <ColorInputsWrapper>
      <ColorInput type={'number'} value={rgbaColor.r} onChange={(e: any) => handleRgbaColorChange(e, 'r')} />
      <ColorInput type={'number'} value={rgbaColor.g} onChange={(e: any) => handleRgbaColorChange(e, 'g')} />
      <ColorInput type={'number'} value={rgbaColor.b} onChange={(e: any) => handleRgbaColorChange(e, 'b')} />
    </ColorInputsWrapper>
  );
};

export default memo(RgbaColorInput);
