import isEmpty from 'lodash/isEmpty';
import { snippetBodyRegex, snippetHeadRegex } from '../config/constants';
import { IAnalyticsSnippet } from '../interfaces/analytics-snippet';

interface ISnippetErrors {
  [key: string]: any;
  name?: string;
  headCode?: string;
  bodyCode?: string;
}

export default function (snippet: IAnalyticsSnippet): any {
  const errors: ISnippetErrors = {};

  const getGenericError = () => `Please enter a valid HTML code`;

  const methods = {
    validate: () => {
      if (isEmpty(snippet.name)) {
        methods.setErrors('name', 'Name cannot be empty');
      }

      if (isEmpty(snippet.headCode) || !snippetHeadRegex.test(snippet.headCode)) {
        methods.setErrors('headCode', getGenericError());
      }

      if (isEmpty(snippet.bodyCode) || !snippetBodyRegex.test(snippet.bodyCode)) {
        methods.setErrors('bodyCode', getGenericError());
      }
    },
    setErrors: (key: string, value: string) => {
      errors[key] = value;
    },
    getErrors: () => {
      return errors;
    },
    isValid: () => {
      return Object.keys(methods.getErrors()).length === 0;
    },
  };

  return methods;
}
