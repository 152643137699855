import api from '../api';
import { IFont } from 'interfaces/fonts';
import { normalizeUserFontShape } from 'utils/fontUtils';
import type { NormalizedWorkspaceFont, WorkspaceFont, GoogleFont } from './interface';

const mapWorkspaceFonts = (input: WorkspaceFont[]) => {
  const output: IFont[] = [];

  input.forEach((font) => {
    const existingFontFamily = output.find((parsedFont) => parsedFont.family === font.family);
    if (existingFontFamily) {
      existingFontFamily.variants.push(font.subFamilyName.toLowerCase());
      existingFontFamily.paths[String(font.weight)] = font.url;
      existingFontFamily.weight.push(+font.weight);
      return;
    }

    output.push(normalizeUserFontShape(font));
  });

  return output;
};

export const fontsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    workspaceFontsList: builder.query<NormalizedWorkspaceFont[], string>({
      query: () => 'font/workspace',
      providesTags: (result: any[] | undefined) => {
        if (result) {
          const fonts = [...result.map(({ _id: id }) => ({ type: 'WorkspaceFonts' as const, id }))];
          return [...fonts, { type: 'WorkspaceFonts', id: 'LIST' }];
        }

        return [{ type: 'WorkspaceFonts', id: 'LIST' }];
      },
      transformResponse: mapWorkspaceFonts,
      keepUnusedDataFor: 86400,
    }),
    googleFontsList: builder.query<GoogleFont[], void>({
      query: () => 'font/google',
      providesTags: [{ type: 'GoogleFonts', id: 'LIST' }],
      keepUnusedDataFor: 86400,
    }),
    addWorkspaceFont: builder.mutation<NormalizedWorkspaceFont, FormData>({
      query: (formData) => ({
        url: 'font/upload',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [{ type: 'WorkspaceFonts', id: 'LIST' }],
      transformResponse: (response: WorkspaceFont) => normalizeUserFontShape(response),
    }),
    removeWorkspaceFont: builder.mutation<void, string>({
      query: (id) => ({
        url: `font/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [{ type: 'WorkspaceFonts', id }],
    }),
  }),
});

export const {
  useWorkspaceFontsListQuery,
  useAddWorkspaceFontMutation,
  useRemoveWorkspaceFontMutation,
  useGoogleFontsListQuery,
} = fontsApi;
