import styled from 'styled-components';
import { EmptyWidgetListIcon } from '../../../icons';
import { toggleCreateWidgetModal } from 'redux/features/widget/widgetSlice';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const EmptyListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Image = styled(EmptyWidgetListIcon)`
  margin: 0 0 42px;
  color: var(--shade-100);
`;

const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--white);
  max-width: 340px;
  text-align: center;
  margin: 0 auto 16px;
`;

const Button = styled.button`
  display: inline-block;
  padding: 8.5px 12px 7.5px;
  color: var(--shade-100);
  border: 1px solid var(--shade-100);
  filter: drop-shadow(0px 4px 12px var(--black-16));
  border-radius: 6px;
  background: none;
  cursor: pointer;
`;

const EmptyWidgetList: React.VFC = () => {
  const dispatch = useDispatch();
  const handleCreateWidget = () => dispatch(toggleCreateWidgetModal(true));

  return (
    <Wrapper>
      <EmptyListWrapper>
        <Image />

        <Text>
          Immerse your audience.
          <br /> Create your first carousel.
        </Text>

        <Button onClick={handleCreateWidget}>Create Carousel</Button>
      </EmptyListWrapper>
    </Wrapper>
  );
};

export default EmptyWidgetList;
