import getDefaultModifierKey, { DEFAULT_KEY } from 'utils/getKeyboardModifierKey';
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu';
import styled, { css } from 'styled-components';
import { batch } from 'react-redux';
import { Hamburger, NormalIconWrapper } from '../icons';
import { memo, useCallback, useMemo } from 'react';
import { saveAmpStoryChanges } from 'redux/features/amp-story/ampStorySlice';
import { selectTemplateType, toggleSaveTemplateModal } from 'redux/features/templates/templatesSlice';
import { TemplateType } from 'redux/services/templates/templates';
import { useAppDispatch, useAppSelector } from 'hooks';
import { USER_ROLES } from 'config/constants';

const StyledMenu = styled(Menu)`
  margin: 0;
  background: none;
  border: none;
  &&& {
    .context-menu {
      background: red;
    }
  }
`;

const StyledSubMenu = styled(SubMenu)`
  &&& {
    border: none;
    border-radius: 8px;
    transition: background-color 200ms ease;
    &.rc-menu-submenu-selected {
      background-color: unset;
    }
    &.rc-menu-submenu-active {
      background-color: var(--shade-500-85);
      backdrop-filter: blur(50px);
    }
    &.rc-menu-submenu-open {
      background-color: var(--primary-10);
      backdrop-filter: blur(50px);
      svg,
      svg > * {
        fill: var(--primary);
      }
    }
    > div {
      background: none;
      padding: 0;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        margin: 0;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)<{ $isDisabled: boolean }>`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: none;
    margin: 0 12px 4px 12px;
    padding: 3px 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--shade-100);
    cursor: pointer;
    transition: background-color 200ms ease, color 200ms ease;
    &.rc-menu-item-active {
      ${({ $isDisabled }) =>
        !$isDisabled &&
        css`
          background-color: var(--primary);
          color: var(--shade-900);
          > span {
            color: var(--shade-900-85);
          }
        `}
    }
    ${({ $isDisabled }) =>
      $isDisabled &&
      css`
        color: var(--shade-300-85);
        cursor: default;
      `}
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ShortCut = styled.span`
  color: var(--shade-300);
  margin-left: 12px;
  transition: color 200ms ease;
`;

const HeaderContextMenu = () => {
  const dispatch = useAppDispatch();
  const userRoleName = useAppSelector((state) => state.auth.user?.role.name);
  const isMediaUploading = useAppSelector((state) => state.helpers.isMediaUploading);

  const defaultButtonText = useMemo(() => (getDefaultModifierKey() === DEFAULT_KEY.CONTROL ? 'Ctrl' : 'Cmd'), []);

  const saveStory = useCallback(() => !isMediaUploading && dispatch(saveAmpStoryChanges(false, true)), [
    dispatch,
    isMediaUploading,
  ]);

  const openTemplateModal = useCallback(
    (type: TemplateType) => () => {
      if (!isMediaUploading) {
        batch(() => {
          dispatch(selectTemplateType(type));
          dispatch(toggleSaveTemplateModal(true));
        });
      }
    },
    [dispatch, isMediaUploading],
  );

  return (
    <StyledMenu triggerSubMenuAction="click" mode="horizontal" openAnimation="slide-up">
      <StyledSubMenu
        popupClassName={'context-menu'}
        popupOffset={[0, 20]}
        title={
          <NormalIconWrapper margin={'0 0 0 4px'}>
            <Hamburger />
          </NormalIconWrapper>
        }
        key="1"
      >
        <StyledMenuItem key="1-2" onClick={saveStory} $isDisabled={isMediaUploading}>
          Save<ShortCut>{defaultButtonText}+S</ShortCut>
        </StyledMenuItem>
        <StyledMenuItem key="1-3" onClick={openTemplateModal(TemplateType.Personal)} $isDisabled={isMediaUploading}>
          Save as template
        </StyledMenuItem>
        <StyledMenuItem key="1-4" onClick={openTemplateModal(TemplateType.Team)} $isDisabled={isMediaUploading}>
          Save as team template
        </StyledMenuItem>
        {userRoleName === USER_ROLES.ADMIN && (
          <StyledMenuItem key="1-5" onClick={openTemplateModal(TemplateType.Global)} $isDisabled={isMediaUploading}>
            Save as global template
          </StyledMenuItem>
        )}
      </StyledSubMenu>
    </StyledMenu>
  );
};

export default memo(HeaderContextMenu);
