import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

type ISearchValue = string;
type IDebounceSearchConfig = [string, string, (value: string) => void];

interface IConfig {
  searchString: string;
  debounceTime: number;
}

const defaultConfig = {
  searchString: '',
  debounceTime: 300,
};

const useDebounceSearch = (config?: IConfig): IDebounceSearchConfig => {
  const { searchString, debounceTime } = config ?? defaultConfig;
  const [searchValue, setSearchValue] = useState<ISearchValue>(searchString);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<ISearchValue>(searchString);

  const onDebounce = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
    }, debounceTime),
    [],
  );

  useEffect(() => {
    setDebouncedSearchValue((prev) => (prev !== searchString ? searchString : prev));
    setSearchValue((prev) => (prev !== searchString ? searchString : prev));
  }, [searchString]);

  const onSearchChange = (value: string) => {
    setSearchValue(value);
    onDebounce(value);
  };

  return [searchValue, debouncedSearchValue, onSearchChange];
};

export default useDebounceSearch;
