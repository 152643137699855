import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ITitleText {
  className?: string;
  children: ReactNode;
}

const Text = styled.span`
  color: var(--white);
`;

const TitleText = ({ className, children }: ITitleText): JSX.Element => {
  return <Text className={className}>{children}</Text>;
};

export default TitleText;
