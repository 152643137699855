import { memo } from 'react';

interface INative {
  className?: string;
}

const Native = ({ className }: INative): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.71429 0H22.2857C22.7404 0 23.1764 0.180612 23.4979 0.502103C23.8194 0.823593 24 1.25963 24 1.71429V32.5714C24 33.0261 23.8194 33.4621 23.4979 33.7836C23.1764 34.1051 22.7404 34.2857 22.2857 34.2857H1.71429C1.25963 34.2857 0.823594 34.1051 0.502103 33.7836C0.180612 33.4621 0 33.0261 0 32.5714V1.71429C0 1.25963 0.180612 0.823593 0.502103 0.502103C0.823594 0.180612 1.25963 0 1.71429 0ZM12 25.7143C11.5453 25.7143 11.1093 25.8949 10.7878 26.2164C10.4663 26.5379 10.2857 26.9739 10.2857 27.4286C10.2857 27.8832 10.4663 28.3193 10.7878 28.6408C11.1093 28.9622 11.5453 29.1429 12 29.1429C12.4547 29.1429 12.8907 28.9622 13.2122 28.6408C13.5337 28.3193 13.7143 27.8832 13.7143 27.4286C13.7143 26.9739 13.5337 26.5379 13.2122 26.2164C12.8907 25.8949 12.4547 25.7143 12 25.7143Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Native);
