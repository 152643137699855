import { memo } from 'react';
import { COLORS } from '../../config/constants';

interface ILayers {
  isActive?: boolean;
}

const Layers = ({ isActive }: ILayers): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.48487 10.7097L11.5518 15.5894C11.7311 15.6833 11.8207 15.6833 12 15.6833C12.1793 15.6833 12.2689 15.6833 12.4482 15.5894L20.5151 10.7097C20.6944 10.6158 20.784 10.522 20.8737 10.3343C21.1426 9.8651 20.9633 9.30205 20.5151 9.02053L12.4482 4.14076C12.1793 3.95308 11.8207 3.95308 11.5518 4.14076L3.48487 9.02053C3.30561 9.11437 3.21597 9.20821 3.12634 9.3959C2.94708 9.8651 3.03671 10.4282 3.48487 10.7097ZM20.8737 13.7126C20.6048 13.2434 20.067 13.1496 19.6188 13.3372L12 17.9355L4.3812 13.3372C3.93304 13.0557 3.39524 13.2434 3.12634 13.7126C2.85744 14.1818 3.03671 14.7449 3.48487 15.0264L11.5518 19.9062C11.7311 20 11.8207 20 12 20C12.1793 20 12.2689 20 12.4482 19.9062L20.5151 15.0264C20.9633 14.7449 21.0529 14.1818 20.8737 13.7126Z"
      fill={isActive ? COLORS.PRIMARY : COLORS.SHADE_100}
    />
  </svg>
);

export default memo(Layers);
