const data = [
  [31536000, (isPlural: boolean) => ` year${isPlural ? 's' : ''} ago`],
  [2592000, (isPlural: boolean) => ` month${isPlural ? 's' : ''} ago`],
  [604800, (isPlural: boolean) => ` week${isPlural ? 's' : ''} ago`],
  [86400, (isPlural: boolean) => ` day${isPlural ? 's' : ''} ago`],
  [3600, (isPlural: boolean) => ` hour${isPlural ? 's' : ''} ago`],
  [60, (isPlural: boolean) => ` minute${isPlural ? 's' : ''} ago`],
];

const sinceTime = (date: string): string => {
  if (date) {
    const seconds = Math.floor((+new Date() - Date.parse(date)) / 1000);

    let interval;
    for (let i = 0; i < data.length; i++) {
      interval = parseFloat((seconds / <number>data[i][0]).toFixed(0));
      if (interval >= 1) {
        return interval + <string>(data[i][1] as (param: boolean) => string)(interval > 1);
      }
    }
    return parseFloat(seconds.toFixed(0)) + ' s ago';
  } else {
    return 'No time specified';
  }
};

export default sinceTime;
