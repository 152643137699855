import FolderBreadcrumbs from './folder-breadcrumbs';
import FoldersSection from './folders-section';
import PageTitle from '../shared/page-title/page-title';
import React, { useEffect, useMemo } from 'react';
import StoriesSection from 'components/stories/shared/elements/stories-section/stories-section';
import { EmptyStoryList, NoStoriesFound } from 'components/stories/shared/elements';
import { expandRecursively } from 'redux/features/navigation/navigationSlice';
import isEmpty from 'lodash/isEmpty';
import { StoriesWrapper } from '../stories/shared/structure';
import { useAppDispatch, useAppSelector, useQuery } from '../../hooks';
import { useFolderQuery, useFoldersListQuery } from 'redux/services/folders/folders';
import { useHistory, useParams } from 'react-router-dom';
import { useStoryListQuery } from 'redux/services/stories/stories';

const Folder: React.FC = () => {
  const dispatch = useAppDispatch();
  const searchParams = useQuery();
  const { id: folderId } = useParams<{ id: string }>();
  const { push } = useHistory();

  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const selectedTeamId = useAppSelector((state) => state.auth.user?.selectedTeamId);

  const query = useMemo(() => (searchParams.get('title') ? `search[title]=${searchParams.get('title')}` : ''), [
    searchParams,
  ]);

  const { data: folder } = useFolderQuery(
    { workspaceId: selectedWorkspaceId ?? '', folderId },
    { skip: !folderId || !selectedWorkspaceId },
  );

  const { data: folders } = useFoldersListQuery(
    { workspaceId: selectedWorkspaceId ?? '', folderId, teamId: selectedTeamId },
    { skip: !selectedWorkspaceId || !folderId },
  );

  const { data: stories } = useStoryListQuery(
    {
      teamId: selectedTeamId,
      workspaceId: selectedWorkspaceId ?? '',
      folder: folderId,
      query,
    },
    { skip: !selectedWorkspaceId },
  );

  useEffect(() => {
    if (folder) {
      dispatch(expandRecursively(folder));
    }
  }, [dispatch, folder]);

  // Unmount folder if switch workspace
  useEffect(() => {
    if (folder?.workspace && folder?.workspace !== selectedWorkspaceId) {
      push(selectedTeamId ? '/teams' : '/my-stories');
    }
  }, [folder, push, selectedTeamId, selectedWorkspaceId]);

  return (
    <StoriesWrapper>
      <PageTitle text={folder?.title ?? ''} />

      {folder && <FolderBreadcrumbs folder={folder} />}

      <FoldersSection
        isFolderPage
        // nestedFolder
        parentFolder={folder?._id}
        folders={folders}
        storiesLength={stories?.stories?.length}
      />

      <div style={{ marginTop: '40px' }} />

      {stories?.stories?.length === 0 && isEmpty(query) && <EmptyStoryList nestedFolder />}

      {stories?.stories?.length === 0 && !isEmpty(query) && <NoStoriesFound />}

      {(stories?.stories?.length ?? 0) > 0 && <StoriesSection stories={stories?.stories ?? []} header="Stories" />}
    </StoriesWrapper>
  );
};

export default Folder;
