import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ITutorialSlideButtons {
  padding?: string;
  activeIndex: number;
  buttonCount: number;
  onButtonClick: (index: number) => () => void;
}

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: calc(100% - 100px);
  @media screen and (max-width: 767px) {
    top: 10px;
  }
`;

const InnerWrapper = styled.div<{ $padding?: string }>`
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  ${({ $padding }) => $padding}
`;

const ButtonWrapper = styled.div`
  padding: 0 1px;
  margin: 0 3px;
`;

const Button = styled.div<{ $isActive: boolean }>`
  width: 5px;
  height: 5px;
  outline: 2px solid ${({ $isActive }) => ($isActive ? 'var(--primary)' : 'transparent')};
  border-radius: 50%;
  transition: 0.12s ease;
  background: ${({ $isActive }) => ($isActive ? 'var(--primary)' : 'var(--shade-100)')};
`;

const Buttons = ({ padding, activeIndex, buttonCount, onButtonClick }: ITutorialSlideButtons): JSX.Element => {
  return (
    <InnerWrapper $padding={padding}>
      {[...Array(buttonCount).keys()].map((number) => (
        <ButtonWrapper key={`slide-button-${number}`} onClick={onButtonClick(number)}>
          <Button $isActive={activeIndex === number} />
        </ButtonWrapper>
      ))}
    </InnerWrapper>
  );
};

const Wrapper = ({ className, children }: { className?: string; children: ReactNode }) => {
  return <OuterWrapper className={className}>{children}</OuterWrapper>;
};

const TutorialSlideButtons = ({ children }: { children: ReactNode }): JSX.Element => {
  return <>{children}</>;
};

TutorialSlideButtons.Wrapper = Wrapper;
TutorialSlideButtons.Buttons = Buttons;

export default TutorialSlideButtons;
