import React from 'react';
import styled from 'styled-components';
import Search from '../../shared/search';

interface IUnsplashSearch {
  value: string;
  placeholder?: string;
  onChange: any;
}

const SearchWrapper = styled.div`
  display: block;
  padding: 0 20px;
  margin-bottom: 16px;
`;

const UnsplashSearch = ({ value, placeholder, onChange }: IUnsplashSearch): JSX.Element => {
  return (
    <SearchWrapper>
      <Search onChange={onChange} value={value} placeholder={placeholder} />
    </SearchWrapper>
  );
};

export default UnsplashSearch;
