import { useState, VFC, memo, useCallback } from 'react';
import CountUp from 'react-countup';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import { Times } from '../icons';
import { Arrow } from '../icons/analytics';
import SettingsInfo from '../settings/shared/settings-info';
import { makeFriendly } from 'utils/chart';

const Container = styled.div`
  flex: 1 1 250px;
  position: relative;
  min-height: 141px;
`;

const Row = styled.div<{ skipMargin?: boolean; verticalAlign?: 'center' | 'flex-end' | 'flex-start' }>`
  display: flex;
  align-items: ${({ verticalAlign }) => verticalAlign ?? 'center'};
  margin-bottom: 18px;
  justify-content: space-between;
  ${({ skipMargin }) => skipMargin && `margin-bottom: 0;`}
`;

const LabelContainer = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin-left: 12px;
  margin-right: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ValueContainer = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--white);
  align-self: baseline;
`;

const TrendContainer = styled.div<{ isGrowing?: boolean }>`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.01em;
  color: var(--shade-100);

  > * {
    fill: var(--shade-100);
  }

  svg {
    transform: rotate(180deg);
  }

  ${({ isGrowing }) =>
    isGrowing &&
    `
    color: var(--success);
    > * {
        fill: var(--success);
    }
    svg {
      transform: rotate(0deg);
    }
  `}
`;

const Description = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
`;

const Base = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  transition: transform 500ms linear;
  will-change: transform;
  background: var(--shade-900-85);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  padding: 28px 38px 31px;
`;

const Front = styled(Base)<{ isRotated: boolean }>`
  transform: perspective(600px) rotateX(0deg);
  ${({ isRotated }) => isRotated && `transform: perspective(600px) rotateX(180deg);`}
`;

const Back = styled(Base)<{ isRotated: boolean }>`
  transform: perspective(600px) rotateX(-180deg);
  ${({ isRotated }) => isRotated && `transform: perspective(600px) rotateX(0deg);`}
`;

const CloseButton = styled.div`
  margin-left: 12px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    transform: scale(1.75);
  }
  svg > * {
    fill: var(--shade-100);
  }
`;

type Trend = {
  value: number;
  direction: 'UP' | 'DOWN';
};

type Props = {
  label: string;
  value: string | number;
  trend: Trend;
  icon: VFC;
  description: string;
  isLoading: boolean;
  isNumeric?: boolean;
};

const AnalyticsCard: VFC<Props> = ({ label, value, trend, icon: Icon, description, isLoading, isNumeric = true }) => {
  const [isRotated, setRotated] = useState(false);

  const onOutsideClick = useCallback(() => {
    if (isRotated) {
      setRotated(false);
    }
  }, [isRotated]);

  const onClick = () => setRotated((v) => !v);

  return (
    <Container>
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <Front isRotated={isRotated}>
          <Row>
            <Icon />
            <LabelContainer>{label}</LabelContainer>
            <SettingsInfo onClick={onClick} />
          </Row>
          <Row skipMargin verticalAlign="flex-end">
            <ValueContainer>
              {isLoading && '-'}
              {!isLoading && (
                <CountUp
                  duration={1}
                  end={+value}
                  {...(isNumeric ? { formattingFn: makeFriendly } : {})}
                  {...(!isNumeric ? { suffix: '%' } : {})}
                />
              )}
            </ValueContainer>
            {!isLoading && isFinite(trend.value) && (
              <TrendContainer isGrowing={trend.direction === 'UP'}>
                <Arrow />
                {<CountUp duration={1} end={trend.value} suffix={'%'} decimals={1} />}
              </TrendContainer>
            )}
          </Row>
        </Front>
        <Back isRotated={isRotated}>
          <Row verticalAlign="flex-start">
            <Description>{description}</Description>
            <CloseButton onClick={onClick}>
              <Times />
            </CloseButton>
          </Row>
        </Back>
      </OutsideClickHandler>
    </Container>
  );
};

export default memo(AnalyticsCard);
