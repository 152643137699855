import DashboardLayout from 'components/layouts';
import styled from 'styled-components';
import { AnalyticsCard, AnalyticsTable, Datepicker, Empty } from 'components/analytics';
import { CompletionRate, CTR, StoryViews } from 'components/icons/analytics';
import { FilterLogicalOperator, Operator } from 'redux/services/analytics/interface';
import { useAnalyticsStoriesQuery, useHasPublishedStoriesQuery } from 'redux/services/stories/stories';
import { useAppSelector, useSpinner } from 'hooks';
import { Suspense, lazy, useCallback, useMemo, useState } from 'react';
import { useRunReportQuery } from 'redux/services/analytics/analytics';
import { VFC } from 'react';

const LineChart = lazy(() => import('components/analytics/line-chart'));

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--shade-900-85);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 30px 38px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Container = styled.div`
  padding: 0 92px 126px;
  background: var(--shade-500-85);
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 42px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Title = styled.h2`
  padding: 0;
  margin: 0;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const Wrapper = styled.div<{ grow?: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  ${({ grow }) =>
    grow &&
    `
    flex: ${grow};
  `}
`;

function parse(dateString: string) {
  const validatedDate = dateString.match(/^(\d{4})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/);
  if (validatedDate?.length) {
    // make a date
    const newDate = new Date(+validatedDate[1], +validatedDate[2] - 1, +validatedDate[3]);
    // check if month stayed the same (ie that day number is valid)
    if (newDate.getMonth() == +validatedDate[2] - 1) {
      return newDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
    }
  }

  return '';
}

function round(value: number, precision: number) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function percentageChange(oldValue: number, newValue: number) {
  const percentage = ((newValue - oldValue) / oldValue) * 100;
  return percentage;
}

function formatDate(date: string | Date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

function buildPriorDate(daysPrior: number) {
  const today = new Date();
  return formatDate(new Date(new Date().setDate(today.getDate() - daysPrior)));
}

const InsightsPage: VFC = () => {
  const { Spinner, spinnerProps } = useSpinner({
    color: '#f6522b',
    backgroundColor: 'rgba(20, 20, 31, 0.85)',
    disableOverlay: true,
  });

  const workspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId) ?? '';

  const [prevStartDate, setPrevStartDate] = useState(buildPriorDate(60));
  const [prevEndDate, setPrevEndDate] = useState(buildPriorDate(31));
  const [startDate, setStartDate] = useState(buildPriorDate(30));
  const [endDate] = useState(buildPriorDate(1));
  const [periodLength, setPeriodLength] = useState(30);

  const changePeriod = useCallback((newPeriod: number) => {
    setStartDate(buildPriorDate(newPeriod));
    setPrevEndDate(buildPriorDate(newPeriod + 1));
    setPrevStartDate(buildPriorDate(newPeriod * 2));
    setPeriodLength(newPeriod);
  }, []);

  const { data: hasPublishedStories, isLoading: isStoriesCheckLoading } = useHasPublishedStoriesQuery(
    workspaceId ?? '',
    { skip: !workspaceId },
  );

  const {
    data: chartData,
    isLoading: isChartDataLoading,
    isUninitialized: isChartUninitialized,
    isFetching,
  } = useRunReportQuery(
    {
      inputReports: [
        // Story views
        {
          dateRanges: [
            { startDate: prevStartDate, endDate: prevEndDate },
            { startDate, endDate },
          ],
          dimensions: [{ name: 'ga:date' }],
          metrics: [{ expression: 'ga:hits' }],
          dimensionFilterClauses: [
            {
              operator: FilterLogicalOperator.AND,
              filters: [
                {
                  dimensionName: 'ga:eventAction',
                  operator: Operator.EXACT,
                  expressions: ['story_visible'],
                },
                {
                  dimensionName: 'ga:dimension1',
                  operator: Operator.EXACT,
                  expressions: [workspaceId ?? ''],
                },
              ],
            },
          ],
          includeEmptyRows: true,
          hideValueRanges: true,
        },
        // Story completed
        {
          dateRanges: [
            { startDate: prevStartDate, endDate: prevEndDate },
            { startDate, endDate },
          ],
          metrics: [{ expression: 'ga:hits' }],
          dimensionFilterClauses: [
            {
              operator: FilterLogicalOperator.AND,
              filters: [
                {
                  dimensionName: 'ga:eventAction',
                  operator: Operator.EXACT,
                  expressions: ['story_complete'],
                },
                {
                  dimensionName: 'ga:dimension1',
                  operator: Operator.EXACT,
                  expressions: [workspaceId ?? ''],
                },
              ],
            },
          ],
          includeEmptyRows: true,
          hideValueRanges: true,
        },
        //  Link click
        {
          dateRanges: [
            { startDate: prevStartDate, endDate: prevEndDate },
            { startDate, endDate },
          ],
          metrics: [{ expression: 'ga:hits' }],
          dimensionFilterClauses: [
            {
              operator: FilterLogicalOperator.AND,
              filters: [
                {
                  dimensionName: 'ga:eventAction',
                  operator: Operator.EXACT,
                  expressions: ['link_click'],
                },
                {
                  dimensionName: 'ga:dimension1',
                  operator: Operator.EXACT,
                  expressions: [workspaceId ?? ''],
                },
              ],
            },
          ],
          includeEmptyRows: true,
          hideValueRanges: true,
        },
        // All stories
        {
          dateRanges: [
            { startDate: prevStartDate, endDate: prevEndDate },
            { startDate, endDate },
          ],
          metrics: [{ expression: 'ga:hits' }],
          dimensions: [{ name: 'ga:dimension3' }],
          dimensionFilterClauses: [
            {
              operator: FilterLogicalOperator.AND,
              filters: [
                {
                  dimensionName: 'ga:dimension1',
                  operator: Operator.EXACT,
                  expressions: [workspaceId ?? ''],
                },
                {
                  dimensionName: 'ga:eventAction',
                  operator: Operator.IN_LIST,
                  expressions: ['story_visible', 'link_click', 'story_complete'],
                },
              ],
            },
          ],
          pivots: [
            {
              dimensions: [{ name: 'ga:eventAction' }],
              metrics: [{ expression: 'ga:hits' }],
            },
          ],
          hideValueRanges: true,
        },
      ],
      workspaceId,
    },
    { skip: !workspaceId || !hasPublishedStories },
  );

  const {
    labels,
    dataset,
    currentSV: currentStoryViews,
    svTrend: storyViewsTrend,
    crTrend: completionRateTrend,
    ctrTrend: clickThroughTrend,
    crPercentage: completionRatePercentage,
    ctrPercentage: clickThroughPercentage,
    storiesAnalytics: storiesAnalyticsData,
    ids: storiesIds,
  } = useMemo(() => {
    const startDateIndex = chartData?.reports?.[0]?.data?.rows?.findIndex(
      (row) => row.dimensions?.[0] === startDate.replace(/-/g, ''),
    );

    const currentPeriodData = chartData?.reports?.[0]?.data?.rows?.slice(startDateIndex);

    const { x, y } = currentPeriodData?.reduce(
      ({ x, y }, { dimensions, metrics }) => {
        x.push(parse(dimensions?.[0]));
        // Get metrics from second report (Actual report for current period)
        y.push(Number(metrics?.[1]?.values?.[0]));

        return { x, y };
      },

      { x: [] as string[], y: [] as number[] },
    ) ?? { x: [], y: [] };

    const prevSV = +(chartData?.reports?.[0]?.data?.totals?.[0]?.values?.[0] ?? 0);
    const currentSV = +(chartData?.reports?.[0]?.data?.totals?.[1]?.values?.[0] ?? 0);
    const svTrend = percentageChange(prevSV, currentSV);

    const prevCR = +(chartData?.reports?.[1]?.data?.totals?.[0]?.values?.[0] ?? 0);
    const currentCR = +(chartData?.reports?.[1]?.data?.totals?.[1]?.values?.[0] ?? 0);
    const crTrend = percentageChange(prevCR, currentCR);
    const crPercentage = (currentCR / currentSV) * 100;

    const prevCTR = +(chartData?.reports?.[2]?.data?.totals?.[0]?.values?.[0] ?? 0);
    const currentCTR = +(chartData?.reports?.[2]?.data?.totals?.[1]?.values?.[0] ?? 0);
    const ctrTrend = percentageChange(prevCTR, currentCTR);
    const ctrPercentage = (currentCTR / currentSV) * 100;

    let storiesAnalytics = null;
    const ids: string[] = [];

    const storiesReport = chartData?.reports?.[3];

    if (storiesReport) {
      const { columnHeader, data } = storiesReport;
      const { pivotHeaders } = columnHeader?.metricHeader;
      const { rows } = data;

      storiesAnalytics = rows?.map(({ dimensions, metrics }) => {
        const { pivotValueRegions } = metrics?.[1] ?? {};
        const pivotValues = pivotValueRegions?.[0]?.values;

        const { pivotHeaderEntries } = pivotHeaders?.[0] ?? {};

        const values = pivotValues?.reduce((acc: Record<string, number>, currentValue, currentIndex) => {
          const metricName = pivotHeaderEntries?.[currentIndex]?.dimensionValues?.[0];
          const metricValue = currentValue;

          acc[metricName] = +metricValue;

          return acc;
        }, {});

        const views = values?.['story_visible'] ?? 0;
        const storyCompleted = values?.['story_complete'] ?? 0;
        const linkClicks = values?.['link_click'] ?? 0;

        const cr = views ? `${round((storyCompleted / views) * 100, 1)}%` : 0;
        const ctr = views ? `${round((linkClicks / views) * 100, 1)}%` : 0;

        ids.push(dimensions?.[0]);

        return {
          _id: dimensions?.[0],
          views,
          cr,
          ctr,
        };
      });
    }

    return {
      labels: x,
      dataset: y,
      currentSV,
      currentCR,
      currentCTR,
      svTrend,
      crTrend,
      ctrTrend,
      crPercentage,
      ctrPercentage,
      storiesAnalytics,
      ids,
    };
  }, [chartData?.reports, startDate]);

  const { data: storiesMetaData, isLoading: isStoriesMetaDataLoading } = useAnalyticsStoriesQuery(storiesIds, {
    skip: !storiesAnalyticsData || !storiesIds.length,
  });

  const { storiesData } = useMemo(() => {
    if (storiesAnalyticsData && storiesMetaData) {
      const parsedStoriesData = storiesAnalyticsData.flatMap((story) => {
        const metadata = storiesMetaData?.find(({ _id }) => _id === story._id);

        if (metadata) {
          return [
            {
              ...story,
              cover: metadata?.cover,
              title: metadata?.title,
              cuts: metadata?.cuts,
              datePublished: new Date(metadata?.datePublished),
            },
          ];
        }

        return [];
      });

      return { storiesData: parsedStoriesData };
    }

    return { storiesData: null };
  }, [storiesAnalyticsData, storiesMetaData]);

  const isLoading = isChartDataLoading || isChartUninitialized || isFetching;

  return (
    <DashboardLayout>
      <Container>
        <Row>
          <Title>Workspace Insights</Title>
          {hasPublishedStories && (
            <Datepicker
              startDate={startDate}
              endDate={endDate}
              periodLength={periodLength}
              changePeriod={changePeriod}
            />
          )}
        </Row>
        {hasPublishedStories && (
          <>
            <Wrapper>
              <AnalyticsCard
                trend={{ value: storyViewsTrend, direction: storyViewsTrend > 0 ? 'UP' : 'DOWN' }}
                value={currentStoryViews}
                label="Story views"
                icon={StoryViews}
                description="Story Views shows you the number of times people have watched your Stories."
                isLoading={isLoading}
              />
              <AnalyticsCard
                trend={{ value: clickThroughTrend, direction: clickThroughTrend > 0 ? 'UP' : 'DOWN' }}
                value={clickThroughPercentage}
                label="CTR"
                icon={CTR}
                description="Click-Through Rate shows you how often people who watched your Stories clicked on a Call-To-Action (CTA)."
                isNumeric={false}
                isLoading={isLoading}
              />
              <AnalyticsCard
                trend={{ value: completionRateTrend, direction: completionRateTrend > 0 ? 'UP' : 'DOWN' }}
                value={completionRatePercentage}
                label="Completion rate"
                icon={CompletionRate}
                description="Completion Rate shows you how often people reached the last slide of your Stories."
                isNumeric={false}
                isLoading={isLoading}
              />
            </Wrapper>
            <Wrapper grow={'1 0 430px'}>
              <Suspense
                fallback={
                  <SpinnerContainer>
                    <Spinner {...spinnerProps} isVisible />
                  </SpinnerContainer>
                }
              >
                <LineChart
                  labels={labels}
                  dataset={dataset}
                  icon={StoryViews}
                  title="Story views"
                  isLoading={isLoading}
                />
              </Suspense>
            </Wrapper>

            <Wrapper grow={'1 0 233px'}>
              <AnalyticsTable cells={storiesData ?? []} isLoading={isLoading || isStoriesMetaDataLoading} />
            </Wrapper>
          </>
        )}
        {!hasPublishedStories && !isStoriesCheckLoading && <Empty />}
      </Container>
    </DashboardLayout>
  );
};

export default InsightsPage;
