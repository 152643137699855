import backButtonIcon from './../../assets/images/header/back-button-icon.svg';
import InlineEditor from '../inline-editor';
import previewButtonIcon from './../../assets/images/header/preview-btn-icon.svg';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import SaveIndicator from '../editor-header/save-indicator/save-indicator';
import styled, { css } from 'styled-components';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';
import isNull from 'lodash/isNull';
import { PreviewContext } from 'context/widget/is-preview';
import { PrimaryButton } from '../buttons';
import { useDebounce } from '../../hooks';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';
import { WidgetHeaderContextMenu } from '../widget-context-menu';
import type { PreviewContextType } from 'context/widget/is-preview';
import { useHistory } from 'react-router';

interface Props {
  handlePublishWidget: () => Promise<void>;
  handleSaveWidget: (showNotification?: boolean) => Promise<void>;
}

type StoryTitleProps = {
  isActive: boolean;
};

const HeaderContainer = styled.div`
  background: var(--shade-900-85);
  min-height: var(--header-height);
  display: flex;
  align-items: center;
  padding: 0 40px;
`;

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const StoryTitle = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin: 0 7.5px 0 16px;
  padding: 6px 8px 4px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0);
  box-sizing: border-box;
  backdrop-filter: blur(50px);
  border-radius: 6px;
  input {
    padding: 0;
  }
  &:hover {
    background: var(--shade-700-85);
    border-color: rgba(255, 255, 255, 0.2);
  }
  ${(props: StoryTitleProps) =>
    props.isActive &&
    `
    border-color: var(--primary);                
    &:hover {
      border-color: var(--primary);
    }
  `}
`;

const PrimaryButtonWrapper = styled.div`
  position: relative;
  margin: 0 0 0 20px;
  width: 50px;
`;

const ButtonWithIconWrapper = styled.div`
  margin: 0 4px;
`;

const ButtonWithIcon = styled.button<{ isHovered?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 6px;
  background: none;
  outline: none;
  border: none;
  transition: 0.12s ease;
  cursor: pointer;
  transition: background-color 225ms ease;
  ${({ isHovered }) =>
    isHovered &&
    css`
      background-color: var(--shade-500-85);
    `}
  &:hover {
    background-color: var(--shade-500-85);
  }
`;

const BackButtonLabel = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
  margin-left: 16px;
  cursor: pointer;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0 32px 0 4px;
  color: var(--white);

  &:hover {
    > button:first-of-type {
      background-color: var(--shade-500-85);
    }
  }
`;

const WidgetEditorHeader: React.VFC<Props> = ({ handlePublishWidget, handleSaveWidget }): JSX.Element => {
  const { push } = useHistory();

  const { isPreview, handlePreview } = useContext(PreviewContext) as PreviewContextType;
  const { widget, setWidget } = useContext(WidgetContext) as WidgetContextType;
  const { changesCount, setChangesCount } = useContext(ChangesContext) as ChangesContextType;

  const [activeEditor, setActiveEditor] = useState(false);
  const [widgetName, setWidgetName] = useState(widget?.name);

  const debouncedWidgetTitle = useDebounce<string>(widgetName, 1000);

  const onStoryTitleChange = useCallback((name: string | number) => {
    if ((name as string).length > 30) {
      return;
    }
    setWidgetName(name as string);
  }, []);

  const onBlurCallback = useCallback(() => {
    setWidgetName((prev) => (prev === '' ? 'new title' : prev));
  }, []);

  const onTogglePreview = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      handlePreview();
    },
    [handlePreview],
  );

  const goBack = async () => {
    if (!isNull(changesCount) && changesCount > 0) {
      handleSaveWidget(false);
    }

    push('/my-carousels');
  };

  useEffect(() => {
    setWidgetName(widget?.name);
  }, [widget?.name]);

  useEffect(() => {
    if (debouncedWidgetTitle && debouncedWidgetTitle !== widget?.name) {
      setWidget((prev) => ({ ...prev, name: debouncedWidgetTitle }));
      setChangesCount((prev) => prev + 1);
    }
  }, [debouncedWidgetTitle, setChangesCount, setWidget, widget?.name]);

  return (
    <HeaderContainer>
      {widget?._id && isPreview && (
        <BackButtonWrapper>
          <ButtonWithIcon onClick={onTogglePreview}>
            <img src={backButtonIcon} alt="Back" />
          </ButtonWithIcon>
          <BackButtonLabel onClick={onTogglePreview}>Back to Carousel Editor</BackButtonLabel>
        </BackButtonWrapper>
      )}

      {widget?._id && !isPreview && (
        <>
          <BackButtonWrapper>
            <ButtonWithIcon onClick={goBack}>
              <img src={backButtonIcon} alt="Back" />
            </ButtonWithIcon>
          </BackButtonWrapper>

          <WidgetHeaderContextMenu handleSaveWidget={handleSaveWidget} />

          <StoryTitle isActive={activeEditor}>
            <InlineEditor
              open={activeEditor}
              setOpen={setActiveEditor}
              onChange={onStoryTitleChange}
              onBlurCallback={onBlurCallback}
              value={widgetName}
            />
          </StoryTitle>

          <SaveIndicator changeCount={changesCount} />

          {/* {!activeEditor && (
            <Tooltip
              placement="right"
              visible={changesCount > 0}
              overlay={<CustomTooltip>Saving</CustomTooltip>}
              transitionName="rc-tooltip-zoom"
              destroyTooltipOnHide={{ keepParent: false }}
              align={{
                offset: [12, 0],
              }}
            >
              <Spinner {...spinnerProps} isVisible={changesCount > 0} />
            </Tooltip>
          )} */}

          <RightColumn>
            <ButtonWithIconWrapper>
              <ButtonWithIcon type="button" onClick={onTogglePreview}>
                <img src={previewButtonIcon} alt="Preview Carousel" />
              </ButtonWithIcon>
            </ButtonWithIconWrapper>

            <PrimaryButtonWrapper>
              <PrimaryButton text={'Publish'} onClick={handlePublishWidget} />
            </PrimaryButtonWrapper>
          </RightColumn>
        </>
      )}
    </HeaderContainer>
  );
};

export default memo(WidgetEditorHeader);
