import React from 'react';

const TextIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46447 4.46447C3 5.92893 3 8.28595 3 13V15C3 19.714 3 22.0711 4.46447 23.5355C5.92893 25 8.28595 25 13 25H15C19.714 25 22.0711 25 23.5355 23.5355C25 22.0711 25 19.714 25 15V13C25 8.28595 25 5.92893 23.5355 4.46447C22.0711 3 19.714 3 15 3H13C8.28595 3 5.92893 3 4.46447 4.46447ZM17 10H11C10.4 10 10 10.4 10 11C10 11.6 10.4 12 11 12H13V17C13 17.6 13.4 18 14 18C14.6 18 15 17.6 15 17V12H17C17.6 12 18 11.6 18 11C18 10.4 17.6 10 17 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TextIcon;
