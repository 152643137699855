import styled from 'styled-components';

const StyledFormWrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  & * {
    box-sizing: border-box;
  }
`;

const AuthBackgroundContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const AuthBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  z-index: 0;
`;

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: var(--black-70);
`;

const Logo = styled.img`
  width: 141px;
  height: auto;
`;

const LogoLink = styled.a`
  z-index: 1;
  position: absolute;
  top: 21px;
  left: 26px;
`;

export default {
  StyledFormWrapper,
  AuthBackgroundContainer,
  AuthBackground,
  Logo,
  LogoLink,
  Overlay,
};
