import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { IFolder } from 'redux/services/folders/interfaces';
import { RootState } from '../../rootReducer';

type Selector = (...args: any) => any;

interface InitialState {
  isMyFoldersExpanded: boolean;
  isTeamFoldersExpanded: boolean;
  [folderId: string]: boolean;
}

const initialState = {
  isMyFoldersExpanded: false,
  isTeamFoldersExpanded: false,
} as InitialState;

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    expandMyFolders: (state) => {
      state.isMyFoldersExpanded = true;
    },
    collapseMyFolders: (state) => {
      state.isMyFoldersExpanded = false;
    },
    expandTeamFolders: (state) => {
      state.isTeamFoldersExpanded = true;
    },
    collapseTeamFolders: (state) => {
      state.isTeamFoldersExpanded = false;
    },
    expandFolder: (state, action: PayloadAction<string>) => {
      if (isNil(state[action.payload])) {
        state[action.payload] = true;
      }
    },
    collapseFolder: (state, action: PayloadAction<string>) => {
      if (!isNil(state[action.payload])) {
        delete state[action.payload];
      }
    },
    expandRecursively: (state, action: PayloadAction<IFolder>) => {
      const folder = action.payload;
      if (folder.team) {
        state.isTeamFoldersExpanded = true;
      } else {
        state.isMyFoldersExpanded = true;
      }

      folder.ancestors?.forEach((ancestor) => {
        state[ancestor._id] = true;
      });
    },
  },
});

export const {
  expandMyFolders,
  collapseMyFolders,
  expandTeamFolders,
  collapseTeamFolders,
  expandFolder,
  collapseFolder,
  expandRecursively,
} = navigationSlice.actions;
export default navigationSlice.reducer;

export const makeSelectCurrentFolderState = (): Selector =>
  createSelector(
    (state: RootState) => state.navigation,
    (_: RootState, folderId: string) => folderId,
    (navigation, folderId) => get(navigation, folderId),
  );
