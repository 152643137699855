import { memo } from 'react';

interface IReplace {
  className?: string;
}

const Replace = ({ className }: IReplace): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 29.9999V16.875H13.125L7.09312 22.9124C9.1743 25.0406 12.0234 26.2432 15 26.2499C19.7615 26.2429 24.0028 23.2391 25.59 18.75H25.6238C25.8385 18.1399 26.001 17.5126 26.1094 16.875H29.8819C28.937 24.3747 22.5591 29.9996 15 29.9999H14.9813C11.0039 30.0118 7.18718 28.4319 4.38188 25.6124L0 29.9999ZM3.88875 13.125H0.11625C1.06078 5.62811 7.43437 0.00417162 14.9906 7.63116e-05H15C18.9781 -0.012652 22.7957 1.56734 25.6012 4.38761L30 7.63116e-05V13.125H16.875L22.9163 7.08759C20.8329 4.95694 17.9799 3.75413 15 3.75012C10.2385 3.75715 5.99715 6.76093 4.41 11.25H4.37625C4.15979 11.8596 3.99542 12.487 3.88875 13.125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Replace);
