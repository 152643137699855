import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import PrivateRoute from './guards/PrivateRoute';
import PublicRoute from './guards/PublicRoute';

import {
  MyStoriesPage,
  MyWidgetsPage,
  WidgetsEditorPage,
  EditorPage,
  NotFoundPage,
  LoginPage,
  ForgotPasswordCodePage,
  ForgotPasswordConfirmPage,
  ForgotPasswordPage,
  BrandingSettingsPage,
  PublishingSettingsPage,
  AllMyStoriesPage,
  ProfilePage,
  WorkspaceSettingsPage,
  MembersSettingsPage,
  TeamsPage,
  AcceptInvitationPage,
  TeamsAllStoriesPage,
  FolderPage,
  TrialExpiredPage,
  InsightsPage,
  SignUpPage,
  DPAAuthenticationPage,
  ConversionPage,
  SettingsApiPage,
  TeamFolderPage,
} from 'pages';
import { MY_STORIES, SETTINGS, TEAMS, FORGOTTEN_PASSWORD } from 'config/main-page-paths';
import { useAppSelector } from 'hooks';

import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { WorkspaceTypes } from 'redux/services/workspaces/interface';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { usePageTracking } from 'hooks/usePageTracking';

const Routes = (): JSX.Element => {
  const history = useHistory();
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);

  const { isTrialWorkspace, trialRemainingDays } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace }) => {
      let trialRemainingDays = null;

      const trialEndsAtString = workspace?.trialEndsAt;
      if (trialEndsAtString) {
        const createdAt = new Date();
        const trialEndsAt = new Date(trialEndsAtString);
        const differenceInTime = trialEndsAt.getTime() - createdAt.getTime();
        const daysLeft = differenceInTime / (1000 * 3600 * 24);

        trialRemainingDays = daysLeft >= 0 ? daysLeft : 0;
      }

      return {
        trialRemainingDays,
        isTrialWorkspace: workspace?.type === WorkspaceTypes.TRIAL,
      };
    },
  });

  const fromTrialPageToStories = isTrialWorkspace
    ? typeof trialRemainingDays === 'number' && trialRemainingDays > 0
    : true;

  const shouldGoToTrialExpiredPage = isTrialWorkspace
    ? typeof trialRemainingDays === 'number' && trialRemainingDays === 0
    : false;

  useEffect(() => {
    if (shouldGoToTrialExpiredPage && history && history?.location?.pathname !== '/trial-expired') {
      history?.push('/trial-expired');
    }
  }, [history, history?.location?.pathname, shouldGoToTrialExpiredPage]);

  usePageTracking();

  return (
    <Switch>
      <Route path="/workspace/invite/:token" component={AcceptInvitationPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/sign-up" component={SignUpPage} />
      <Route path="/dpa-auth" component={DPAAuthenticationPage} />
      <PublicRoute path={`${FORGOTTEN_PASSWORD}-code`} component={ForgotPasswordCodePage} />
      <PublicRoute path={`${FORGOTTEN_PASSWORD}-confirm`} component={ForgotPasswordConfirmPage} />
      <PublicRoute path={FORGOTTEN_PASSWORD} component={ForgotPasswordPage} />

      <PrivateRoute path="/profile" component={ProfilePage} />
      <PrivateRoute exact path={SETTINGS} render={() => <Redirect to={`${SETTINGS}/workspace`} />} />
      <PrivateRoute path={`${SETTINGS}/workspace`} component={WorkspaceSettingsPage} />
      <PrivateRoute path={`${SETTINGS}/publishing`} component={PublishingSettingsPage} />
      <PrivateRoute path={`${SETTINGS}/branding`} component={BrandingSettingsPage} />
      <PrivateRoute path={`${SETTINGS}/members`} component={MembersSettingsPage} />
      <PrivateRoute path={`${SETTINGS}/api`} component={SettingsApiPage} />

      <PrivateRoute path="/story/:id" component={EditorPage} />

      <PrivateRoute exact path="/" render={() => <Redirect to={MY_STORIES} />} />
      <PrivateRoute exact path={`${MY_STORIES}/folder/:id`} component={FolderPage} />
      <PrivateRoute exact path={`${MY_STORIES}/all`} component={AllMyStoriesPage} />
      <PrivateRoute path={MY_STORIES} component={MyStoriesPage} />

      <PrivateRoute exact path={`${TEAMS}/folder/:id`} component={TeamFolderPage} />
      <PrivateRoute exact path={`${TEAMS}/all-stories`} component={TeamsAllStoriesPage} />
      <PrivateRoute path={TEAMS} component={TeamsPage} />

      <PrivateRoute path="/carousel/:id" component={WidgetsEditorPage} />
      <PrivateRoute path="/my-carousels" component={MyWidgetsPage} />

      <PrivateRoute path="/trial-expired" component={TrialExpiredPage}>
        {fromTrialPageToStories && <Redirect to={MY_STORIES} />}
      </PrivateRoute>

      <PrivateRoute path="/story-conversion" component={ConversionPage} />

      <PrivateRoute path="/insights" component={InsightsPage} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
