import { ReactNode } from 'react';
import styled from 'styled-components';

interface IPosterSectionsWrapper {
  children: ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  padding: 0 8px;
`;

const PosterSectionsWrapper = ({ children }: IPosterSectionsWrapper) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PosterSectionsWrapper;
