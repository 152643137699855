export enum IColorType {
  BgColor = 'bgColor',
  FillColor = 'fillColor',
  BorderColor = 'borderColor',
}

export interface IRGBColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface IHSLColor {
  h: number;
  s: number;
  l: number;
  a: number;
}
export interface RgbInterface {
  red: number;
  green: number;
  blue: number;
}

export interface IColorPair {
  [key: string]: any;
  leftColor: IRGBColor;
  rightColor: IRGBColor;
}

export interface IHSLColor {
  h: number;
  s: number;
  l: number;
  a: number;
}
export interface RgbInterface {
  red: number;
  green: number;
  blue: number;
}
