import InputBasic from '../shared/input-basic';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { InputLabel } from '../export-modal/shared/elements';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from 'hooks';
import { useCreateFolderMutation, useRenameFolderMutation } from 'redux/services/folders/folders';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { IFolder } from 'redux/services/folders/interfaces';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  height: auto;
  box-shadow: 24px 32px 72px rgba(0, 0, 0, 0.18);
  background: var(--shade-85-900);
  border-radius: 12px;
`;

const MessageContainer = styled.div`
  flex-grow: 10;
  display: flex;
  flex-direction: column;
`;

const Message = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  letter-spacing: 0.01em;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;

  button {
    cursor: pointer;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  justify-content: center;
  padding: 0 30px;
`;

interface IProps {
  isCreateFolderModalOpen: boolean;
  onClose: () => void;
  folder?: IFolder | null;
  parentFolder?: string | null;
}

const customStyles = {
  overlay: {
    backgroundColor: 'var(--shade-500-85)',
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    maxWidth: '390px',
    borderRadius: '12px',
    border: 'none',
    boxShadow: '24px 32px 72px var(--black-18)',
    display: 'flex',
    background: 'var(--shade-900-95)',
  },
};

const CreateFolderModal: React.FC<IProps> = ({ isCreateFolderModalOpen, onClose, folder, parentFolder }) => {
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const [folderName, setFolderName] = useState(folder?.title || '');

  const { defaultTeamId } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace }) => ({
      defaultTeamId: workspace?.teams?.[0]?._id,
    }),
  });

  const [createFolder, { isLoading: isCreateFolderLoading }] = useCreateFolderMutation();
  const [renameFolder, { isLoading: isRenameFolderLoading }] = useRenameFolderMutation();

  const isMyStoriesPage: boolean = window.location.href.includes('my-stories');
  const label = folder ? 'Rename folder' : 'Create new folder';

  const handleFolderCreate = useCallback(async () => {
    if (!folderName) {
      return;
    }

    if (folder) {
      await renameFolder({
        title: folderName,
        folderId: folder._id,
      }).unwrap();
    } else {
      await createFolder({
        title: folderName,
        folderId: parentFolder,
        teamId: isMyStoriesPage ? null : defaultTeamId,
      }).unwrap();
    }

    onClose();
  }, [folderName, folder, onClose, renameFolder, createFolder, parentFolder, isMyStoriesPage, defaultTeamId]);

  const handleFolderNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleFolderCreate();
      }
    },
    [handleFolderCreate],
  );

  useEffect(() => {
    if (folder?.title) {
      setFolderName(folder.title);
    }
  }, [folder?.title]);

  return (
    <Modal
      closeTimeoutMS={300}
      isOpen={isCreateFolderModalOpen}
      style={customStyles}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
    >
      <ModalContainer>
        <MessageContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
            <Message>{label}</Message>
          </div>
          <div style={{ display: 'flex', margin: '8px 0', flexDirection: 'column' }}>
            <InputLabel text="Name" />
            <InputBasic
              type="text"
              onChange={handleFolderNameChange}
              onKeyDown={handleKeyDown}
              placeholder={'Untitled'}
              value={folderName}
              autoFocus
            />
          </div>
        </MessageContainer>
        <ButtonContainer>
          <StyledSecondaryButton text={'Cancel'} onClick={onClose} />
          <PrimaryButton
            loader={true}
            isLoading={isCreateFolderLoading || isRenameFolderLoading}
            isDisabled={!folderName}
            text={label}
            onClick={handleFolderCreate}
          />
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default memo(CreateFolderModal);
