import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
`;

const Pill = styled.div<{ isActive?: boolean }>`
  padding: 7px 12px;
  border-radius: 6px;
  background-color: ${({ isActive }) => (isActive ? 'var(--shade-100)' : 'var(--shade-700-85)')};
  color: ${({ isActive }) => (isActive ? 'var(--shade-900)' : 'var(--white)')};
  backdrop-filter: blur(25px);
  transition: background-color 225ms ease, color 225ms ease;

  cursor: pointer;
  white-space: nowrap;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  &:hover {
    background-color: ${({ isActive }) => (isActive ? 'var(--shade-100)' : 'var(--shade-500-85)')};
    color: ${({ isActive }) => (isActive ? 'var(--shade-900)' : 'var(--white)')};
  }
`;

interface IPills {
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: Dispatch<SetStateAction<string[]>>;
  displayOption?: (option: string) => string;
}

const Pills = ({ options, selectedOptions, setSelectedOptions, displayOption }: IPills): JSX.Element => {
  return (
    <Container>
      {options?.map((o) => (
        <Pill
          key={o}
          isActive={selectedOptions.includes(o)}
          onClick={() =>
            setSelectedOptions((prev) => {
              if (prev.includes(o)) {
                return prev.filter((option) => option !== o);
              }
              return [...prev, o];
            })
          }
        >
          {typeof displayOption === 'function' ? displayOption(o) : o}
        </Pill>
      ))}
    </Container>
  );
};

export default Pills;
