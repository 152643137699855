import { memo } from 'react';

interface IProps {
  className?: string;
}

const ArrowUp = ({ className }: IProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.475 5.22119L5.475 8.16856C5.175 8.4633 5.175 8.9054 5.475 9.20014C5.775 9.49488 6.225 9.49488 6.525 9.20014L8.25 7.5054L8.25 12.5791C8.25 13.0212 8.55 13.3159 9 13.3159C9.45 13.3159 9.75 13.0212 9.75 12.5791L9.75 7.5054L11.475 9.20014C11.625 9.34751 11.775 9.42119 12 9.42119C12.45 9.42119 12.75 9.12645 12.75 8.68435C12.75 8.4633 12.675 8.31593 12.525 8.16856L9.525 5.22119C9.225 4.92645 8.775 4.92645 8.475 5.22119Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ArrowUp);
