import isNil from 'lodash/isNil';

import { Action, AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IErrorInitialState {
  error: Error | null;
}

interface IErrorPayload {
  error: Error;
}

interface RejectedAction extends Action {
  payload: IErrorPayload;
}

const initialState: IErrorInitialState = {
  error: null,
};

const isActionWithError = (action: AnyAction): action is RejectedAction => !isNil(action?.payload?.error);

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    catchErrors(state, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    clearErrors(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isActionWithError,
      // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
      (state, action) => {
        state.error = action.payload.error;
      },
    );
  },
});
export const { catchErrors, clearErrors } = errorSlice.actions;
export default errorSlice.reducer;
