import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../buttons';
import { ChevronLeft, ChevronRight } from '../../icons';

const MainButton = styled(PrimaryButton)`
  padding: 9px 23px;
  justify-self: flex-end;
  margin: 0;
  transition: 0.12s ease;
  &:hover {
    background: var(--primary-85);
  }
`;

const PrevButton = styled(SecondaryButton)`
  padding: 9px 23px;
  transition: 0.12s ease;
  z-index: 10;
  position: relative;
  &:hover {
    background: var(--shade-100-85);
  }
`;

const Link = styled.a`
  display: inline-block;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: var(--shade-100);
  margin: 0 30px 0 auto;
  justify-self: flex-end;
  transition: 0.12s ease;
  z-index: 10;
  position: relative;
  &:hover {
    color: var(--shade-100-85);
  }
`;

const PrevIcon = styled(ChevronLeft)`
  width: 6px;
  height: 10px;
  margin-right: 8px;
`;

const NextIcon = styled(ChevronRight)`
  width: 6px;
  height: 10px;
  margin-left: 8px;
`;

const ImageText = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0 0 6px;
`;

const ContentRow = styled.div<{ $padding?: string; $minHeight?: string; $alignItems?: string }>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: ${({ $padding }) => $padding};
  min-height: ${({ $minHeight }) => $minHeight};
  align-items: ${({ $alignItems }) => $alignItems};
  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
`;
const Row = styled.div<{ $padding?: string; $alignItems?: string }>`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  align-items: center;
  padding: ${({ $padding }) => $padding};
  align-items: ${({ $alignItems }) => $alignItems};
`;

const FeatureColumn = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentColumn = styled.div<{ $padding?: string }>`
  flex: 1;
  order: 2;
  padding: ${({ $padding }) => $padding};
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`;

const ImageColumn = styled.div<{ $padding?: string; $margin?: string }>`
  background: var(--shade-500);
  border-radius: 12px;
  flex: 0 0 auto;
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  @media screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`;

export default {
  MainButton,
  PrevButton,
  Link,
  PrevIcon,
  NextIcon,
  ImageColumn,
  ImageText,
  ContentRow,
  ContentColumn,
  Row,
  FeatureColumn,
};
