import Folder from './folder';
import { memo } from 'react';
import { IFolder } from 'redux/services/folders/interfaces';

interface IProps {
  folders: IFolder[];
  folderId?: string;
  selectedFolderId?: string | null;
  setSelectedFolderId: React.Dispatch<React.SetStateAction<string | null>>;
}

const TreeRecursive: React.FC<IProps> = ({ folders, folderId, selectedFolderId, setSelectedFolderId }) => {
  return (
    <>
      {folders.map((folder) => (
        <Folder
          key={folder._id}
          item={folder}
          setSelectedFolderId={setSelectedFolderId}
          selectedFolderId={selectedFolderId}
          folderId={folderId}
        />
      ))}
    </>
  );
};

export default memo(TreeRecursive);
