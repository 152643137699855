import api from '../api';
import type { Gif } from './interface';

export const gifsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRandomGif: builder.query<Gif, void>({
      query: () => 'gifs/random',
      providesTags: (response) => [{ type: 'Gifs', id: response?.id }],
      transformResponse: (response: Gif[]) => response?.[0],
      keepUnusedDataFor: 0,
    }),
    getSuccessGif: builder.query<Gif, void>({
      query: () => 'gifs/success',
      providesTags: (response) => [{ type: 'Gifs', id: response?.id }],
      transformResponse: (response: Gif[]) => response?.[0],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetRandomGifQuery, useGetSuccessGifQuery, usePrefetch } = gifsApi;
