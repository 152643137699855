import ChangePasswordForm from './change-password-form';
import InputReadOnly from '../shared/input-read-only';
import { useState, memo } from 'react';
import styled, { css } from 'styled-components';
import { BoxColumn, BoxRow, BoxWrapper, HeaderWrapper, MainWrapper } from './shared/structure';
import { EditPasswordButton, InputLabel, Notification, ProfileAvatar, ProfileUsername } from './shared/elements';
import { setIsPasswordChanged } from '../../redux/features/user/userSlice';
import { useAppDispatch, useAppSelector, useDidUpdateEffect } from '../../hooks';

const UserName = styled(ProfileUsername)`
  padding-left: 34px;
`;

const ChangePasswordRow = styled(BoxRow)<{ showChangePassword?: boolean }>`
  ${({ showChangePassword }) =>
    !showChangePassword &&
    css`
      margin-top: -6px;
    `};
`;

const Profile = (): JSX.Element => {
  const userEmail = useAppSelector((state) => state.auth.user?.email);
  const userUsername = useAppSelector((state) => state.auth.user?.username);
  const isDPAUser = useAppSelector((state) => state.auth.user?.provider === 'dpa');

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isPasswordChanged, setPasswordChanged] = useState(false);
  const [isSuccessfulModalVisible, setIsSuccessfulModalVisible] = useState(false);

  const onEditPasswordButtonClick = () => setShowChangePassword(true);

  useDidUpdateEffect(() => {
    if (isPasswordChanged) {
      setShowChangePassword(false);
      setIsSuccessfulModalVisible(true);
      setTimeout(() => {
        setIsSuccessfulModalVisible(false);
        setPasswordChanged(false);
      }, 3000);
    }
  }, [isPasswordChanged]);

  return (
    <MainWrapper>
      {isSuccessfulModalVisible && (
        <Notification
          emphasisText={'Saved.'}
          text={'Your password has been updated.'}
          config={{ left: '50%', transform: 'translateX(-50%)', zIndex: '4' }}
        />
      )}

      <HeaderWrapper>
        <ProfileAvatar />

        <UserName username={userUsername} />
      </HeaderWrapper>

      <BoxWrapper>
        <BoxRow>
          <BoxColumn width={'238px'}>
            <InputLabel text={'E-Mail'} />
            <InputReadOnly value={userEmail} type="email" />
          </BoxColumn>
        </BoxRow>
      </BoxWrapper>
      {!isDPAUser && (
        <BoxWrapper>
          <BoxRow>
            <BoxColumn width={'238px'}>
              <form>
                <InputLabel text={'Password'} />
                <InputReadOnly value={'********'} type="password" />
              </form>
            </BoxColumn>
          </BoxRow>

          <ChangePasswordRow showChangePassword={showChangePassword}>
            <BoxColumn width={'238px'}>
              {showChangePassword ? (
                <ChangePasswordForm setPasswordChanged={setPasswordChanged} />
              ) : (
                <EditPasswordButton onClick={onEditPasswordButtonClick} />
              )}
            </BoxColumn>
          </ChangePasswordRow>
        </BoxWrapper>
      )}
    </MainWrapper>
  );
};

export default memo(Profile);
