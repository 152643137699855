import { memo } from 'react';

interface IFullscreen {
  className?: string;
}

const Fullscreen = ({ className }: IFullscreen): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3636 27.9545C16.3636 26.7273 15.5455 25.9091 14.3182 25.9091L6.95454 25.9091L25.9091 6.95455L25.9091 14.3182C25.9091 15.5455 26.7273 16.3636 27.9545 16.3636C29.1818 16.3636 30 15.5455 30 14.3182L30 2.04545C30 0.818182 29.1818 -3.57639e-08 27.9545 -8.94097e-08L15.6818 -6.25868e-07C14.4545 -6.79513e-07 13.6364 0.818181 13.6364 2.04545C13.6364 3.27273 14.4545 4.09091 15.6818 4.09091L23.0455 4.09091L4.09091 23.0455L4.09091 15.6818C4.09091 14.4545 3.27273 13.6364 2.04545 13.6364C0.818181 13.6364 -6.31828e-07 14.4545 -6.85474e-07 15.6818L-1.22193e-06 27.9545C-1.27558e-06 29.1818 0.818181 30 2.04545 30L14.3182 30C15.5455 30 16.3636 29.1818 16.3636 27.9545Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Fullscreen);
