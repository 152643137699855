import { ReactNode } from 'react';
import styled from 'styled-components';

interface IStoryItemWrapper {
  children: ReactNode;
}

const Wrapper = styled.div`
  margin: 0 12px 40px;
`;

const StoryItemWrapper = ({ children }: IStoryItemWrapper) => {
  return <Wrapper>{children}</Wrapper>;
};

export default StoryItemWrapper;
