import { memo } from 'react';

const CTR = (): JSX.Element => {
  return (
    <svg
      style={{
        background: '#201A3A',
        borderRadius: '4px',
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5716 12.0001C20.5716 16.734 16.734 20.5716 12.0001 20.5716C7.26627 20.5716 3.42871 16.734 3.42871 12.0001C3.42871 7.26627 7.26627 3.42871 12.0001 3.42871C16.734 3.42871 20.5716 7.26627 20.5716 12.0001ZM17.0001 12.0001C17.0001 14.7616 14.7615 17.0001 12.0001 17.0001C9.23865 17.0001 7.00008 14.7616 7.00008 12.0001C7.00008 9.23872 9.23865 7.00014 12.0001 7.00014C14.7615 7.00014 17.0001 9.23872 17.0001 12.0001ZM12.0001 14.143C13.1836 14.143 14.143 13.1836 14.143 12.0001C14.143 10.8167 13.1836 9.85728 12.0001 9.85728C10.8167 9.85728 9.85728 10.8167 9.85728 12.0001C9.85728 13.1836 10.8167 14.143 12.0001 14.143Z"
        fill="#5735C7"
      />
    </svg>
  );
};
export default memo(CTR);
