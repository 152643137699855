import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ISidebarRowWrapper {
  children: ReactNode;
  alignItems?: string;
}

const StyledSidebarRowWrapper = styled.div<{ alignItems?: string }>`
  display: flex;
  padding: 0 20px 0 24px;
  justify-content: space-between;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  margin: 0 0 16px;
  width: 100%;
  &:last-of-type {
    margin: 0;
  }
`;

const EditorSidebarRowWrapper = ({ children, alignItems }: ISidebarRowWrapper): JSX.Element => {
  return <StyledSidebarRowWrapper alignItems={alignItems}>{children}</StyledSidebarRowWrapper>;
};

export default EditorSidebarRowWrapper;
