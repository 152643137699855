import { memo } from 'react';

interface ICustomizable {
  className?: string;
}

const Customizable = ({ className }: ICustomizable): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0264 10.0982L11.3654 30.3509C10.6467 31.2706 9.58747 31.7879 8.45262 31.8071L2.2109 31.8837C1.87045 31.8837 1.58673 31.6538 1.51108 31.3281L0.0925048 25.1775C-0.153381 24.0471 0.0925048 22.8783 0.811247 21.9777L11.9139 7.60732C12.1031 7.37739 12.4435 7.34099 12.6705 7.51152L17.3423 11.2287C17.645 11.4777 18.0611 11.6119 18.4961 11.5544C19.4229 11.4394 20.0471 10.5964 19.9525 9.69582C19.8958 9.23596 19.6688 8.85275 19.3662 8.56535C19.2716 8.4887 14.8267 4.92485 14.8267 4.92485C14.543 4.69492 14.4863 4.27339 14.7132 3.9879L16.4723 1.70588C18.0989 -0.38262 20.936 -0.574225 23.2247 1.24602L25.8538 3.33452C26.9319 4.17759 27.6506 5.2889 27.8965 6.45769C28.1802 7.74336 27.8776 9.00604 27.0264 10.0982Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Customizable);
