import React from 'react';
import styled from 'styled-components';

const StyledCustomColorsLabel = styled.div<{ type?: string }>`
  text-align: left;
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 19px 0 12px;
`;

interface ICustomColorsLabelProps {
  title?: 'Favorites' | 'Brand' | 'Preset' | 'Brand colors';
}

const CustomColorsLabel: React.FC<ICustomColorsLabelProps> = ({ title }) => {
  return <StyledCustomColorsLabel>{title}</StyledCustomColorsLabel>;
};

CustomColorsLabel.defaultProps = {
  title: 'Favorites',
};

export default CustomColorsLabel;
