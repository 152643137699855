import { memo } from 'react';

interface IAnimate {
  className?: string;
}

const Animate = ({ className }: IAnimate): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0993 19.5626L13.5622 26.5591C13.6277 26.6914 13.7258 26.8064 13.8478 26.8941C13.9699 26.9818 14.1123 27.0397 14.2628 27.0626C14.4133 27.0856 14.5674 27.073 14.7117 27.0259C14.856 26.9788 14.9862 26.8986 15.0911 26.7923L20.6336 21.1665C20.7817 21.0164 20.9789 20.9197 21.1923 20.8927L29.1838 19.8876C29.3347 19.8684 29.4786 19.8143 29.603 19.7298C29.7274 19.6453 29.8285 19.5329 29.8976 19.4025C29.9668 19.272 30.0019 19.1273 29.9999 18.9809C29.998 18.8345 29.959 18.6907 29.8864 18.562L26.0338 11.755C25.9314 11.573 25.8971 11.3626 25.9368 11.1592L27.4124 3.54124C27.4404 3.39705 27.4315 3.24846 27.3863 3.10834C27.3412 2.96822 27.2612 2.84078 27.1534 2.73706C27.0455 2.63333 26.913 2.55645 26.7672 2.51305C26.6215 2.46965 26.467 2.46106 26.317 2.488L18.3944 3.90687C18.1828 3.94499 17.964 3.91206 17.7747 3.81358L10.6955 0.109176C10.5615 0.0393222 10.4119 0.00187516 10.2595 6.86195e-05C10.1071 -0.00173792 9.95657 0.0321506 9.82085 0.0988068C9.68513 0.165463 9.56833 0.262884 9.48053 0.382656C9.39272 0.502427 9.33656 0.64095 9.31689 0.78626L8.2716 8.46892C8.24349 8.67406 8.14295 8.86369 7.9868 9.00607L2.13597 14.3355C2.02542 14.4363 1.94206 14.5615 1.89309 14.7003C1.84411 14.839 1.831 14.9872 1.85487 15.1319C1.87875 15.2766 1.9389 15.4135 2.03013 15.5309C2.12136 15.6483 2.24093 15.7425 2.37851 15.8055L9.65489 19.1353C9.84968 19.2244 10.0066 19.3753 10.0993 19.5626ZM8.85214 22.462L6.6395 20.3344L0 26.7171L2.21421 28.8462L8.85214 22.462Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Animate);
