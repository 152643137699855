import { ReactNode } from 'react';
import styled from 'styled-components';

interface IStoriesWrapper {
  children: ReactNode;
  hiddenOverflow?: boolean;
}
const Wrapper = styled.div<{ innerWidth?: number; hiddenOverflow?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  padding: ${innerWidth < 1460 ? '0 70px' : '0 92px'};
  ${({ hiddenOverflow }) => hiddenOverflow && `overflow: hidden;`}
`;

const StoriesWrapper = ({ children, hiddenOverflow = true }: IStoriesWrapper): JSX.Element => {
  return (
    <Wrapper innerWidth={window.innerWidth} hiddenOverflow={hiddenOverflow}>
      {children}
    </Wrapper>
  );
};

export default StoriesWrapper;
