export enum WorkspaceTypes {
  PAID = 'PAID',
  TRIAL = 'TRIAL',
}

export enum RoleName {
  owner = 'owner',
  admin = 'admin',
  user = 'user',
}

export interface TeamUser {
  _id: string;
  username: string;
  email: string;
  avatar?: string;
}

interface Team {
  _id: string;
  title: string;
  author: string;
  users: string[];
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

interface CustomCDN {
  isEnabled: boolean;
  cdns?: string[];
  mask?: string;
}

export interface PopulatedTeam extends Omit<Team, 'users'> {
  users: TeamUser[];
}

export interface Workspace {
  _id: string;
  logoUrl?: string;
  title: string;
  users: { _id: string }[];
}

export interface WorkspaceDetails extends Workspace {
  type: WorkspaceTypes;
  trialEndsAt: string;
  publisher?: string;
  publication?: string;
  publisherLogoUrl?: string;
  favIconLogo?: string;
  googleAnalytics?: string;
  googleSignInAppId?: string;
  customCDN: CustomCDN;
  extendedAccess: {
    isEnabled: boolean;
    title: string;
    description: string;
    notification: string;
    locale: string;
    buttonText: string;
  };
  owner: string;
  teams: Team[];
  users: {
    role: {
      _id: string;
      name: RoleName;
    };
    _id: string;
  }[];
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  domainSettings: {
    domainList: Domain[];
    activeDomain?: Domain;
  };
  isStoryConversionEnabled: boolean;
  isGoogleAnalyticsEnabled: boolean;
  apiKey?: string;
  sitemapInitialized?: boolean;
  newssitemap?: {
    isInitialized?: boolean;
    publication?: string;
  };
  rssFeed?: {
    [key: string]: any;
    isInitialized?: boolean;
    title?: string;
    description?: string;
    link?: string;
    copyright?: string;
    language?: string;
    managingEditor?: string;
  };
  stylePresets: {
    [key: string]: StylePreset;
    title: StylePreset;
    subHeadline: StylePreset;
    headLine: StylePreset;
    normalText: StylePreset;
    smallText: StylePreset;
  };
  colorPalettes: WorkspaceColorPalette[];
}

export interface GeneralSettingsUpdateRequest {
  title?: string;
  isGoogleAnalyticsEnabled?: boolean;
  logo?: string;
}

export interface SeoSettingsUpdateRequest {
  favicon?: string;
  publisher?: string;
  publication?: string;
  publisherLogo?: string;
}

export interface DeleteSeoImageRequest {
  logo?: string;
  publisherLogo?: string;
  favicon?: string;
}

export interface CreateCustomDomainRequest {
  workspaceId: string;
  domainName: string;
}

export interface DeleteCustomDomainRequest {
  workspaceId: string;
  domainId: string;
}

export interface Domain {
  _id: string;
  author: string;
  workspaces: string[];
  groupName: string;
  domainName: string;
}

export interface AddAnalyticsTrackingAccountRequest {
  workspaceId: string;
  googleAnalytics: {
    trackingAccountId: string;
  };
}

enum SnippetStatus {
  Live = 'Live',
  Pending = 'Pending',
  Rejected = 'Rejected',
}
export interface Snippet {
  _id: string;
  author: string;
  workspace: string;
  name: string;
  headCode: string;
  bodyCode: string;
  status: SnippetStatus;
  rejectMessage: string;
}

export interface SaveCustomSnippetRequest {
  name: string;
  headCode: string;
  bodyCode: string;
}

export interface DeleteCustomSnippetRequest {
  workspaceId: string;
  snippetId: string;
}

export interface GetWorkspaceMembersRequest {
  offset?: number;
  limit?: number;
  sortOptions?: string;
  workspaceId: string;
}

enum WorkspaceUserStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
}

export const memberSortOrder = [WorkspaceUserStatus.Accepted, WorkspaceUserStatus.Pending];

export interface WorkspaceUser {
  _id: string;
  status: WorkspaceUserStatus;
  avatar?: string;
  username?: string;
  email: string;
  addedAt: string;
  role: {
    name: string;
  };
}

export interface InviteWorkspaceMembersRequest {
  users: (
    | {
        email: string;
        role: string;
      }
    | undefined
  )[];
  workspaceId: string;
}

export interface UpdateWorkspaceMemberRole {
  role: string;
  userId: string;
  workspaceId: string;
}

export interface RemoveWorkspaceMemberRequest {
  id: string;
  workspaceId: string;
}

export interface InitializeSitemapRequest {
  type: 'sitemap' | 'newssitemap' | 'rssfeed';
}

export interface UpdateRssMetaRequest {
  title?: string;
  description?: string;
  link?: string;
  copyright?: string;
  language?: string;
  managingEditor?: string;
}
export interface StylePreset {
  title: string;
  fontFamily: string;
  size: number;
  weight: number;
  style?: string[];
}

export interface UpdateWorkspaceFontStyles {
  workspaceId: string;
  title?: StylePreset;
  subHeadline?: StylePreset;
  headLine?: StylePreset;
  normalText?: StylePreset;
  smallText?: StylePreset;
}

export interface WorkspaceColorPalette {
  _id: string;
  name: string;
  colors: {
    _id: string;
    color: string;
  }[];
}

export interface CreateWorkspaceColorPalette {
  name: string;
  workspaceId: string;
}

export interface UpdateWorkspaceColorPalette {
  paletteId: string;
  workspaceId: string;
  name?: string | number;
  addColors?: {
    color: string;
  }[];
  removeColors?: {
    _id: string;
  }[];
}

export interface DeleteWorkspaceColorPalette {
  paletteId: string;
  workspaceId: string;
}
