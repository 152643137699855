import { ReactNode } from 'react';
import Styled from './page-title-styled';

interface IPageTitleWrapper {
  children: ReactNode;
}

const PageTitleWrapper = ({ children }: IPageTitleWrapper): JSX.Element => {
  return <Styled.TitleWrapper>{children}</Styled.TitleWrapper>;
};

export default PageTitleWrapper;
