import React from 'react';

const RoundQuestion = (): JSX.Element => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55599 0.222168C3.97266 0.222168 0.222656 3.97217 0.222656 8.5555C0.222656 13.1388 3.97266 16.8888 8.55599 16.8888C13.1393 16.8888 16.8893 13.1388 16.8893 8.5555C16.8893 3.97217 13.1393 0.222168 8.55599 0.222168ZM7.76931 9.24886C7.71506 9.48034 7.68613 9.76427 7.68251 10.1006H8.92491C8.92853 9.82576 8.9647 9.58162 9.03342 9.36822C9.10576 9.15482 9.24139 8.9577 9.44032 8.77686C9.67542 8.55622 9.89424 8.33559 10.0968 8.11496C10.303 7.89072 10.4693 7.652 10.5959 7.39882C10.7225 7.14202 10.7858 6.85447 10.7858 6.53619C10.7858 5.87068 10.5815 5.35527 10.1727 4.98996C9.76403 4.62104 9.19437 4.43658 8.46376 4.43658C8.0225 4.43658 7.62283 4.51615 7.26476 4.67529C6.9103 4.83082 6.62637 5.06411 6.41298 5.37516C6.2032 5.68622 6.0965 6.07503 6.09288 6.54161H7.43837C7.44199 6.19439 7.54868 5.94302 7.75846 5.78749C7.96824 5.62835 8.20334 5.54877 8.46376 5.54877C8.78205 5.54877 9.02438 5.6392 9.19075 5.82004C9.35713 6.00089 9.44032 6.25769 9.44032 6.59044C9.44032 6.89426 9.35352 7.15106 9.1799 7.36084C9.00991 7.567 8.80013 7.78763 8.55056 8.02273C8.33355 8.2289 8.16175 8.42602 8.03516 8.61409C7.91218 8.80217 7.82357 9.01376 7.76931 9.24886ZM7.77474 11.2671C7.64091 11.4081 7.574 11.58 7.574 11.7825C7.574 11.9887 7.64091 12.1605 7.77474 12.2979C7.91218 12.4353 8.10026 12.5041 8.33898 12.5041C8.57769 12.5041 8.76396 12.4353 8.89779 12.2979C9.03523 12.1605 9.10395 11.9887 9.10395 11.7825C9.10395 11.58 9.03523 11.4081 8.89779 11.2671C8.76396 11.126 8.57769 11.0555 8.33898 11.0555C8.10026 11.0555 7.91218 11.126 7.77474 11.2671Z"
        fill="#ABABBA"
      />
    </svg>
  );
};

export default RoundQuestion;
