import { ReactNode } from 'react';
// Styles
import styled from 'styled-components';

interface IBottomBarButtonWrapper {
  children: ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}

const ButtonWrapper = styled.div`
  position: relative;
  pointer-events: auto;
  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const BottomBarButtonWrapper = ({ children, onClick }: IBottomBarButtonWrapper): JSX.Element => {
  return <ButtonWrapper onMouseDown={onClick}>{children}</ButtonWrapper>;
};

export default BottomBarButtonWrapper;
