import styled from 'styled-components';

const OuterWrapper = styled.div`
  padding: 50px 0 24px;
`;

const SmartphoneWrapper = styled.div`
  background: var(--shade-900-20);
  border-radius: 76px;
  overflow: hidden;
  max-width: 725px;
  width: 100%;
`;

const SmartphoneHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 52px 35px 16px 44px;
  background: var(--shade-300-85);
  border-radius: 76px 76px 0 0;
`;

const HamburgerWrapper = styled.div`
  width: 62px;
`;

const HamburgerLine = styled.div`
  background: var(--white-10);
  height: 8px;
  border-radius: 2px;
  &:not(:last-of-type) {
    margin-bottom: 7px;
  }
`;

const HeaderUser = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: var(--white-10);
`;

const FirstSection = styled.div`
  background: var(--shade-300-85);
  padding: 18px 38px;
`;

const FirstSectionTitle = styled.div`
  background: var(--white-10);
  border-radius: 6px;
  margin: 0 auto 18px;
  max-width: 48%;
  height: 18px;
`;

const FirstSectionText = styled.div`
  display: inline-block;
  width: calc(33% - 12px);
  background: var(--white-10);
  height: 11px;
  border-radius: 6px;
  margin: 0 6px;
  &:last-of-type {
    margin: 0;
  }
`;

const SecondSection = styled.div`
  padding: 44px 28px 135px;
`;

const SecondSectionItem = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 28px;
`;

const SecondSectionItemImage = styled.div`
  height: 145px;
  background: var(--white-10);
  border-radius: 7px;
  margin: 0 0 24px;
`;

const SecondSectionItemText = styled.div`
  background: var(--white-10);
  height: 11px;
  border-radius: 6px;
  margin: 0 0 6px;
  &:last-of-type {
    margin: 0;
    width: 55%;
  }
`;

const SmartphoneOuterWrapper = styled.div`
  padding: 34px 0 24px;
`;

const SmartphoneSmallWrapper = styled.div`
  background: var(--shade-900-20);
  border-radius: 40px;
  overflow: hidden;
  max-width: 725px;
  width: 100%;
`;

const SmartphoneSmallHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px 8px;
  background: var(--shade-300-85);
  border-radius: 40px 40px 0 0;
`;

const HamburgerSmallWrapper = styled.div`
  width: 30px;
`;

const HamburgerSmallLine = styled.div`
  background: var(--white-10);
  height: 4px;
  border-radius: 2px;
  &:not(:last-of-type) {
    margin-bottom: 3px;
  }
`;

const HeaderSmallUser = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--white-10);
`;

const FirstSmallSection = styled.div`
  background: var(--shade-300-85);
  padding: 9px 22px;
`;

const FirstSmallSectionTitle = styled.div`
  background: var(--white-10);
  border-radius: 6px;
  margin: 0 auto 9px;
  max-width: 48%;
  height: 9px;
`;

const FirstSmallSectionText = styled.div`
  display: inline-block;
  width: calc(35% - 6px);
  background: var(--white-10);
  height: 5px;
  border-radius: 6px;
  margin: 0 3px;
  &:nth-of-type(2) {
    width: calc(30% - 6px);
  }
  &:last-of-type {
    margin: 0;
  }
`;

const SecondSmallSection = styled.div`
  padding: 22px 14px 26px;
`;

const SecondSmallSectionItem = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 14px;
`;

const SecondSmallSectionItemImage = styled.div`
  height: 70px;
  background: var(--white-10);
  border-radius: 7px;
  margin: 0 0 11px;
`;

const SecondSmallSectionItemText = styled.div`
  background: var(--white-10);
  height: 5px;
  border-radius: 7px;
  margin: 0 0 5px;
  &:last-of-type {
    margin: 0;
    width: 55%;
  }
`;

const PCWrapper = styled.div`
  border-radius: 7px;
  overflow: hidden;
  background: var(--shade-900-20);
`;

const PCBrowserBar = styled.div`
  display: flex;
  align-items: center;
  background: var(--shade-100);
  border-radius: 7px 7px 0 0;
  padding: 16px 17px;
  position: relative;
`;

const PCBrowserButton = styled.div`
  display: inline-block;
  background: var(--shade-900-20);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  &:nth-of-type(3) {
    margin-right: 30px;
  }
`;

const PCBrowserSearchbar = styled.div`
  height: 34px;
  border-radius: 7px;
  background: var(--shade-900-20);
  width: 70%;
  margin: auto;
`;

const PCBrowserEmptySpace = styled.div`
  width: 95px;
`;

const PCHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--shade-300-85);
  padding: 16px 22px;
`;

const PCHeaderColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

const PCHeaderText = styled.div`
  width: 80px;
  height: 15px;
  border-radius: 7px;
  background: var(--white-10);
  &:nth-of-type(2) {
    margin: 0 25px;
  }
`;

const PCUser = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--white-10);
`;

const PCUserName = styled.div`
  height: 30px;
  width: 130px;
  background: var(--white-10);
  margin-right: 24px;
  border-radius: 7px;
`;

const ExternalWidgetWrapper = styled.div`
  padding: 30px 0 20px;
  width: 100%;
`;

export default {
  ExternalWidgetWrapper,
  SmartphoneWrapper,
  SmartphoneSmallWrapper,
  SmartphoneHeader,
  SmartphoneSmallHeader,
  HamburgerWrapper,
  HamburgerSmallWrapper,
  HamburgerLine,
  HamburgerSmallLine,
  HeaderUser,
  HeaderSmallUser,
  FirstSection,
  FirstSmallSection,
  FirstSectionTitle,
  FirstSmallSectionTitle,
  FirstSectionText,
  FirstSmallSectionText,
  SecondSection,
  SecondSmallSection,
  SecondSectionItem,
  SecondSmallSectionItem,
  SecondSectionItemImage,
  SecondSmallSectionItemImage,
  SecondSectionItemText,
  SecondSmallSectionItemText,
  SmartphoneOuterWrapper,
  OuterWrapper,
  PCWrapper,
  PCBrowserBar,
  PCBrowserButton,
  PCBrowserSearchbar,
  PCBrowserEmptySpace,
  PCHeader,
  PCHeaderColumn,
  PCHeaderText,
  PCUser,
  PCUserName,
};
