import { useSelector } from 'react-redux';
import styled from 'styled-components';

import bottomSocialOverlay from '../../assets/images/social-overlay/bottom-social-overlay.svg';
import topSocialOverlay from '../../assets/images/social-overlay/top-social-overlay.svg';
import { RootState } from '../../redux/rootReducer';

const SafeAreaWrapper = styled.div<{ isVisible: boolean }>`
  display: ${(isVisible) => (isVisible ? 'block' : 'none')};
`;

const TopStoryMargin = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  z-index: 11;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
`;

const BottomStoryMargin = styled.div`
  position: absolute;
  top: auto;
  bottom: 0;
  padding: 0 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  z-index: 11;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
`;

const StoryImageWrapper = styled.div`
  width: 100%;
`;

const StoryImage = styled.img`
  width: 100%;
  box-shadow: none;
`;

const SafeArea: React.FC = () => {
  const isActive = useSelector((state: RootState) => state.safeArea.isActive);
  const showFullScreenIEditor = useSelector((state: RootState) => state.helpers.showFullScreenIEditor);
  const slides = useSelector((state: RootState) => state.ampStory.present?.cuts);

  return (
    <SafeAreaWrapper isVisible={showFullScreenIEditor}>
      {slides?.length !== 0 && isActive && (
        <>
          <TopStoryMargin>
            <StoryImageWrapper>
              <StoryImage src={topSocialOverlay} alt="oval" />
            </StoryImageWrapper>
          </TopStoryMargin>

          <BottomStoryMargin>
            <StoryImageWrapper>
              <StoryImage src={bottomSocialOverlay} alt="oval" />
            </StoryImageWrapper>
          </BottomStoryMargin>
        </>
      )}
    </SafeAreaWrapper>
  );
};

export default SafeArea;
