import HamburgerSm from '../../icons/hamburger-sm';
import styled, { css } from 'styled-components';
import { Suspense, lazy, memo, useCallback, useContext } from 'react';
import { WidgetActiveSlideContext, WidgetActiveSlideContextType } from 'context/widget/active-slide';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';
import LockIcon from 'components/icons/lock';
import UnlockIcon from 'components/icons/unlock';
import { IWidgetStory } from 'redux/services/widgets/interface';
import type { DropResult } from 'react-beautiful-dnd';

const DragDropContext = lazy(() =>
  import('react-beautiful-dnd').then((module) => ({ default: module.DragDropContext })),
);
const Draggable = lazy(() => import('react-beautiful-dnd').then((module) => ({ default: module.Draggable })));
const Droppable = lazy(() => import('react-beautiful-dnd').then((module) => ({ default: module.Droppable })));

const LayersMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 238px;
  position: absolute;
  bottom: calc(100% + 8px);
  background: var(--shade-900-85);
  border: 1px solid var(--shade-700-85);
  box-shadow: 24px 32px 72px var(--black-18);
  border-radius: 6px;
  z-index: 112;
  max-height: calc(
    var(--editor-height) - (var(--editor-wrapper-padding) * 2) - var(--layer-button-height) -
      var(--toolbar-layers-menu-offset) - 74px
  );
`;

const LayersWrapper = styled.div`
  padding: 0 12px 7px;
  margin-top: 16px;
  flex: 1;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    transition: background 0.12s ease;
  }
  &:hover {
    scrollbar-width: thin;
    scrollbar-color: var(--shade-300-85) transparent;
  }
  &:hover::-webkit-scrollbar {
    width: 3px;
    border-radius: 2px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: var(--shade-300-85);
  }
`;

const LayerItemImageWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  flex: 0 0 18px;
  & svg {
    max-width: 100%;
    color: var(--shade-100);
  }
`;

const LayerItemButtonWrapper = styled(LayerItemImageWrapper)<{ isActive?: boolean }>`
  ${({ isActive }) =>
    !isActive &&
    css`
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.12s ease;
    `}
`;

const LayerItemName = styled.div<{ isActive?: boolean }>`
  flex: 1;
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  text-transform: capitalize;
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--shade-900);
    `}
`;

const LayerItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 3px 10px;
  border-radius: 6px;
  margin-bottom: 4px;
  cursor: pointer !important;
  transition: background 0.12s ease;
  &:hover {
    background: var(--primary);
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background: var(--primary);
      & ${LayerItemImageWrapper} svg {
        color: var(--shade-900);
      }
      & ${LayerItemButtonWrapper} {
        pointer-events: auto;
        visibility: visible;
        opacity: 1;
      }

      & ${LayerItemName} {
        color: var(--shade-900);
      }
    `}

  &:hover ${LayerItemImageWrapper} svg {
    color: var(--shade-900);
  }
  &:hover ${LayerItemName} {
    color: var(--shade-900);
  }
  &:hover ${LayerItemButtonWrapper} {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
  }
  &:focus {
    cursor: grab;
  }
`;

const WidgetEditorLayersMenu = (): JSX.Element => {
  const { widget, setWidget } = useContext(WidgetContext) as WidgetContextType;
  const { widgetActiveSlide, setWidgetActiveSlide } = useContext(
    WidgetActiveSlideContext,
  ) as WidgetActiveSlideContextType;
  const { setChangesCount } = useContext(ChangesContext) as ChangesContextType;

  const widgetStories = widget.editorDetails.stories;

  const onSelectLayer = (position: number) => () => {
    setWidgetActiveSlide(position);
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const widgetStoriesCopy = [...widgetStories];

      const [reorderedStory] = widgetStoriesCopy.splice(sourceIndex, 1);
      widgetStoriesCopy.splice(destinationIndex || 0, 0, reorderedStory);

      setWidgetActiveSlide(destinationIndex);
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          stories: widgetStoriesCopy.map((s, index) => ({ ...s, index })),
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setChangesCount, setWidget, setWidgetActiveSlide, widgetStories],
  );

  const toggleLock = useCallback(
    (story: IWidgetStory) => () => {
      const widgetStoriesCopy = [...widgetStories].map((s, index) => {
        if (story.url === s.url) {
          return { ...s, isLocked: !story.isLocked, index };
        }

        return { ...s, index };
      });

      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          stories: widgetStoriesCopy,
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setChangesCount, setWidget, widgetStories],
  );

  return (
    <LayersMenuWrapper>
      <LayersWrapper>
        <Suspense fallback={<></>}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" ignoreContainerClipping={false} isCombineEnabled={true}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {widgetStories.map((item, index: number) => (
                    <Draggable key={'item' + index} draggableId={'item' + index} index={index} shouldRespectForcePress>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <LayerItem isActive={widgetActiveSlide === index} key={index} onClick={onSelectLayer(index)}>
                            <LayerItemImageWrapper>
                              <HamburgerSm />
                            </LayerItemImageWrapper>
                            <LayerItemName>{item.title}</LayerItemName>
                            <LayerItemButtonWrapper isActive={item.isLocked} onClick={toggleLock(item)}>
                              {item.isLocked ? <LockIcon /> : <UnlockIcon />}
                            </LayerItemButtonWrapper>
                          </LayerItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Suspense>
      </LayersWrapper>
    </LayersMenuWrapper>
  );
};

export default memo(WidgetEditorLayersMenu);
