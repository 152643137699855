import {
  IAmpStory,
  MovePersonalStoriesRequest,
  MoveTeamStoriesRequest,
  Story,
  StoryListParams,
  StoryListResponse,
} from './interface';
import api from '../api';

export const storiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    analyticsStories: builder.query<Story[], string[]>({
      query: (storiesIds) => ({ url: 'story/analytics/stories', method: 'POST', body: { storiesIds } }),
      transformResponse: ({ stories }) => stories,
    }),
    hasPublishedStories: builder.query<boolean, string>({
      query: () => ({ url: 'story/has-published-stories' }),
      keepUnusedDataFor: 0,
    }),
    storyList: builder.query<StoryListResponse, StoryListParams>({
      query: ({ query, offset, limit, teamId, forcePersonal, folder }) =>
        `story${`?offset=${offset}&limit=${limit}&${query}${teamId ? `&team=${teamId}` : ''}${
          forcePersonal ? `&personal=true` : ''
        }${folder ? `&folder=${folder}` : ''}`}`,
      providesTags: (result: StoryListResponse | undefined) => {
        if (result) {
          const stories = [...result.stories.map(({ _id: id }) => ({ type: 'Stories' as const, id }))];
          return [...stories, { type: 'Stories', id: 'LIST' }];
        }

        return [{ type: 'Stories', id: 'LIST' }];
      },
      keepUnusedDataFor: 86400,
    }),
    movePersonalStories: builder.mutation<boolean, MovePersonalStoriesRequest>({
      query: (body) => ({
        url: 'story/move',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { storiesIds, folderId }) => {
        const invalidationArray = [
          { type: 'Stories' as const, id: 'LIST' },
          { type: 'Folder' as const, id: 'LIST' },
          ...storiesIds.map((id) => ({ type: 'Stories' as const, id })),
        ];

        if (folderId) {
          invalidationArray.push({ type: 'Folder' as const, id: folderId });
        }

        return invalidationArray;
      },
    }),
    moveTeamStories: builder.mutation<boolean, MoveTeamStoriesRequest>({
      query: (body) => ({
        url: 'story/team/move',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { storiesIds, folderId }) => {
        const invalidationArray = [
          { type: 'Stories' as const, id: 'LIST' },
          { type: 'Folder' as const, id: 'LIST' },
          ...storiesIds.map((id) => ({ type: 'Stories' as const, id })),
        ];

        if (folderId) {
          invalidationArray.push({ type: 'Folder' as const, id: folderId });
        }

        return invalidationArray;
      },
    }),
    duplicateStory: builder.mutation<IAmpStory, string>({
      query: (storyId) => ({
        url: 'story/duplicate',
        method: 'POST',
        body: { storyId },
      }),
      invalidatesTags: [{ type: 'Stories', id: 'LIST' }],
    }),
    deleteStory: builder.mutation<boolean, string>({
      query: (storyId) => ({
        url: `story/${storyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Stories', id: 'LIST' }, { type: 'Folder', id: 'LIST' }, { type: 'Widgets' }],
    }),
  }),
});

export const {
  useAnalyticsStoriesQuery,
  useHasPublishedStoriesQuery,
  useStoryListQuery,
  useMovePersonalStoriesMutation,
  useMoveTeamStoriesMutation,
  useDuplicateStoryMutation,
  useDeleteStoryMutation,
} = storiesApi;
