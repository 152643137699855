import { memo, useEffect, useState } from 'react';
import ColorInput from './color-input';

import { IRGBColor } from '../../../../interfaces/colors';

import { hslaToRgba, rgbaToHsla } from '../../../../utils/parseColors';
import isEqual from 'lodash/isEqual';
import ColorInputsWrapper from '../structure/color-inputs-wrapper';

interface IHslaColorInputProps {
  color: IRGBColor;
  handleColorChange: (color: IRGBColor) => void;
}

const HslaColorInput = ({ color, handleColorChange }: IHslaColorInputProps): JSX.Element => {
  const [hslaColor, setHslaColor] = useState(rgbaToHsla(color));
  const [isManuallyChanged, setIsManuallyChanged] = useState(false);

  useEffect(() => {
    !isManuallyChanged && setHslaColor(rgbaToHsla(color));
    setIsManuallyChanged(false);
  }, [color]);

  const isValidInput = (field: string, value: number) =>
    field === 'h' ? value >= 0 && value <= 360 : value >= 0 && value <= 100;

  const handleHslaColorChange = (event: any, field: string) => {
    const { value } = event.target;
    const newValue = Math.round(value);

    const newColor = { ...hslaColor, [field]: newValue };
    if (isValidInput(field, value)) {
      setHslaColor(newColor);
      setIsManuallyChanged(true);
      handleColorChange(hslaToRgba(newColor));
    }
  };

  return (
    <ColorInputsWrapper>
      <ColorInput type={'number'} value={hslaColor.h} onChange={(e: any) => handleHslaColorChange(e, 'h')} />
      <ColorInput type={'number'} value={hslaColor.s} onChange={(e: any) => handleHslaColorChange(e, 's')} />
      <ColorInput type={'number'} value={hslaColor.l} onChange={(e: any) => handleHslaColorChange(e, 'l')} />
    </ColorInputsWrapper>
  );
};

export default memo(HslaColorInput, (props: IHslaColorInputProps, nextProps: IHslaColorInputProps): boolean => {
  return isEqual(props.color, nextProps.color);
});
