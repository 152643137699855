import { memo } from 'react';

const DeleteSM = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5247 7.43901C17.7841 7.43901 18 7.65703 18 7.93445V8.19095C18 8.46162 17.7841 8.6864 17.5247 8.6864H6.4759C6.21591 8.6864 6 8.46162 6 8.19095V7.93445C6 7.65703 6.21591 7.43901 6.4759 7.43901H8.41972C8.81457 7.43901 9.15821 7.15483 9.24704 6.75389L9.34883 6.29354C9.50704 5.66647 10.0277 5.25 10.6235 5.25H13.3765C13.9659 5.25 14.4924 5.66647 14.6447 6.26047L14.7536 6.75321C14.8418 7.15483 15.1854 7.43901 15.581 7.43901H17.5248H17.5247ZM16.5372 16.8154C16.7401 14.9005 17.0955 10.351 17.0955 10.3052C17.1084 10.1661 17.0637 10.0345 16.9749 9.9285C16.8795 9.82927 16.7589 9.77055 16.6261 9.77055H7.37897C7.24541 9.77055 7.11833 9.82927 7.03015 9.9285C6.94068 10.0345 6.89658 10.1661 6.90307 10.3052C6.90426 10.3136 6.91701 10.4738 6.93832 10.7417C7.03301 11.9319 7.29674 15.2469 7.46715 16.8154C7.58774 17.971 8.33661 18.6974 9.42134 18.7237C10.2584 18.7432 11.1207 18.75 12.0025 18.75C12.833 18.75 13.6766 18.7432 14.5396 18.7237C15.6619 18.7041 16.4101 17.9906 16.5372 16.8154H16.5372Z"
      fill="#ABABBA"
    />
  </svg>
);

export default memo(DeleteSM);
