import { memo } from 'react';

const ArrowFromTop = (): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.525 14.7789L12.525 11.8316C12.825 11.5368 12.825 11.0947 12.525 10.8C12.225 10.5053 11.775 10.5053 11.475 10.8L9.75 12.4947L9.75 7.42105C9.75 6.97894 9.45 6.68421 9 6.68421C8.55 6.68421 8.25 6.97894 8.25 7.42105L8.25 12.4947L6.525 10.8C6.375 10.6526 6.225 10.5789 6 10.5789C5.55 10.5789 5.25 10.8737 5.25 11.3158C5.25 11.5368 5.325 11.6842 5.475 11.8316L8.475 14.7789C8.775 15.0737 9.225 15.0737 9.525 14.7789ZM13.5 3.73684C13.5 3.29474 13.2 3 12.75 3L5.25 3C4.8 3 4.5 3.29474 4.5 3.73684C4.5 4.17895 4.8 4.47368 5.25 4.47368L12.75 4.47368C13.2 4.47368 13.5 4.17895 13.5 3.73684Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ArrowFromTop);
