import DashboardLayout from '../components/layouts';
import StoriesHome from '../components/stories/stories-home';
import TutorialDashboard from '../components/tutorial-modal/tutorial-dashboard/tutorial-dashboard';
import { useAppSelector } from '../hooks';
import { useEffect } from 'react';
import { useSelectTeamMutation } from 'redux/services/auth/auth';

const MyStoriesPage = (): JSX.Element => {
  const teamId = useAppSelector((state) => state.auth.user?.selectedTeamId);
  const tutorialDashboardPassed = useAppSelector((state) => state.auth.user?.tutorialDashboardPassed);

  const [selectTeam, { isUninitialized, isLoading, isError, isSuccess }] = useSelectTeamMutation();

  useEffect(() => {
    if (teamId) {
      selectTeam(null);
    }
  }, [selectTeam, teamId]);

  const canRenderStories = (!teamId && isUninitialized) || (!isLoading && !isUninitialized && !isError && isSuccess);

  return (
    <DashboardLayout withHeaderSearchStory>
      {canRenderStories && <StoriesHome />}
      {!tutorialDashboardPassed && <TutorialDashboard />}
    </DashboardLayout>
  );
};

export default MyStoriesPage;
