import { ReactNode } from 'react';
import styled from 'styled-components';

interface ISliderArrowButtonsWrapper {
  isSticky?: boolean;
  children: ReactNode;
}

const Wrapper = styled.div<{ isSticky?: boolean }>`
  margin-left: auto;
  justify-self: flex-end;
  ${({ isSticky }) => isSticky && `display: none`}
`;

const SliderArrowButtonsWrapper = ({ isSticky, children }: ISliderArrowButtonsWrapper) => {
  return <Wrapper isSticky={isSticky}>{children}</Wrapper>;
};

export default SliderArrowButtonsWrapper;
