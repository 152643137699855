import styled from 'styled-components';
import TagsDropdown from './tags-dropdown';
import { clearSelectedTags, setSelectedTags } from 'redux/features/tags/tagsSlice';
import { createSelector } from 'reselect';
import { memo, useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tag } from 'redux/services/tags/interface';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTagsListQuery } from 'redux/services/tags/tags';

interface IProps {
  test?: string;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const Tags: React.VFC<IProps> = () => {
  const dispatch = useAppDispatch();
  const [isFirstRequestDone, setFirstRequestDone] = useState(false);
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const storyTags = useAppSelector((state) => state.ampStory.present?.tags);

  const selectAndMapTags = useMemo(() => {
    const empty: {
      value: string;
      label: string;
      id: string;
    }[] = [];

    return createSelector(
      (data: Tag[] | undefined) => data,
      (data) => data?.map((tag) => ({ value: tag.name, label: tag.name, id: tag._id })) ?? empty,
    );
  }, []);

  const { tags, isLoading } = useTagsListQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data, isLoading }) => ({
      tags: selectAndMapTags(data),
      isLoading,
    }),
  });

  useEffect(() => {
    if (!isLoading && tags?.length && !isFirstRequestDone) {
      const selectedTags = storyTags?.map((storyTag: string) => tags?.find((o) => o?.id === storyTag));
      dispatch(setSelectedTags(selectedTags));
      setFirstRequestDone(true);
    }
  }, [dispatch, isFirstRequestDone, isLoading, storyTags, tags]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedTags());
    };
  }, [dispatch]);

  return (
    <Container>
      <TagsDropdown options={tags ?? []} workspaceId={selectedWorkspaceId ?? ''} isLoading={isLoading} />
    </Container>
  );
};

export default memo(Tags);
