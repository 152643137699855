import Editor from '../components/editor';
import EditorHeader from '../components/editor-header';
import styled from 'styled-components';
import TutorialEditor from '../components/tutorial-modal/tutorial-editor/tutorial-editor';
import { batch, useDispatch, useSelector } from 'react-redux';
import { DEFAULT_EDITOR_FRAME_SIZE } from '../config/constants';
import { getFitEditorHeight } from '../utils/editorUtils';
import { pushCrisp } from '../components/crisp/utils/crispUtils';
import { RootState } from '../redux/rootReducer';
import { useAppSelector } from '../hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import {
  setFitZoomPercent,
  setFrameSizeAndZoomPercent,
  stopAnimation,
} from '../redux/features/editor/helpers/helpersSlice';

const ResizeContainer = styled.div``;

const EditorPage: React.FC = () => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const areAnimationsRunning = useSelector((state: RootState) => state.helpers.areAnimationsRunning);
  const tutorialStoryEditorPassed = useAppSelector((state) => state.auth?.user?.tutorialStoryEditorPassed);

  const updateEditorDimensions = useCallback(() => {
    const fitPercent = Number((getFitEditorHeight() / DEFAULT_EDITOR_FRAME_SIZE.HEIGHT).toFixed(2)) * 100;
    batch(() => {
      dispatch(setFrameSizeAndZoomPercent(fitPercent));
      dispatch(setFitZoomPercent(fitPercent));
    });
  }, [dispatch]);

  const handlePageContainerClick = () => areAnimationsRunning && dispatch(stopAnimation());

  useResizeDetector({
    onResize: updateEditorDimensions,
    targetRef: containerRef,
    handleWidth: false,
    skipOnMount: true,
  });

  useEffect(() => {
    pushCrisp('do', 'chat:hide');

    return () => {
      pushCrisp('do', 'chat:show');
    };
  }, []);

  // There is a race condition when calling useResizeDetector first time and results in bad set state.
  // To avoid such behaior skip resize on mount and call the function handle once the page is fully mounted.
  useEffect(() => {
    updateEditorDimensions();
  }, [updateEditorDimensions]);

  return (
    <ResizeContainer ref={containerRef} onClick={handlePageContainerClick}>
      <EditorHeader />
      <Editor />

      {!tutorialStoryEditorPassed && <TutorialEditor />}
    </ResizeContainer>
  );
};

export default EditorPage;
