import { useCallback, useEffect, useRef, useState } from 'react';

interface IuseResizeModalBodyHeight {
  header?: HTMLDivElement | null;
  modalWrapper?: HTMLDivElement | null;
  footer?: HTMLDivElement | null;
}

const useResizeModalBodyHeight = ({
  header,
  modalWrapper,
  footer,
}: IuseResizeModalBodyHeight): { bodyHeight: string } => {
  const [bodyHeight, setBodyHeight] = useState('100% ');
  const oldModalWrapperPaddingTop = useRef(
    modalWrapper ? window.getComputedStyle(modalWrapper, null).getPropertyValue('padding-top') : -1,
  );

  const calculateHeight = useCallback(() => {
    if (header && modalWrapper && footer) {
      const modalWrapperStyles = window.getComputedStyle(modalWrapper, null);
      const modalWrapperPaddingTop = modalWrapperStyles.getPropertyValue('padding-top');
      const modalWrapperPaddingBottom = modalWrapperStyles.getPropertyValue('padding-bottom');

      const headerHeight = header?.offsetHeight;
      const footerHeight = footer?.offsetHeight;

      setBodyHeight(
        `calc(100vh - ${footerHeight}px - ${headerHeight}px - ${modalWrapperPaddingTop} - ${modalWrapperPaddingBottom})`,
      );
      oldModalWrapperPaddingTop.current = modalWrapperPaddingTop;
    }
  }, [footer, header, modalWrapper]);

  useEffect(() => {
    calculateHeight();
  }, [calculateHeight]);

  return { bodyHeight };
};

export default useResizeModalBodyHeight;
