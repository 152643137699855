import { memo } from 'react';

interface ISocial {
  className?: string;
}

const Social = ({ className }: ISocial): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45354 6C5.45354 3.17157 5.45354 1.75736 6.33222 0.87868C7.2109 0 8.62511 0 11.4535 0H18.5448C21.3732 0 22.7874 0 23.6661 0.87868C24.5448 1.75736 24.5448 3.17157 24.5448 6V24.0005C24.5448 26.8289 24.5448 28.2431 23.6661 29.1218C22.7874 30.0005 21.3732 30.0005 18.5448 30.0005H11.4535C8.62511 30.0005 7.2109 30.0005 6.33222 29.1218C5.45354 28.2431 5.45354 26.8289 5.45354 24.0005V6ZM0 6.81829C0 6.06517 0.610531 5.45464 1.36366 5.45464C2.11679 5.45464 2.72732 6.06517 2.72732 6.8183V23.1822C2.72732 23.9353 2.11679 24.5459 1.36366 24.5459C0.610531 24.5459 0 23.9353 0 23.1822V6.81829ZM28.6364 5.45464C27.8833 5.45464 27.2728 6.06517 27.2728 6.81829V23.1822C27.2728 23.9353 27.8833 24.5459 28.6364 24.5459C29.3896 24.5459 30.0001 23.9353 30.0001 23.1822V6.8183C30.0001 6.06517 29.3896 5.45464 28.6364 5.45464Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Social);
