import EditorSidebarSectionTitle from '../../shared/elements/editor-sidebar-section-title';
import EditorSidebarSectionTitleWrapper from '../../shared/structure/editor-sidebar-section-title-wrapper';
import EditorSidebarSectionWrapper from '../../shared/structure/editor-sidebar-section-wrapper';
import React, { memo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import ToggleSwitch from '../../../shared/toggle-switch';
import { IFieldValue } from 'interfaces/field-value';
import { ILayer } from 'interfaces/layer';
import { useDidUpdateEffect } from 'hooks';

const StyledEditorSidebarSectionTitleWrapper = styled(EditorSidebarSectionTitleWrapper)<{ isSectionHidden: boolean }>`
  ${({ isSectionHidden }) =>
    isSectionHidden &&
    css`
      margin: 0 0 8px;
    `}
`;

interface IProps {
  layer: ILayer;
  handleBatchLayerChange: ([{ field, value }]: IFieldValue[]) => void;
}

const EditorSidebarAuthorCreditSettings: React.FC<IProps> = ({ layer, handleBatchLayerChange }) => {
  const [isAuthorCreditsLayer, setAuthorCreditsLayer] = useState(
    layer?.settings?.layerSettings.isAuthorCreditsLayer ?? false,
  );

  const toggle = useCallback(() => {
    setAuthorCreditsLayer((prev) => !prev);
  }, []);

  useDidUpdateEffect(() => {
    const changes = [{ field: 'settings.layerSettings.isAuthorCreditsLayer', value: isAuthorCreditsLayer }];

    handleBatchLayerChange(changes);
  }, [isAuthorCreditsLayer]);

  return (
    <EditorSidebarSectionWrapper>
      <StyledEditorSidebarSectionTitleWrapper isSectionHidden={false}>
        <EditorSidebarSectionTitle text={'Author credit'} />
        <ToggleSwitch isOn={isAuthorCreditsLayer} onClick={toggle} />
      </StyledEditorSidebarSectionTitleWrapper>
    </EditorSidebarSectionWrapper>
  );
};

export default memo(EditorSidebarAuthorCreditSettings);
