import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Folder } from '../../icons';

const MenuOuterWrapper = styled.div<{ isMenuVisible?: boolean }>`
  position: absolute;
  top: 18px;
  right: 18px;
  visibility: visible;
  opacity: ${({ isMenuVisible }) => (isMenuVisible ? '1' : '0')};
  transition: 0.12s ease;
  z-index: 1;
  cursor: pointer;
  line-height: 0;
`;

const FolderBorderWrapper = styled.div<{ isDraggingOver: boolean }>`
  &&& a {
    border: 1px solid var(--shade-700-85);
    border-color: ${(props) => (props.isDraggingOver ? `var(--primary)` : `var(--shade-700-85)`)};
  }
`;

const FolderWrapper = styled(Link)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: var(--shade-700-85);
  border-radius: 12px;
  padding: 16px 20px 22px 20px;
  text-decoration: none;
  position: relative;
  width: 298px;
  height: 194px;
  &:hover {
    text-decoration: none;
    ${MenuOuterWrapper} {
      opacity: 1;
    }
  }
`;

const FolderWrapperDiv = styled.div`
  display: flex;
  background: var(--shade-700-85);
  border-radius: 12px;
  padding: 0px 32px 0px 1px;
  align-items: center;
  text-decoration: none;
  position: relative;
  &:hover {
    text-decoration: none;
    ${MenuOuterWrapper} {
      opacity: 1;
    }
  }
`;

const FolderName = styled.h4`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--white);
  margin: 0 0 4px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 10px);
`;

const FolderDate = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0;
`;

const FolderContentWrapper = styled.div`
  width: calc(100% - 27px);
  margin-bottom: 18px;
`;

const FolderIcon = styled(Folder)`
  width: 27px;
  height: auto;
  flex: 0 0 27px;
`;

const MenuWrapper = styled.div`
  position: relative;
`;

const MenuTrigger = styled.button`
  display: inline-block;
  padding: 5px 2px;
  background: transparent;
  border: none;
  border-radius: 4px;
  outline: none;
  color: var(--white);
  line-height: 0;
  cursor: pointer;
  transition: 0.12s ease;
  &:hover {
    background: var(--white);
    color: var(--shade-700-85);
  }
`;

const DropdownWrapper = styled.div<{ isOverFlowing?: boolean; isMenuVisible?: boolean }>`
  visibility: ${({ isMenuVisible }) => (isMenuVisible ? `visible` : 'hidden')};
  pointer-events: ${({ isMenuVisible }) => (isMenuVisible ? `auto` : 'none')};
  background: var(--shade-700);
  border: 1px solid var(--white-10);
  box-shadow: 24px 32px 72px var(--black-18);
  border-radius: 12px;
  padding: 16px 12px;
  position: absolute;
  top: ${({ isOverFlowing }) => (isOverFlowing ? `calc(100% - 130px)` : `calc(100% + 2px)`)};
  left: 0;
  min-width: 110px;
`;

const DropdownItem = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  border-radius: 6px;
  padding: 4px 8px;
  transition: 0.12s ease;
  cursor: pointer;
  &:not(:last-child) {
    margin: 0 0 4px;
  }
  &:hover {
    color: var(--shade-900);
    background: var(--primary);
  }
`;

const EmptyThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ThumbnailsWrapper = styled.div`
  display: flex;
  gap: 7px;
  width: 100%;
  align-items: center;
`;

const FolderThumbnail = styled.img`
  display: flex;
  width: 58px;
  height: 104px;
  border-radius: 8px;
`;

const FolderStoriesCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  font-family: Heebo;
  height: 104px;
  border-radius: 8px;
  color: var(--white);
  background: var(--white-10);
`;

export default {
  DropdownItem,
  DropdownWrapper,
  MenuWrapper,
  MenuTrigger,
  MenuOuterWrapper,
  FolderIcon,
  FolderDate,
  FolderWrapper,
  FolderWrapperDiv,
  FolderName,
  FolderBorderWrapper,
  FolderContentWrapper,
  EmptyThumbnailWrapper,
  FolderThumbnail,
  ThumbnailsWrapper,
  FolderStoriesCounter,
};
