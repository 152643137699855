import { ReactNode, memo } from 'react';
import styled, { css } from 'styled-components';
import { columnType } from '../../../../config/constants';

interface IEditorSidebarColumn {
  flexGrow: '1' | '0';
  type?: columnType.WHOLE | columnType.TWO_THIRDS | columnType.HALF | columnType.ONE_THIRD;
  justifyContent?: 'flex-start' | 'flex-end' | 'center';
  children: ReactNode;
}

const StyledColorPickerColumn = styled.div<{ flexGrow?: string; type?: string; justifyContent?: string }>`
  display: flex;
  flex-basis: ${({ type }) => type};
  flex-grow: ${({ flexGrow }) => flexGrow};
  min-width: ${({ type }) => type};
  align-items: center;
  padding: 0 4px;
  justify-self: ${({ justifyContent }) => justifyContent};
  justify-content: ${({ justifyContent }) => justifyContent};
  ${({ justifyContent }) =>
    justifyContent === 'flex-end' &&
    css`
      margin-right: 0;
      margin-left: auto;
    `}
`;

const ColorPickerColumn = ({ flexGrow, type, justifyContent, children }: IEditorSidebarColumn): JSX.Element => {
  return (
    <StyledColorPickerColumn flexGrow={flexGrow} type={type} justifyContent={justifyContent}>
      {children}
    </StyledColorPickerColumn>
  );
};

ColorPickerColumn.defaultProps = {
  flexGrow: '0',
  justifyContent: 'flex-start',
  type: columnType.WHOLE,
};

export default memo(ColorPickerColumn);
