import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IBoxWrapper {
  children: ReactNode;
}

const Wrapper = styled.div`
  background: var(--shade-900-85);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 12px;
  padding: 21px 25px;
  margin: 0 0 28px;
`;

const BoxWrapper = ({ children }: IBoxWrapper): JSX.Element => {
  return <Wrapper>{children}</Wrapper>;
};

export default BoxWrapper;
