import DashboardLayout from '../components/layouts';
import SettingsWrapper from '../components/settings/shared/settings-wrapper';
import SettingsTabs from '../components/settings/settings-tabs';
import { WorkspaceSettings } from '../components/settings/workspace-settings';

const SettingsWorkspacePage = (): JSX.Element => {
  return (
    <DashboardLayout>
      <SettingsWrapper>
        <SettingsTabs />

        <WorkspaceSettings />
      </SettingsWrapper>
    </DashboardLayout>
  );
};

export default SettingsWorkspacePage;
