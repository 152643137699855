import React from 'react';
import { EDITOR_MODAL_STORIES } from './widget-editor-modal-type-names';
import WidgetEditorModalStorySelector from '../widget-editor-modal-story-selector/widget-editor-modal-story-selector';

interface IModalComponents {
  [key: string]: React.FC;
}
const MODAL_COMPONENTS: IModalComponents = {
  [EDITOR_MODAL_STORIES]: WidgetEditorModalStorySelector,
};

export default MODAL_COMPONENTS;
