import { memo } from 'react';

const Hamburger = (): JSX.Element => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 5H1C0.4 5 0 5.4 0 6C0 6.6 0.4 7 1 7H17C17.6 7 18 6.6 18 6C18 5.4 17.6 5 17 5ZM1 2H17C17.6 2 18 1.6 18 1C18 0.4 17.6 0 17 0H1C0.4 0 0 0.4 0 1C0 1.6 0.4 2 1 2ZM17 10H1C0.4 10 0 10.4 0 11C0 11.6 0.4 12 1 12H17C17.6 12 18 11.6 18 11C18 10.4 17.6 10 17 10Z"
        fill="#ABABBA"
      />
    </svg>
  );
};

export default memo(Hamburger);
