import React, { useMemo, VFC } from 'react';
import styled from 'styled-components';
import Search from '../../components/shared/search';
import { StorySearchWrapper } from '../../components/layouts/shared/story-search/story-search-styled';
import { memo } from 'react';
import { useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { ChevronLeft } from '../icons';
import { useSpinner } from '../../hooks';

type Props = {
  cells: Cell[];
  isLoading: boolean;
};

type Cell = {
  cover: string;
  cr: string | number;
  ctr: string | number;
  cuts: number;
  datePublished: Date;
  title: string;
  views: number;
  _id: string;
};

const ElipsisText = styled.div`
  min-width: 0;
  max-width: 100%;
  padding-right: 12px;

  // display: block; /* Fallback for non-webkit */
  // display: -webkit-box;
  // height: 2.6em; /* Fallback for non-webkit, line-height * 2 */
  // line-height: 1.3em;
  // -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // text-overflow: ellipsis;
`;

const Container = styled.div`
  background: var(--shade-900-85);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 30px 38px;
  display: flex;
  gap: 50px;
  flex-direction: column;
  overflow: hidden;
`;

const Row = styled.div<{ flex: number; direction?: string; margin?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  gap: 36px;
  flex: ${({ flex }) => flex};
  ${({ margin }) => `margin: ${margin ?? 0} `};
  .search-wrapper {
    margin: 0;
    transform: unset;
  }
`;

const Title = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const StyledTable = styled.table`
  flex: 1;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 0 0 18px 0;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 2px solid var(--shade-700-85);
      border-radius: 6px;
      align-items: center;
      td {
        padding: 16px 0;
      }
    }
  }
`;

const StyledTableHeader = styled.th`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledTableCell = styled.td`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const Cover = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--shade-700-85);
  border: 1.5px solid var(--shade-300);
  box-sizing: border-box;
  backdrop-filter: blur(50px);
`;

const CoverImage = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  max-width: 100%;
  object-fit: cover;
  border: 2.5px solid transparent;
  border-radius: 50%;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const PaginationButton = styled.button<{ right?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;

  cursor: pointer;
  svg {
    transform: scale(0.75) ${({ right }) => (right ? 'rotate(180deg)' : '')};
    > * {
      transition: fill 225ms ease;
      fill: white;
    }
  }
  &:hover {
    svg {
      > * {
        fill: var(--primary);
      }
    }
  }
`;

const PageButton = styled.div<{ isActive?: boolean; disabled?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  padding: 1px 0 0 1px;
  letter-spacing: 0.01em;
  color: var(--shade-900);
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 225ms ease, color 225ms ease;
  color: var(--white);
  cursor: pointer;
  &:hover {
    background-color: var(--primary-20);
  }
  ${({ isActive }) => isActive && `color: var(--shade-900); background-color: var(--primary);`}
  ${({ disabled }) => disabled && `pointer-events: none;`}
`;

const columns = [
  {
    Header: 'Story Title',
    accessor: 'title' as const,
    Cell: ({
      cell: {
        row: { original },
      },
    }: any) => {
      const { cover, title } = original;

      return (
        <TitleWrapper>
          <Cover>
            <CoverImage src={cover} alt="Story cover image" />
          </Cover>
          <ElipsisText>{title}</ElipsisText>
        </TitleWrapper>
      );
    },
    width: 2.5,
  },
  {
    Header: 'Story Views',
    accessor: 'views' as const,
    width: 1,
  },
  {
    Header: 'Slides',
    accessor: 'cuts' as const,
    width: 1,
  },
  {
    Header: 'CTR',
    accessor: 'ctr' as const,
    width: 1,
  },
  {
    Header: 'Completion Rate',
    accessor: 'cr' as const,
    width: 1.5,
  },
  {
    Header: 'Published',
    accessor: 'datePublished' as const,
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => value?.toLocaleDateString(),
    sortType: 'datetime',
    width: 1,
  },
];

const RotatedChevron = styled(ChevronLeft)<{ up?: boolean }>`
  vertical-align: middle;
  margin-left: 12px;
  transform: scale(0.75) rotate(${({ up }) => (up ? '90deg' : '-90deg')});
`;

const paginationGenerator = (current: number, last: number, width = 2) => {
  const left = current - width;
  const right = current + width + 1;
  const range = [];
  const rangeWithDots: (string | number)[] = [];
  let l: number;

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i <= right)) {
      range.push(i);
    } else if (i < left) {
      i = left - 1;
    } else if (i > right) {
      range.push(last);
      break;
    }
  }

  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  });

  return rangeWithDots;
};

const AnalyticsTable: VFC<Props> = ({ cells, isLoading }) => {
  // Aditional memo for table cells.
  const data = useMemo(() => cells, [cells]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
  );

  const { Spinner, spinnerProps } = useSpinner({
    color: '#f6522b',
    backgroundColor: 'rgba(20, 20, 31, 0.85)',
  });

  const pagination = useMemo(() => [...paginationGenerator(pageIndex, pageOptions.length)], [
    pageIndex,
    pageOptions.length,
  ]);

  if (isLoading) {
    return (
      <Container>
        <Spinner {...spinnerProps} isVisible={isLoading} />
      </Container>
    );
  }

  return (
    <Container>
      <Row flex={0}>
        <Title>All Stories ({data.length})</Title>
        <StorySearchWrapper className="search-wrapper" isHidden={false}>
          <Search hasBorder placeholder="Search Stories" value={globalFilter ?? ''} onChange={setGlobalFilter} />
        </StorySearchWrapper>
      </Row>
      <Row flex={1} direction="column">
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {column.isSorted && <RotatedChevron up={!column.isSortedDesc} />}
                  </StyledTableHeader>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row: any) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell: any) => {
                        // Apply the cell props
                        return (
                          <StyledTableCell {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </StyledTableCell>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </StyledTable>
        <Pagination>
          <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ChevronLeft />
          </PaginationButton>
          {pagination?.map((currentPage) => (
            <PageButton
              key={currentPage}
              disabled={currentPage === '...'}
              isActive={currentPage === pageIndex + 1}
              onClick={() => gotoPage(+currentPage - 1)}
            >
              {currentPage}
            </PageButton>
          ))}
          <PaginationButton right onClick={() => nextPage()} disabled={!canNextPage}>
            <ChevronLeft />
          </PaginationButton>
        </Pagination>
      </Row>
    </Container>
  );
};

export default memo(AnalyticsTable);
