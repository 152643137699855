import ExclamationMark from '../../icons/exclamation-mark';
import ForgottenPasswordLink from '../shared/forgotten-password-link';
import FormInput from '../shared/form-input';
import FormStyles from './../shared/form-styles';
import styled from 'styled-components';
import Title from '../shared/title';
import { LoginRequest } from 'redux/services/auth/interface';
import { useForm } from 'react-hook-form';
import { memo } from 'react';

type IProps = {
  onLogin: (user: LoginRequest) => void;
  onResendVerification: () => void;
  onGoBack: () => void;
  submitionErrors?: boolean;
  verificationError?: string;
};

const ResendEmailButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  text-decoration: underline;
  background: none;
  color: inherit;
  font-family: inherit;
  cursor: pointer;
  font: inherit;
`;

const LoginForm = ({
  onLogin,
  onResendVerification,
  submitionErrors,
  verificationError,
  onGoBack,
}: IProps): JSX.Element => {
  const {
    handleSubmit,
    register,
    errors: validationErrors,
    formState: { isValid },
  } = useForm({ mode: 'all' });

  return (
    <FormStyles.StyledFormWrapper>
      <FormStyles.StyledForm onSubmit={handleSubmit(onLogin)}>
        <Title text="Log in" />
        {verificationError && (
          <FormStyles.StyledFormError>
            {verificationError} Please check your inbox or{' '}
            <ResendEmailButton type="button" onClick={onResendVerification}>
              resend email
            </ResendEmailButton>
            .
          </FormStyles.StyledFormError>
        )}
        {!verificationError && submitionErrors && (
          <FormStyles.StyledFormError>
            We couldnt find an account matching your credentials. Please try again or{' '}
            <FormStyles.StyledFormErrorLink href={'mailto:hello@cutnut.net'} target="_blank" rel="noopener noreferrer">
              contact support
            </FormStyles.StyledFormErrorLink>
            .
          </FormStyles.StyledFormError>
        )}
        <FormStyles.StyledLabel>Email</FormStyles.StyledLabel>
        <FormInput type="email" name="email" placeHolder="Enter your email" autoComplete="off" register={register} />
        {validationErrors.email && (
          <FormStyles.StyledInputError>
            <ExclamationMark />
            <FormStyles.StyledErrorText> Please enter a valid email </FormStyles.StyledErrorText>
          </FormStyles.StyledInputError>
        )}
        <FormStyles.StyledLabel>Password</FormStyles.StyledLabel>

        <FormInput
          type="password"
          name="password"
          autoComplete="off"
          placeHolder="Enter your password"
          register={register}
        />
        <ForgottenPasswordLink />

        <FormStyles.StyledFormButton text="Login" type="submit" disabled={!isValid} />

        <FormStyles.SignupText>
          <FormStyles.GoBackButton onClick={onGoBack}>Go back</FormStyles.GoBackButton>
        </FormStyles.SignupText>
      </FormStyles.StyledForm>
    </FormStyles.StyledFormWrapper>
  );
};

export default memo(LoginForm);
