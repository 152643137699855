import { IDomain } from './domains';

export interface ICurrentTeamUser {
  _id: string;
  username: string;
  email: string;
  avatar?: string | null;
}

export interface ICurrentTeam {
  _id: string;
  title: string;
  isDeleted: boolean;
  author: string;
  users: ICurrentTeamUser[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ITeam {
  _id: string;
  title: string;
  author: string;
  users: string[];
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export enum workspaceTypes {
  PAID = 'PAID',
  TRIAL = 'TRIAL',
}

export interface IWorkspace {
  _id: string;
  title: string;
  type: workspaceTypes;
  trialEndsAt: string;
  publisher?: string;
  logoUrl?: string;
  publisherLogoUrl?: string;
  favIconLogo?: string;
  googleAnalytics?: string;
  owner: string;
  teams: ITeam[];
  users: { role: string; _id: string }[];
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  domainSettings: {
    activeDomain?: IDomain;
    domainList: IDomain[];
  };
}

export interface IWorkspaceUser {
  _id: string;
  status?: string;
  avatar?: string;
  username?: string;
  email: string;
  addedAt: string;
  role: {
    name: string;
  };
}

export type IInvitedUsers = (
  | {
      email: string;
      role: string;
    }
  | undefined
)[];

export enum USER_ROLES_NAMES {
  CREATOR = 'Creator',
  ADMIN = 'Admin',
}

export enum USER_ROLES_VALUES {
  CREATOR = 'user',
  ADMIN = 'admin',
  OWNER = 'owner',
}

export interface IWorkspaceRole {
  _id: string;
  name: string;
}
