import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ISectionTitleButtonWrapper {
  isSticky?: boolean;
  margin?: string;
  children: ReactNode;
}

const Wrapper = styled.div<{ isSticky?: boolean; margin: string }>`
  margin: ${({ margin }) => margin};
  ${({ isSticky }) => isSticky && `display: none`}
`;

const SectionTitleButtonWrapper = ({
  isSticky,
  margin = '0 0 0 12px',
  children,
}: ISectionTitleButtonWrapper): JSX.Element => {
  return (
    <Wrapper margin={margin} isSticky={isSticky}>
      {children}
    </Wrapper>
  );
};

export default SectionTitleButtonWrapper;
