import DashboardLayout from 'components/layouts';
import SettingsWrapper from 'components/settings/shared/settings-wrapper';
import SettingsTabs from 'components/settings/settings-tabs';
import SeoSettings from 'components/settings/seo-settings';
import CustomDomainsSettings from 'components/settings/custom-domains-settings';
import AnalyticsSettings from 'components/settings/analytics-settings';
import Sitemaps from 'components/settings/sitemaps';
import GoogleSignInSettings from 'components/settings/google-sign-in-settings';

const SettingsPublishingPage = (): JSX.Element => {
  return (
    <DashboardLayout>
      <SettingsWrapper>
        <SettingsTabs />

        <CustomDomainsSettings />
        <SeoSettings />
        <AnalyticsSettings />
        <Sitemaps />
        <GoogleSignInSettings />
      </SettingsWrapper>
    </DashboardLayout>
  );
};

export default SettingsPublishingPage;
