import Menu, { Item as MenuItem, SubMenu } from 'rc-menu';
import styled from 'styled-components';
import { Hamburger, NormalIconWrapper } from '../icons';
import { memo } from 'react';

const StyledMenu = styled(Menu)`
  margin: 0;
  background: none;
  border: none;
  &&& {
    .context-menu {
      background: red;
    }
  }
`;

const StyledSubMenu = styled(SubMenu)`
  &&& {
    border: none;
    border-radius: 8px;
    transition: background-color 200ms ease;
    &.rc-menu-submenu-selected {
      background-color: unset;
    }
    &.rc-menu-submenu-active {
      background-color: var(--shade-500-85);
      backdrop-filter: blur(50px);
    }
    &.rc-menu-submenu-open {
      background-color: var(--primary-10);
      backdrop-filter: blur(50px);
      svg,
      svg > * {
        fill: var(--primary);
      }
    }
    > div {
      background: none;
      padding: 0;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        margin: 0;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: none;
    margin: 0 12px 4px 12px;
    padding: 3px 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--shade-100);
    cursor: pointer;
    transition: background-color 200ms ease, color 200ms ease;
    &.rc-menu-item-active {
      background-color: var(--primary);
      color: var(--shade-900);
      > span {
        color: var(--shade-900-85);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

interface Props {
  handleSaveWidget: () => Promise<void>;
}

const WidgetHeaderContextMenu: React.VFC<Props> = ({ handleSaveWidget }) => (
  <StyledMenu triggerSubMenuAction="click" mode="horizontal" openAnimation="slide-up">
    <StyledSubMenu
      popupClassName={'context-menu'}
      popupOffset={[0, 20]}
      title={
        <NormalIconWrapper margin={'0 0 0 4px'}>
          <Hamburger />
        </NormalIconWrapper>
      }
      key="1"
    >
      <StyledMenuItem key="1-2" onClick={handleSaveWidget}>
        Save
      </StyledMenuItem>
    </StyledSubMenu>
  </StyledMenu>
);

export default memo(WidgetHeaderContextMenu);
