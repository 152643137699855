import React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  targetSelector: string;
}

const ColorPickerPortal: React.FC<IProps> = ({ children, targetSelector }) => {
  const selector = targetSelector;
  const element = document.getElementById(selector);

  return element ? ReactDOM.createPortal(<>{children}</>, element) : null;
};

export default ColorPickerPortal;
