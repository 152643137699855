import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface IListItemWrapper {
  id?: string;
  className?: string;
  children: ReactNode;
  margin?: number | string;
  position?: string;
  flex?: string;
  onMouseEnter?: (param: any) => void;
  onMouseLeave?: (param: any) => void;
  oddStyles?: string;
}

const Wrapper = styled.div<{ margin?: number | string; position?: string; flex?: string; oddStyles?: string }>`
  position: ${({ position }) => position};
  flex: ${({ flex }) => flex};
  margin: ${({ margin }) => margin};
  ${({ oddStyles }) =>
    oddStyles
      ? css`
          &:nth-child(odd) {
            ${oddStyles}
          }
        `
      : ''}
`;

const ListItemWrapper = ({
  id,
  className,
  children,
  margin,
  position,
  flex,
  onMouseEnter,
  onMouseLeave,
  oddStyles,
}: IListItemWrapper): JSX.Element => {
  return (
    <Wrapper
      id={id}
      className={className}
      margin={margin}
      position={position}
      flex={flex}
      oddStyles={oddStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Wrapper>
  );
};

export default ListItemWrapper;
