import { memo } from 'react';

interface IInfinite {
  className?: string;
}

const Infinite = ({ className }: IInfinite) => {
  return (
    <svg
      className={className}
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2857 2.03894C19.468 1.22296 18.4183 0.679285 17.2801 0.482178C16.1418 0.285071 14.9704 0.444138 13.926 0.937657C13.0641 1.36889 12.3302 2.0185 11.7977 2.82173L8.29602 7.84901C7.99294 8.31973 7.57283 8.70369 7.07681 8.9633C6.4649 9.25221 5.77861 9.34483 5.112 9.22846C4.44539 9.1121 3.83108 8.79244 3.35324 8.3133C2.75347 7.69041 2.41841 6.85936 2.41841 5.99466C2.41841 5.12996 2.75347 4.2989 3.35324 3.67601C3.96863 3.06191 4.8025 2.71702 5.67188 2.71702C6.54126 2.71702 7.37513 3.06191 7.99052 3.67601C8.20823 3.89372 8.5035 4.01602 8.81138 4.01602C9.11926 4.01602 9.41453 3.89372 9.63224 3.67601C9.84994 3.45831 9.97225 3.16304 9.97225 2.85516C9.97225 2.54728 9.84994 2.252 9.63224 2.0343C8.5805 0.98709 7.1565 0.399538 5.67231 0.400408C4.18813 0.401279 2.76482 0.9905 1.71431 2.03894C0.679248 3.09734 0.099679 4.51891 0.099679 5.9993C0.099679 7.47969 0.679248 8.90126 1.71431 9.95966C2.53196 10.7751 3.58129 11.3185 4.71915 11.5156C5.857 11.7127 7.02795 11.5539 8.07224 11.0609C8.93413 10.6297 9.66794 9.9801 10.2005 9.17687L13.704 4.14959C14.0071 3.67887 14.4272 3.29491 14.9232 3.0353C15.5353 2.74693 16.2214 2.65461 16.8879 2.77095C17.5544 2.8873 18.1687 3.20663 18.6468 3.6853C19.2466 4.30818 19.5816 5.13924 19.5816 6.00394C19.5816 6.86864 19.2466 7.6997 18.6468 8.32259C18.0314 8.93669 17.1975 9.28158 16.3282 9.28158C15.4588 9.28158 14.6249 8.93669 14.0095 8.32259C13.9017 8.21479 13.7738 8.12928 13.6329 8.07094C13.4921 8.0126 13.3411 7.98258 13.1887 7.98258C13.0362 7.98258 12.8853 8.0126 12.7444 8.07094C12.6036 8.12928 12.4756 8.21479 12.3678 8.32259C12.26 8.43038 12.1745 8.55836 12.1162 8.6992C12.0578 8.84004 12.0278 8.991 12.0278 9.14344C12.0278 9.29589 12.0578 9.44685 12.1162 9.58769C12.1745 9.72853 12.26 9.8565 12.3678 9.9643C13.419 11.0127 14.8431 11.6014 16.3277 11.6014C17.8123 11.6014 19.2364 11.0127 20.2876 9.9643C21.3227 8.9059 21.9022 7.48434 21.9022 6.00394C21.9022 4.52355 21.3227 3.10199 20.2876 2.04359L20.2857 2.03894Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Infinite);
