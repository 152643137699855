import React, { useCallback, useContext, useEffect, useRef } from 'react';
import Styled from './widget-editor-device-styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { loadScript, removeScript } from '../../utils/common';
import useMutationObservable from '../../hooks/useMutationObservable';
import { environment } from '../../config/environment';
import { previewMutationListener } from '../../utils/widgetUtils';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

interface IWidgetEditorIframePC {
  updateScrollbars: () => void;
}

const WidgetEditorIframePC = ({ updateScrollbars }: IWidgetEditorIframePC) => {
  const { widget } = useContext(WidgetContext) as WidgetContextType;
  const widgetKey = widget.key;

  const cutnutwidgetRef = useRef<HTMLDivElement | null>(null);
  const widgetWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // local - process.env.PUBLIC_URL | other - environment.widgetHost
    loadScript(environment.widgetHost as string);

    return () => {
      removeScript(environment.widgetHost as string);
    };
  }, []);

  const lightboxMutation = useCallback((mutationList: MutationRecord[] | undefined) => {
    if (mutationList) {
      previewMutationListener(cutnutwidgetRef.current)(mutationList);
    }
  }, []);

  useMutationObservable(widgetWrapperRef.current, lightboxMutation);

  useMutationObservable(cutnutwidgetRef.current, updateScrollbars);

  return (
    <Styled.OuterWrapper>
      <Styled.PCWrapper>
        <Styled.PCBrowserBar>
          <Styled.PCBrowserButton />
          <Styled.PCBrowserButton />
          <Styled.PCBrowserButton />

          <Styled.PCBrowserSearchbar />

          <Styled.PCBrowserEmptySpace />
        </Styled.PCBrowserBar>
        <Styled.PCHeader>
          <Styled.PCHeaderColumn>
            <Styled.PCHeaderText />
            <Styled.PCHeaderText />
            <Styled.PCHeaderText />
          </Styled.PCHeaderColumn>

          <Styled.PCHeaderColumn>
            <Styled.PCUserName />
            <Styled.PCUser />
          </Styled.PCHeaderColumn>
        </Styled.PCHeader>

        <Styled.ExternalWidgetWrapper ref={widgetWrapperRef}>
          <div className="cutnutwidget" id={widgetKey} ref={cutnutwidgetRef} />
        </Styled.ExternalWidgetWrapper>

        <Styled.FirstSection>
          <Styled.FirstSectionTitle />

          <Styled.FirstSectionText />
          <Styled.FirstSectionText />
          <Styled.FirstSectionText />
        </Styled.FirstSection>

        <Styled.SecondSection>
          <Styled.SecondSectionItem>
            <Styled.SecondSectionItemImage />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
          </Styled.SecondSectionItem>

          <Styled.SecondSectionItem>
            <Styled.SecondSectionItemImage />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
            <Styled.SecondSectionItemText />
          </Styled.SecondSectionItem>
        </Styled.SecondSection>
      </Styled.PCWrapper>
    </Styled.OuterWrapper>
  );
};

export default WidgetEditorIframePC;
