import styled from 'styled-components';
import Info from '../../icons/info';

const StyledSettingsInfo = styled.div`
  cursor: pointer;
  svg > * {
    fill: var(--shade-100);
  }
`;

interface ISettingsInfo {
  onClick: () => void;
}

const SettingsInfo: React.FC<ISettingsInfo> = ({ onClick }) => {
  return (
    <StyledSettingsInfo onClick={onClick}>
      <Info />
    </StyledSettingsInfo>
  );
};

export default SettingsInfo;
