import CreateFolderModal from '../create-folder-modal';
import FolderItem from './folder-item/folder-item';
import MessageModal from 'components/message-modal';
import MoveModal from 'components/move-modal';
import React, { useCallback, useState } from 'react';
import Section from '../layouts/shared/section-title';
import SectionTitleButtonWrapper from '../stories/shared/structure/section-title-button-wrapper';
import styled from 'styled-components';
import { EmptyFolderList } from '../stories/shared/elements';
import { RoundPlus } from '../icons';
import { toast, Zoom } from 'react-toastify';
import { useDeleteFolderMutation } from 'redux/services/folders/folders';
import { IFolder } from 'redux/services/folders/interfaces';

interface IProps {
  parentFolder?: string | null;
  isFolderPage?: boolean;
  isEmptyState?: boolean;
  customPadding?: string;
  nestedFolder?: boolean;
  storiesLength?: number;
  folders?: IFolder[] | null;
}

const SectionWrapper = styled.div`
  margin: 60px 0 0;
`;

const FoldersWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-width: 100%;
  margin: 0 -12px;
  justify-content: flex-start;
  > div {
    margin: 0 12px 25px;
    min-width: 200px;
    position: relative;
  }
`;

const FolderColumn = styled.div<{ isDraggingOver: boolean }>`
  &&& a {
    border: 1px solid var(--shade-700-85);
    border-color: ${(props) => (props.isDraggingOver ? `var(--primary)` : `var(--shade-700-85)`)};
  }
`;

const AddFolderButton = styled.button`
  background: none;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
  }
`;

const deleteDescription = 'All Stories inside the folder will be deleted.';
const deleteMessage = 'Are you sure you want to delete the selected folder?';

const FoldersSection: React.FC<IProps> = ({
  parentFolder,
  isFolderPage,
  isEmptyState,
  storiesLength,
  folders,
  customPadding,
  nestedFolder,
}) => {
  const [isCreateNewFolderModalOpen, setCreateNewFolderModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMoveModalOpen, setMoveModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<IFolder | null>(null);

  const [deleteFolder] = useDeleteFolderMutation();

  const onCreateNewFolder = () => setCreateNewFolderModal(true);
  const onRenameModalOpen = useCallback((folder: IFolder) => {
    setSelectedFolder(folder);
    setCreateNewFolderModal(true);
  }, []);
  const onMoveModalOpen = useCallback((folder: IFolder) => {
    setSelectedFolder(folder);
    setMoveModalOpen(true);
  }, []);
  const onDeleteModalOpen = useCallback((folder: IFolder) => {
    setSelectedFolder(folder);
    setIsDeleteModalOpen(true);
  }, []);

  const onCloseCreateModal = useCallback(() => {
    setSelectedFolder(null);
    setCreateNewFolderModal(false);
  }, []);
  const onCloseDeleteModal = useCallback(() => {
    setSelectedFolder(null);
    setIsDeleteModalOpen(false);
  }, []);
  const onCloseMoveModal = useCallback(() => {
    setSelectedFolder(null);
    setMoveModalOpen(false);
  }, []);

  const onDelete = useCallback(async () => {
    if (!selectedFolder) {
      return;
    }
    try {
      await deleteFolder({ folderId: selectedFolder._id, parentFolder }).unwrap();
      toast.info(`Your folder has been deleted successfully.`, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        transition: Zoom,
      });

      onCloseDeleteModal();
    } catch (err) {
      console.error(err);
    }
  }, [deleteFolder, onCloseDeleteModal, parentFolder, selectedFolder]);

  if (isEmptyState && !folders?.length) {
    return <></>;
  }

  if ((!folders?.length && isFolderPage && !storiesLength) || (!folders?.length && !isFolderPage)) {
    return <EmptyFolderList parentFolder={parentFolder} customPadding={customPadding} nestedFolder={nestedFolder} />;
  }

  return (
    <SectionWrapper>
      <Section.Wrapper>
        <Section.Title>
          <Section.Text>Folders</Section.Text>
        </Section.Title>

        <SectionTitleButtonWrapper margin="0 0 0 auto">
          <AddFolderButton type="button" onClick={onCreateNewFolder}>
            <RoundPlus className="roundplusbtn" size="28px" />
          </AddFolderButton>
        </SectionTitleButtonWrapper>
      </Section.Wrapper>

      {folders?.length ? (
        <FoldersWrapper>
          {folders?.map((folder) => {
            return (
              <FolderItem
                key={folder._id}
                folder={folder}
                onRenameModalOpen={onRenameModalOpen}
                onDeleteModalOpen={onDeleteModalOpen}
                onMoveModalOpen={onMoveModalOpen}
              />
            );
          })}
        </FoldersWrapper>
      ) : (
        <EmptyFolderList parentFolder={parentFolder} customPadding={customPadding} nestedFolder={nestedFolder} />
      )}

      {isDeleteModalOpen && (
        <MessageModal
          description={deleteDescription}
          isOpen={isDeleteModalOpen}
          message={deleteMessage}
          shouldCloseOnOverlayClick={true}
          onCancel={onCloseDeleteModal}
          onAccept={onDelete}
        />
      )}
      {isMoveModalOpen && (
        <MoveModal
          folderId={selectedFolder?._id}
          showModal={isMoveModalOpen}
          onCloseMoveStoryModal={onCloseMoveModal}
          contextFolderId={parentFolder}
        />
      )}
      {isCreateNewFolderModalOpen && (
        <CreateFolderModal
          folder={selectedFolder}
          isCreateFolderModalOpen={isCreateNewFolderModalOpen}
          onClose={onCloseCreateModal}
          parentFolder={parentFolder}
        />
      )}
    </SectionWrapper>
  );
};

export default FoldersSection;
