import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

interface IImageWrapper extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  className?: string;
  children: ReactNode;
}

const StyledImageWrapper = styled.div`
  position: relative;
  margin: 0 0 16px;
`;

const ImageWrapper = ({ id, className, children, ...rest }: IImageWrapper): JSX.Element => {
  return (
    <StyledImageWrapper id={id} className={className} {...rest}>
      {children}
    </StyledImageWrapper>
  );
};

export default ImageWrapper;
