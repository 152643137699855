import { ReactNode } from 'react';
import { ChevronLeft, ChevronRight } from '../../../icons';
import styled, { css } from 'styled-components';
interface ISliderArrowButton {
  children: ReactNode;
}
const SliderArrowButton = ({ children }: ISliderArrowButton) => {
  return <>{children}</>;
};

interface IArrowButton {
  isDisabled?: boolean;
  onClick?: () => void;
  rotate?: boolean;
}

const Button = styled.button<{ isDisabled?: boolean; $rotate?: boolean }>`
  display: inline-flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background: none;
  color: ${({ isDisabled }) => (isDisabled ? 'var(--shade-300)' : 'var(--white)')};
  outline: none;
  border: none;
  cursor: pointer;
  & + & {
    margin-left: 8px;
  }
  ${({ $rotate }) =>
    $rotate &&
    css`
      transform: rotate(90deg);
    `}
`;

const ArrowButtonLeft = ({ isDisabled, onClick }: IArrowButton) => {
  return (
    <Button isDisabled={isDisabled} onClick={onClick}>
      <ChevronLeft />
    </Button>
  );
};

const ArrowButtonRight = ({ isDisabled, onClick, rotate }: IArrowButton) => {
  return (
    <Button isDisabled={isDisabled} onClick={onClick} $rotate={rotate}>
      <ChevronRight />
    </Button>
  );
};

SliderArrowButton.Left = ArrowButtonLeft;
SliderArrowButton.Right = ArrowButtonRight;

export default SliderArrowButton;
