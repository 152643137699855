import { Brand, Social, Collaborate, Carousel } from '../../icons/tutorial';

const features = [
  {
    icon: {
      component: <Social />,
      width: '30px',
      height: '30px',
    },
    textInfo: {
      text: 'Create Web- & Social Stories',
      maxWidth: '106px',
    },
  },
  {
    icon: {
      component: <Collaborate />,
      width: '30px',
      height: '30px',
    },

    textInfo: {
      text: 'Collaborate with your team',
      maxWidth: '110px',
    },
  },
  {
    icon: {
      component: <Carousel />,
      width: '30px',
      height: '30px',
    },

    textInfo: {
      text: 'Show the Stories Carousel on your website',
      maxWidth: '173px',
    },
  },
  {
    icon: {
      component: <Brand />,
      width: '30px',
      height: '30px',
    },
    textInfo: {
      text: 'Control your brand',
      maxWidth: '83px',
    },
  },
];

export { features };
