import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: var(--primary);
  color: var(--shade-900);
  border-radius: 6px;
  padding: 9px 10px;
  cursor: pointer;
  border: none;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  transition: background-color 225ms ease;
  &: disabled {
    background-color: var(--primary-50);
    cursor: not-allowed;
  }
`;

type IFormButton = {
  className?: string;
  disabled?: boolean;
  text: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
};

const FormButton = ({ className, disabled, text, type, onClick }: IFormButton): JSX.Element => (
  <StyledButton className={className} disabled={disabled} type={type} onClick={onClick}>
    {' '}
    {text}
  </StyledButton>
);

export default FormButton;
