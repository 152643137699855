import ColorPicker from '../../../color-picker';
import EditorSidebarHalfColumn from '../../shared/structure/editor-sidebar-half-column';
import EditorSidebarLabel from '../../shared/elements/editor-sidebar-label';
import EditorSidebarRowWrapper from '../../shared/structure/editor-sidebar-row-wrapper';
import EditorSidebarSectionTitle from '../../shared/elements/editor-sidebar-section-title';
import EditorSidebarSectionTitleWrapper from '../../shared/structure/editor-sidebar-section-title-wrapper';
import EditorSidebarSectionWrapper from '../../shared/structure/editor-sidebar-section-wrapper';
import EditorSidebarValuesWrapper from '../../shared/structure/editor-sidebar-values-wrapper';
import produce from 'immer';
import { hexToRgb, rgbaToHexa } from '../../../../utils/parseColors';
import { IColorType } from '../../../../interfaces/colors';
import { IFieldValue } from 'interfaces/field-value';
import { ILayer } from 'interfaces/layer';
import { ImmerState } from 'immer/dist/internal';
import { memo, useCallback, useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import set from 'lodash/set';

interface IProps {
  layer: ILayer;
  parentLayer?: ILayer;
  handleBatchLayerChange: ([{ field, value }]: IFieldValue[]) => void;
}

const EditorSidebarGradientSettings: React.FC<IProps> = ({ layer, parentLayer, handleBatchLayerChange }) => {
  const [gradientSvgColors, setGradientSvgColors] = useState(layer?.content?.gradient?.colors);

  const handleColorChange = useCallback(
    (el: string) => (_: unknown, c: string | number) => {
      const newColor = rgbaToHexa(c.toString());

      const newColors = produce(gradientSvgColors, (draftState: ImmerState) => {
        set(draftState, `${el}.color`, newColor);
      });

      if (!isNil(parentLayer?._id) && layer._id !== parentLayer?._id) {
        const updatedLayers = parentLayer?.childLayers?.map((cl) => {
          if (cl._id === layer._id) {
            const nextState = produce(cl, (draftState: ImmerState) => {
              set(draftState, `content.gradient.colors`, newColors);
            });

            return nextState;
          }

          return cl;
        });

        setGradientSvgColors(newColors);
        handleBatchLayerChange([{ field: `childLayers`, value: updatedLayers }]);
        return;
      }

      setGradientSvgColors(newColors);
      handleBatchLayerChange([{ field: 'content.gradient.colors', value: newColors }]);
    },
    [gradientSvgColors, handleBatchLayerChange, layer._id, parentLayer?._id, parentLayer?.childLayers],
  );

  useEffect(() => {
    setGradientSvgColors(layer?.content?.gradient?.colors);
  }, [layer?.content?.gradient?.colors]);

  return (
    <EditorSidebarSectionWrapper>
      <EditorSidebarSectionTitleWrapper>
        <EditorSidebarSectionTitle text={'Gradient'} />
      </EditorSidebarSectionTitleWrapper>
      <EditorSidebarRowWrapper alignItems={'flex-start'}>
        <EditorSidebarLabel text={'Color'} paddingTop={'6px'} />

        <EditorSidebarValuesWrapper>
          <EditorSidebarHalfColumn justifyContent={'flex-end'} flexWrap={'wrap'} width={'auto'}>
            {Object.keys(gradientSvgColors ?? {})?.map((el, index) => {
              return (
                <ColorPicker
                  key={index}
                  isDisabled={layer?.settings.generalSettings.locked}
                  colorType={IColorType.FillColor}
                  leftColor={hexToRgb(gradientSvgColors?.[el]?.color ?? '')}
                  defaultLeftColor={hexToRgb(gradientSvgColors?.[el]?.color ?? '')}
                  handleColorChange={handleColorChange(el)}
                />
              );
            })}
          </EditorSidebarHalfColumn>
        </EditorSidebarValuesWrapper>
      </EditorSidebarRowWrapper>
    </EditorSidebarSectionWrapper>
  );
};

export default memo(EditorSidebarGradientSettings);
