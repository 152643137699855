import { memo } from 'react';

interface ICustomFonts {
  className?: string;
}

const CustomFonts = ({ className }: ICustomFonts): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 0H12.5C11 0 10 1 10 2.5C10 4 11 5 12.5 5H16.25L8.25 25H2.5C1 25 0 26 0 27.5C0 29 1 30 2.5 30H10H17.5C19 30 20 29 20 27.5C20 26 19 25 17.5 25H13.75L21.75 5H27.5C29 5 30 4 30 2.5C30 1 29 0 27.5 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(CustomFonts);
