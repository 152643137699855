import ColorPicker from '../../../color-picker';
import EditorSidebarHalfColumn from '../../../editor-sidebar/shared/structure/editor-sidebar-half-column';
import EditorSidebarLabel from '../../../editor-sidebar/shared/elements/editor-sidebar-label';
import EditorSidebarLabelWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-label-wrapper';
import EditorSidebarLayoutInput from '../../../editor-sidebar/shared/elements/editor-sidebar-layout-input';
import EditorSidebarRowWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-row-wrapper';
import EditorSidebarSectionTitle from '../../../editor-sidebar/shared/elements/editor-sidebar-section-title';
import EditorSidebarSectionTitleWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-section-title-wrapper';
import EditorSidebarSectionWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-section-wrapper';
import EditorSidebarSliderWithInput from '../../../editor-sidebar/shared/editor-sidebar-slider-with-input';
import EditorSidebarValuesWrapper from '../../../editor-sidebar/shared/structure/editor-sidebar-values-wrapper';
import limit from '../../constants/limits';
import lockImage from '../../../../assets/images/editor-sidebar/lock-icon.svg';
import lockImageActive from '../../../../assets/images/editor-sidebar/lock-icon-active.svg';
import React, { memo, useCallback, useContext, useState } from 'react';
import Select from '../../../shared/select';
import styled from 'styled-components';
import ToggleSwitch from '../../../shared/toggle-switch';
import { IColorType } from '../../../../interfaces/colors';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';
import { useDebounce, useDidUpdateEffect } from 'hooks';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';

const LockImage = styled.img`
  padding: 0 4px;
`;

const thicknessOptions = Array.from(Array(limit.thickness + 1).keys()).map((option) => ({
  name: option,
  value: option,
}));

const WidgetEditorSidebarShapeSettings = (): JSX.Element => {
  const { widget, setWidget } = useContext(WidgetContext) as WidgetContextType;
  const { setChangesCount } = useContext(ChangesContext) as ChangesContextType;
  const {
    width: sizeW,
    height: sizeH,
    border_color: defaultBorderColor,
    border_width,
    radius,
    spacing,
    shadow,
    hasborderpadding,
  } = widget.editorDetails.style;

  const [lockedSize, setLockedSize] = useState(false);
  const [ratio, setRatio] = useState(1.0);
  const [borderColor, setBorderColor] = useState(defaultBorderColor);

  const debouncedBorderColor = useDebounce<string>(borderColor, 50);

  const setArrowEvents = useCallback(
    (event, field: 'width' | 'height' | 'radius' | 'spacing' | 'shadow', locked: boolean, ratio: number) => {
      if (event.keyCode === 38 || event.keyCode === 40) {
        const value = event.keyCode === 38 ? Math.round(+event.target.value) + 1 : Math.round(+event.target.value) - 1;

        if (locked && (field === 'width' || field === 'height')) {
          if (field === 'width') {
            const newheight = value * ratio;
            setWidget((prev) => ({
              ...prev,
              editorDetails: {
                ...prev.editorDetails,
                style: {
                  ...prev.editorDetails.style,
                  height: newheight.toFixed(0),
                },
              },
            }));
          }

          if (field === 'height') {
            const newwidth = value / ratio;
            setWidget((prev) => ({
              ...prev,
              editorDetails: {
                ...prev.editorDetails,
                style: {
                  ...prev.editorDetails.style,
                  width: newwidth.toFixed(0),
                },
              },
            }));
          }
        }

        setWidget((prev) => ({
          ...prev,
          editorDetails: {
            ...prev.editorDetails,
            style: {
              ...prev.editorDetails.style,
              [field]: value,
            },
          },
        }));
        setChangesCount((prev) => prev + 1);
      }
    },
    [setWidget, setChangesCount],
  );

  // WIDTH
  const onWidthChange = useCallback(
    ({ target: { value } }: any) => {
      if (lockedSize) {
        const newheight = parseFloat(value) * ratio;

        setWidget((prev) => ({
          ...prev,
          editorDetails: {
            ...prev.editorDetails,
            style: {
              ...prev.editorDetails.style,
              height: newheight.toFixed(0),
              width: value,
            },
          },
        }));
        setChangesCount((prev) => prev + 1);
        return;
      }

      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            width: value,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [lockedSize, ratio, setWidget, setChangesCount],
  );

  const onWidthInputArrowDown = (event: React.SyntheticEvent) => {
    setArrowEvents(event, 'width', lockedSize, ratio);
  };

  // HEIGHT
  const onHeightChange = useCallback(
    ({ target: { value } }: any) => {
      if (lockedSize) {
        const newwidth = parseFloat(value) * ratio;

        setWidget((prev) => ({
          ...prev,
          editorDetails: {
            ...prev.editorDetails,
            style: {
              ...prev.editorDetails.style,
              widget: newwidth.toFixed(0),
              height: value,
            },
          },
        }));
        setChangesCount((prev) => prev + 1);
        return;
      }

      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            height: value,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [lockedSize, ratio, setWidget, setChangesCount],
  );

  const onHeightInputArrowDown = (event: React.SyntheticEvent) => {
    setArrowEvents(event, 'height', lockedSize, ratio);
  };

  const onToggleLockSize = () => {
    setRatio(parseFloat(sizeH) / parseFloat(sizeW));
    setLockedSize(!lockedSize);
  };

  const onRadiusChange = useCallback(
    (value: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            radius: value,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onRadiusInputArrowDown = useCallback(
    (event: { target: HTMLInputElement; key: string }, min: number, max: number) => {
      const value = Number(event.target.value);
      if (
        (value >= min && value < max && event.key === 'ArrowUp') ||
        (value > min && value <= max && event.key === 'ArrowDown')
      ) {
        setArrowEvents(event, 'radius', lockedSize, ratio);
      }
    },
    [lockedSize, ratio, setArrowEvents],
  );

  const onRadiusInputArrowDownAdvanced = useCallback(
    (event: { target: HTMLInputElement; key: string }) => {
      onRadiusInputArrowDown(event, 0, limit.round);
    },
    [onRadiusInputArrowDown],
  );

  // BORDER COLOR
  const onBorderColorChange = useCallback((_: string, value: any) => {
    setBorderColor(value);
  }, []);

  // SPACING
  const onSpacingChange = useCallback(
    (value: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            spacing: value,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onSpacingInputArrowDown = useCallback(
    (event: { target: HTMLInputElement; key: string }, min: number, max: number) => {
      const value = Number(event.target.value);
      if (
        (value >= min && value < max && event.key === 'ArrowUp') ||
        (value > min && value <= max && event.key === 'ArrowDown')
      ) {
        setArrowEvents(event, 'spacing', lockedSize, ratio);
      }
    },
    [lockedSize, ratio, setArrowEvents],
  );

  const onSpacingInputArrowDownAdvanced = useCallback(
    (event: { target: HTMLInputElement; key: string }) => {
      onSpacingInputArrowDown(event, 0, limit.round);
    },
    [onSpacingInputArrowDown],
  );

  // SHADOW
  const onShadowChange = useCallback(
    (value: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            shadow: value,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onShadowInputArrowDown = useCallback(
    (event: { target: HTMLInputElement; key: string }, min: number, max: number) => {
      const value = Number(event.target.value);
      if (
        (value >= min && value < max && event.key === 'ArrowUp') ||
        (value > min && value <= max && event.key === 'ArrowDown')
      ) {
        setArrowEvents(event, 'shadow', lockedSize, ratio);
      }
    },
    [lockedSize, ratio, setArrowEvents],
  );

  const onShadowInputArrowDownAdvanced = useCallback(
    (event: { target: HTMLInputElement; key: string }) => {
      onShadowInputArrowDown(event, 0, limit.round);
    },
    [onShadowInputArrowDown],
  );

  const onThicknessChange = useCallback(
    (value: number) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          style: {
            ...prev.editorDetails.style,
            border_width: value,
          },
        },
      }));
      setChangesCount((prev) => prev + 1);
    },
    [setWidget, setChangesCount],
  );

  const onBorderGapChange = useCallback(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          hasborderpadding: prev.editorDetails.style.hasborderpadding === 0 ? 1 : 0,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [setWidget, setChangesCount]);

  const onColorReset = useCallback((type: string) => {
    //Todo - do the reset
  }, []);

  const onResetBorderColor = useCallback(() => onColorReset(IColorType.BorderColor), [onColorReset]);

  useDidUpdateEffect(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          border_color: debouncedBorderColor,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [debouncedBorderColor, setWidget, setChangesCount]);

  return (
    <EditorSidebarSectionWrapper>
      <EditorSidebarSectionTitleWrapper>
        <EditorSidebarSectionTitle text={'Shape'} />
      </EditorSidebarSectionTitleWrapper>
      <>
        <EditorSidebarRowWrapper>
          <EditorSidebarLabelWrapper>
            <EditorSidebarLabel text={'Size'} />

            {lockedSize ? (
              <LockImage src={lockImageActive} alt="Locked" onClick={onToggleLockSize} />
            ) : (
              <LockImage src={lockImage} alt="Unlocked" onClick={onToggleLockSize} />
            )}
          </EditorSidebarLabelWrapper>

          <EditorSidebarValuesWrapper>
            <EditorSidebarHalfColumn>
              <EditorSidebarLayoutInput
                isDisabled={false}
                sign={'W'}
                value={sizeW}
                onKeyDown={onWidthInputArrowDown}
                onChange={onWidthChange}
              />
            </EditorSidebarHalfColumn>

            <EditorSidebarHalfColumn>
              <EditorSidebarLayoutInput
                isDisabled={false}
                sign={'H'}
                value={sizeH}
                onKeyDown={onHeightInputArrowDown}
                onChange={onHeightChange}
              />
            </EditorSidebarHalfColumn>
          </EditorSidebarValuesWrapper>
        </EditorSidebarRowWrapper>

        <EditorSidebarRowWrapper>
          <EditorSidebarLabel text={'Radius'} />

          <EditorSidebarSliderWithInput
            min={0}
            max={50}
            step={1}
            value={radius}
            sign={'px'}
            isDisabled={false}
            onChange={onRadiusChange}
            onInputArrowDown={onRadiusInputArrowDownAdvanced}
          />
        </EditorSidebarRowWrapper>

        <EditorSidebarRowWrapper>
          <EditorSidebarLabel text={'Border'} />

          <EditorSidebarValuesWrapper>
            <EditorSidebarHalfColumn justifyContent={'flex-end'}>
              <ColorPicker
                colorType={IColorType.FillColor}
                isDisabled={false}
                defaultLeftColor={borderColor}
                leftColor={borderColor}
                handleColorChange={onBorderColorChange}
                handleColorReset={onResetBorderColor}
              />
            </EditorSidebarHalfColumn>
            <EditorSidebarHalfColumn>
              <Select
                isDisabled={false}
                selectOption={border_width}
                options={thicknessOptions}
                onSelect={onThicknessChange}
              />
            </EditorSidebarHalfColumn>
          </EditorSidebarValuesWrapper>
        </EditorSidebarRowWrapper>

        <EditorSidebarRowWrapper>
          <EditorSidebarLabel text={'Border Gap'} />

          <EditorSidebarHalfColumn justifyContent={'flex-end'} padding="0 8px 0 4px">
            <ToggleSwitch isOn={hasborderpadding} onClick={onBorderGapChange} />
          </EditorSidebarHalfColumn>
        </EditorSidebarRowWrapper>

        <EditorSidebarRowWrapper>
          <EditorSidebarLabel text={'Spacing'} />

          <EditorSidebarSliderWithInput
            min={0}
            max={100}
            step={1}
            value={spacing}
            sign={'px'}
            isDisabled={false}
            onChange={onSpacingChange}
            onInputArrowDown={onSpacingInputArrowDownAdvanced}
          />
        </EditorSidebarRowWrapper>

        <EditorSidebarRowWrapper>
          <EditorSidebarLabel text={'Shadow'} />

          <EditorSidebarSliderWithInput
            min={0}
            max={50}
            step={1}
            value={shadow}
            sign={'px'}
            isDisabled={false}
            onChange={onShadowChange}
            onInputArrowDown={onShadowInputArrowDownAdvanced}
          />
        </EditorSidebarRowWrapper>
      </>
    </EditorSidebarSectionWrapper>
  );
};

export default memo(WidgetEditorSidebarShapeSettings);
