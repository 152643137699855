import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IEditorSidebarSectionTitleWrapper {
  className?: string;
  children: ReactNode;
}

const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin: 0 0 24px;
`;

const EditorSidebarSectionTitleWrapper = ({ className, children }: IEditorSidebarSectionTitleWrapper) => {
  return <SectionTitleWrapper className={className}>{children}</SectionTitleWrapper>;
};

export default EditorSidebarSectionTitleWrapper;
