import { createSlice } from '@reduxjs/toolkit';

interface ISafeAreaState {
  isActive: boolean;
  topSafeArea: string;
  bottomSafeArea: string;
  iframeHeight: number;
}

const initialState = {
  isActive: false,
} as ISafeAreaState;

const safeAreaSlice = createSlice({
  name: 'safeArea',
  initialState,
  reducers: {
    toggleSafeArea(state) {
      state.isActive = !state.isActive;
    },
  },
});

export const { toggleSafeArea } = safeAreaSlice.actions;
export default safeAreaSlice.reducer;
