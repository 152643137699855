import FormButton from './form-button';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledFormWrapper = styled.div<{ customWidth?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : '375px')};
  background-color: var(--shade-900);
  box-shadow: 0px 4px 30px 20px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  align-items: center;
  z-index: 1;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StyledForm = styled.form<{ customPadding?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ customPadding }) => (customPadding ? customPadding : '30px 68px 56px')};
  align-items: left;
  border-radius: 12px;
  @media (max-width: 768px) {
    padding: 10%;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 42px 68px;
  align-items: left;
  border-radius: 12px;
  background: var(--shade-900);
`;

const StyledLabel = styled.label`
  color: var(--white);
  padding-bottom: 4px;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-top: 14px;
`;

const StyledInputError = styled.div`
  display: flex;
  margin-top: 3.7px;
  align-items: flex-start;
`;

const StyledErrorText = styled.span`
  font-family: Heebo;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--warning);
  padding-left: 4px;
  align-self: center;
`;

const StyledFormError = styled.span`
  max-width: 100%;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--white);
  background: var(--warning);
  border-radius: 6px;
  padding: 11px 12px;
`;

const StyledFormErrorLink = styled.a`
  color: var(--white);
  text-decoration: underline;
`;

const StyledFormButton = styled(FormButton)`
  margin-top: 25px;
`;

const SignupText = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--white);
  margin: 15px 0 0;
`;

const SignUpLink = styled(Link)`
  color: var(--primary);
  font: inherit;
  text-decoration: none;
`;

const GoBackButton = styled.button`
  color: var(--primary);
  font: inherit;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  background: transparent;
  border: none;
`;

const TermsContainer = styled.label`
  display: flex;
  flex-direction: row;
  max-width: 237px;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export default {
  TermsContainer,
  SignUpLink,
  SignupText,
  StyledFormButton,
  StyledFormErrorLink,
  StyledFormError,
  StyledErrorText,
  StyledInputError,
  StyledLabel,
  StyledForm,
  StyledFormWrapper,
  StyledWrapper,
  GoBackButton,
};
