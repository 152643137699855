import styled from 'styled-components';
import { SNIPPET_PENDING, SNIPPET_LIVE, SNIPPET_REJECTED } from '../../../config/constants';
import WarningIcon from '../../icons/warning';
import CheckCircle from '../../icons/check-circle';

type ISnippetStatusProps = {
  status?: string;
  canExpand: boolean;
};

interface IExplanationText {
  [key: string]: string;
  Live: string;
  Rejected: string;
  Pending: string;
}

const handleColorType = (status?: string) => {
  switch (status) {
    case SNIPPET_LIVE:
      return 'var(--success)';
    case SNIPPET_PENDING:
      return 'var(--shade-100)';
    default:
      return 'var(--warning)';
  }
};

const StyledSettingsSnippetStatus = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PendingText = styled.div<{ status?: string }>`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.01em;
  display: none;
  margin-left: 5.5px;
  transition: display 1s ease;
  color: ${({ status }) => handleColorType(status)};
`;

const IconWrapper = styled.div<{ status?: string }>`
  line-height: 0;
  svg > * {
    fill: ${({ status }) => handleColorType(status)};
  }
  &:hover ~ ${PendingText} {
    display: block;
    transition: display 1s ease;
  }
`;

const SettingsSnippetStatus = ({ status, canExpand }: ISnippetStatusProps): JSX.Element => {
  const explanationText: IExplanationText = {
    Rejected: '',
    Live: 'Your snippet is live',
    Pending: 'Your snippet is being reviewed by us (usually takes between 12-48 hours)',
  };

  return (
    <StyledSettingsSnippetStatus>
      <IconWrapper status={status}>{status === SNIPPET_LIVE ? <CheckCircle /> : <WarningIcon />}</IconWrapper>
      {canExpand && <PendingText status={status}>{status && explanationText[status]}</PendingText>}
    </StyledSettingsSnippetStatus>
  );
};

export default SettingsSnippetStatus;
