import React from 'react';
import Title from '../shared/title';
import Subtitle from '../shared/subtitle';
import FormStyles from './../shared/form-styles';
import styled from 'styled-components';
import FormButton from '../shared/form-button';
import DPAIdButton from '../shared/dpa-id-button';

const Divider = styled.div`
  margin: 30px 0;
  position: relative;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--shade-300);
  &:before,
  &:after {
    width: 40%;
    height: 1px;
    content: '';
    background: var(--shade-300);
    position: absolute;
  }
  &:before {
    left: 12px;
  }
  &:after {
    right: 12px;
  }
`;

interface Props {
  onLoginClick: VoidFunction;
  onDPAClick: VoidFunction;
}

const LoginWelcome: React.VFC<Props> = ({ onLoginClick, onDPAClick }) => {
  return (
    <FormStyles.StyledFormWrapper>
      <FormStyles.StyledWrapper>
        <Title margin={'0 0 12px 0'} text="Log in" />
        <Subtitle margin={'0 0 28px 0'} text="Welcome back to Zazu. We have missed you." />
        <FormButton text="Login with Email" type="button" onClick={onLoginClick} />
        <Divider>or</Divider>
        <DPAIdButton text="Anmelden mit dpa ID" onClick={onDPAClick} />
        <FormStyles.SignupText>
          New to Zazu? <FormStyles.SignUpLink to={'/sign-up'}>Sign up</FormStyles.SignUpLink>
        </FormStyles.SignupText>
      </FormStyles.StyledWrapper>
    </FormStyles.StyledFormWrapper>
  );
};

export default LoginWelcome;
