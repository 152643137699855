import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IBoxRow {
  className?: string;
  children: ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  margin: 0 -5px;
  min-width: 100%;
  &:not(:last-of-type) {
    margin: 0 -5px 26px;
  }
`;

const BoxRow = ({ className, children }: IBoxRow): JSX.Element => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default BoxRow;
