import { AxiosResponse } from 'axios';
import { batch } from 'react-redux';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { gifsUrls } from '../../../../config/urls';
import { IGif } from '../../../../interfaces/media/gif';
import api from '../../../../utils/api';
import { concatMediaArrays, getPendingMediaContent } from '../../../../utils/mediaUtils';
import { AppThunk } from '../../../store';
import { addMediaName } from '../../media/mediaSlice';
import { addPendingMedia, toggleIsMediaUploading } from '../helpers/helpersSlice';
import { IUploadedMedia } from '../../../../interfaces/media/common';
import { layerTypes } from '../../../../interfaces/layer-types';
import { updateStoryConfig } from '../../amp-story/ampStorySlice';

interface IGifsState {
  gifs: IGif[];
  searchTitle: string;
  isTrendSearch: boolean;
  isGifsActive: boolean;
}

const initialState = {
  gifs: [],
  searchTitle: '',
  isTrendSearch: true,
  isGifsActive: true,
} as IGifsState;

const gifsSlice = createSlice({
  name: 'gifs',
  initialState,
  reducers: {
    addGifs(state, action: PayloadAction<IGif[]>) {
      state.gifs = concatMediaArrays(state.gifs, action.payload);
    },
    resetGifs(state) {
      state.gifs = [];
    },
    toggleGifsActive(state) {
      state.isGifsActive = !state.isGifsActive;
    },
    setIsTrendSearch(state, action: PayloadAction<boolean>) {
      state.isTrendSearch = action.payload;
    },
    setSearchTitle(state, action: PayloadAction<string>) {
      state.searchTitle = action.payload;
    },
  },
});

export const { addGifs, resetGifs, setSearchTitle, toggleGifsActive, setIsTrendSearch } = gifsSlice.actions;
export default gifsSlice.reducer;

export const loadGifs = (title = '', offset: number, searchType: string): AppThunk => async (dispatch) => {
  try {
    const gifsResponse: AxiosResponse<IGif[]> = await api.get(gifsUrls.getGifs(searchType, offset, title));

    batch(() => {
      dispatch(setSearchTitle(title));
      dispatch(setIsTrendSearch(!title));
      dispatch(addGifs(gifsResponse?.data));
    });
  } catch (err) {
    // TODO !!!
    console.error(err);
  }
};

export const selectGif = (gif: IGif, temporaryGif: any): AppThunk => async (dispatch, getState) => {
  batch(() => {
    dispatch(toggleIsMediaUploading(true));
  });

  const storyId = getState().ampStory.present._id;
  try {
    const uploadGifResponse: AxiosResponse<IUploadedMedia> = await api.post(gifsUrls.uploadGif, {
      fileName: `${gif.slug}.webp`,
      url: `${gif.images.original.webp}/download`,
    });

    const pendingMedia = getPendingMediaContent(layerTypes.IMAGE, uploadGifResponse.data);

    batch(() => {
      dispatch(addPendingMedia({ storyId, temporaryId: temporaryGif.id, pendingMedia }));
      dispatch(toggleIsMediaUploading(false));
      dispatch(addMediaName(temporaryGif.name));
    });
    dispatch(updateStoryConfig());
  } catch (err) {
    // TODO !!!
    console.error(err);
    return;
  }
};
