import DashboardLayout from '../components/layouts';
import SettingsWrapper from '../components/settings/shared/settings-wrapper';
import SettingsTabs from '../components/settings/settings-tabs';
import { FontSettings } from '../components/settings/font-settings';
import ColorSettings from '../components/settings/color-settings/color-settings';

const SettingsBrandingPage = (): JSX.Element => {
  return (
    <DashboardLayout>
      <SettingsWrapper>
        <SettingsTabs />

        <FontSettings />

        <ColorSettings />
      </SettingsWrapper>
    </DashboardLayout>
  );
};

export default SettingsBrandingPage;
