import DashboardLayout from 'components/layouts';
import React, { useCallback, useState } from 'react';
import ConversionTable from 'components/story-conversion/conversion-table';
import ConversionModal from 'components/story-conversion/conversion-modal';
import styled from 'styled-components';
import { Article } from 'redux/services/articles/interface';
import { Redirect } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useArticlesListQuery } from 'redux/services/articles/articles';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import ExportModal from 'components/export-modal/export-modal';
import SocialMediaSuccessModal from 'components/export-modal/social-media-success-modal';
import SuccessfullyPublishedModal from 'components/export-modal/successfully-published-modal';
import { Times } from 'components/icons';
import { BackButton } from 'components/export-modal/shared/elements';
import { setIsExportModalOpen } from 'redux/features/export/exportSlice';

const StyledTimesIcon = styled(Times)`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const EmptyImage = styled.img`
  margin-bottom: 42px;
`;

const EmptyText = styled.div`
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white);
  white-space: pre;
`;

const EmptyWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  margin: auto;
`;

const StyledTab = styled.div<{ isActive: boolean }>`
  padding: 2px 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 21px;
  font-family: Heebo;
  font-style: normal;
  text-decoration: none;
  letter-spacing: 0.01em;
  color: var(--shade-100-85);
  ${({ isActive }) => isActive && `color: var(--white);`}
`;

const Container = styled.div`
  padding: 0 92px 126px;
  background: var(--shade-500-85);
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
`;

const Row = styled.div<{ justifyContent?: 'space-between' | 'flex-start' | 'center' }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
`;

const Title = styled.h2`
  padding: 0;
  margin: 0;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const Disclaimer = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const READY_TO_CONVERT = 'Ready-to-convert';
const CONVERTED_TAB = 'Converted';

const TABS = [READY_TO_CONVERT, CONVERTED_TAB];

const ConversionPage: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isExportModalOpen = useAppSelector((state) => state.export.isExportModalOpen);
  const isPublishedModalOpen = useAppSelector((state) => state.export.isPublishedModalOpen);
  const isSocialMediaSuccessModalOpen = useAppSelector((state) => state.export.isSocialMediaSuccessModalOpen);

  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [selectedArticle, setSelectedArticle] = useState<string | null>(null);

  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const { isStoryConversionEnabled, isSuccess } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace, isSuccess }) => ({
      isStoryConversionEnabled: workspace?.isStoryConversionEnabled,
      isSuccess,
    }),
  });

  const { readyToConvert, convertedArticles, isLoading } = useArticlesListQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: articles, isLoading }) => {
      const readyToConvert: Article[] = [];
      const convertedArticles: Article[] = [];
      const hasArticles = !!articles?.length;

      articles?.forEach((article) => {
        if (article.convertedAt !== null) {
          convertedArticles.push(article);
          return;
        }
        readyToConvert.push(article);
      });

      readyToConvert?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt));
      convertedArticles?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt));

      return {
        readyToConvert,
        convertedArticles,
        hasArticles,
        isLoading,
      };
    },
  });

  const onConvertStory = useCallback((id: string) => {
    setSelectedArticle(id);
  }, []);

  const onClose = useCallback(() => {
    setSelectedArticle(null);
  }, []);

  const onExportModalClose = useCallback(() => {
    dispatch(setIsExportModalOpen(false));
  }, [dispatch]);

  if (isSuccess && !isStoryConversionEnabled) {
    return <Redirect to="/" />;
  }

  if (!isLoading && !readyToConvert.length && !convertedArticles.length) {
    return (
      <DashboardLayout>
        <Container>
          <Row>
            <Title>Story Conversion</Title>
          </Row>
          <EmptyWrapper>
            <EmptyImage src={'/assets/images/empty-conversion.svg'} />
            <EmptyText>{`Articles ready to be converted into\nStories will appear on this page.`}</EmptyText>
          </EmptyWrapper>
        </Container>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Container>
        <Row>
          <Title>Story Conversion</Title>
        </Row>
        <Row justifyContent="flex-start">
          {TABS.map((tab) => (
            <StyledTab key={tab} isActive={tab === activeTab} onClick={() => setActiveTab(tab)}>
              {tab}
            </StyledTab>
          ))}
        </Row>
        {activeTab === CONVERTED_TAB && (
          <Row>
            <Disclaimer>All converted articles are stored during 30 days after conversion date.</Disclaimer>
          </Row>
        )}
        <Row>
          <ConversionTable
            isLoading={isLoading}
            cells={activeTab === TABS[0] ? readyToConvert : convertedArticles}
            onConvertStory={onConvertStory}
          />
        </Row>
      </Container>
      {selectedArticle !== null && <ConversionModal onClose={onClose} selectedArticle={selectedArticle} />}
      {isExportModalOpen && (
        <ExportModal
          conversion
          customBackButton={<BackButton icon={<StyledTimesIcon />} onClick={onExportModalClose} />}
        />
      )}
      {isPublishedModalOpen && <SuccessfullyPublishedModal />}
      {isSocialMediaSuccessModalOpen && <SocialMediaSuccessModal />}
    </DashboardLayout>
  );
};

export default ConversionPage;
