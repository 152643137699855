import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { environment } from 'config/environment';
import { RootState } from './rootReducer';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { refreshTokens } from './features/auth/authSlice';
import { RefreshResponse } from './services/auth/interface';
import { authApi } from './services/auth/auth';
import { Mutex } from 'async-mutex';

// create a new mutex
const mutex = new Mutex();

const prepareHeaders = (headers: Headers, { getState }: Pick<BaseQueryApi, 'getState'>): Headers => {
  const token = (getState() as RootState).auth?.accessToken?.token;

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};

const baseQuery = fetchBaseQuery({ baseUrl: `${environment.host}`, prepareHeaders });
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const state = api.getState() as RootState;
        // try to get a new token
        let refreshToken: string | null | undefined = state?.auth?.refreshToken?.token;
        if (!refreshToken) {
          refreshToken = localStorage.getItem('refreshToken');
        }

        const refreshResult = await baseQuery(
          {
            url: '/user/token/refresh',
            method: 'POST',
            body: { token: refreshToken },
          },
          api,
          extraOptions,
        );
        if (refreshResult?.data) {
          // store the new token
          api.dispatch(refreshTokens(refreshResult?.data as RefreshResponse));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(
            authApi.endpoints.logout.initiate({
              userId: state.auth.user?._id ?? '',
              token: refreshToken ?? '',
            }),
          );
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export { baseQueryWithReauth, mutex };
