import { memo } from 'react';

const CompletionRate = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#2D2923"
      />
      <path
        d="M12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4ZM15.36 10.64L11.52 14.48C11.2 14.8 10.72 14.8 10.4 14.48L8.64 12.72C8.32 12.4 8.32 11.92 8.64 11.6C8.96 11.28 9.44 11.28 9.76 11.6L10.96 12.8L14.24 9.52C14.56 9.2 15.04 9.2 15.36 9.52C15.68 9.84 15.68 10.32 15.36 10.64Z"
        fill="#E6C13B"
      />
    </svg>
  );
};
export default memo(CompletionRate);
