import CtaLayer from './cta-layer';
import TextLayer from './text-layer';
import VideoLayer from './video-layer';
import ImageLayer from './image-layer';
import ShapeLayer from './shape-layer';
import GradientLayer from './gradient-layer';
import OutlinkLayer from './outlink-layer';
import TextPreviewLayer from './text-preview';
import GroupLayer from './group-layer';

export default {
  Cta: CtaLayer,
  Text: TextLayer,
  Video: VideoLayer,
  Image: ImageLayer,
  Shape: ShapeLayer,
  Gradient: GradientLayer,
  Outlink: OutlinkLayer,
  TextPreview: TextPreviewLayer,
  Group: GroupLayer,
};
