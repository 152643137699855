import FormButton from '../shared/form-button';
import styled from 'styled-components';
import SuccessMessage from './success-message';
import TextButton from '../shared/form-text-button';
import Title from '../shared/title';
import LoginStyled from 'components/auth/shared/login-styled';
import zazuLogo1x from 'assets/images/zazu-logo1x.png';
import zazuLogo2x from 'assets/images/zazu-logo2x.png';
import zazuLogo3x from 'assets/images/zazu-logo3x.png';
import zazuBg1x from 'assets/images/auth-backgroundx1.webp';
import zazuBg2x from 'assets/images/auth-backgroundx2.webp';
import zazuBg3x from 'assets/images/auth-backgroundx3.webp';
import { DEFAULT_TOAST_CONFIG, passwordStrength, passwordStrengthStyles } from '../../../config/constants';
import { lazy, Suspense } from 'react';
import { resetPassword } from '../../../redux/features/user/userSlice';
import { RootState } from '../../../redux/rootReducer';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

const StyledPageContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  & * {
    box-sizing: border-box;
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 367px;
  background-color: var(--shade-900);
  box-shadow: 0px 4px 30px 20px rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 12px;
  align-items: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 34px 36px 69px;
  align-items: left;
`;

const StyledFormInner = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 35px;
`;

const StyledInformation = styled.p`
  margin: -5px 0 22px;

  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--shade-100);
`;

const StyledTextButton = styled(TextButton)`
  align-self: flex-start;
  line-height: 18px;
  padding-top: 15px;
  padding-bottom: 4px;
`;

const StyledInput = styled.input`
  border-radius: 6px;
  width: 100%;
  margin-top: 3px;
  background-color: var(--shade-700);
  outline: none;
  border: 1px solid transparent;
  color: var(--white);
  align-items: center;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 7px 6.61px;
  &:focus {
    border-color: var(--primary);
  }
  &::placeholder {
    font-family: Heebo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--shade-300);
  }
`;

const StyledCodeError = styled.span`
  max-width: 100%;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--white);
  background: var(--warning);
  border-radius: 6px;
  padding: 5.5px 6px;
  margin: -6px 0 16px;
`;

const StyledFormButton = styled(FormButton)`
  margin-top: 23px;
`;

type ILocationState = {
  data: string;
  token: string;
};

type IPassword = { password: string };

const PasswordStrengthBar = lazy(() => import('react-password-strength-bar'));

const ForgotPasswordConfirm = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const {
    handleSubmit,
    register,
    errors,
    formState: { isValid },
    getValues,
    watch,
  } = useForm({ mode: 'all' });
  const inputValues = getValues();

  const onPasswordReset = ({ password }: IPassword) => {
    const { data, token } = location?.state as ILocationState;

    dispatch(resetPassword({ password, apiKey: data, token: token }, history));
    toast.info(<SuccessMessage />, DEFAULT_TOAST_CONFIG);
  };

  return (
    <StyledPageContainer>
      <LoginStyled.AuthBackgroundContainer>
        <LoginStyled.AuthBackground srcSet={`${zazuBg3x} 3x, ${zazuBg2x} 2x, ${zazuBg1x} 1x`} />
        <LoginStyled.Overlay />
      </LoginStyled.AuthBackgroundContainer>
      <LoginStyled.LogoLink href={'//zazuapp.co'} target={'_blank'} rel="noopener noreferrer">
        <LoginStyled.Logo srcSet={`${zazuLogo3x} 3x,${zazuLogo2x} 2x, ${zazuLogo1x} 1x`} />
      </LoginStyled.LogoLink>
      <StyledFormWrapper>
        <StyledForm onSubmit={handleSubmit(onPasswordReset)}>
          <Title text="Change your password" />
          <StyledFormInner>
            <StyledInformation>Please enter your new password for {user.tempEmail}</StyledInformation>
            {errors.password_repeat && (
              <StyledCodeError className="error">{errors.password_repeat.message}</StyledCodeError>
            )}
            <StyledInput
              placeholder="Enter a new password"
              type="password"
              name="password"
              autoComplete="off"
              ref={register({
                required: true,
                minLength: 6,
              })}
            />
            <Suspense fallback={<></>}>
              <PasswordStrengthBar
                password={inputValues.password}
                minLength={6}
                scoreWords={passwordStrength}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                shortScoreWord="Password should be 6 or more characters"
                scoreWordStyle={passwordStrengthStyles}
              />
            </Suspense>
            <StyledInput
              placeholder="Confirm your password"
              type="password"
              name="password_repeat"
              autoComplete="off"
              ref={register({
                validate: (value) => value === watch('password') || 'The passwords do not match',
              })}
            />
            <StyledFormButton type="submit" text="Set new password" disabled={!isValid} />
            <StyledTextButton
              color="var(--primary)"
              onClick={() => history.push('/login')}
              text="Go Back"
              name="back-button"
            />
          </StyledFormInner>
        </StyledForm>
      </StyledFormWrapper>
    </StyledPageContainer>
  );
};

export default ForgotPasswordConfirm;
