import { memo } from 'react';

interface ILockSM {
  className?: string;
}

const LockSM = ({ className }: ILockSM): JSX.Element => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 11.0005C10.6589 11 10.8254 11 11 11H13C13.1746 11 13.3411 11 13.5 11.0005V9C13.5 8.17157 12.8284 7.5 12 7.5C11.1716 7.5 10.5 8.17157 10.5 9V11.0005ZM9 11.0507C8.34994 11.1147 7.91238 11.2592 7.58579 11.5858C7 12.1716 7 13.1144 7 15C7 16.8856 7 17.8284 7.58579 18.4142C8.17157 19 9.11438 19 11 19H13C14.8856 19 15.8284 19 16.4142 18.4142C17 17.8284 17 16.8856 17 15C17 13.1144 17 12.1716 16.4142 11.5858C16.0876 11.2592 15.6501 11.1147 15 11.0507V9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9V11.0507Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(LockSM);
