import FoldersSection from '../folders/folders-section';
import StoriesSection from '../stories/shared/elements/stories-section/stories-section';
import TeamTitle from './shared/team-title';
import { EmptyStoryList } from '../stories/shared/elements';
import { memo } from 'react';
import { StoriesWrapper } from '../stories/shared/structure';
import { useAppSelector } from '../../hooks';
import { useFoldersListQuery } from 'redux/services/folders/folders';
import { useStoryListQuery } from 'redux/services/stories/stories';

const STORIES_LIMIT = 6;
const POLLING = {
  refetchOnFocus: true,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: 30,
  pollingInterval: 30000,
};

const Teams: React.FC = () => {
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const selectedTeamId = useAppSelector((state) => state.auth.user?.selectedTeamId);

  const { data: recentStories, isLoading: areStoriesLoading } = useStoryListQuery(
    { limit: STORIES_LIMIT, workspaceId: selectedWorkspaceId ?? '', teamId: selectedTeamId },
    {
      skip: !selectedWorkspaceId || !selectedTeamId,
      ...POLLING,
    },
  );

  const { data: rootFolders, isLoading: areFoldersLoading } = useFoldersListQuery(
    {
      workspaceId: selectedWorkspaceId ?? '',
      folderId: undefined,
      teamId: selectedTeamId,
    },
    {
      skip: !selectedWorkspaceId || !selectedTeamId,
      ...POLLING,
    },
  );

  return (
    <StoriesWrapper>
      <TeamTitle />
      {recentStories?.stories?.length === 0 && (
        <EmptyStoryList
          text={'Start collaborating.\nCreate a Story and move it \nto the Teams section.'}
          hasButton={false}
        />
      )}

      {recentStories?.stories && recentStories?.stories?.length > 0 && (
        <StoriesSection
          stories={recentStories.stories}
          headerButtonUrl="/teams/all-stories"
          withStoryAuthor
          limitStories
        />
      )}

      <FoldersSection
        isEmptyState={!recentStories?.stories?.length}
        customPadding={'78px 10% 36px'}
        folders={rootFolders}
        storiesLength={recentStories?.stories?.length}
      />
    </StoriesWrapper>
  );
};

export default memo(Teams);
