import api from '../api';
import { IFont } from 'interfaces/fonts';

export enum TemplateType {
  Global = 'global',
  Team = 'team',
  Personal = 'personal',
}

export interface Template {
  _id: string;
  amp: {
    key: string;
  };
  cover: {
    url: string;
    _id: string;
  };
  title: string;
  posterPortrait3x4Url?: string;
  cuts: [
    {
      cover: {
        url: string;
        _id: string;
      };
    },
  ];
}

export interface TemplateDetails {
  _id: string;
  cuts: any[];
  initialHeight: string | number;
  initialWidth: string | number;
  googleFonts: IFont[];
}

interface TemplatesListResponse {
  templates: Template[];
  totalCount: number;
  hasNext: boolean;
}

interface GetTemplatesRequest {
  type: TemplateType;
  workspaceId: string;
}

interface SaveTemplateRequest {
  type: TemplateType;
  storyId: string;
  selectedSlides: number[];
  title: string;
  teamId?: string | null;
}

export const templatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query<TemplatesListResponse, GetTemplatesRequest>({
      query: ({ type }) => `template/${type}`,
      providesTags: (result: TemplatesListResponse | undefined) => {
        if (result) {
          const templates = [...result.templates.map(({ _id: id }) => ({ type: 'Templates' as const, id }))];
          return [...templates, { type: 'Templates', id: 'LIST' }];
        }

        return [{ type: 'Templates', id: 'LIST' }];
      },
    }),
    getTemplate: builder.mutation<TemplateDetails, string>({
      query: (id) => ({
        url: `template/${id}`,
      }),
    }),
    deleteTemplate: builder.mutation<void, string>({
      query: (id) => ({
        url: `template/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [{ type: 'Templates', id }],
    }),
    deleteGlobalTemplate: builder.mutation<void, string>({
      query: (id) => ({
        url: `template/global/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [{ type: 'Templates', id }],
    }),
    saveTemplate: builder.mutation<Template, SaveTemplateRequest>({
      query: (body) => ({
        url: `template/save/${body.type}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Templates', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetTemplateMutation,
  useDeleteTemplateMutation,
  useDeleteGlobalTemplateMutation,
  useSaveTemplateMutation,
  usePrefetch,
} = templatesApi;
