import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const localLog = (...args: any[]): void => {
  if (window.location.href.includes('localhost')) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    localLog('Tracking page view', location.pathname + location.search);

    (window as any).gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};

export { usePageTracking };
