import { memo } from 'react';

interface ITag {
  color?: string;
  width?: string;
  height?: string;
}

const Tags = ({ color = '#ABABBA', width = '24px', height = '24px' }: ITag): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.36411 17.997C8.85891 17.9985 8.37416 17.7976 8.0181 17.4392L0.557897 9.98089C0.164252 9.58854 -0.0375545 9.04332 0.00578351 8.48923L0.481744 2.23892C0.549729 1.29812 1.29984 0.550848 2.24089 0.486434L8.4912 0.0104738C8.5407 1.35553e-06 8.58925 1.35756e-06 8.63875 1.35756e-06C9.14294 -0.000602425 9.62648 0.200218 9.98191 0.557828L17.4421 8.01517C17.7993 8.37223 18 8.8566 18 9.36166C18 9.86673 17.7993 10.3511 17.4421 10.7082L10.7101 17.4392C10.3539 17.7974 9.86927 17.9983 9.36411 17.997ZM5.32511 3.4193C4.46346 3.41955 3.70938 3.99848 3.48657 4.83083C3.26376 5.66319 3.62776 6.54142 4.37406 6.9721C5.12036 7.40278 6.06289 7.27853 6.67208 6.66916L6.67874 6.66344L6.68541 6.65678L6.67779 6.66344C7.2189 6.11757 7.37869 5.29987 7.08289 4.59046C6.78709 3.88104 6.09373 3.41907 5.32511 3.4193Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(Tags);
