import styled from 'styled-components';

const MessageWrapper = styled.span`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const Bold = styled.span`
  font-weight: bold;
`;

const SuccessMessage = (): JSX.Element => {
  return (
    <MessageWrapper>
      <Bold>Success.</Bold> Your password has been updated.
    </MessageWrapper>
  );
};

export default SuccessMessage;
