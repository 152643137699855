import { Customizable, Integration, Update, Native } from 'components/icons/tutorial';

const features = [
  {
    icon: {
      component: <Customizable />,
      width: '30px',
      height: '30px',
    },
    textInfo: {
      text: 'Fully customizable',
      maxWidth: '86px',
    },
  },
  {
    icon: {
      component: <Integration />,
      width: '30px',
      height: '30px',
    },

    textInfo: {
      text: 'Fast integration ',
      maxWidth: '70px',
    },
  },
  {
    icon: {
      component: <Update />,
      width: '30px',
      height: '30px',
    },

    textInfo: {
      text: 'Updated live',
      maxWidth: '58px',
    },
  },
  {
    icon: {
      component: <Native />,
      width: '30px',
      height: '30px',
    },
    textInfo: {
      text: 'Native experience',
      maxWidth: '71px',
    },
  },
];

export { features };
