import { ILayer } from '../interfaces/layer';

export const updateLayerAnimations = (layer: ILayer): void => {
  if (layer.settings.animateInDuration === undefined) {
    layer.settings.animateInDuration = (layer.settings as any)?.animateDuration ?? 0;

    if ((layer.settings as any).animateDuration !== undefined) {
      delete (layer.settings as any).animateDuration;
    }
  }

  if (layer.settings.animateInDelay === undefined) {
    layer.settings.animateInDelay = (layer.settings as any)?.animateDelay ?? 0;

    if ((layer.settings as any).animateDelay !== undefined) {
      delete (layer.settings as any).animateDelay;
    }
  }

  if (layer.settings.animateOut === undefined) {
    layer.settings.animateOut = '';
  }
  if (layer.settings.animateOutDelay === undefined) {
    layer.settings.animateOutDelay = (layer.settings.animateInDuration ?? 0) + (layer.settings.animateInDelay ?? 0);
  }
  if (layer.settings.animateOutDuration === undefined) {
    layer.settings.animateOutDuration = 2;
  }
};
