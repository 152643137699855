import { memo } from 'react';

interface ICrop {
  className?: string;
}

const Crop = ({ className }: ICrop): JSX.Element => {
  return (
    <svg
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99563 2L8.81836 22.4546C8.81836 23.178 9.1057 23.8716 9.61716 24.3831C10.1286 24.8946 10.8223 25.1819 11.5456 25.1819H32.0003"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.99551L22.4546 8.81824C23.178 8.81824 23.8716 9.10558 24.3831 9.61704C24.8946 10.1285 25.1819 10.8222 25.1819 11.5455V32.0001"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(Crop);
