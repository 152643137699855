import { memo } from 'react';

interface IChevronDownIcon {
  className?: string;
}

const ChevronDownIcon = ({ className }: IChevronDownIcon): JSX.Element => (
  <svg className={className} width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.91665 0.349164C6.68913 0.122992 6.32168 0.122992 6.09415 0.349164L3.99999 2.41416L1.93499 0.349164C1.70747 0.122992 1.34001 0.122992 1.11249 0.349164C0.883753 0.576034 0.882238 0.945377 1.10911 1.17411C1.11023 1.17525 1.11136 1.17637 1.11249 1.1775L3.58582 3.65083C3.81269 3.87957 4.18203 3.88108 4.41077 3.65421C4.4119 3.65309 4.41303 3.65196 4.41415 3.65083L6.91665 1.1775C7.14539 0.950626 7.1469 0.581283 6.92003 0.352546C6.91891 0.351414 6.91778 0.350287 6.91665 0.349164H6.91665Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ChevronDownIcon);
