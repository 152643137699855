import SectionTitleWrapper from './section-title-wrapper';
import Title from './title';
import TitleLink from './title-link';
import TitleText from './title-text';
import { ReactNode } from 'react';
import Splitter from './splitter';

interface ISection {
  children: ReactNode;
}

const Section = ({ children }: ISection): JSX.Element => {
  return <>{children}</>;
};

Section.Wrapper = SectionTitleWrapper;
Section.Title = Title;
Section.Link = TitleLink;
Section.Text = TitleText;
Section.Splitter = Splitter;

export default Section;
