import LoginForm from 'components/auth/login-form';
import LoginStyled from 'components/auth/shared/login-styled';
import LoginWelcome from 'components/auth/login-welcome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import zazuLogo1x from 'assets/images/zazu-logo1x.png';
import zazuLogo2x from 'assets/images/zazu-logo2x.png';
import zazuLogo3x from 'assets/images/zazu-logo3x.png';
import zazuBg1x from 'assets/images/auth-backgroundx1.webp';
import zazuBg2x from 'assets/images/auth-backgroundx2.webp';
import zazuBg3x from 'assets/images/auth-backgroundx3.webp';
import { DEFAULT_TOAST_CONFIG } from 'config/constants';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isUserAuthenticated } from 'redux/features/auth/authSlice';
import { LoginRequest, VerifyEmailRequest } from 'redux/services/auth/interface';
import { pushCrisp } from 'components/crisp/utils/crispUtils';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector, useQuery, useSpinner } from 'hooks';
import { useClaimTokenMutation } from 'redux/services/dpa/dpa';
import { useLoginMutation, useResendVerificationEmailMutation, useVerifyEmailMutation } from 'redux/services/auth/auth';
import { environment } from '../config/environment';

export enum QueryKeys {
  Code = 'code',
  WorkspaceToken = 'workspaceToken',
  VerificationToken = 'verificationToken',
  Email = 'email',
}

const config = {
  spinnerType: 'SyncLoader' as const,
  size: 20,
};

const LoginPage: React.VFC = () => {
  const { replace } = useHistory();
  const query = useQuery();
  const { Spinner, spinnerProps } = useSpinner(config);

  const dpaCode = query.get(QueryKeys.Code);
  const workspaceToken = query.get(QueryKeys.WorkspaceToken);
  const urlEmail = query.get(QueryKeys.Email);
  const verificationToken = query.get(QueryKeys.VerificationToken);

  const isAuthenticated = useAppSelector(isUserAuthenticated);
  // Toggle dpa id
  const [isEmailLoginSelected, selectEmailLogin] = useState(false);

  const [login, { data, error, reset }] = useLoginMutation();
  const [verifyEmail] = useVerifyEmailMutation();
  const [resendVerification] = useResendVerificationEmailMutation();
  const [claimDPAToken] = useClaimTokenMutation();

  const verificationError = useMemo(() => {
    const parsedErrors = ((error as FetchBaseQueryError)?.data as any)?.errors;

    if (parsedErrors && Array.isArray(parsedErrors)) {
      return parsedErrors?.find((error: any) => error?.path === 'isVerified');
    }

    return null;
  }, [error]);

  const handleSubmit = useCallback(
    async ({ email, password }: LoginRequest) => {
      const { accessToken, refreshToken } = await login({ email, password }).unwrap();

      // Set tokens
      localStorage.setItem('accessToken', accessToken?.token);
      localStorage.setItem('accessToken:exp', accessToken?.exp.toString());
      localStorage.setItem('accessToken:iat', accessToken?.iat.toString());

      localStorage.setItem('refreshToken', refreshToken?.token);
      localStorage.setItem('refreshToken:exp', refreshToken?.exp.toString());
      localStorage.setItem('refreshToken:iat', refreshToken?.iat.toString());

      pushCrisp('do', 'session:reset');
    },
    [login],
  );

  const handleSwitchToLogin = useCallback(() => {
    selectEmailLogin(true);
  }, []);

  const handleLoginWithDPA = useCallback(() => {
    const host = window.location.protocol + '//' + window.location.host;
    window.open(
      `https://sso.dpa-id.de/cas/oidc/authorize?response_type=code&client_id=${environment.dpiClientId}&redirect_uri=${host}/login&scope=profile`,
      '_self',
    );
  }, []);

  const handleVerificationResend = useCallback(async () => {
    const verificationToken = verificationError?.verificationToken;

    if (verificationToken) {
      reset();
      await resendVerification({ verificationToken });
      toast.info(`Verification email has been sent.`, DEFAULT_TOAST_CONFIG);
    }
  }, [resendVerification, reset, verificationError?.verificationToken]);

  const handleVerification = useCallback(
    async (input: VerifyEmailRequest) => {
      try {
        const { email } = await verifyEmail(input).unwrap();
        toast.info(`Your email ${email} has been verified. You can sign-in now.`, DEFAULT_TOAST_CONFIG);
        replace('/login');
      } catch (err) {
        toast.info('Something went wrong. Please try again', DEFAULT_TOAST_CONFIG);
        replace('/login');
      }
    },
    [replace, verifyEmail],
  );

  const handleGoBack = useCallback(() => {
    selectEmailLogin(false);
  }, []);

  useEffect(() => {
    if (urlEmail && verificationToken) {
      handleVerification({
        verificationToken,
        email: urlEmail,
      });
    }
  }, [handleVerification, urlEmail, verificationToken]);

  useEffect(() => {
    const claimTokens = async () => {
      if (dpaCode) {
        try {
          const { accessToken } = await claimDPAToken(dpaCode).unwrap();

          // Set tokens
          localStorage.setItem('accessToken', accessToken?.token);
          localStorage.setItem('accessToken:exp', accessToken?.exp.toString());
          localStorage.setItem('accessToken:iat', accessToken?.iat.toString());
        } catch (err) {
          console.error(err);
          toast.info(`Something went wrong.  Please try again`, DEFAULT_TOAST_CONFIG);
        } finally {
          replace('/login');
        }
      }
    };
    claimTokens();
  }, [claimDPAToken, dpaCode, replace]);

  if (dpaCode) {
    return <Spinner {...spinnerProps} isVisible />;
  }

  if (isAuthenticated && !dpaCode && !workspaceToken && !urlEmail && !verificationToken) {
    return <Redirect to="/" />;
  }

  if (!error && data) {
    return workspaceToken ? <Redirect to={`/workspace/invite/${workspaceToken}`} /> : <Redirect to="/" />;
  }

  return (
    <>
      <LoginStyled.StyledFormWrapper>
        <LoginStyled.AuthBackgroundContainer>
          <LoginStyled.AuthBackground srcSet={`${zazuBg3x} 3x, ${zazuBg2x} 2x, ${zazuBg1x} 1x`} />
          <LoginStyled.Overlay />
        </LoginStyled.AuthBackgroundContainer>
        <LoginStyled.LogoLink href={'//zazuapp.co'} target={'_blank'} rel="noopener noreferrer">
          <LoginStyled.Logo srcSet={`${zazuLogo3x} 3x,${zazuLogo2x} 2x, ${zazuLogo1x} 1x`} />
        </LoginStyled.LogoLink>
        {isEmailLoginSelected && (
          <LoginForm
            onLogin={handleSubmit}
            verificationError={verificationError?.message}
            submitionErrors={!!error}
            onResendVerification={handleVerificationResend}
            onGoBack={handleGoBack}
          />
        )}
        {!isEmailLoginSelected && <LoginWelcome onLoginClick={handleSwitchToLogin} onDPAClick={handleLoginWithDPA} />}
      </LoginStyled.StyledFormWrapper>
    </>
  );
};

export default LoginPage;
