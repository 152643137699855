import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';

import MessageModal from '../message-modal';

interface IProps {
  when?: boolean | undefined;
  onConfirm?: () => void;
  navigate: (path: string) => void;
}

const message = 'You have unsaved changes at the moment. Are you sure you want to leave the editor?';

const EditorSavePrompt = ({ when, navigate, onConfirm }: IProps): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const beforeUnloadListener = (event: any) => {
    event.preventDefault();
    return (event.returnValue = message);
  };

  useEffect(() => {
    if (when) {
      addEventListener('beforeunload', beforeUnloadListener);
    } else {
      window.onbeforeunload = null;
      removeEventListener('beforeunload', beforeUnloadListener);
    }

    return () => {
      window.onbeforeunload = null;
      removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [when]);

  // const closeModal = () => {
  //   setModalVisible(false);
  // };

  // const handleBlockedNavigation = (nextLocation: Location): boolean => {
  //   if (!confirmedNavigation && when) {
  //     setModalVisible(true);
  //     setLastLocation(nextLocation);
  //     return false;
  //   }
  //   return true;
  // };

  // const handleConfirmNavigationClick = () => {
  //   setModalVisible(false);
  //   onConfirm && onConfirm();
  //   setConfirmedNavigation(true);
  // };

  // useEffect(() => {
  //   if (confirmedNavigation && lastLocation) {
  //     navigate(lastLocation.pathname);
  //   }
  // }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      {/* <Prompt when={when} message={message} /> */}

      {/* <MessageModal
        message={message}
        isOpen={modalVisible}
        shouldCloseOnOverlayClick={true}
        cancelButtonText={'Back to editor'}
        acceptButtonText={'Leave without saving'}
        onCancel={closeModal}
        onAccept={handleConfirmNavigationClick}
      /> */}
    </>
  );
};

export default EditorSavePrompt;
