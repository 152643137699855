import { ChangeEvent } from 'react';
import styled from 'styled-components';

import Error from '../../shared/error';
import TextArea from '../../shared/text-area';
import SettingsLabel from './settings-label';

const StyledSettingsTextareaWithLabel = styled.div`
  min-width: 228px;
`;

interface ISettingsTextareaWithLabel {
  name?: string;
  label?: string;
  value: string;
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  error?: string | null;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SettingsTextareaWithLabel = ({
  name,
  label,
  value,
  error,
  className,
  isDisabled,
  isReadOnly,
  placeholder,
  onChange,
}: ISettingsTextareaWithLabel): JSX.Element => {
  return (
    <StyledSettingsTextareaWithLabel>
      {label && <SettingsLabel label={label} />}
      <TextArea
        className={className}
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        minRows={4}
        hasError={Boolean(error)}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      />

      {error && <Error text={error} />}
    </StyledSettingsTextareaWithLabel>
  );
};

export default SettingsTextareaWithLabel;
