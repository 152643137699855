import DashboardLayout from '../components/layouts';
import Teams from '../components/teams/teams';
import { memo, useCallback, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '../hooks';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { useSelectTeamMutation } from 'redux/services/auth/auth';

const TeamsPage = (): JSX.Element => {
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);

  const { teamId } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data, isFetching }) => ({
      teamId: data?.teams?.[0]?._id,
      isFetching,
    }),
  });

  const [selectTeam, { isUninitialized, isLoading, isError, isSuccess }] = useSelectTeamMutation();

  const onTeamDeselect = useCallback(() => {
    selectTeam(null);
  }, [selectTeam]);

  useEffect(() => {
    if (teamId) {
      selectTeam(teamId);
      window.addEventListener('beforeunload', onTeamDeselect);
    }

    return () => {
      window.removeEventListener('beforeunload', onTeamDeselect);
    };
  }, [teamId, selectTeam, onTeamDeselect]);

  const canRenderStories = (teamId && isUninitialized) || (!isLoading && !isUninitialized && !isError && isSuccess);

  return <DashboardLayout withHeaderSearchStory>{canRenderStories && <Teams />}</DashboardLayout>;
};

export default memo(TeamsPage);
