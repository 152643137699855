import { ChangeEvent, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { userUrls } from '../../config/urls';
import api from '../../utils/api';
import { PrimaryButton, SecondaryButton } from '../buttons';
import TextArea from '../shared/text-area';
import getEnhancedBugDescription from '../../utils/getEnhancedBugDescription';
import { toast, Zoom } from 'react-toastify';

type IProps = {
  isOpen: boolean;
  onCancel?: () => void;
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  flex-grow: 10;
  display: flex;
  flex-direction: column;
  margin: 0 0 35px;
`;

const Message = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  letter-spacing: 0.01em;
  margin: 0;
`;

const SubMessage = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 8px 0 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  > * {
    justify-content: center;
  }
`;

const AcceptButton = styled(PrimaryButton)`
  padding: 7px 19px;
  min-width: 105px;
`;

const CancelButton = styled(SecondaryButton)`
  padding: 7px 19px 6px;
  min-width: 105px;
`;

const TextAreaLabel = styled.label`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #ababba;
  margin-bottom: 10px;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const SupportModal = ({ isOpen, onCancel }: IProps): JSX.Element => {
  const customStyles = {
    overlay: {
      backgroundColor: 'var(--shade-500-85)',
      zIndex: 9999,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '24px',
      maxWidth: '390px',
      borderRadius: '12px',
      border: 'none',
      boxShadow: '24px 32px 72px var(--black-18)',
      display: 'flex',
      background: 'var(--shade-900-95)',
    },
  };

  const [description, setDescription] = useState('');
  const [error, setError] = useState('');

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async () => {
    if (!description) {
      return;
    }

    const enhancedDescription = getEnhancedBugDescription(description);
    try {
      await api.post(userUrls.addBugReport, { description: enhancedDescription });

      setDescription('');

      onCancel && onCancel();

      toast.info('Thank you for your feedback. We will take care of your issue.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        transition: Zoom,
      });
    } catch (err) {
      setError(err?.response?.data.message);
    }
  };

  return (
    <Modal
      closeTimeoutMS={300}
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
    >
      <ModalContainer>
        <MessageContainer>
          <Message>Report a bug</Message>

          <SubMessage>
            Having an issue? Sorry for that. Please describe your issue in detail below to help us resolve your problem
            quickly.
          </SubMessage>

          <TextAreaWrapper>
            <TextAreaLabel>Bug description</TextAreaLabel>
            <TextArea
              minRows={13}
              maxRows={13}
              id="description"
              name="description"
              value={description}
              placeholder="Please describe here the issue you are facing"
              onChange={handleDescriptionChange}
            />
          </TextAreaWrapper>
        </MessageContainer>
        <ButtonContainer>
          <CancelButton text="Cancel" onClick={onCancel} />

          <AcceptButton text={'Send report'} onClick={handleSubmit} isDisabled={!description} />
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SupportModal;
