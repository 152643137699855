import styled from 'styled-components';

const IframeContainer = styled.div`
  position: relative;
  background: var(--shade-500);
  padding: 0 0 38px;
`;

const WidgetSlideImageWrap = styled.div<{ styles: any }>`
  position: relative;
  margin-right: ${(props) => `${props.styles.spacing}px`};
  text-align: center;
`;

const WidgetSlideImage = styled.div<{ styles: any; img?: string }>`
  border-radius: ${(props) => props.styles.radius + 'px' || '25px'};
  border: ${(props) => props.styles.border_width + 'px solid ' + props.styles.border_color || '25px'};
  margin: auto;
  cursor: pointer;
  object-fit: cover;
  padding: ${(props) => (props.styles.hasborderpadding == 1 ? '3px' : '0')};
  width: ${(props) => props.styles.width + 'px' || '50px'};
  height: ${(props) => props.styles.height + 'px' || '50px'};
  ${(props) => (props.img != '' ? 'background-image: url(' + props.img + ');' : 'background: #444444;')}
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-clip: content-box;
  box-shadow: ${({ styles }) => `rgb(0, 0, 0) 0 0 ${styles.shadow}px 0;`};
`;

const WidgetSlideTextarea = styled.textarea<{ styles: any; width: number }>`
  resize: none;
  overflow: hidden;
  text-align: ${(props) => props.styles.align || 'center'};
  margin-top: 10px;
  border: 0 none;
  outline: none;
  background: inherit;
  line-height: ${(props) => props.styles.lineheight || 1.5};
  font-family: ${(props) => props.styles.fontfamily || 'Montserrat'};
  color: ${(props) => props.styles.fontcolor || 'white'};
  font-size: ${(props) => props.styles.fontsize + 'px' || '12px'};
  text-shadow: ${(props) =>
    props.styles.hasdescriptionshadow == 1 ? props.styles.fontshadowcolor + ' 2px 2px 6px ' : 'none'};
  ${(props) =>
    props.styles.titlebelow == 0
      ? 'position:absolute; bottom:5px; left:0; display:block;width:100%; padding:0 10px;'
      : `width: ${props.width}px`};
`;

const WidgetTitleTextarea = styled.textarea<{ styles: any }>`
  padding: 0 0 0 1.5rem;
  //align title with 1st slide
  /* padding-left: ${({ styles }) => `${+styles.spacing < 45 ? `${90 + (0 - styles.spacing)}px` : `1.5rem`}`}; */
  width: 91%;
  resize: none;
  overflow: hidden;
  border: 0 none;
  outline: none;
  background: inherit;
  text-align: left;
  font-family: ${(props) => props.styles.titlefontfamily || 'Montserrat'};
  color: ${(props) => props.styles.titlefontcolor || 'white'};
  font-size: ${(props) => props.styles.titlefontsize + 'px' || '12px'};
  text-shadow: ${(props) =>
    props.styles.hastitleshadow == 1 ? props.styles.titlefontshadowcolor + ' 2px 2px 6px ' : 'none'};
  transform: translate(25px, 10px);
`;

export default {
  IframeContainer,
  WidgetSlideImageWrap,
  WidgetSlideImage,
  WidgetSlideTextarea,
  WidgetTitleTextarea,
};
