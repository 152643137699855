import { memo } from 'react';

interface IVisualEffects {
  className?: string;
}

const VisualEffects = ({ className }: IVisualEffects): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C29.9909 23.2805 23.2805 29.9909 15 30ZM14.976 27H15V2.99999C8.37258 2.99337 2.99463 8.36058 2.98799 14.988C2.98137 21.6154 8.34858 26.9934 14.976 27Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(VisualEffects);
