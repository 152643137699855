import styled, { css } from 'styled-components';
import { MouseEventHandler } from 'react';
import { ReactNode } from 'react';

interface IEditorSidebarSectionWrapper {
  className?: string;
  children: ReactNode;
  onMouseEnter?: MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave?: MouseEventHandler<HTMLDivElement> | undefined;
  isDisabled?: boolean;
}

const StyledSidebarSectionWrapper = styled.div<{ isDisabled?: boolean }>`
  display: block;
  padding: 28px 0 19px;
  border-bottom: 1px solid var(--shade-700-85);
  max-width: 260px;
  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 24px;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.45;
      cursor: default;
      * {
        cursor: default;
      }
    `}
`;

const EditorSidebarSectionWrapper = ({ className, children, ...rest }: IEditorSidebarSectionWrapper): JSX.Element => {
  return (
    <StyledSidebarSectionWrapper {...rest} className={className}>
      {children}
    </StyledSidebarSectionWrapper>
  );
};

export default EditorSidebarSectionWrapper;
