import { memo } from 'react';

const ArrowFromBottom = (): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.475 3.22105L5.475 6.16842C5.175 6.46316 5.175 6.90526 5.475 7.2C5.775 7.49474 6.225 7.49474 6.525 7.2L8.25 5.50526L8.25 10.579C8.25 11.0211 8.55 11.3158 9 11.3158C9.45 11.3158 9.75 11.0211 9.75 10.579L9.75 5.50526L11.475 7.2C11.625 7.34737 11.775 7.42105 12 7.42105C12.45 7.42105 12.75 7.12632 12.75 6.68421C12.75 6.46316 12.675 6.31579 12.525 6.16842L9.525 3.22105C9.225 2.92632 8.775 2.92632 8.475 3.22105ZM4.5 14.2632C4.5 14.7053 4.8 15 5.25 15L12.75 15C13.2 15 13.5 14.7053 13.5 14.2632C13.5 13.8211 13.2 13.5263 12.75 13.5263L5.25 13.5263C4.8 13.5263 4.5 13.8211 4.5 14.2632Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ArrowFromBottom);
