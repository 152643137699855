import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsStoryLockedIgnored } from '../../../redux/features/editor/helpers/helpersSlice';
import InaccessibleModal, { InaccessibleModalType } from './inaccessible-modal';

interface IStoryInaccessibleModal {
  isOpen: boolean;
  user: string;
  onClose: () => void;
}

const StoryInaccessibleModal = ({ isOpen, user, onClose }: IStoryInaccessibleModal): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onOpenAnywayHandler = useCallback(() => {
    dispatch(setIsStoryLockedIgnored(true));
    onClose();
  }, [dispatch, onClose]);

  const onCloseClick = useCallback(() => {
    history.push('/teams');
  }, [history]);

  return (
    <InaccessibleModal
      isOpen={isOpen}
      user={user}
      type={InaccessibleModalType.STORY}
      onClose={onCloseClick}
      onAccept={onOpenAnywayHandler}
    />
  );
};

export default memo(StoryInaccessibleModal);
