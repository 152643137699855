import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledTextButton = styled(Link)`
  background: none;
  border: none;
  margin: 0;
  cursor: pointer;
  padding: 2px 0 3px;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--shade-100);
  align-self: flex-end;
  text-decoration: none;
  &:focus {
    border-color: none;
    outline: none;
  }
`;

type IFormTextButton = {
  className?: string;
};

const ForgottenPasswordLink = ({ className }: IFormTextButton): JSX.Element => (
  <StyledTextButton className={className} to={'/forgot-password'}>
    Forgot password?
  </StyledTextButton>
);

export default ForgottenPasswordLink;
