import invitedIcon from '../../assets/images/icons/invited.svg';
import MessageModal from '../message-modal';
import Select from '../../components/shared/select';
import styled, { css } from 'styled-components';
import { IWorkspaceUser } from '../../interfaces/workspaces';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'hooks';
import { USER_ROLE_OPTIONS } from '../../config/constants';

import {
  useRemoveWorkspaceMemberMutation,
  useUpdateWorkspaceMemberRoleMutation,
} from 'redux/services/workspaces/workspaces';

const StyledTableData = styled.td`
  border-bottom: 2px solid var(--shade-500);
  font-weight: normal;
  padding: 16px 10px 16px 0;
  word-break: break-word;
`;

const DateTableData = styled(StyledTableData)`
  padding-right: 17px;
`;

const StyledBorders = styled.div`
  width: 100%;
  height: 100%;
  border: 2.5px solid var(--shade-900);
  margin: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAvatarWrapper = styled.div`
  border-radius: 50%;
  color: var(--shade-900);
  width: 48px;
  height: 48px;
  background: var(--shade-100);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  cursor: default;
  font-size: 28px;
  border: 1.5px solid var(--shade-300);
  backdrop-filter: blur(50px);
`;

const StyledImage = styled.img<{ isInvited?: boolean }>`
  height: 100%;
  width: 100%;
  display: block;
  max-width: 100%;
  object-fit: cover;
  border: 2.5px solid transparent;
  border-radius: 50%;
  padding: ${({ isInvited }) => (isInvited ? '6px' : '0')};
`;

const StyledTableRow = styled.tr<{ pending?: boolean }>`
  color: var(--white);
  ${({ pending }) =>
    pending &&
    css`
      td {
        color: var(--shade-100);
      }

      td img {
        padding: 5px;
      }
    `}
`;

const SelectWrapper = styled.div`
  max-width: fit-content;
  &&&& {
    .select-trigger {
      > div {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: var(--white);
      }
      &:hover:not(.active) {
        background: var(--shade-700);
        border-color: transparent;
      }
      &.active {
        background: var(--shade-700);
      }
    }
    .select-trigger + div {
      margin-left: -16px;
      width: calc(100% + 16px);
      > div > div:not(:empty) {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        padding: 4px 8px;
      }
    }
  }
`;

const Label = styled.h2`
  margin: 0;
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const ContentLabel = styled.span`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type userRoles = {
  [key: string]: { name: string; value: string };
};

const USER_ROLES: userRoles = {
  admin: { name: 'Admin', value: 'admin' },
  user: { name: 'Creator', value: 'user' },
};

interface IWorkspaceMembersTableRowProps {
  user: IWorkspaceUser;
  isDisabled?: boolean;
}

const WorkspaceMembersTableRow = ({ user, isDisabled: isNotAdmin }: IWorkspaceMembersTableRowProps): JSX.Element => {
  const [role, setRole] = useState('');
  const [isRemoveMemberModalOpen, setIsRemoveMemberModalOpen] = useState(false);
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);

  const isDisabled = useMemo(() => user.status === 'PENDING', [user.status]) || isNotAdmin;
  const onCancelRemoveMember = useCallback(() => {
    setIsRemoveMemberModalOpen(false);
  }, []);

  const [removeWorkspaceUser] = useRemoveWorkspaceMemberMutation();
  const [updateMemberRole] = useUpdateWorkspaceMemberRoleMutation();

  useEffect(() => {
    setRole(USER_ROLES[user.role?.name]?.name);
  }, [user.role?.name]);

  const onAcceptRemoveMember = useCallback(
    (user) => () => {
      if (selectedWorkspaceId) {
        removeWorkspaceUser({ id: user._id, workspaceId: selectedWorkspaceId });
        setIsRemoveMemberModalOpen(false);
      }
    },
    [removeWorkspaceUser, selectedWorkspaceId],
  );

  const onSelectHandler = useCallback(
    (user: IWorkspaceUser) => (selectedOption: string) => {
      const userId = user._id;
      if (selectedOption === 'remove') {
        setIsRemoveMemberModalOpen(true);
        return;
      }

      if (selectedWorkspaceId) {
        setRole(USER_ROLES[selectedOption].name);
        updateMemberRole({
          role: selectedOption,
          userId,
          workspaceId: selectedWorkspaceId,
        });
      }
    },
    [selectedWorkspaceId, updateMemberRole],
  );

  return (
    <>
      <StyledTableRow pending={user.status === 'PENDING'}>
        <StyledTableData>
          <UserWrapper>
            <StyledAvatarWrapper>
              <StyledBorders>
                {user.avatar || user.status === 'PENDING' ? (
                  <StyledImage src={user.status === 'PENDING' ? invitedIcon : user.avatar} alt="" />
                ) : (
                  user?.username?.slice(0, 1).toUpperCase()
                )}
              </StyledBorders>
            </StyledAvatarWrapper>
            <ContentLabel>{user?.username || 'Invited'}</ContentLabel>
          </UserWrapper>
        </StyledTableData>
        <StyledTableData>
          <ContentLabel>{user.email}</ContentLabel>
        </StyledTableData>
        <DateTableData>
          <ContentLabel>{new Date(user.addedAt).toDateString().slice(3)}</ContentLabel>
        </DateTableData>
        {user.role?.name === 'owner' ? (
          <StyledTableData>
            <Label>Owner</Label>
          </StyledTableData>
        ) : (
          <StyledTableData>
            <SelectWrapper>
              <Select
                isDisabled={isDisabled}
                onSelect={onSelectHandler(user)}
                selectOption={role}
                options={USER_ROLE_OPTIONS}
                dropdownTriggerStyles={'background: transparent; padding: 10.5px 32px 10.5px 16px; margin-left: -17px;'}
              />
            </SelectWrapper>
          </StyledTableData>
        )}
      </StyledTableRow>

      <MessageModal
        isOpen={isRemoveMemberModalOpen}
        message={'Are you sure you want to remove this member from the Workspace?'}
        shouldCloseOnOverlayClick={true}
        onCancel={onCancelRemoveMember}
        onAccept={onAcceptRemoveMember(user)}
        acceptButtonText={`Remove ${user?.username}`}
      />
    </>
  );
};

export default memo(WorkspaceMembersTableRow);
