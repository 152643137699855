import { useState, useCallback } from 'react';

type IUseToggle = (initialValue: boolean) => IUseToggleResult;

type IUseToggleResult = {
  isShown: boolean;
  toggle: () => void;
  setShow: () => void;
  setHide: () => void;
};

const useToggle: IUseToggle = (initialValue = false): IUseToggleResult => {
  const [isShown, setIsShown] = useState(initialValue);
  const toggle = useCallback(() => {
    setIsShown((isShown) => !isShown);
  }, []);
  const setShow = useCallback(() => {
    setIsShown(true);
  }, []);
  const setHide = useCallback(() => {
    setIsShown(false);
  }, []);
  return { isShown, toggle, setShow, setHide };
};

export default useToggle;
