import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

type SelectedTag = {
  label: string;
  value: string;
  id: string;
};

interface TagsState {
  selectedTags: readonly SelectedTag[];
}

const initialState = {
  selectedTags: [],
} as TagsState;

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setSelectedTags: (state, action: PayloadAction<readonly SelectedTag[]>) => {
      state.selectedTags = castDraft(action.payload);
    },
    clearSelectedTags: () => {
      return initialState;
    },
  },
});

export const { setSelectedTags, clearSelectedTags } = tagsSlice.actions;
export default tagsSlice.reducer;
