import InputBasic from '../shared/input-basic';
import styled from 'styled-components';
import { Dispatch, SetStateAction, SyntheticEvent, useMemo, useState } from 'react';
import { InputLabel } from './shared/elements';
import { PrimaryButton } from '../buttons';
import { useChangePasswordMutation } from 'redux/services/auth/auth';
import { useEffect } from 'react';

const InputWrapper = styled.div`
  margin: 0 0 18px;
`;

const StyledCodeError = styled.p`
  max-width: 100%;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #ffffff;
  background: #e73b64;
  border-radius: 6px;
  padding: 4px 12px;
  margin: 0 0 20px;
`;

const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  max-width: none;
  padding: 7px 12px;
`;

interface IProps {
  setPasswordChanged: Dispatch<SetStateAction<boolean>>;
}

const ChangePasswordForm: React.VFC<IProps> = ({ setPasswordChanged }) => {
  const [changePassword, { isError, isSuccess }] = useChangePasswordMutation();
  const [newPasswords, setNewPasswords] = useState({ password: '', confirmationPassword: '' });
  const [error, setError] = useState('');

  const onFormFieldChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const name = event?.currentTarget?.name;
    const value = event?.currentTarget?.value;

    if (error.length) {
      setError('');
    }

    setNewPasswords((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    const { password, confirmationPassword } = newPasswords;

    if (password.length < 6 || confirmationPassword.length < 6) {
      return setError('Password minimum length is 6 characters');
    }
    if (password !== confirmationPassword) {
      return setError('Passwords do not match');
    }

    changePassword(password);
  };

  useEffect(() => {
    if (isError) {
      setError('Something went wrong');
      return;
    }

    if (isSuccess) {
      setPasswordChanged(true);
    }
  }, [isError, isSuccess, setPasswordChanged]);

  const isButtonEnabled = useMemo(() => {
    const { password, confirmationPassword } = newPasswords;
    return password.length === confirmationPassword.length && password.length > 0 && confirmationPassword.length > 0;
  }, [newPasswords]);

  return (
    <div>
      <form onSubmit={onFormSubmit}>
        <InputLabel text={'New password'} />
        {error && <StyledCodeError>{error}</StyledCodeError>}
        <InputWrapper>
          <InputBasic
            placeholder="Enter your new password"
            type="password"
            name="password"
            value={newPasswords?.password}
            autoComplete="off"
            onChange={onFormFieldChange}
          />
        </InputWrapper>
        <InputWrapper>
          <InputBasic
            placeholder="Confirm your new password"
            type="password"
            name="confirmationPassword"
            value={newPasswords?.confirmationPassword}
            autoComplete="off"
            onChange={onFormFieldChange}
          />
        </InputWrapper>
        <SubmitButton isDisabled={!isButtonEnabled} type="submit" text="Save new password" />
      </form>
    </div>
  );
};

export default ChangePasswordForm;
