import { UserToken } from 'redux/services/auth/interface';

const checkJWTValidity = ():
  | {
      accessToken: UserToken;
      refreshToken?: UserToken;
    }
  | false => {
  const accessToken = localStorage.getItem('accessToken');
  const accessTokenIat = localStorage.getItem('accessToken:iat');
  const accessTokenExp = localStorage.getItem('accessToken:exp');

  const refreshToken = localStorage.getItem('refreshToken');
  const refreshTokenIat = localStorage.getItem('refreshToken:iat');
  const refreshTokenExp = localStorage.getItem('refreshToken:exp');

  if (accessToken) {
    return {
      accessToken: {
        token: accessToken,
        iat: Number(accessTokenIat),
        exp: Number(accessTokenExp),
      },
      ...(refreshToken && {
        refreshToken: {
          token: refreshToken,
          iat: Number(refreshTokenIat),
          exp: Number(refreshTokenExp),
        },
      }),
    };
  }

  return false;
};

export default checkJWTValidity;
