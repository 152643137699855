import checkJWTValidity from 'utils/checkJWTValidity';
import Crisp from './components/crisp/components/crisp';
import React, { useEffect } from 'react';
import Routes from './routes';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import WrappedToastContainer from './components/toast/toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { CRISP_WEBSITE_ID } from './components/crisp/constants';
import { hotjar } from 'react-hotjar';
import { isUserAuthenticated } from 'redux/features/auth/authSlice';
import { loadAllUserFonts, unloadAllUserFonts } from './utils/fontUtils';
import { PENDING_MEDIA_QUEUE } from './config/constants';
import { persistPendingMediaQueue } from './redux/features/editor/helpers/helpersSlice';
import { pushCrisp } from './components/crisp/utils/crispUtils';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useAppSelector, useSpinner } from './hooks';
import { useAuthenticateQuery } from 'redux/services/auth/auth';
import { useDispatch } from 'react-redux';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { useWorkspaceFontsListQuery } from 'redux/services/fonts/fonts';
import { environment } from 'config/environment';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { Spinner, spinnerProps } = useSpinner();
  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const pendingMediaQueue = useAppSelector((state) => state.helpers.pendingMediaQueue);
  const isAppLoading = useAppSelector((state) => state.loader.isLoading);
  const isAuthenticated = useAppSelector(isUserAuthenticated);

  // Exchange token for user
  const credentials = checkJWTValidity();

  // Try to authenticate the user
  const { isLoading: isAuthenticationLoading, data: authenticatedUser } = useAuthenticateQuery(
    credentials || skipToken,
    { skip: isAuthenticated },
  );

  // Fetch current workspace
  useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken);

  // Fetch workspace specific fonts;
  const { data: workspaceFonts } = useWorkspaceFontsListQuery(selectedWorkspaceId ?? skipToken);

  // Exchange the stored token for user
  const stringifiedPendingMediaQueue = localStorage.getItem(PENDING_MEDIA_QUEUE) ?? '{}';

  useEffect(() => {
    if (
      Object.keys(pendingMediaQueue).length === 0 &&
      Object.keys(JSON.parse(stringifiedPendingMediaQueue)).length > 0
    ) {
      dispatch(persistPendingMediaQueue(JSON.parse(stringifiedPendingMediaQueue)));
    }
  }, [dispatch, pendingMediaQueue, stringifiedPendingMediaQueue]);

  useEffect(() => {
    if (workspaceFonts && workspaceFonts.length) {
      unloadAllUserFonts();
      loadAllUserFonts(workspaceFonts);
    }
  }, [workspaceFonts]);

  useEffect(() => {
    if (authenticatedUser) {
      pushCrisp('set', {
        'user:email': [authenticatedUser?.email],
        'user:nickname': [authenticatedUser?.username],
      });
    }
  }, [authenticatedUser]);

  useEffect(() => {
    hotjar.initialize(environment.hotjarId, 6);
  }, []);

  if (isAuthenticationLoading) {
    return <Spinner {...spinnerProps} isVisible={true} />;
  }

  return (
    <>
      <Spinner {...spinnerProps} isVisible={isAppLoading} />
      <Crisp crispWebsiteId={CRISP_WEBSITE_ID} />
      <Router>
        <ScrollToTop />
        <Routes />
      </Router>
      <WrappedToastContainer />
    </>
  );
};

export default App;
