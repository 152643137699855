import styled from 'styled-components';
import { Play } from '../../icons';

const VideoModalButton = styled.button`
  display: flex;
  align-items: center;
  padding: 18px 23px 18px 18px;
  background: var(--shade-300);
  border-radius: 10px;
  cursor: pointer;
  color: var(--white);
  max-width: 194px;
  margin: 17px auto 3px;
  border: none;
  transition: 0.12s ease;
  &:hover {
    background: var(--shade-300-85);
  }
`;

const VideoButtonText = styled.span`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
  margin: 0 14px 0 0;
`;

const VideoButtonIcon = styled(Play)`
  width: 20px;
  height: 24px;
`;

const Splitter = styled.div`
  margin: 38px 0;
  height: 2px;
  background: var(--shade-700-85);
`;

const SecondaryTitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--white);
  margin: 0 0 44px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -5px;
  min-width: 100%;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

const Column = styled.div<{ flexBasis: string }>`
  display: flex;
  justify-content: center;
  flex: ${({ flexBasis }) => `0 0 ${flexBasis}`};
  margin: 0 5px;
  @media screen and (max-width: 767px) {
    flex: 0 0 calc(50% - 10px);
  }
`;

export default {
  SecondaryTitle,
  Splitter,
  VideoModalButton,
  VideoButtonIcon,
  VideoButtonText,
  Row,
  Column,
};
