import OutsideClickHandler from 'react-outside-click-handler';
import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import SettingsInputWithLabel from 'components/settings/shared/settings-input-with-label';
import styled from 'styled-components';
import { ModalBodyWrapper, ModalExportOuterWrapper, ModalHeaderWrapper, ModalWrapper } from 'components/shared/modal';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { toggleCreateWidgetModal } from 'redux/features/widget/widgetSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useCreateWidgetMutation, useGetPresetsQuery } from 'redux/services/widgets/widgets';
import { useHistory } from 'react-router';
import { useTagsListQuery } from 'redux/services/tags/tags';

const OverrideOuterWrapper = styled(ModalExportOuterWrapper)`
  > * {
    max-width: 560px;
    align-items: center;
    justify-content: center;
  }
`;

const OverrideModalHeader = styled(ModalHeaderWrapper)`
  &&& {
    padding: 21px 24px 18px;
  }
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 21px;
`;

const Row = styled.div<{ margin?: string; gap?: string; justify?: 'space-between'; maxHeight?: string }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-y: auto;

  &&& {
    > button {
      padding: 7px 24px;
    }
  }

  gap: ${({ gap }) => (gap ? gap : '8px')};

  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ justify }) => justify && `justify-content: ${justify};`}

  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    transition: background 0.12s ease;
  }
  &:hover {
    scrollbar-width: thin;
    scrollbar-color: var(--shade-300-85) transparent;
  }
  &:hover::-webkit-scrollbar {
    width: 3px;
    border-radius: 2px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: var(--shade-300-85);
  }
`;

const Label = styled.p`
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0;
`;

const Pill = styled.div<{ isActive?: boolean }>`
  background: rgba(80, 80, 98, 0.85);
  background-color: var(--shade-300-85);
  border-radius: 4px;
  padding: 1px 8px;
  cursor: pointer;
  transition: background-color 225ms ease, color 225ms ease;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--white);

  ${({ isActive }) =>
    isActive &&
    `
  background-color: var(--primary);
  color: var(--shade-900);
  `}
`;

const Divider = styled.div`
  width: 46px;
  height: 1px;
  background-color: var(--white-10);
  margin: 10px 0;
`;

const LayoutContainer = styled.div<{ isSelected?: boolean }>`
  background-color: var(--shade-700);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 6px;
  padding: 0 0 0 16px;
  width: 160px;
  height: 101px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 225ms ease;

  ${({ isSelected }) => isSelected && `border-color: var(--primary);`}
`;

const PresetTitle = styled.h4`
  margin: 0 0 8px 0;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--shade-100-85);
  text-transform: capitalize;
`;

const PresetRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

const PresetImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const CreateWidgetModal: React.VFC = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const closeModal = useCallback(() => {
    dispatch(toggleCreateWidgetModal(false));
  }, [dispatch]);

  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);

  const { data: presets } = useGetPresetsQuery(selectedWorkspaceId ?? skipToken);
  const { data: workspaceTags } = useTagsListQuery(selectedWorkspaceId ?? skipToken);
  const [createWidget, { isLoading: isNewWidgetLoading, isSuccess, data: newWidget }] = useCreateWidgetMutation();

  const [name, setName] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<string>('');

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleTagsChange = useCallback((tag: string, clearTags?: boolean) => {
    if (clearTags) {
      setTags([]);
      return;
    }
    setTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      }
      return [...prev, tag];
    });
  }, []);

  const handlePresetChange = useCallback((preset: string) => setSelectedPreset(preset), []);

  const handleCreateWidget = useCallback(() => {
    createWidget({
      name,
      tags,
      preset: selectedPreset,
    });
  }, [createWidget, name, selectedPreset, tags]);

  const isFormReadyForSubmit = name !== '' && selectedPreset !== '';

  useEffect(() => {
    if (!isNewWidgetLoading && isSuccess && newWidget?._id) {
      closeModal();
      push(`/carousel/${newWidget?._id}`);
    }
  }, [push, closeModal, isSuccess, isNewWidgetLoading, newWidget?._id]);

  return (
    <OverrideOuterWrapper>
      <OutsideClickHandler display="flex" onOutsideClick={closeModal}>
        <ModalWrapper>
          <OverrideModalHeader>
            <ModalTitle>Create a new Carousel</ModalTitle>
          </OverrideModalHeader>
          <ModalBodyWrapper>
            <BodyContainer>
              <Row margin="0 0 18px 0">
                <SettingsInputWithLabel
                  value={name}
                  onChange={handleNameChange}
                  label="Set your Carousel name"
                  placeholder="e.g. Main page Carousel"
                />
              </Row>
              <Row margin="0 0 6px 0">
                <Label>Decide which Stories to display</Label>
              </Row>
              <Row>
                <Pill onClick={() => handleTagsChange('', true)} isActive={!tags?.length}>
                  All Stories
                </Pill>
              </Row>
              <Divider />
              <Row margin="0 0 18px 0" maxHeight="76px">
                {workspaceTags?.map((wsTag) => (
                  <Pill isActive={tags.includes(wsTag._id)} key={wsTag._id} onClick={() => handleTagsChange(wsTag._id)}>
                    {wsTag.name}
                  </Pill>
                ))}
              </Row>
              <Row margin="0 0 6px 0">
                <Label>Select a preset layout</Label>
              </Row>
              <Row gap="16px" margin="0 0 20px 0">
                {presets?.map((preset) => (
                  <LayoutContainer
                    key={preset._id}
                    isSelected={selectedPreset === preset.name}
                    onClick={() => handlePresetChange(preset.name)}
                  >
                    <PresetTitle>{preset.name}</PresetTitle>
                    <PresetRow>
                      {preset.placeholders.map((placeholder) => (
                        <PresetImage key={placeholder} src={placeholder} alt="Preset-placeholder" />
                      ))}
                    </PresetRow>
                  </LayoutContainer>
                ))}
              </Row>
              <Row justify="space-between">
                <SecondaryButton text="Cancel" onClick={closeModal} />
                <PrimaryButton
                  text="Create"
                  isDisabled={!isFormReadyForSubmit || isNewWidgetLoading}
                  loader
                  isLoading={isNewWidgetLoading}
                  onClick={handleCreateWidget}
                />
              </Row>
            </BodyContainer>
          </ModalBodyWrapper>
        </ModalWrapper>
      </OutsideClickHandler>
    </OverrideOuterWrapper>
  );
};

export default memo(CreateWidgetModal);
