import { memo } from 'react';

const Bold = (): JSX.Element => {
  return (
    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 4.39999C9.5 2.49999 7.9 0.899994 6 0.899994H1.5C0.9 0.899994 0.5 1.29999 0.5 1.89999V11.9C0.5 12.5 0.9 12.9 1.5 12.9H6C7.9 12.9 9.5 11.3 9.5 9.39999C9.5 8.49999 9.1 7.49999 8.4 6.89999C9.1 6.29999 9.5 5.39999 9.5 4.39999ZM6 10.9H2.5V7.89999H6C6.8 7.89999 7.5 8.59999 7.5 9.39999C7.5 10.2 6.8 10.9 6 10.9ZM6 5.89999H2.5V2.89999H6C6.8 2.89999 7.5 3.59999 7.5 4.39999C7.5 5.19999 6.8 5.89999 6 5.89999Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default memo(Bold);
