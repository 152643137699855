import React from 'react';

import styled from 'styled-components';

const StyledDashboardColumn = styled.div<{ direction?: string; maxWidth?: string }>`
  display: flex;
  flex-grow: 1;
  ${({ direction }) =>
    direction &&
    `
      flex-direction: ${direction};
    `}
  ${({ maxWidth }) =>
    maxWidth &&
    `
      max-width: ${maxWidth};
    `}
`;

interface IDashboardColumnProps {
  direction?: string;
  maxWidth?: string;
}

const DashboardColumn: React.FC<IDashboardColumnProps> = ({ children, direction, maxWidth }) => {
  return (
    <StyledDashboardColumn direction={direction} maxWidth={maxWidth}>
      {children}
    </StyledDashboardColumn>
  );
};

export default DashboardColumn;
