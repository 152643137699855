import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IListItemsWrapper {
  children: ReactNode;
}

const StyledListItemsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 6px 12px 0;
  min-width: 100%;
`;

const ListItemsWrapper: React.FC<IListItemsWrapper> = ({ children }) => {
  return <StyledListItemsWrapper>{children}</StyledListItemsWrapper>;
};

export default ListItemsWrapper;
