import React from 'react';

const ImageIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H13C10.5864 5 8.97746 5.00425 7.78014 5.16522C6.64034 5.31846 6.17737 5.57998 5.87868 5.87868C5.57998 6.17737 5.31846 6.64034 5.16522 7.78014C5.00425 8.97746 5 10.5864 5 13V14.9476C6.6409 13.9324 8.10319 13.5027 9.56883 13.711C11.4846 13.9832 13.2107 15.3273 15.1978 17.2784C17.0587 19.1058 18.6947 19.485 20.0831 19.3155C21.1281 19.1878 22.0899 18.7449 22.9734 18.2306C22.9988 17.3423 23 16.2852 23 15V13C23 10.5864 22.9958 8.97746 22.8348 7.78014C22.6815 6.64034 22.42 6.17737 22.1213 5.87868C21.8226 5.57998 21.3597 5.31846 20.2199 5.16522C19.0225 5.00425 17.4136 5 15 5ZM4.46447 4.46447C3 5.92893 3 8.28595 3 13V15C3 19.714 3 22.0711 4.46447 23.5355C5.92893 25 8.28595 25 13 25H15C19.714 25 22.0711 25 23.5355 23.5355C25 22.0711 25 19.714 25 15V13C25 8.28595 25 5.92893 23.5355 4.46447C22.0711 3 19.714 3 15 3H13C8.28595 3 5.92893 3 4.46447 4.46447ZM14 10.5625C14 8.66402 15.539 7.125 17.4375 7.125C19.336 7.125 20.875 8.66402 20.875 10.5625C20.875 12.461 19.336 14 17.4375 14C15.539 14 14 12.461 14 10.5625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ImageIcon;
