import { memo } from 'react';

interface IText {
  className?: string;
}

const Text = ({ className }: IText): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.997 1.997C0 3.994 0 7.20812 0 13.6364V16.3636C0 22.7919 0 26.006 1.997 28.003C3.994 30 7.20812 30 13.6364 30H16.3636C22.7919 30 26.006 30 28.003 28.003C30 26.006 30 22.7919 30 16.3636V13.6364C30 7.20812 30 3.994 28.003 1.997C26.006 0 22.7919 0 16.3636 0H13.6364C7.20812 0 3.994 0 1.997 1.997ZM19.0909 9.54545H10.9091C10.0909 9.54545 9.54545 10.0909 9.54545 10.9091C9.54545 11.7273 10.0909 12.2727 10.9091 12.2727H13.6364V19.0909C13.6364 19.9091 14.1818 20.4545 15 20.4545C15.8182 20.4545 16.3636 19.9091 16.3636 19.0909V12.2727H19.0909C19.9091 12.2727 20.4545 11.7273 20.4545 10.9091C20.4545 10.0909 19.9091 9.54545 19.0909 9.54545Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Text);
