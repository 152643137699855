import AddFontButton from './add-font-button';
import FontField from './font-field';
import SettingsColumn from '../shared/settings-column';
import SettingsRow from '../shared/settings-row';
import SettingsSectionTitle from '../shared/settings-section-title';
import SettingsTitle from '../shared/settings-title';
import styled from 'styled-components';
import { ChangeEvent, useState, memo, useRef } from 'react';
import { MoveableTooltip } from '../../tooltip';
import { RoleName } from 'redux/services/workspaces/interface';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '../../../hooks';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { useAddWorkspaceFontMutation, useWorkspaceFontsListQuery } from 'redux/services/fonts/fonts';
import FontStylesModule from './font-styles-module';
import { createPortal } from 'react-dom';

const Title = styled(SettingsTitle)`
  margin: 0 0 14px;
`;

const FontSettingsWrapper = styled.div`
  padding: 7px 41px 14px 12px;
  margin-bottom: 28px;
`;

const OverrideSettingsCard = styled.div`
  width: 100%;
  border-radius: 12px;
  background: var(--shade-900-85);
  backdrop-filter: blur(50px);
  box-shadow: 24px 32px 72px var(--black-18);

  z-index: 1;
  position: relative;
`;

const FontSettings: React.FC = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const userId = useAppSelector((state) => state.auth.user?._id);
  const isFontStyleModalOpenState = useAppSelector((state) => state.helpers.isFontStyleModalOpenState);

  const { isWorkspaceAdmin } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace }) => ({
      isWorkspaceAdmin: workspace?.users?.find((u) => u._id === userId)?.role.name !== RoleName.user,
    }),
  });

  const { data: workspaceFonts } = useWorkspaceFontsListQuery(selectedWorkspaceId ?? skipToken);
  const [addWorkspaceFont] = useAddWorkspaceFontMutation();

  const onFontUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    const filesArray = Array.from(files ?? []);

    for await (const file of filesArray) {
      const formData = new FormData();
      formData.append('file', file);
      addWorkspaceFont(formData);
    }
  };

  return (
    <>
      <OverrideSettingsCard ref={containerRef}>
        <FontSettingsWrapper onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          <SettingsColumn>
            <SettingsRow>
              <Title title={'Fonts'} />
            </SettingsRow>
            <SettingsRow gap="42px">
              <SettingsColumn flexBasis={'auto'} minWidth={'286px'} padding="0">
                <SettingsSectionTitle text={'Text styles'} />
                <FontStylesModule parentRef={containerRef} />
              </SettingsColumn>
              <SettingsColumn flexBasis={'auto'} minWidth={'286px'} padding="0">
                <SettingsSectionTitle text={'Brand fonts'} />
                {workspaceFonts?.map((font) => {
                  return <FontField key={font._id} font={font} />;
                })}

                <AddFontButton isDisabled={!isWorkspaceAdmin} onClick={onFontUpload} />
              </SettingsColumn>
            </SettingsRow>
          </SettingsColumn>
        </FontSettingsWrapper>
      </OverrideSettingsCard>

      {!isWorkspaceAdmin &&
        createPortal(
          <MoveableTooltip
            showTooltip={showTooltip}
            text="Contact your Workspace admin in order to modify Workspace settings"
          />,
          document.body,
        )}
    </>
  );
};

export default memo(FontSettings);
