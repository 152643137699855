import api from '../api';

import {
  CreateWidgetRequest,
  PaginatedResponse,
  Preset,
  PublishWidgetRequest,
  UpdateTagsRequest,
  UpdateWidgetMetadataRequest,
  WidgetDetails,
} from './interface';

export const widgetsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    widgetsList: builder.query<PaginatedResponse<WidgetDetails[]>, string>({
      query: () => 'widget/list',
      providesTags: (response: PaginatedResponse<WidgetDetails[]> | undefined) => {
        if (response) {
          const providedTags = [...response?.data?.map(({ _id: id }) => ({ type: 'Widgets' as const, id }))];
          return [...providedTags, { type: 'Widgets', id: 'LIST' }];
        }

        return [{ type: 'Widgets', id: 'LIST' }];
      },
    }),
    getWidget: builder.query<WidgetDetails, string>({
      query: (id) => `widget/${id}`,
      providesTags: (_, __, id) => [{ type: 'Widgets', id }],
    }),
    createWidget: builder.mutation<WidgetDetails, CreateWidgetRequest>({
      query: (body) => ({
        url: 'widget/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Widgets', id: 'LIST' }],
    }),
    deleteWidget: builder.mutation<void, string>({
      query: (id) => ({
        url: `widget/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [{ type: 'Widgets', id }],
    }),
    duplicateWidget: builder.mutation<void, string>({
      query: (widgetId) => ({
        url: 'widget/duplicate',
        method: 'POST',
        body: { widgetId },
      }),
      invalidatesTags: [{ type: 'Widgets', id: 'LIST' }],
    }),
    updateWidgetMetadata: builder.mutation<WidgetDetails, UpdateWidgetMetadataRequest>({
      query: (body) => ({
        url: 'widget/metadata/update',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Widgets', id: result?._id }],
    }),
    updateTags: builder.mutation<WidgetDetails, UpdateTagsRequest>({
      query: (body) => ({
        url: 'widget/tags/update',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (widget) => [{ type: 'Widgets', id: widget?._id }],
    }),
    getPresets: builder.query<Preset[], string>({
      query: () => `widget/presets/list`,
      keepUnusedDataFor: 86400,
      providesTags: (presets: Preset[] | undefined) => {
        if (presets) {
          const tags = [...presets.map(({ _id: id }) => ({ type: 'Presets' as const, id }))];
          return [...tags, { type: 'Presets', id: 'LIST' }];
        }

        return [{ type: 'Presets', id: 'LIST' }];
      },
    }),
    publishWidget: builder.mutation<void, PublishWidgetRequest>({
      query: (body) => ({
        url: 'widget/publisher/data',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Widgets', id }],
    }),
  }),
});

export const {
  useWidgetsListQuery,
  useGetWidgetQuery,
  useCreateWidgetMutation,
  useDeleteWidgetMutation,
  useDuplicateWidgetMutation,
  useUpdateWidgetMetadataMutation,
  useUpdateTagsMutation,
  useGetPresetsQuery,
  usePublishWidgetMutation,
  usePrefetch,
} = widgetsApi;
