import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IFeature {
  textInfo: {
    text: string;
    maxWidth: string;
  };
  icon: {
    component: ReactNode;
    width: string;
    height: string;
  };
  iconBackground?: string;
}

const FeatureWrapper = styled.div.attrs<{ $maxWidth: string }>(({ $maxWidth }) => ({
  style: {
    width: $maxWidth,
  },
}))<any>`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 0 39px;
`;

const FeatureIcon = styled.div<{ $iconBackground?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: ${({ $iconBackground }) => $iconBackground ?? 'var(--shade-700-85)'};
  border-radius: 8px;
  margin: 0 auto 8px;
`;

const FeatureIconWrapper = styled.div.attrs<{ $width: string; $height: string }>(({ $width, $height }) => ({
  style: {
    width: $width,
    height: $height,
  },
}))<any>`
  svg {
    width: 100%;
    height: 100%;
    color: var(--shade-100);
  }
`;

const FeatureText = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white);
  margin: 0;
  min-height: 40px;
`;

const Feature = ({ textInfo, icon, iconBackground }: IFeature): JSX.Element => {
  return (
    <FeatureWrapper $maxWidth={textInfo.maxWidth}>
      <FeatureIcon $iconBackground={iconBackground}>
        <FeatureIconWrapper $width={icon.width} $height={icon.height}>
          {icon.component}
        </FeatureIconWrapper>
      </FeatureIcon>

      <FeatureText>{textInfo.text}</FeatureText>
    </FeatureWrapper>
  );
};

export default Feature;
