import { memo } from 'react';

interface IHome {
  className?: string;
}

const Home = ({ className }: IHome): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6678 24.246V20.6679C10.6678 19.7613 11.4051 19.0247 12.3182 19.019H15.6715C16.5888 19.019 17.3325 19.7572 17.3325 20.6679V24.2356C17.3324 25.0221 17.9715 25.6612 18.7635 25.6669H21.0512C22.1197 25.6696 23.1454 25.2502 23.9019 24.501C24.6583 23.7519 25.0835 22.7348 25.0835 21.674V11.5103C25.0835 10.6535 24.7009 9.84068 24.0388 9.29093L16.267 3.12015C14.9084 2.0408 12.9681 2.07567 11.6497 3.20311L4.04493 9.29093C3.35161 9.82447 2.93722 10.6397 2.91675 11.5103V21.6636C2.91675 23.8745 4.72203 25.6669 6.94896 25.6669H9.18444C9.5658 25.6696 9.93251 25.5212 10.2032 25.2544C10.4738 24.9877 10.626 24.6247 10.626 24.246H10.6678Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Home);
