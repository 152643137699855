import MODAL_COMPONENTS from './constants/widget-editor-modal-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

const WidgetEditorModal = (): JSX.Element | null => {
  const modalType = useSelector((state: RootState) => state.editorModal.modalType);

  if (modalType) {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    return <ModalComponent />;
  }

  return null;
};

export default WidgetEditorModal;
