import { pushCrisp } from '../utils/crispUtils';

interface ICrisp {
  crispWebsiteId: string;
  crispTokenId?: string;
  crispRuntimeConfig?: string | number | boolean | any;
  attributes?: { [key: string]: string[] | number[] };
  configuration?: { [key: string]: string[] | number[] };
  safeMode?: boolean;
}

const Crisp = ({
  crispWebsiteId = '',
  crispTokenId = '',
  crispRuntimeConfig = {},
  safeMode = false,
  configuration = {},
  attributes = {},
}: ICrisp): JSX.Element => {
  if ((window as any)?.$crisp === undefined) {
    (window as any).$crisp = [['safe', safeMode]];
  }

  pushCrisp('set', attributes);
  pushCrisp('config', configuration);

  const scriptUrl = 'https://client.crisp.chat/l.js';
  const scripts = document.querySelector(`script[src='${scriptUrl}']`);
  if (scripts === null) {
    (window as any).CRISP_WEBSITE_ID = crispWebsiteId;
    (window as any).CRISP_TOKEN_ID = crispTokenId;
    (window as any).CRISP_RUNTIME_CONFIG = crispRuntimeConfig;

    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    document.head.appendChild(script);
  }

  return <></>;
};

export default Crisp;
