import Styled from './widget-editor-iframe-styled';
import SlideImage from './slide-image';
import { changeActiveBullet, scaleTextarea } from '../../utils/widgetUtils';
import { ChangeEventHandler, Suspense, lazy, memo, useCallback, useContext, useMemo } from 'react';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';
import { PreviewContext, PreviewContextType } from 'context/widget/is-preview';
// import { Splide, SplideSlide } from 'cutnut-react-splide';
import { useEffect } from 'react';
import { useRef } from 'react';
import { WidgetActiveSlideContext, WidgetActiveSlideContextType } from 'context/widget/active-slide';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';
import type { IWidgetStory } from 'redux/services/widgets/interface';
import '../../css/widget-editor.css';
import 'cutnut-react-splide/splide/dist/css/themes/splide-sea-green.min.css';

const Splide = lazy(() => import('cutnut-react-splide').then((module) => ({ default: module.Splide })));
const SplideSlide = lazy(() => import('cutnut-react-splide').then((module) => ({ default: module.SplideSlide })));

const splideOptions1 = {
  type: 'slide',
  autoWidth: true,
  focus: 'center',
  arrows: true,
  perPage: 1,
};

const WidgetEditorFrame: React.VFC = (): JSX.Element => {
  const { widget, setWidget } = useContext(WidgetContext) as WidgetContextType;
  const { isPreview } = useContext(PreviewContext) as PreviewContextType;
  const { widgetActiveSlide, setWidgetActiveSlide } = useContext(
    WidgetActiveSlideContext,
  ) as WidgetActiveSlideContextType;
  const { setChangesCount } = useContext(ChangesContext) as ChangesContextType;

  const titleRef = useRef<HTMLTextAreaElement | null>(null);
  const itemsRef = useRef<HTMLTextAreaElement[] | null[]>([]);
  const splideRef = useRef<any>(null);

  const widgetTitleRef = useRef<string>(widget?.editorDetails?.title ?? '');
  const storyTitles = useRef<string[]>(widget?.editorDetails?.stories?.map((story) => story.text) ?? []);

  const wstyle = widget.editorDetails.style;
  const hasArrows = wstyle.hasarrows ? 'block' : 'none';
  const hasBullets = wstyle.hasbullets ? 'flex' : 'none';

  const selSlideStyle = !isPreview
    ? wstyle.border_width < 1
      ? 'border: 3px solid white !important;'
      : 'border-color: white !important;'
    : '';

  const styles = useMemo(
    () => `.splide__track {
        padding: ${wstyle.shadow > 30 ? ` 45px 0 ${wstyle.shadow + 5}px 0` : '45px 0 30px 0'};
        }
      .splide__list{
        padding: 0 0 0 55px !important;  
      }
      .splide--slide {
        padding: 0 !important;
      }
    .splide__slide:nth-child(${widgetActiveSlide + 2}) .wslide {
      ${selSlideStyle}
    }
    .splide__slide:last-of-type > div {
        margin-right: 55px
    }
    .splide__arrow { 
      display: ${hasArrows};      
      top: ${+wstyle.height / 2 + 45}px;
    }    
    .splide__arrow svg { fill: ${wstyle.arrowscolor}; display: block; } 
    .splide__arrow:hover svg{ fill: ${wstyle.arrowscolor} } 
    .splide__pagination { 
      display: ${hasBullets};
      top: calc(100% - 1em) !important;
    } 
    .splide__pagination__page { background: ${wstyle.bulletscolor} } 
    .splide__pagination__page.is-active,.splide__pagination__page:hover {
      background: ${wstyle.bulletsactivecolor} !important 
    }`,
    [
      wstyle.shadow,
      widgetActiveSlide,
      selSlideStyle,
      hasArrows,
      wstyle.height,
      wstyle.arrowscolor,
      hasBullets,
      wstyle.bulletscolor,
      wstyle.bulletsactivecolor,
    ],
  );

  const onSlideClick = useCallback(
    (value: number) => () => {
      if (value !== widgetActiveSlide) {
        setWidgetActiveSlide(value);
        changeActiveBullet(value);
      }
    },
    [setWidgetActiveSlide, widgetActiveSlide],
  );

  const onWidgetSlidesTitleChanged: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      setWidget((prev) => ({
        ...prev,
        editorDetails: {
          ...prev.editorDetails,
          title: event?.target.value,
        },
      }));
    },
    [setWidget],
  );

  const onWidgetSlidesTitleBlur = () => {
    if (widget?.editorDetails?.title !== undefined && widgetTitleRef.current !== widget?.editorDetails?.title) {
      setChangesCount((prev) => prev + 1);
      widgetTitleRef.current = widget.editorDetails.title;
    }
  };

  const onWidgetSlideTitleBlur = (storyIndex: number) => () => {
    if (
      widget?.editorDetails?.stories?.[storyIndex]?.title !== undefined &&
      storyTitles.current?.[storyIndex] !== undefined &&
      storyTitles.current?.[storyIndex] !== widget?.editorDetails?.stories?.[storyIndex]?.text
    ) {
      setChangesCount((prev) => prev + 1);
      storyTitles.current[storyIndex] = widget?.editorDetails?.stories?.[storyIndex]?.text;
    }
  };

  const onWidgetSlideTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      setWidget((prev) => {
        const updatedStories = prev.editorDetails.stories.map((story, index) => {
          if (index === widgetActiveSlide) {
            return { ...story, text: event.target.value, isDirty: true };
          }
          return story;
        });

        return {
          ...prev,
          editorDetails: {
            ...prev.editorDetails,
            stories: updatedStories,
          },
        };
      });
    },
    [setWidget, widgetActiveSlide],
  );

  const onMove = useCallback(
    (newIndex: any) => {
      setWidgetActiveSlide(newIndex._i);
    },
    [setWidgetActiveSlide],
  );

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, widget.editorDetails.stories.length);

    itemsRef.current.map((textareaItem: HTMLTextAreaElement | null) => {
      scaleTextarea(textareaItem);
    });
  }, [itemsRef, widget.editorDetails]);

  useEffect(() => {
    scaleTextarea(titleRef.current);
  }, [titleRef, widget.editorDetails]);

  return (
    <Styled.IframeContainer>
      {widget.editorDetails.style.titleenabled ? (
        <Styled.WidgetTitleTextarea
          disabled={isPreview}
          styles={wstyle.titlefontfamily ? { ...wstyle } : { ...wstyle, titlefontfamily: 'Heebo' }}
          value={widget.editorDetails.title}
          onBlur={onWidgetSlidesTitleBlur}
          onChange={onWidgetSlidesTitleChanged}
          ref={titleRef}
        />
      ) : (
        <></>
      )}
      <Suspense fallback={<></>}>
        <Splide ref={splideRef} options={splideOptions1} onMove={onMove}>
          <style
            dangerouslySetInnerHTML={{
              __html: styles,
            }}
          />

          {widget.editorDetails.stories.map((w: IWidgetStory, index: number) => {
            return (
              <SplideSlide key={'ss' + index}>
                <Styled.WidgetSlideImageWrap styles={wstyle} key={index}>
                  <SlideImage
                    styles={wstyle}
                    src={w.image}
                    key={'ssi' + index}
                    onClick={onSlideClick(index)}
                    className="wslide"
                    descriptionValue={w.text}
                    onDescriptionChange={onWidgetSlideTextareaChange}
                    onBlur={onWidgetSlideTitleBlur(index)}
                  />
                  {wstyle.descriptionenabled && wstyle.titlebelow === 1 ? (
                    <Styled.WidgetSlideTextarea
                      width={wstyle.width}
                      ref={(el) => (itemsRef.current[index] = el)}
                      rows={1}
                      disabled={isPreview}
                      key={'sst' + index}
                      styles={wstyle.fontfamily ? { ...wstyle } : { ...wstyle, fontfamily: 'Heebo' }}
                      value={w.text}
                      onChange={onWidgetSlideTextareaChange}
                      onClick={onSlideClick(index)}
                      onBlur={onWidgetSlideTitleBlur(index)}
                    />
                  ) : (
                    <></>
                  )}
                </Styled.WidgetSlideImageWrap>
              </SplideSlide>
            );
          })}
        </Splide>
      </Suspense>
    </Styled.IframeContainer>
  );
};

export default memo(WidgetEditorFrame);
