import { memo } from 'react';

interface IDomains {
  className?: string;
}

const Domains = ({ className }: IDomains): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.476 29.642V24.8141C10.476 23.5816 11.4824 22.5825 12.7238 22.5825H17.262C17.8582 22.5825 18.4299 22.8176 18.8515 23.2361C19.2731 23.6546 19.5099 24.2222 19.5099 24.8141V29.642C19.5061 30.1544 19.7085 30.6471 20.0722 31.0107C20.4358 31.3743 20.9306 31.5788 21.4468 31.5788H24.5429C25.9889 31.5826 27.377 31.0149 28.4008 30.0012C29.4246 28.9874 30 27.6109 30 26.1754V12.4213C30 11.2617 29.4822 10.1618 28.5862 9.41787L18.0537 1.06716C16.2216 -0.397007 13.5965 -0.349732 11.819 1.17944L1.52686 9.41787C0.588538 10.1399 0.0277141 11.2431 0 12.4213V26.1614C0 29.1534 2.44323 31.5788 5.45712 31.5788H8.48256C9.55457 31.5789 10.4258 30.7202 10.4336 29.6561L10.476 29.642Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Domains);
