import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const Pill = styled.div<{ isLeft?: boolean; isRight?: boolean; isActive?: boolean }>`
  width: 78px;
  height: 33px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: min(78px, 20%);
  }

  ${({ isLeft }) => isLeft && 'border-radius: 6px 0 0 6px;'}
  ${({ isRight }) => isRight && 'border-radius: 0 6px 6px 0;'}
    
  border: 1px solid var(--shade-300);

  background-color: ${({ isActive }) => (isActive ? 'var(--shade-100)' : 'var(--shade-700-85)')};
  color: ${({ isActive }) => (isActive ? 'var(--shade-900)' : 'var(--white)')};
  backdrop-filter: blur(25px);
  transition: background-color 225ms ease, color 225ms ease;

  cursor: pointer;
  white-space: nowrap;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  &:hover {
    background-color: ${({ isActive }) => (isActive ? 'var(--shade-100)' : 'var(--shade-500-85)')};
    color: ${({ isActive }) => (isActive ? 'var(--shade-900)' : 'var(--white)')};
  }
`;

interface IPills {
  options: string[];
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
  displayOption?: (option: string) => string;
}

const GroupSelect = ({ options, selectedOption, setSelectedOption, displayOption }: IPills): JSX.Element => {
  return (
    <Container>
      {options?.map((o, i) => (
        <Pill
          key={o}
          isActive={selectedOption === o}
          isLeft={i === 0}
          isRight={i === options?.length - 1}
          onClick={() => setSelectedOption(o)}
        >
          {typeof displayOption === 'function' ? displayOption(o) : o}
        </Pill>
      ))}
    </Container>
  );
};

export default GroupSelect;
