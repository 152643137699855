import Checkbox from '../shared/checkbox';
import ExclamationMark from '../../icons/exclamation-mark';
import FormButton from '../shared/form-button';
import FormInput from '../shared/form-input';
import FormStyles from './../shared/form-styles';
import styled from 'styled-components';
import Title from '../shared/title';
import { ISignUpPayload, setIsSignUpSuccessful, signUp } from '../../../redux/features/user/userSlice';
import { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import {
  COMPANY_MAP,
  COMPANY_SIZE,
  USAGE_MAP,
  ZAZU_USAGE,
  passwordStrength,
  passwordStrengthStyles,
} from '../../../config/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useForm } from 'react-hook-form';
import PillsContainer from 'components/pills/pills';
import GroupSelect from 'components/group-select/group-select';

const StyledLinkContainer = styled.div`
  align-items: start;
  max-width: 211px;
  font-family: Heebo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
`;

const StyledText = styled.p`
  color: var(--white);
  margin-top: 2.2px;
  margin-left: 3px;
  margin-bottom: 0;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--primary);
`;

const SuccessText = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0;
`;

const InputWrapper = styled.div`
  max-width: 312px;
`;

const ButtonWithMargin = styled(FormButton)`
  margin-top: 45px;
`;

const PasswordStrengthBar = lazy(() => import('react-password-strength-bar'));

const SignUp = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const signUpErrors = useAppSelector((state) => state.error?.error);
  const isSignUpSuccessful = useAppSelector((state) => state.user.isSignUpSuccessful);
  const {
    handleSubmit,
    register,
    errors,
    watch,
    formState: { isValid },
  } = useForm({ mode: 'all' });
  const password = watch('password');
  const responseErrors = useMemo(() => {
    if ((signUpErrors as any)?.response?.data?.errors?.length) {
      const errorsArr = (signUpErrors as any)?.response?.data?.errors;
      return errorsArr?.reduce((obj: any, item: any) => ((obj[item.path] = item.message), obj), {});
    }

    if ((signUpErrors as any)?.response?.data?.errors) {
      const errorsObj = (signUpErrors as any)?.response?.data?.errors;
      const errorTypes = Object.keys(errorsObj);

      return errorTypes?.reduce((obj: any, errorType) => {
        if (errorType === 'username') {
          return (obj[errorType] = `Invalid username.`), obj;
        }
        if (errorType === 'email') {
          return (obj[errorType] = 'Email already exist.'), obj;
        }

        return (obj[errorType] = (signUpErrors as any)?.response?.data?.errors?.[errorType]?.message), obj;
      }, {});
    }

    return null;
  }, [signUpErrors]);

  const firstStepValues = useRef<Omit<ISignUpPayload, 'workspaceTitle' | 'usageType' | 'companySize'>>({
    email: '',
    username: '',
    password: '',
    privacyPolicy: false,
  });
  const [step, setStep] = useState(0);
  const [zazuUsageOptions, setZazuUsageOptions] = useState<string[]>([]);
  const [companySize, setCompanySize] = useState<string>('');

  const isSecondStepValid = useMemo(() => zazuUsageOptions.length > 0 && companySize !== '' && isValid, [
    companySize,
    isValid,
    zazuUsageOptions.length,
  ]);

  const displayZazuUsage = (option: string) => USAGE_MAP.get(option) ?? '';
  const displayCompanySize = (option: string) => COMPANY_MAP.get(option) ?? '';

  const onSubmit = ({ username, email, password, privacyPolicy, workspaceTitle }: ISignUpPayload) => {
    if (!step) {
      setStep((prev) => prev + 1);
      firstStepValues.current = {
        username,
        email,
        password,
        privacyPolicy,
      };
      return;
    }

    if (!isSecondStepValid) {
      return;
    }

    dispatch(signUp({ ...firstStepValues.current, workspaceTitle, usageType: zazuUsageOptions, companySize }));
  };

  useEffect(() => {
    return () => {
      dispatch(setIsSignUpSuccessful(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (responseErrors) {
      setStep(0);
      setZazuUsageOptions([]);
      setCompanySize('');
    }
  }, [responseErrors]);

  if (isSignUpSuccessful) {
    return (
      <FormStyles.StyledFormWrapper>
        <FormStyles.StyledForm>
          <Title text="Check your email!" />

          <SuccessText>
            We need to verify it’s really you. It will also help us to send you important notifications.
          </SuccessText>

          <FormStyles.SignupText>
            <FormStyles.SignUpLink to={'/login'}>Go back</FormStyles.SignUpLink>
          </FormStyles.SignupText>
        </FormStyles.StyledForm>
      </FormStyles.StyledFormWrapper>
    );
  }

  if (step === 0) {
    return (
      <FormStyles.StyledFormWrapper>
        <FormStyles.StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Title text="Sign up" />

          <FormStyles.StyledLabel>Name</FormStyles.StyledLabel>
          <FormInput
            type="text"
            name="username"
            placeHolder="Enter your name"
            autoComplete="off"
            register={register}
            defaultValue={firstStepValues.current.username}
          />
          {responseErrors?.username && (
            <FormStyles.StyledInputError>
              <ExclamationMark />
              <FormStyles.StyledErrorText> {responseErrors?.username} </FormStyles.StyledErrorText>
            </FormStyles.StyledInputError>
          )}

          <FormStyles.StyledLabel>Email</FormStyles.StyledLabel>
          <FormInput
            type="email"
            name="email"
            placeHolder="Enter your email"
            autoComplete="off"
            register={register}
            defaultValue={firstStepValues.current.email}
          />
          {errors.email && (
            <FormStyles.StyledInputError>
              <ExclamationMark />
              <FormStyles.StyledErrorText> Please enter a valid email </FormStyles.StyledErrorText>
            </FormStyles.StyledInputError>
          )}
          {responseErrors?.email && (
            <FormStyles.StyledInputError>
              <ExclamationMark />
              <FormStyles.StyledErrorText> {responseErrors?.email} </FormStyles.StyledErrorText>
            </FormStyles.StyledInputError>
          )}

          <FormStyles.StyledLabel>Password</FormStyles.StyledLabel>
          <FormInput
            placeHolder="Enter your password"
            type="password"
            name="password"
            autoComplete="off"
            register={register}
            config={{
              required: true,
              minLength: 6,
            }}
          />
          <Suspense fallback={<></>}>
            <PasswordStrengthBar
              password={password}
              minLength={6}
              scoreWords={passwordStrength}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              shortScoreWord="Password should be 6 or more characters"
              scoreWordStyle={passwordStrengthStyles}
            />
          </Suspense>

          {responseErrors?.password && (
            <FormStyles.StyledInputError>
              <ExclamationMark />
              <FormStyles.StyledErrorText> {responseErrors?.password} </FormStyles.StyledErrorText>
            </FormStyles.StyledInputError>
          )}

          <FormStyles.TermsContainer>
            <Checkbox name="privacyPolicy" innerRef={register({ required: true })} />

            <StyledLinkContainer>
              <StyledText>
                I have read and accepted the{' '}
                <StyledLink href="https://zazuapp.co/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </StyledLink>{' '}
                and the{' '}
                <StyledLink href="https://zazuapp.co/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </StyledLink>
                .
              </StyledText>
            </StyledLinkContainer>
          </FormStyles.TermsContainer>

          {responseErrors?.privacyPolicy && (
            <FormStyles.StyledInputError>
              <ExclamationMark />
              <FormStyles.StyledErrorText> {responseErrors?.privacyPolicy} </FormStyles.StyledErrorText>
            </FormStyles.StyledInputError>
          )}

          <FormButton text="Create new account" type="submit" disabled={!isValid} />

          <FormStyles.SignupText>
            Already have an account? <FormStyles.SignUpLink to={'/login'}>Log in</FormStyles.SignUpLink>
          </FormStyles.SignupText>
        </FormStyles.StyledForm>
      </FormStyles.StyledFormWrapper>
    );
  }

  if (step === 1) {
    return (
      <FormStyles.StyledFormWrapper customWidth={631}>
        <FormStyles.StyledForm onSubmit={handleSubmit(onSubmit)} customPadding="56px 121px 56px 122px">
          <Title text="Set up your workspace" />
          <SuccessText>Your workspace is where you and your team create Stories together.</SuccessText>
          <FormStyles.StyledLabel>Workspace name</FormStyles.StyledLabel>
          <InputWrapper>
            <FormInput
              type="text"
              name="workspaceTitle"
              placeHolder="Enter workspace name"
              autoComplete="off"
              register={register}
            />
          </InputWrapper>
          <FormStyles.StyledLabel>What will you use Zazu for?</FormStyles.StyledLabel>
          <PillsContainer
            options={ZAZU_USAGE}
            selectedOptions={zazuUsageOptions}
            setSelectedOptions={setZazuUsageOptions}
            displayOption={displayZazuUsage}
          />
          <FormStyles.StyledLabel>Company size</FormStyles.StyledLabel>
          <GroupSelect
            options={COMPANY_SIZE}
            selectedOption={companySize}
            setSelectedOption={setCompanySize}
            displayOption={displayCompanySize}
          />
          <ButtonWithMargin text="Next" type="submit" disabled={!isSecondStepValid} />
        </FormStyles.StyledForm>
      </FormStyles.StyledFormWrapper>
    );
  }

  return <></>;
};

export default SignUp;
