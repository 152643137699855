import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import plusImage from '../../../../assets/images/editor-modal/plus.svg';

interface IEditorModalUploadButton {
  isDisabled: boolean;
  acceptedFileTypes: string;
  onClick: (event?: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton = styled.button<{ isDisabled?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${({ isDisabled }) => (isDisabled ? 'var(--primary-20)' : 'var(--primary)')};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: none;
  outline: none;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
`;

const HiddenInput = styled.input`
  display: none;
`;

const EditorModalUploadButton = ({ isDisabled, acceptedFileTypes, onClick }: IEditorModalUploadButton): JSX.Element => {
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onButtonClick = useCallback(() => {
    if (hiddenInputRef.current && !isDisabled) {
      hiddenInputRef.current.click();
    }
  }, [isDisabled]);

  return (
    <>
      <UploadButton isDisabled={isDisabled} type="button" onClick={onButtonClick}>
        <img src={plusImage} alt="Plus" />
      </UploadButton>
      <HiddenInput accept={acceptedFileTypes} type="file" ref={hiddenInputRef} onChange={onClick} />
    </>
  );
};

export default EditorModalUploadButton;
