import React, { useState, useCallback } from 'react';
import * as SpinnersList from 'react-spinners';
import styled from 'styled-components';

type ISpinnerConfig = {
  /** Mount visible */
  startVisible?: boolean;
  /** Loader color */
  color?: string;
  /** Background color */
  backgroundColor?: string;
  /** Disable global overlay */
  disableOverlay?: boolean;
  /** Loader size */
  size?: number;
  /** Margin between loader elements */
  margin?: number;
  /** Spinner type */
  spinnerType?: 'ClipLoader' | 'SyncLoader' | 'DotLoader';
};

type IUseSpinner = (config?: ISpinnerConfig) => IUseSpinnerResult;

type SpinnerProps = {
  isVisible: boolean;
  color?: string;
  backgroundColor?: string;
  disableOverlay?: boolean;
  size?: number;
  margin?: number;
  spinnerType?: string;
  ref?: React.RefObject<HTMLElement>;
};

type IUseSpinnerResult = {
  /** A function you can call to show the spinner */
  show: () => void;
  /** A function you can call to hide the spinner */
  hide: () => void;
  /** The props you should spread onto the Spinner component */
  spinnerProps: SpinnerProps;
  /** The hook returns the UI Spinner component as a nicety so you don't have to mess with importing it */
  Spinner: React.FC<SpinnerProps>;
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 9998;
  height: 100%;
  width: 100%;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;

const defaultConfig = {
  startVisible: false,
  color: '#fff',
  backgroundColor: 'rgba(51,51,51,0.8)',
  disableOverlay: false,
  size: 20,
  margin: 10,
  spinnerType: 'SyncLoader',
};

const useSpinner: IUseSpinner = (config) => {
  const { startVisible, color, backgroundColor, disableOverlay, size, margin, spinnerType } = {
    ...defaultConfig,
    ...config,
  };
  const [isSpinnerActive, setSpinnerActive] = useState(startVisible);

  const showSpinner = useCallback(() => {
    setSpinnerActive(true);
  }, [setSpinnerActive]);

  const hideSpinner = useCallback(() => {
    setSpinnerActive(false);
  }, [setSpinnerActive]);

  return {
    isVisible: isSpinnerActive,
    show: showSpinner,
    hide: hideSpinner,
    spinnerProps: {
      isVisible: isSpinnerActive,
      color,
      backgroundColor,
      disableOverlay,
      size,
      margin,
      spinnerType,
    },
    Spinner,
  } as IUseSpinnerResult;
};

const Spinner: React.FC<SpinnerProps> = ({
  isVisible,
  color,
  backgroundColor,
  disableOverlay,
  size,
  margin,
  spinnerType,
  ref,
}) => {
  const SelectedSpinner = (SpinnersList as any)[spinnerType as string];
  return disableOverlay ? (
    <SelectedSpinner ref={ref} color={color} loading={isVisible} size={size} margin={margin} />
  ) : (
    <Overlay style={{ backgroundColor, display: isVisible ? 'block' : 'none' }}>
      <LoaderContainer>
        <SelectedSpinner ref={ref} color={color} loading={isVisible} size={size} margin={margin} />
      </LoaderContainer>
    </Overlay>
  );
};

export default useSpinner;
