import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ITitleLink {
  className?: string;
  children: ReactNode;
  isActive?: boolean;
  to: string;
}

const StyledLink = styled(Link)<{ $isActive?: boolean }>`
  text-decoration: none;
  color: inherit;
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: var(--white);
    `}
`;

const TitleLink = ({ className, isActive, to, children }: ITitleLink): JSX.Element => {
  return (
    <StyledLink className={className} $isActive={isActive} to={to}>
      {children}
    </StyledLink>
  );
};

TitleLink.defualtProps = {
  isActive: false,
};

export default TitleLink;
