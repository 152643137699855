import { memo } from 'react';

interface IBrand {
  className?: string;
}

const Brand = ({ className }: IBrand): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C23.283 0 30 5.967 30 13.3335C29.9988 15.5433 29.1203 17.6622 27.5576 19.2247C25.9949 20.7871 23.8758 21.6652 21.666 21.666H18.717C17.334 21.666 16.2165 22.7835 16.2165 24.1665C16.2165 24.7995 16.467 25.383 16.8495 25.8165C17.25 26.2665 17.5005 26.85 17.5005 27.4995C17.5005 28.884 16.35 30 15 30C6.717 30 0 23.283 0 15C0 6.717 6.717 0 15 0ZM8.25 15C8.84674 15 9.41903 14.7629 9.84099 14.341C10.2629 13.919 10.5 13.3467 10.5 12.75C10.5 12.1533 10.2629 11.581 9.84099 11.159C9.41903 10.7371 8.84674 10.5 8.25 10.5C7.65326 10.5 7.08097 10.7371 6.65901 11.159C6.23705 11.581 6 12.1533 6 12.75C6 13.3467 6.23705 13.919 6.65901 14.341C7.08097 14.7629 7.65326 15 8.25 15ZM21.75 15C22.3467 15 22.919 14.7629 23.341 14.341C23.7629 13.919 24 13.3467 24 12.75C24 12.1533 23.7629 11.581 23.341 11.159C22.919 10.7371 22.3467 10.5 21.75 10.5C21.1533 10.5 20.581 10.7371 20.159 11.159C19.7371 11.581 19.5 12.1533 19.5 12.75C19.5 13.3467 19.7371 13.919 20.159 14.341C20.581 14.7629 21.1533 15 21.75 15ZM15 10.5C15.5967 10.5 16.169 10.2629 16.591 9.84099C17.0129 9.41903 17.25 8.84674 17.25 8.25C17.25 7.65326 17.0129 7.08097 16.591 6.65901C16.169 6.23705 15.5967 6 15 6C14.4033 6 13.831 6.23705 13.409 6.65901C12.9871 7.08097 12.75 7.65326 12.75 8.25C12.75 8.84674 12.9871 9.41903 13.409 9.84099C13.831 10.2629 14.4033 10.5 15 10.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Brand);
