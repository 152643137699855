import EditorSidebarLabel from '../../shared/elements/editor-sidebar-label';
import EditorSidebarRowWrapper from '../../shared/structure/editor-sidebar-row-wrapper';
import EditorSidebarSectionTitle from '../../shared/elements/editor-sidebar-section-title';
import EditorSidebarSectionTitleWrapper from '../../shared/structure/editor-sidebar-section-title-wrapper';
import EditorSidebarSectionWrapper from '../../shared/structure/editor-sidebar-section-wrapper';
import EditorSidebarSliderWithInput from '../../shared/editor-sidebar-slider-with-input';
import limit from '../../constants/limits';
import produce from 'immer';
import { getArrowEventValue } from '../../../../utils/common';
import { IFieldValue } from '../../../../interfaces/field-value';
import { ILayer } from '../../../../interfaces/layer';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import { memo, useCallback, useEffect, useState } from 'react';
import { ImmerState } from 'immer/dist/internal';
import EditorSiderbarFilters from 'components/editor-sidebar/shared/elements/editor-sidebar-filters';
import { ICssFilter } from 'interfaces/editor-model';

interface IEditorSidebarVideoSettingsProps {
  layer: ILayer;
  parentLayer?: ILayer;
  handleLayerChange: ({ field, value }: IFieldValue) => void;
}

const EditorSidebarVideoSettings = ({
  layer,
  parentLayer,
  handleLayerChange,
}: IEditorSidebarVideoSettingsProps): JSX.Element => {
  const isActiveLayerLocked = layer.settings.generalSettings.locked;
  const permissionForFullScreen = layer.settings.layerSettings.permissionForFullScreen;
  const [currentRadius, setCurrentRadius] = useState(Number(layer.settings.generalSettings.round));

  useEffect(() => setCurrentRadius(Number(layer.settings.generalSettings.round)), [
    layer.settings.generalSettings.round,
  ]);

  const onRadiusChange = useCallback(
    (value: number) => {
      if (isActiveLayerLocked) {
        return;
      }

      if (!isNil(parentLayer?._id) && layer._id !== parentLayer?._id) {
        const updatedLayers = parentLayer?.childLayers?.map((cl) => {
          if (cl._id === layer._id) {
            const nextState = produce(cl, (draftState: ImmerState) => {
              set(draftState, 'settings.generalSettings.round', Number(value));
            });

            return nextState;
          }

          return cl;
        });

        setCurrentRadius(value);
        handleLayerChange({ field: 'childLayers', value: updatedLayers });
        return;
      }

      handleLayerChange({ field: 'settings.generalSettings.round', value: Number(value) });
    },
    [handleLayerChange, isActiveLayerLocked, layer._id, parentLayer?._id, parentLayer?.childLayers],
  );

  const onRadiusInputArrowDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (isActiveLayerLocked) {
        return;
      }
      if (
        (Number(currentRadius) === limit.round && event.key === 'ArrowUp') ||
        (Number(currentRadius) === 0 && event.key === 'ArrowDown')
      ) {
        return;
      }

      const value = getArrowEventValue(event) + Number(currentRadius);

      if (!isNil(parentLayer?._id) && layer._id !== parentLayer?._id) {
        const updatedLayers = parentLayer?.childLayers?.map((cl) => {
          if (cl._id === layer._id) {
            const nextState = produce(cl, (draftState: ImmerState) => {
              set(draftState, 'settings.generalSettings.round', value);
            });

            return nextState;
          }

          return cl;
        });

        setCurrentRadius(value);
        handleLayerChange({ field: 'childLayers', value: updatedLayers });
        return;
      }

      setCurrentRadius(value);
      handleLayerChange({ field: 'settings.generalSettings.round', value });
    },
    [currentRadius, handleLayerChange, isActiveLayerLocked, layer._id, parentLayer?._id, parentLayer?.childLayers],
  );

  const onSelectFilter = useCallback(
    (filter: ICssFilter) => {
      handleLayerChange({ field: 'settings.layerSettings.cssFilter', value: filter });
    },
    [handleLayerChange],
  );

  return (
    <EditorSidebarSectionWrapper>
      <EditorSidebarSectionTitleWrapper>
        <EditorSidebarSectionTitle text={'Video'} />
      </EditorSidebarSectionTitleWrapper>
      <EditorSidebarRowWrapper>
        <EditorSiderbarFilters
          src={layer?.content?.image?.url}
          onSelectFilter={onSelectFilter}
          selectedFilterName={layer?.settings?.layerSettings?.cssFilter?.name ?? 'None'}
        />
      </EditorSidebarRowWrapper>
      <EditorSidebarRowWrapper>
        <EditorSidebarLabel text={'Radius'} />

        <EditorSidebarSliderWithInput
          min={0}
          max={limit.round}
          step={1}
          value={currentRadius}
          sign={'%'}
          isDisabled={permissionForFullScreen || isActiveLayerLocked}
          onChange={onRadiusChange}
          onInputArrowDown={onRadiusInputArrowDown}
        />
      </EditorSidebarRowWrapper>
    </EditorSidebarSectionWrapper>
  );
};

export default memo(EditorSidebarVideoSettings);
