import React, { useState } from 'react';
import EditorModalWrapper from '../../editor-modals/shared/editor-modal-wrapper/editor-modal-wrapper';
import EditorModalLabel from '../../editor-modals/shared/editor-modal-label/editor-modal-label';
import { useDispatch } from 'react-redux';
import { onOutsideClickModal } from '../../../redux/features/editor-modal/editorModalSlice';
import EditorModalLabelsWrapper from '../../editor-modals/shared/editor-modal-labels-wrapper/editor-modal-labels-wrapper';
import OutsideClickHandler from 'react-outside-click-handler';
import { layerTypes } from '../../../interfaces/layer-types';
import {
  EDITOR_MODAL_STORIES_TAB_MY_STORIES,
  EDITOR_MODAL_STORIES_TAB_TEAM_STORIES,
} from '../constants/widget-editor-modal-story-tabs';
import { Stories } from '../../templates';

const WidgetEditorModalStorySelector: React.FC = () => {
  const dispatch = useDispatch();
  const tabNames = [EDITOR_MODAL_STORIES_TAB_MY_STORIES, EDITOR_MODAL_STORIES_TAB_TEAM_STORIES];

  const [activeTab, setActiveTab] = useState(tabNames[0]);
  return (
    <OutsideClickHandler onOutsideClick={(event) => dispatch(onOutsideClickModal(event, layerTypes.TEMPLATES))}>
      <EditorModalWrapper>
        <EditorModalLabelsWrapper>
          {tabNames.map((tabName) => {
            return (
              <EditorModalLabel
                key={tabName}
                text={tabName}
                isActive={tabName === activeTab}
                onClick={() => setActiveTab(tabName)}
              />
            );
          })}
        </EditorModalLabelsWrapper>

        <Stories activeTab={activeTab} />
      </EditorModalWrapper>
    </OutsideClickHandler>
  );
};

export default WidgetEditorModalStorySelector;
