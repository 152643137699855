import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IAmpColumn {
  children: ReactNode;
}

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 40.9%;
  padding: 16px 32px 0 32px;
  border-left: 1px solid var(--shade-500);
`;

const WidgetColumn = ({ children }: IAmpColumn): JSX.Element => {
  return <Column>{children}</Column>;
};

export default WidgetColumn;
