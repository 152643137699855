import { ReactNode } from 'react';
import styled from 'styled-components';

interface ISectionColumn {
  width?: string;
  children: ReactNode;
}

const Column = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : '100%')};
  padding: 0 8px;
`;

const SectionColumn = ({ width, children }: ISectionColumn) => {
  return <Column width={width}>{children}</Column>;
};

export default SectionColumn;
