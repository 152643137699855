import OutsideClickHandler from 'react-outside-click-handler';
import sinceTime from '../../../utils/sinceTime';
import Styled from './folder-item-styled';
import { DotsIcon, EmptyFolder } from '../../icons';
import { IFolder } from 'redux/services/folders/interfaces';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { preventDefault } from '../../../utils/common';
import { useAppSelector } from '../../../hooks';
import { useDrop } from 'react-dnd';

export interface FolderDropResult {
  folder: string;
  isSidebar: boolean;
  team?: string | null;
}

interface IFolderItem {
  folder: IFolder;
  onRenameModalOpen: (folder: IFolder) => void;
  onMoveModalOpen: (folder: IFolder) => void;
  onDeleteModalOpen: (folder: IFolder) => void;
}

const FolderItem: React.FC<IFolderItem> = ({ folder, onRenameModalOpen, onMoveModalOpen, onDeleteModalOpen }) => {
  const hasTeam = useAppSelector((state) => state.auth.user?.selectedTeamId);
  const storiesCounter = folder?.totalStories;

  const onDrop = useCallback(
    () => ({
      folder: folder._id,
      team: hasTeam,
      isSidebar: false,
    }),
    [folder._id, hasTeam],
  );

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'STORY',
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const link = useMemo(() => (hasTeam ? `/teams/folder/${folder._id}` : `/my-stories/folder/${folder._id}`), [
    folder._id,
    hasTeam,
  ]);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuTriggerRef = useRef<HTMLButtonElement | null>(null);

  const setMenuPosition = useCallback(() => {
    if (menuRef && menuRef.current && menuTriggerRef && menuTriggerRef.current) {
      const totalHeight = menuTriggerRef?.current?.getBoundingClientRect().top + menuRef?.current?.clientHeight;

      if (totalHeight > window.innerHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, []);

  const onDropdownTriggerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuPosition();
    preventDefault(event);
    setIsMenuVisible(!isMenuVisible);
  };

  const onCloseMenu = useCallback(() => setIsMenuVisible(false), []);
  const onRename = useCallback(() => onRenameModalOpen(folder), [folder, onRenameModalOpen]);
  const onMove = useCallback(() => onMoveModalOpen(folder), [folder, onMoveModalOpen]);
  const onDelete = useCallback(() => onDeleteModalOpen(folder), [folder, onDeleteModalOpen]);

  useEffect(() => {
    setMenuPosition();
  }, [setMenuPosition]);

  return (
    <Styled.FolderBorderWrapper ref={drop} role={'Folder'} isDraggingOver={isOver}>
      <OutsideClickHandler onOutsideClick={onCloseMenu}>
        <Styled.FolderWrapper to={link}>
          <Styled.FolderContentWrapper>
            <Styled.FolderName>{folder.title}</Styled.FolderName>
            <Styled.FolderDate>Updated {sinceTime(folder.updatedAt)}</Styled.FolderDate>
          </Styled.FolderContentWrapper>
          <Styled.MenuOuterWrapper isMenuVisible={isMenuVisible}>
            <Styled.MenuWrapper>
              <Styled.MenuTrigger ref={menuTriggerRef} onClick={onDropdownTriggerClick}>
                <DotsIcon />
              </Styled.MenuTrigger>
              <Styled.DropdownWrapper
                isMenuVisible={isMenuVisible}
                ref={menuRef}
                isOverFlowing={isOverflowing}
                onClick={preventDefault}
              >
                <Styled.DropdownItem onClick={onRename}>Rename</Styled.DropdownItem>
                <Styled.DropdownItem onClick={onMove}>Move to</Styled.DropdownItem>
                <Styled.DropdownItem onClick={onDelete}>Delete</Styled.DropdownItem>
              </Styled.DropdownWrapper>
            </Styled.MenuWrapper>
          </Styled.MenuOuterWrapper>

          <Styled.ThumbnailsWrapper>
            {folder?.recentStories && storiesCounter && storiesCounter > 0 ? (
              <>
                {storiesCounter > 4 ? (
                  <>
                    {folder?.recentStories?.slice(0, 3)?.map((story) => (
                      <Styled.FolderThumbnail key={story._id} src={story.cover.url} alt="Story Cover Image" />
                    ))}
                    <Styled.FolderStoriesCounter>+{storiesCounter - 3}</Styled.FolderStoriesCounter>
                  </>
                ) : (
                  folder?.recentStories?.map((story) => (
                    <Styled.FolderThumbnail key={story._id} src={story.cover.url} alt="Story Cover Image" />
                  ))
                )}
              </>
            ) : (
              <Styled.EmptyThumbnailWrapper>
                <EmptyFolder secondaryColor={'var(--shade-100)'} />
              </Styled.EmptyThumbnailWrapper>
            )}
          </Styled.ThumbnailsWrapper>
        </Styled.FolderWrapper>
      </OutsideClickHandler>
    </Styled.FolderBorderWrapper>
  );
};

export default memo(FolderItem);
