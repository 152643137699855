interface IEditorSiderbarFilters {
  src: string;
  onSelectFilter: (filter: ICssFilter) => void;
  selectedFilterName?: string;
}

import styled from 'styled-components';
import EditorSiderbarFilter from './editor-sidebar-filter';
import { CSS_FILTERS } from 'config/constants';
import { ICssFilter } from 'interfaces/editor-model';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const EditorSiderbarFilters = ({ src, onSelectFilter, selectedFilterName }: IEditorSiderbarFilters): JSX.Element => {
  return (
    <Container>
      {CSS_FILTERS?.map((filter) => (
        <EditorSiderbarFilter
          figureCss={filter.figure}
          beforeCss={filter?.before}
          key={filter.name}
          title={filter.name}
          src={src}
          isSelected={selectedFilterName === filter.name}
          onSelect={() => onSelectFilter(filter)}
        />
      ))}
    </Container>
  );
};

export default EditorSiderbarFilters;
