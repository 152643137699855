import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IMainWrapper {
  children: ReactNode;
}

const Wrapper = styled.div`
  padding: 0px 92px 92px;
`;

const MainWrapper = ({ children }: IMainWrapper): JSX.Element => {
  return <Wrapper>{children}</Wrapper>;
};

export default MainWrapper;
