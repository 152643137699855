import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import WidgetEditorLayersMenu from './widget-editor-layers-menu/widget-editor-layers-menu';
import { BottomBarButtonWrapper } from './../editor-bottom-bar/shared/index';
import { IconButton } from '../buttons';
import { Layers, Tags } from '../icons';
import { memo, useState } from 'react';
import { stopPropagation } from '../../utils/common';

const Container = styled.div`
  width: calc(100% + 64px);
  margin-left: -64px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
`;

const ButtonsGroup = styled.div<{ justifyContent?: string }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 33.33%;
  min-width: 0;
  max-width: calc(33.33% - 86px);
  flex: 1 1 33.33%;
  // Substract the layer container + right margin
  &:nth-child(2) {
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
    padding-left: 86px;
  }
  &:nth-child(3) {
    justify-content: flex-end;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between
  align-content: center;
  align-items: center;
  padding: 0 5px;
`;

const StyledDiv = styled.div`
  color: #ababba;
  font-family: Heebo;
  padding-left: 10px;
`;

const TagsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
`;

interface Props {
  setWidgetTagsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WidgetEditorBottomBar: React.VFC<Props> = ({ setWidgetTagsModalOpen }): JSX.Element => {
  const [isLayersMenuVisible, setIsLayersMenuVisible] = useState(false);

  const onOpenTagsModal = () => setWidgetTagsModalOpen(true);
  const onLayersMenuClick = () => setIsLayersMenuVisible(!isLayersMenuVisible);
  const onOutsideLayersMenuClick = () => setIsLayersMenuVisible(false);

  return (
    <Container onClick={stopPropagation}>
      <ButtonsGroup>
        <OutsideClickHandler onOutsideClick={onOutsideLayersMenuClick}>
          <BottomBarButtonWrapper>
            <IconButton onClick={onLayersMenuClick}>
              <Layers isActive={isLayersMenuVisible} />
            </IconButton>

            {isLayersMenuVisible && <WidgetEditorLayersMenu />}
          </BottomBarButtonWrapper>
        </OutsideClickHandler>
      </ButtonsGroup>

      {/* <ButtonsGroup justifyContent="flex-end">
        <BottomBarButtonWrapper>
          <IconButton onClick={onAddNewSlide}>
            <RoundPlus className="roundplusbtn" size="24px" />
          </IconButton>
        </BottomBarButtonWrapper>

        <BottomBarButtonWrapper>
          <IconButton onClick={onDuplicateSlide}>
            <DuplicateSlide />
          </IconButton>
        </BottomBarButtonWrapper>

        <BottomBarButtonWrapper>
          <IconButton onClick={onDeleteSlide}>
            <Delete />
          </IconButton>
        </BottomBarButtonWrapper>
      </ButtonsGroup> */}

      <TagsWrapper>
        <BottomBarButtonWrapper>
          <IconButton onClick={onOpenTagsModal}>
            <ContentWrapper>
              <Tags />
              <StyledDiv>Tags</StyledDiv>
            </ContentWrapper>
          </IconButton>
        </BottomBarButtonWrapper>
      </TagsWrapper>
    </Container>
  );
};

export default memo(WidgetEditorBottomBar);
