import styled from 'styled-components';

interface ITutorialSectionText {
  text: string;
  maxWidth?: string;
}

const Text = styled.p<{ $maxWidth?: string }>`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--white);
  margin: 0 auto 60px;
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

const TutorialSectionText = ({ text, maxWidth }: ITutorialSectionText): JSX.Element => {
  return <Text $maxWidth={maxWidth}>{text}</Text>;
};

export default TutorialSectionText;
