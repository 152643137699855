import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { makeSelectPropFromActiveSlide, setActiveSlideProps } from '../../../redux/features/amp-story/ampStorySlice';
// Components
import { BottomBarButtonWrapper, Typography } from '../shared';
import { IconButton } from '../../buttons';
import InlineEditor from '../../inline-editor';
// Utils
import debounce from 'lodash/debounce';
// Constants
import { storyConstants } from '../../../config/constants';
import { stopPropagation } from '../../../utils/common';
import OutsideClickHandler from 'react-outside-click-handler';
import ToggleAutoAdvance from './toggle-auto-advance';
import { Infinite } from '../../icons';

const limits = {
  lowerBound: storyConstants.slideMinDuration,
  upperBound: storyConstants.slideMaxDuration,
};

const EditorSlideDuration = (): JSX.Element => {
  const dispatch = useDispatch();
  const selectPropFromActiveSlide = useMemo(makeSelectPropFromActiveSlide, []);
  const activeSlidePosition = useSelector((state: RootState) => state.ampStory.present.activeSlidePosition);
  const activeSlideDuration = useSelector((state: RootState) => selectPropFromActiveSlide(state, 'duration'));
  const isAutoAdvancedDisabled = useSelector((state: RootState) =>
    selectPropFromActiveSlide(state, 'isAutoAdvancedDisabled'),
  );
  const [duration, setDuration] = useState<number | string>(activeSlideDuration);
  const [activeEditor, setActiveEditor] = useState(false);
  const [isAutoAdvancedOpen, setIsAutoAdvancedOpen] = useState(false);

  useEffect(() => {
    setDuration(activeSlideDuration);
  }, [activeSlideDuration]);

  const updateDuration = useCallback(
    debounce((newDuration: number | string) => {
      if (typeof newDuration === 'number' && !isNaN(newDuration) && newDuration !== activeSlideDuration) {
        dispatch(
          setActiveSlideProps({
            field: 'duration',
            value: newDuration,
          }),
        );
      }
    }, 500),
    [dispatch, activeSlidePosition],
  );

  useEffect(() => {
    updateDuration(duration);
  }, [duration, updateDuration]);

  const shouldArrowsFire = (event: any, min: number, max: number) => {
    const value = +event.target.value;
    if (isNaN(value)) {
      return false;
    }
    if (event.key === 'ArrowUp') {
      return !(value >= max);
    }
    if (event.key === 'ArrowDown') {
      return !(value <= min);
    }

    return true;
  };

  const handleDurationKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    let newDuration = Number(duration);

    if (shouldArrowsFire(event, storyConstants.slideMinDuration, storyConstants.slideMaxDuration)) {
      if (event.key === 'ArrowUp') {
        newDuration += 1;
      }
      if (event.key === 'ArrowDown') {
        newDuration -= 1;
      }
      setDuration(newDuration);
    }
  };

  const onButtonClick = () => {
    if (!isAutoAdvancedDisabled && !activeEditor) {
      setActiveEditor(true);
    }
    if (!isAutoAdvancedOpen) {
      setIsAutoAdvancedOpen(true);
    }
    if (isAutoAdvancedDisabled && isAutoAdvancedOpen) {
      setIsAutoAdvancedOpen(false);
    }
  };

  const onOutsideClick = () => {
    setIsAutoAdvancedOpen(false);
  };

  return (
    <BottomBarButtonWrapper onClick={stopPropagation}>
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <IconButton isActive={activeEditor} onClick={onButtonClick}>
          <Typography>
            {isAutoAdvancedDisabled ? (
              <Infinite />
            ) : (
              <>
                <InlineEditor
                  open={activeEditor}
                  setOpen={setActiveEditor}
                  type={'number'}
                  name={'duration'}
                  placeholder={'0'}
                  value={duration}
                  onChange={setDuration}
                  onKeyDown={handleDurationKeyDown}
                  limits={limits}
                />
                {!activeEditor && 's'}
              </>
            )}
          </Typography>
        </IconButton>

        {isAutoAdvancedOpen && <ToggleAutoAdvance />}
      </OutsideClickHandler>
    </BottomBarButtonWrapper>
  );
};

export default EditorSlideDuration;
