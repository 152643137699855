import { memo } from 'react';

interface IFolder {
  className?: string;
  mainColor?: string;
  secondaryColor?: string;
}

const Folder = ({ className, mainColor, secondaryColor }: IFolder): JSX.Element => {
  return (
    <svg
      className={className}
      width="118"
      height="108"
      viewBox="0 0 118 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.8302 18.4841H70.4639C66.1374 18.5128 62.0298 16.5775 59.2883 13.2185L53.5692 5.26733C50.8736 1.87885 46.7661 -0.0656027 42.4461 0.00169091H30.1688C8.13245 0.00169091 4.78388e-06 13.0078 4.78388e-06 35.1235V59.0297C-0.0274342 61.6585 117.991 61.655 117.999 59.0297V52.079C118.104 29.9631 110.182 18.4841 87.8302 18.4841H87.8302Z"
        fill={secondaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.105 26.545C112.993 28.7584 114.451 31.3045 115.406 34.053C117.286 39.7018 118.156 45.6379 117.977 51.5892V72.6708C117.969 75.289 117.777 77.9031 117.4 80.4941C116.683 85.0662 115.079 89.4535 112.679 93.4096C111.576 95.3208 110.236 97.0846 108.692 98.6597C101.702 105.094 92.4198 108.443 82.9353 107.953H34.9892C25.4899 108.44 16.1926 105.092 9.1801 98.6597C7.65442 97.0817 6.33264 95.3178 5.24578 93.4096C2.85992 89.4565 1.29023 85.0644 0.62951 80.4941C0.209388 77.9073 -0.00111561 75.2914 5.34829e-06 72.6708V51.5892C-0.000997612 48.9935 0.139063 46.3992 0.419715 43.8186C0.478713 43.3658 0.567209 42.9204 0.654762 42.4796C0.800781 41.7448 0.944263 41.0228 0.944263 40.3009C1.47678 37.1866 2.44817 34.1638 3.82943 31.3228C7.92115 22.5548 16.3143 18.092 30.0583 18.092H87.7613C95.4594 17.4949 103.1 19.8191 109.165 24.6024C109.862 25.1963 110.512 25.846 111.105 26.545Z"
        fill={mainColor}
      />
    </svg>
  );
};

Folder.defaultProps = {
  mainColor: 'var(--shade-100)',
  secondaryColor: 'var(--shade-300)',
};

export default memo(Folder);
