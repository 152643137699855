import DashboardLayout from '../components/layouts';
import SettingsTabs from '../components/settings/settings-tabs';
import SettingsWrapper from '../components/settings/shared/settings-wrapper';
import styled from 'styled-components';
import { memo, useState } from 'react';
import { MoveableTooltip } from '../components/tooltip';
import { RoleName } from 'redux/services/workspaces/interface';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '../hooks';
import { useGetWorkspaceMembersQuery, useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { WorkspaceMembersHeader, WorkspaceMembersTable } from '../components/workspace-members';

const MembersContainerStyled = styled.div`
  background: var(--shade-900-85);
  width: 100%;
  height: auto;
  border-radius: 12px;
  padding: 35px 45px;
  box-shadow: 24px 32px 72px var(--black-18);
  font-family: 'Heebo';
`;

const SettingsMembersPage = (): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);

  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);
  const userId = useAppSelector((state) => state.auth.user?._id);

  const { isWorkspaceAdmin } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace }) => ({
      isWorkspaceAdmin: workspace?.users?.find((u) => u._id === userId)?.role.name !== RoleName.user,
    }),
  });

  const { data: workspaceUsers } = useGetWorkspaceMembersQuery(
    {
      workspaceId: selectedWorkspaceId ?? '',
    },
    { skip: !selectedWorkspaceId },
  );

  return (
    <DashboardLayout>
      <SettingsWrapper>
        <SettingsTabs />
        <MembersContainerStyled onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          <WorkspaceMembersHeader length={workspaceUsers?.length} isDisabled={!isWorkspaceAdmin} />
          <WorkspaceMembersTable workspaceUsers={workspaceUsers ?? []} isDisabled={!isWorkspaceAdmin} />
          {!isWorkspaceAdmin && (
            <MoveableTooltip
              showTooltip={showTooltip}
              text="Contact your Workspace admin in order to modify Workspace settings"
            />
          )}
        </MembersContainerStyled>
      </SettingsWrapper>
    </DashboardLayout>
  );
};

export default memo(SettingsMembersPage);
