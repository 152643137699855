import styled, { css } from 'styled-components';

type IProps = {
  background?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isBackdropActive?: boolean;
  isBoxShadowActive?: boolean;
  padding?: string;
  width?: string;
  children: React.ReactNode;
  onClick?: (params?: any) => void;
  buttonRef?: any;
  onMouseDown?: (event: React.MouseEvent<HTMLElement>) => void;
  $stroke?: boolean;
};

type ContainerProps = {
  background: string;
  isActive: boolean;
  isDisabled: boolean;
  isBackdropActive: boolean;
  isBoxShadowActive: boolean;
  padding: string;
  width?: string;
  $stroke?: boolean;
};

const Container = styled.div`
  background: ${(props: ContainerProps) => props.background};
  ${(props: ContainerProps) => props.isBoxShadowActive && `box-shadow: 24px 32px 72px var(--black-18);`}
  ${(props: ContainerProps) => props.isBackdropActive && `backdrop-filter: blur(50px);`}
  border-radius: 8px;
  ${(props: ContainerProps) => `padding: ${props.padding};`}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(props: ContainerProps) => props.background};
  transition: 0.12s ease;
  &:hover {
    background: var(--shade-700-85);
    border-color: var(--shade-700-85);
  }
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  &&&&& {
    ${(props: ContainerProps) =>
      props.isActive &&
      !props.$stroke &&
      `
      border-color: var(--primary);    
      &:hover {
        background: var(--primary-10);
      }
      svg > * {
        fill: var(--primary);
        }
      }
    `}
  }
  &&&&& {
    ${(props: ContainerProps) =>
      props.isDisabled &&
      !props.$stroke &&
      `
      svg > * {
        fill: var(--shade-300-85);
        }
      }
    `}
  }

  &&&&& {
    ${(props: ContainerProps) =>
      props.isActive &&
      props.$stroke &&
      `
      border-color: var(--primary);    
      &:hover {
        background: var(--primary-10);
      }
      svg > * {
        stroke: var(--primary);
        }
      }
    `}
  }
  &&&&& {
    ${(props: ContainerProps) =>
      props.isDisabled &&
      props.$stroke &&
      `
      svg > * {
        stroke: var(--shade-300-85);
        }
      }
    `}
  }
`;

const IconButton = ({
  background = 'var(--shade-900-85)',
  isActive = false,
  isDisabled = false,
  isBackdropActive = true,
  isBoxShadowActive = true,
  padding = '7px',
  width,
  onClick,
  onMouseDown,
  children,
  buttonRef,
  $stroke,
}: IProps): JSX.Element => {
  return (
    <Container
      background={background}
      isActive={isActive}
      isDisabled={isDisabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
      padding={padding}
      width={width}
      isBackdropActive={isBackdropActive}
      isBoxShadowActive={isBoxShadowActive}
      ref={buttonRef}
      $stroke={$stroke}
    >
      {children}
    </Container>
  );
};

export default IconButton;
