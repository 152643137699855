import { memo } from 'react';

const ArrowDown = (): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.525 13.0947L12.525 10.1474C12.825 9.85262 12.825 9.41052 12.525 9.11578C12.225 8.82104 11.775 8.82104 11.475 9.11578L9.75 10.8105L9.75 5.73683C9.75 5.29472 9.45 4.99999 9 4.99999C8.55 4.99999 8.25 5.29472 8.25 5.73683L8.25 10.8105L6.52501 9.11578C6.37501 8.96841 6.22501 8.89473 6.00001 8.89473C5.55001 8.89473 5.25001 9.18946 5.25001 9.63157C5.25001 9.85262 5.32501 9.99999 5.47501 10.1474L8.475 13.0947C8.775 13.3895 9.225 13.3895 9.525 13.0947Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ArrowDown);
