import { useMemo, memo } from 'react';
import styled, { css } from 'styled-components';
import { IFolder } from 'redux/services/folders/interfaces';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks';

const sharedCss = css`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

const BreadCrumb = styled(Link)`
  ${sharedCss};
  color: var(--shade-100);
  text-decoration: none;
  &:not(:first-child) {
    margin-left: 4px;
  }
  &:hover {
    text-decoration: none;
  }
`;

const CurrentBreadCrumb = styled.span`
  ${sharedCss};
  color: var(--white);
  margin-left: 4px;
`;

const Dots = styled.span`
  ${sharedCss};
  color: var(--shade-100);
  text-decoration: none;
`;

const BreadcrumbsWrapper = styled.div`
  margin: 16px 0 0;
  position: relative;
  z-index: 3;
`;

interface IProps {
  folder: IFolder;
}

const FolderBreadcrumbs: React.FC<IProps> = ({ folder }) => {
  const hasTeam = useAppSelector((state) => state.auth.user?.selectedTeamId);

  const homeLink = useMemo(() => {
    return hasTeam ? { url: '/teams', title: 'Team' } : { url: '/my-stories', title: 'My Stories' };
  }, [hasTeam]);

  return (
    <BreadcrumbsWrapper>
      <BreadCrumb to={homeLink.url}>{homeLink.title} /</BreadCrumb>

      {folder?.ancestors && folder?.ancestors?.length > 2 && <Dots>... /</Dots>}

      {folder?.ancestors?.slice(-2).map((ancestor) => (
        <BreadCrumb key={ancestor._id} to={`${homeLink.url}/folder/${ancestor._id}`}>
          {ancestor.title} /
        </BreadCrumb>
      ))}

      <CurrentBreadCrumb>{folder?.title}</CurrentBreadCrumb>
    </BreadcrumbsWrapper>
  );
};

export default memo(FolderBreadcrumbs);
