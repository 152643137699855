import EditorSidebarButtonWithIcon from './../../../editor-sidebar/shared/elements/editor-sidebar-button-with-icon';
import EditorSidebarButtonWrapper from './../../../editor-sidebar/shared/structure/editor-sidebar-button-wrapper';
import EditorSidebarLabel from './../../../editor-sidebar/shared/elements/editor-sidebar-label';
import EditorSidebarRowWrapper from './../../../editor-sidebar/shared/structure/editor-sidebar-row-wrapper';
import EditorSidebarSectionWrapper from './../../../editor-sidebar/shared/structure/editor-sidebar-section-wrapper';
import EditorSidebarValuesWrapper from './../../../editor-sidebar/shared/structure/editor-sidebar-values-wrapper';
import styled from 'styled-components';
import { Computer, Smartphone } from '../../../icons';
import { DeviceTypeContext, DeviceTypeContextType } from 'context/widget/device-type';
import { memo, useCallback, useContext } from 'react';
import { WIDGET_DEVICE_TYPE } from './../../constants/widget-editor-sidebar-devices';

const StyledWidgetEditorSidebarSectionWrapper = styled(EditorSidebarSectionWrapper)`
  &:last-of-type {
    border-bottom: 1px solid var(--shade-700-85);
  }
`;

const WidgetEditorSidebarPreviewSettings: React.VFC = (): JSX.Element => {
  const { deviceType, setDeviceType } = useContext(DeviceTypeContext) as DeviceTypeContextType;

  const onDeviceTypeSelect = useCallback(
    (type: WIDGET_DEVICE_TYPE) => () => {
      setDeviceType(type);
    },
    [setDeviceType],
  );

  return (
    <StyledWidgetEditorSidebarSectionWrapper>
      <EditorSidebarRowWrapper>
        <EditorSidebarLabel text={'Device'} />

        <EditorSidebarValuesWrapper justifyContent={'flex-end'}>
          <EditorSidebarButtonWrapper>
            <EditorSidebarButtonWithIcon
              isActive={deviceType === WIDGET_DEVICE_TYPE.SMARTPHONE}
              onClick={onDeviceTypeSelect(WIDGET_DEVICE_TYPE.SMARTPHONE)}
            >
              <Smartphone />
            </EditorSidebarButtonWithIcon>
          </EditorSidebarButtonWrapper>

          <EditorSidebarButtonWrapper>
            <EditorSidebarButtonWithIcon
              isActive={deviceType === WIDGET_DEVICE_TYPE.COMPUTER}
              onClick={onDeviceTypeSelect(WIDGET_DEVICE_TYPE.COMPUTER)}
            >
              <Computer />
            </EditorSidebarButtonWithIcon>
          </EditorSidebarButtonWrapper>
        </EditorSidebarValuesWrapper>
      </EditorSidebarRowWrapper>
    </StyledWidgetEditorSidebarSectionWrapper>
  );
};

export default memo(WidgetEditorSidebarPreviewSettings);
