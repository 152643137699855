import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

interface ISectionTitleWrapper {
  children: ReactNode;
}

const Wrapper = styled.div<{ isSticky?: boolean }>`
  position: sticky;
  margin-bottom: 16px;
  top: 34px;
  ${({ isSticky }) =>
    isSticky &&
    css`
      z-index: 5;
      display: inline-flex;
    `}
`;

const InnerWrapper = styled.div<{ isSticky?: boolean }>`
  display: flex;
  align-items: center;
  ${({ isSticky }) =>
    isSticky &&
    css`
      transition: 0.12s ease;
    `}
`;

const SectionTitleWrapper = ({ children }: ISectionTitleWrapper): JSX.Element => {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onStickyChange = useCallback(
    (element: HTMLElement) => () => {
      const top = element?.getBoundingClientRect().top;
      if ((isSticky && top < -1) || (isSticky && top > 70)) {
        setIsSticky(false);
      }
      if (!isSticky && top === 34) {
        setIsSticky(true);
      }
    },
    [isSticky],
  );

  useEffect(() => {
    if (ref?.current) {
      const element = ref?.current;
      window.addEventListener('scroll', onStickyChange(element));

      return () => window.removeEventListener('scroll', onStickyChange(element));
    }
  }, [onStickyChange]);

  return (
    <Wrapper isSticky={isSticky} ref={ref}>
      <InnerWrapper isSticky={isSticky}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child as React.ReactElement<any>, {
            isSticky,
          });
        })}
      </InnerWrapper>
    </Wrapper>
  );
};

export default SectionTitleWrapper;
