import { layerTypes } from '../interfaces/layer-types';
import { ColorType } from '../interfaces/gradient-color';
import { defaultGradientModel } from '../utils/builders';

const changeColorToGradient = (layer: any): void => {
  if (layer.type === layerTypes.SHAPE || layer.type === layerTypes.CTA_LINK || layerTypes.OUTLINK) {
    ['fillColor' as ColorType, 'borderColor' as ColorType].forEach((key: ColorType) => {
      if (typeof layer.settings.layerSettings.shapeStyles[key] === 'string') {
        const model = { ...defaultGradientModel(key) };
        model.colorType = key;
        model.leftColor = layer.settings.layerSettings.shapeStyles[key];
        layer.settings.layerSettings.shapeStyles[key] = model;
      }
    });
  }
};

export { changeColorToGradient };
