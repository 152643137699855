import React from 'react';
import styled from 'styled-components';

interface ISplitter {
  className?: string;
}

const SplitterElement = styled.span`
  margin: 0 4px;
  color: inherit;
`;

const Splitter = ({ className }: ISplitter): JSX.Element => {
  return <SplitterElement className={className}>/</SplitterElement>;
};

export default Splitter;
