import React from 'react';
import styled from 'styled-components';
import InputBasic from './../../../shared/input-basic';

interface IColorInputElement {
  className?: string;
  isDisabled?: boolean;
  value: string | number;
  type?: string;
  placeholder?: string;
  onKeyDown?: any;
  onChange: any;
}

const ColorInputElement = styled(InputBasic)`
  text-align: center;
  & > input {
    padding: 7px 9px;
    width: 100% !important;
  }
`;

const ColorInput = ({
  className,
  isDisabled,
  value,
  type,
  placeholder,
  onKeyDown,
  onChange,
}: IColorInputElement): JSX.Element => {
  return (
    <ColorInputElement
      className={className}
      isDisabled={isDisabled}
      value={value}
      type={type}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onChange={onChange}
    />
  );
};

export default ColorInput;
