import styled from 'styled-components';
import { memo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useLogoutMutation } from 'redux/services/auth/auth';

const StyledMenu = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  margin: 0;
  background: none;
  border: none;
  min-width: 164px;
  background: rgba(33, 33, 44, 0.85);
  border: 1px solid var(--white-10);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 12px;
  padding: 16px 0;
`;

const StyledSubMenu = styled.div`
  border: none;
  border-radius: 8px;
  transition: background-color 200ms ease;
`;

const ShortCut = styled.span`
  color: var(--shade-300);
  margin-left: 12px;
  transition: color 200ms ease;
`;

const StyledMenuItem = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: none;
    margin: 0 12px 4px;
    padding: 3px 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--shade-100);
    cursor: pointer;
    transition: background-color 200ms ease, color 200ms ease;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: var(--primary);
      color: var(--shade-900);
    }
    &:hover ${ShortCut} {
      color: var(--shade-900-85);
    }
  }
`;

interface Props {
  hasNoHeader?: boolean;
}

const ProfileMenu: React.VFC<Props> = ({ hasNoHeader }) => {
  const { push } = useHistory();
  const [logout] = useLogoutMutation();

  const userId = useAppSelector((state) => state.auth.user?._id) ?? '';
  const token = useAppSelector((state) => state.auth.refreshToken?.token) ?? '';

  // TODO
  // Revoke dpa id token
  const onLogoutClick = () => logout({ userId, token });
  const onProfileClick = () => push('/profile');

  return (
    <StyledMenu>
      <StyledSubMenu>
        {!hasNoHeader && <StyledMenuItem onClick={onProfileClick}>Profile</StyledMenuItem>}

        <StyledMenuItem onClick={onLogoutClick}>Logout</StyledMenuItem>
      </StyledSubMenu>
    </StyledMenu>
  );
};

export default memo(ProfileMenu);
