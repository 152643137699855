import { memo } from 'react';

const Arrow = (): JSX.Element => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.125 8.70215L9.125 13.6144C8.625 14.1057 8.625 14.8425 9.125 15.3337C9.625 15.825 10.375 15.825 10.875 15.3337L13.75 12.5092L13.75 20.9653C13.75 21.7022 14.25 22.1934 15 22.1934C15.75 22.1934 16.25 21.7022 16.25 20.9653L16.25 12.5092L19.125 15.3337C19.375 15.5793 19.625 15.7021 20 15.7021C20.75 15.7021 21.25 15.2109 21.25 14.4741C21.25 14.1057 21.125 13.86 20.875 13.6144L15.875 8.70215C15.375 8.21092 14.625 8.21092 14.125 8.70215Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default memo(Arrow);
