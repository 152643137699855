import { memo, ReactNode } from 'react';

import DashboardColumn from './shared/dashboard-column';
import DashboardHeader from './shared/dashboard-header';
import DashboardSidebar from './shared/sidebar';
import DashboardWrapper from './shared/dashboard-wrapper';

interface IDashboardLayout {
  hasNoHeader?: boolean;
  withHeaderSearchStory?: boolean;
  carouselVersion?: boolean;
  children: ReactNode;
}

const DashboardLayout: React.FC<IDashboardLayout> = ({
  hasNoHeader,
  withHeaderSearchStory,
  carouselVersion,
  children,
}) => (
  <DashboardWrapper>
    <DashboardColumn maxWidth={'320px'}>
      <DashboardSidebar />
    </DashboardColumn>

    <DashboardColumn direction={'column'} maxWidth={'calc(100% - 320px)'}>
      <DashboardHeader
        withStorySearch={withHeaderSearchStory}
        carouselVersion={carouselVersion}
        hasNoHeader={hasNoHeader}
      />
      {children}
    </DashboardColumn>
  </DashboardWrapper>
);

export default memo(DashboardLayout);
