import { memo } from 'react';

interface IReplaceIcon {
  className?: string;
}

const EyeIcon = ({ className }: IReplaceIcon): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33335 6.99984C8.20002 6.99984 7.33335 7.86651 7.33335 8.99984C7.33335 10.1332 8.20002 10.9998 9.33335 10.9998C10.4667 10.9998 11.3334 10.1332 11.3334 8.99984C11.3334 7.86651 10.4667 6.99984 9.33335 6.99984ZM15.9334 8.73317C14.6 5.59984 12.0667 3.6665 9.33335 3.6665C6.60002 3.6665 4.06668 5.59984 2.73335 8.73317C2.66668 8.93317 2.66668 9.06651 2.73335 9.26651C4.06668 12.3998 6.60002 14.3332 9.33335 14.3332C12.0667 14.3332 14.6 12.3998 15.9334 9.26651C16 9.06651 16 8.93317 15.9334 8.73317ZM9.33335 12.3332C7.46669 12.3332 6.00002 10.8665 6.00002 8.99984C6.00002 7.13317 7.46669 5.6665 9.33335 5.6665C11.2 5.6665 12.6667 7.13317 12.6667 8.99984C12.6667 10.8665 11.2 12.3332 9.33335 12.3332V12.3332Z"
        fill="none"
      />
    </svg>
  );
};

export default memo(EyeIcon);
