import { ActionTypes } from 'redux-undo';
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILayer } from 'interfaces/layer';
import { updateLayersScaling } from 'redux/features/amp-story/ampStorySlice';

const isUndoOrRedo = (action: AnyAction) => action.type === ActionTypes.UNDO || action.type === ActionTypes.REDO;

interface State {
  isGroupLayerActive: boolean;
  selectedChildLayer: ILayer | null;
  width: number | null;
  height: number | null;
  offsetX: number | null;
  offsetY: number | null;
  isTextLayerInEditMode: boolean;
  forceRebuild: boolean;
  forceRebuildMulti: boolean;
  multiWidth: number | null;
  multiHeight: number | null;
  multiX: number | null;
  multiY: number | null;
  offsetMultiX: number | null;
  offsetMultiY: number | null;
  multiAngle: number | null;
}

const initialState: State = {
  isGroupLayerActive: false,
  selectedChildLayer: null,
  width: null,
  height: null,
  offsetX: null,
  offsetY: null,
  isTextLayerInEditMode: false,
  forceRebuild: false,
  forceRebuildMulti: false,
  multiWidth: null,
  multiHeight: null,
  multiX: null,
  multiY: null,
  offsetMultiX: null,
  offsetMultiY: null,
  multiAngle: null,
};

const groupLayerHelperSlice = createSlice({
  name: 'groupLayerHelper',
  initialState,
  reducers: {
    toggleGroupLayer: (state, action: PayloadAction<boolean>) => {
      state.isGroupLayerActive = action.payload;
      if (!action.payload) {
        state.selectedChildLayer = null;
      }
    },
    setWidth: (state, action: PayloadAction<number | null>) => {
      state.width = action.payload;
    },
    setHeight: (state, action: PayloadAction<number | null>) => {
      state.height = action.payload;
    },
    setOffsetX: (state, action: PayloadAction<number | null>) => {
      state.offsetX = action.payload;
    },
    setOffsetY: (state, action: PayloadAction<number | null>) => {
      state.offsetY = action.payload;
    },
    selectChildLayer: (state, action: PayloadAction<ILayer | null>) => {
      state.selectedChildLayer = action.payload;
    },
    toggleTextLayerEditMode: (state, action: PayloadAction<boolean>) => {
      state.isTextLayerInEditMode = action.payload;
    },
    toggleForceRebuild: (state, action: PayloadAction<boolean>) => {
      state.forceRebuild = action.payload;
    },
    toggleForceRebuildMulti: (state, action: PayloadAction<boolean>) => {
      state.forceRebuildMulti = action.payload;
    },
    setMultiWidth: (state, action: PayloadAction<number | null>) => {
      state.multiWidth = action.payload;
    },
    setMultiHeight: (state, action: PayloadAction<number | null>) => {
      state.multiHeight = action.payload;
    },
    setMultiX: (state, action: PayloadAction<number | null>) => {
      state.multiX = action.payload;
    },
    setMultiY: (state, action: PayloadAction<number | null>) => {
      state.multiY = action.payload;
    },
    setMultiOffsetX: (state, action: PayloadAction<number | null>) => {
      state.offsetMultiX = action.payload;
    },
    setMultiOffsetY: (state, action: PayloadAction<number | null>) => {
      state.offsetMultiY = action.payload;
    },
    setMultiAngle: (state, action: PayloadAction<number | null>) => {
      state.multiAngle = action.payload;
    },
    clearMulti: (state) => {
      state.multiWidth = null;
      state.multiHeight = null;
      state.multiX = null;
      state.multiY = null;
      state.multiAngle = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isUndoOrRedo, (state) => {
      state.forceRebuild = true;
    });
    builder.addMatcher(isUndoOrRedo, (state) => {
      state.forceRebuildMulti = true;
    });
  },
});
export const {
  toggleGroupLayer,
  setWidth,
  setHeight,
  setOffsetX,
  setOffsetY,
  selectChildLayer,
  toggleTextLayerEditMode,
  toggleForceRebuild,
  toggleForceRebuildMulti,
  setMultiWidth,
  setMultiHeight,
  setMultiX,
  setMultiY,
  setMultiOffsetX,
  setMultiOffsetY,
  setMultiAngle,
  clearMulti,
} = groupLayerHelperSlice.actions;
export default groupLayerHelperSlice.reducer;
