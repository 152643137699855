import { memo } from 'react';

interface IHide {
  className?: string;
}

const Hide = ({ className }: IHide): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99968 5.66683C10.8663 5.66683 12.333 7.1335 12.333 9.00017C12.333 9.1335 12.333 9.3335 12.2663 9.46683L14.2663 11.4668C14.7997 10.8002 15.1997 10.0668 15.5997 9.26683C15.6663 9.06683 15.6663 8.9335 15.5997 8.7335C14.2663 5.60016 11.733 3.66683 8.99968 3.66683C8.26634 3.66683 7.53301 3.80016 6.86634 4.06683L8.53301 5.7335C8.66634 5.66683 8.86634 5.66683 8.99968 5.66683ZM15.4663 14.5335L13.3997 12.4668L11.733 10.8668L10.733 9.86683L8.06634 7.20017L7.13301 6.20016L5.66634 4.66683L3.46634 2.5335C3.19967 2.26683 2.79967 2.26683 2.53301 2.5335C2.26634 2.80016 2.26634 3.20016 2.53301 3.46683L4.59968 5.5335C3.66634 6.46683 2.93301 7.5335 2.39967 8.7335C2.33301 8.9335 2.33301 9.06683 2.39967 9.26683C3.73301 12.4002 6.26634 14.3335 8.99968 14.3335C10.1997 14.3335 11.333 13.9335 12.333 13.2668L14.533 15.4668C14.7997 15.7335 15.1997 15.7335 15.4663 15.4668C15.733 15.2002 15.733 14.8002 15.4663 14.5335ZM8.99968 12.3335C7.13301 12.3335 5.66634 10.8668 5.66634 9.00017C5.66634 8.3335 5.86634 7.7335 6.19968 7.20017L7.19968 8.20017C7.06634 8.40017 6.99968 8.7335 6.99968 9.00017C6.99968 10.1335 7.86634 11.0002 8.99968 11.0002C9.26634 11.0002 9.59968 10.9335 9.86634 10.8002L10.8663 11.8002C10.2663 12.1335 9.66634 12.3335 8.99968 12.3335Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Hide);
