export interface IGradientColor {
  [key: string]: any;
  colorType?: ColorType;
  type: GradientType;
  leftColor: string;
  leftColorPercent: number;
  rightColor: string;
  rightColorPercent: number;
  angle: number;
  horizontalDirection: number;
  verticalDirection: number;
  // constructor(isBgColor?: boolean) {
  //   this.leftColor = isBgColor ? '' : 'rgba(52, 52, 52, 1)';
  // }
}

export type ColorType = 'fillColor' | 'borderColor' | 'bgColor';
export type GradientType = 'solid' | 'linear' | 'radial';

export enum gradientTypes {
  SOLID = 'solid',
  LINEAR = 'linear',
  RADIAL = 'radial',
}
