export default [
  {
    id: 'custom-preset-01',
    title: 'Learn more',
    layer: {
      _id: '501b57bf-2979-48dd-807b-3b83d15de39d',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 297.95,
          offsetX: 116.15,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 125.24,
          height: 38.38,
          fullscreen: false,
          originalWidth: 124,
          originalHeight: 52,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {
            width: null,
            height: null,
          },
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          _id: 'ec452982-60a4-474e-b20d-c876a6d5561e',
          position: 0,
          isTitleDirty: false,
          author: '62e11d1e6a7fbe0029fd9e58',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 265,
              initialOffsetX: 127,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 297.95,
              offsetX: 116.15,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: 30,
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 125.24,
              height: 38.38,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: '2582a6b3-7db2-4c8e-bb25-ee90ad02fe7c',
          position: 1,
          isTitleDirty: false,
          author: '62e11d1e6a7fbe0029fd9e58',
          title: 'Learn more',
          type: 'html',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 308.05,
              offsetX: 130.29,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 96.96000000000001,
              height: 21.21,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value:
              '{"blocks":[{"key":"fn852","text":"Learn more","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"FONT_FAMILY_Heebo"},{"offset":0,"length":10,"style":"FONT_COLOR_rgba(0, 0, 0, 1)"},{"offset":0,"length":10,"style":"FONT_SIZE_26"},{"offset":0,"length":10,"style":"FONT_SPACING_1_4"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="font-family: &quot;Heebo&quot;; white-space: break-spaces; color: rgba(0, 0, 0, 1); font-size: 1.625em; line-height: 1.4">Learn more</span></p>',
            shape: '',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
      ],
    },
    styles: {
      fontFamily: 'Roboto',
      color: 'var(--black)',
      background: 'var(--white)',
      borderRadius: '3px',
      fontWeight: 'bold',
      justifyContent: 'center',
    },
  },
  {
    id: 'custom-preset-02',
    title: 'Learn more',
    layer: {
      _id: '3c39c742-6fa4-4bab-9f6c-2d20dfd87f27',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 301,
          offsetX: 117,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 127,
          height: 39,
          fullscreen: false,
          originalWidth: 127,
          originalHeight: 39,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {},
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          _id: 'd4c706e2-7727-42c5-982d-52d87af9adfe',
          position: 0,
          isTitleDirty: false,
          author: '62e11d1e6a7fbe0029fd9e58',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 265,
              initialOffsetX: 127,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 300.9295,
              offsetX: 117.31150000000001,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: 30,
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 126.49239999999999,
              height: 38.7638,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(0, 0, 0, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: 'eeabca99-c5af-42ab-a563-7bac736e3996',
          position: 1,
          isTitleDirty: false,
          author: '62e11d1e6a7fbe0029fd9e58',
          title: 'Learn more',
          type: 'html',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 310,
              offsetX: 133,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 96,
              height: 21,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value:
              '{"blocks":[{"key":"fn852","text":"Learn more","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"FONT_FAMILY_Heebo"},{"offset":0,"length":10,"style":"FONT_SIZE_26"},{"offset":0,"length":10,"style":"FONT_SPACING_1_4"},{"offset":0,"length":10,"style":"FONT_COLOR_rgba(255, 255, 255, 1)"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="font-family: &quot;Heebo&quot;; white-space: break-spaces; font-size: 1.625em; line-height: 1.4; color: rgba(255, 255, 255, 1)">Learn more</span></p>',
            shape: '',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
      ],
    },
    styles: {
      fontFamily: 'Roboto',
      justifyContent: 'center',
      color: 'var(--white)',
      background: 'var(--black)',
      borderRadius: '3px',
      fontWeight: 'bold',
    },
  },
  {
    id: 'custom-preset-03',
    title: 'Click here',
    layer: {
      _id: '3fc975b3-22af-4632-88f7-f2d62633107d',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 296,
          offsetX: 115,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 124,
          height: 38,
          fullscreen: false,
          originalWidth: 124,
          originalHeight: 55,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {},
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          _id: '17489eae-bcb4-412d-b9a1-b5a2ff2b6dc4',
          position: 0,
          isTitleDirty: false,
          author: '62e11d1e6a7fbe0029fd9e58',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 265,
              initialOffsetX: 127,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 296,
              offsetX: 115,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: 100,
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 124,
              height: 38,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(246, 82, 43, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {},
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: '021a9064-546a-46a8-8dae-8f9ebbb922c4',
          position: 1,
          isTitleDirty: false,
          author: '62e11d1e6a7fbe0029fd9e58',
          title: 'Click here',
          type: 'html',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 303,
              offsetX: 129,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 96,
              height: 24,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {},
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value:
              '{"blocks":[{"key":"e9eta","text":"Click here","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"FONT_COLOR_rgb(255, 255, 255, 1)"},{"offset":0,"length":10,"style":"FONT_SIZE_26"},{"offset":0,"length":10,"style":"FONT_FAMILY_Poppins"},{"offset":0,"length":10,"style":"FONT_SPACING_1_6"},{"offset":0,"length":10,"style":"FONT_WEIGHT_700"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="color: rgb(255, 255, 255, 1); white-space: break-spaces; font-size: 1.625em; font-family: &quot;Poppins&quot;; line-height: 1.6; font-weight: 700">Click here</span></p>',
            shape: '',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
      ],
    },
    styles: {
      fontFamily: 'Poppins',
      justifyContent: 'center',
      color: 'var(--white)',
      background: '#F6522B',
      fontWeight: 'bold',
      borderRadius: '25px',
    },
  },
  {
    id: 'custom-preset-04',
    title: 'Shop now',
    layer: {
      _id: 'd60a3924-f112-4520-ac59-fb2560cef31b',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 296,
          offsetX: 115,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 124,
          height: 38,
          fullscreen: false,
          originalWidth: 124,
          originalHeight: 38,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {},
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          _id: 'e36d1d25-f36f-4871-b1c4-8501352120b2',
          position: 0,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 245,
              initialOffsetX: 117,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 296,
              offsetX: 115,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: 100,
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 124,
              height: 38,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(82, 113, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: 'edd96bad-0663-494d-a399-06237afdbc5b',
          position: 1,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Shop now',
          type: 'html',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 304,
              offsetX: 142,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 87,
              height: 22,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value:
              '{"blocks":[{"key":"d90oj","text":"Shop now","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":8,"style":"FONT_COLOR_rgb(255, 255, 255, 1)"},{"offset":0,"length":8,"style":"FONT_FAMILY_Poppins"},{"offset":0,"length":8,"style":"FONT_WEIGHT_400"},{"offset":0,"length":8,"style":"FONT_SIZE_26"},{"offset":0,"length":8,"style":"FONT_SPACING_1_5"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="color: rgb(255, 255, 255, 1); white-space: break-spaces; font-family: &quot;Poppins&quot;; font-weight: 400; font-size: 1.625em; line-height: 1.5">Shop now</span></p>',
            shape: '',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: '3cb5b7cc-925b-49cf-9e26-94f21789394d',
          temporaryId: '358d83f7-2902-4dae-a33e-0f8482ff9f27',
          position: 2,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Image Layer',
          type: 'image',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 305,
              offsetX: 125,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 20,
              height: 20,
              fullscreen: false,
              originalWidth: 594,
              originalHeight: 600,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'https://cutnut-web-prod.s3.eu-central-1.amazonaws.com/Gradients/Plus.svg',
            link: '',
            html: '',
            shape: '',
            image: {
              url: 'https://cutnut-web-prod.s3.eu-central-1.amazonaws.com/Gradients/Plus.svg',
              id: '62de85a033acdb0033ad28fb',
              name: 'plus',
            },
            video: {
              url: '',
              name: '',
            },
            originalName: 'white-plus-icon-3',
          },
        },
      ],
    },
    styles: {
      fontFamily: 'Poppins',
      color: 'var(--white)',
      justifyContent: 'start',
      background: '#5271FF',
      fontWeight: 'bold',
      borderRadius: '25px',
      padding: '0 0 0 5px',
    },
    plusIcon: true,
  },
  {
    id: 'custom-preset-05',
    title: 'Learn more',
    layer: {
      _id: '746e60da-e9c6-4268-bf97-9ce968f38137',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 295,
          offsetX: 115,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 125,
          height: 40,
          fullscreen: false,
          originalWidth: 125,
          originalHeight: 40,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {},
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          _id: 'ac54ca93-bca0-4d3c-8606-847bd6fc8387',
          position: 0,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 265,
              initialOffsetX: 127,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 298,
              offsetX: 118,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 122,
              height: 37,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(184, 112, 2, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {},
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: '2d5ade8b-2bf8-4ce5-a123-8dc80508dc7e',
          position: 1,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 245,
              initialOffsetX: 117,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 295,
              offsetX: 115,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 121,
              height: 36,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 189, 89, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: '53964e13-51a4-46bb-b032-c3dd5975b386',
          position: 2,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Learn more',
          type: 'html',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 301,
              offsetX: 127,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 97,
              height: 25,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value:
              '{"blocks":[{"key":"17ehb","text":"Learn more","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"FONT_COLOR_rgba(0, 0, 0, 1)"},{"offset":0,"length":10,"style":"FONT_FAMILY_Oswald"},{"offset":0,"length":10,"style":"FONT_WEIGHT_400"},{"offset":0,"length":10,"style":"FONT_SIZE_34"},{"offset":0,"length":10,"style":"FONT_SPACING_1_3"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="color: rgba(0, 0, 0, 1); white-space: break-spaces; font-family: &quot;Oswald&quot;; font-weight: 400; font-size: 2.125em; line-height: 1.3">Learn more</span></p>',
            shape: '',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
      ],
    },
    styles: {
      justifyContent: 'center',
      fontFamily: 'Oswald',
      color: 'var(--black)',
      background: '#FFBD59',
      boxShadow: '4px 4px 0px 0px rgba(184, 112, 2, 1)',
    },
  },
  {
    id: 'custom-preset-06',
    title: 'Show now',
    layer: {
      _id: '273feb17-4c80-4c50-a8a5-f3eb5d574c7f',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 296,
          offsetX: 115,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 124,
          height: 38,
          fullscreen: false,
          originalWidth: 124,
          originalHeight: 38,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {},
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          _id: '5e2c291a-e5ae-40e6-8853-e47194b1c503',
          position: 0,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 245,
              initialOffsetX: 117,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 296,
              offsetX: 115,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: 43,
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 124,
              height: 38,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(2, 187, 212, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: 0,
                height: 0,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: 'd4f47877-51d3-4ec4-bff2-7f176d12f07a',
          temporaryId: 'b0ae79e0-e06b-4440-98d8-433bce7cacc8',
          position: 1,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Image Layer',
          type: 'image',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 305,
              offsetX: 210,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 20,
              height: 20,
              fullscreen: false,
              originalWidth: 1000,
              originalHeight: 1000,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: null,
                height: null,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'https://cutnut-web-prod.s3.eu-central-1.amazonaws.com/Gradients/Tags.svg',
            link: '',
            html: '',
            shape: '',
            image: {
              url: 'https://cutnut-web-prod.s3.eu-central-1.amazonaws.com/Gradients/Tags.svg',
              id: '62de8df733acdb0033ad2992',
              name: 'Tags',
            },
            video: {
              url: '',
              name: '',
            },
            originalName: 'Tags (1)',
          },
        },
        {
          _id: '2dcd4b62-ccdb-4ebd-8f7d-5e1d583c099f',
          position: 2,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Shop now',
          type: 'html',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 304,
              offsetX: 128,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 77,
              height: 22,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {},
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value:
              '{"blocks":[{"key":"8mj0n","text":"Shop now","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":8,"style":"FONT_COLOR_rgb(255, 255, 255, 1)"},{"offset":0,"length":8,"style":"FONT_FAMILY_Poppins"},{"offset":0,"length":8,"style":"FONT_SIZE_26"},{"offset":0,"length":8,"style":"FONT_SPACING_1_5"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="color: rgb(255, 255, 255, 1); white-space: break-spaces; font-family: &quot;Poppins&quot;; font-size: 1.625em; line-height: 1.5">Shop now</span></p>',
            shape: '',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
      ],
    },
    styles: {
      justifyContent: 'center',
      color: 'var(--white)',
      background: '#02BBD4',
      borderRadius: '6px',
      fontFamily: 'Poppins',
    },
    labelIcon: true,
  },
  {
    id: 'custom-preset-07',
    title: 'Get Started',
    layer: {
      _id: 'c8d3df4e-c9dd-4c54-8943-93e0fe1adcbd',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 296,
          offsetX: 115,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 124,
          height: 38,
          fullscreen: false,
          originalWidth: 124,
          originalHeight: 38,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {},
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          _id: '66437dd1-4c36-48dd-9484-28cb50dae574',
          position: 0,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Shape Layer',
          type: 'shape',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: 245,
              initialOffsetX: 117,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 296,
              offsetX: 115,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: 31,
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 124,
              height: 38,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(0, 128, 55, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: 0,
                height: 0,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: 'dd707401-a19a-4f99-a281-125ccc30cb05',
          position: 1,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Get Started',
          type: 'html',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 304,
              offsetX: 122,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 91,
              height: 22,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: 0,
                height: 0,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value:
              '{"blocks":[{"key":"8mj0n","text":"Get Started","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"FONT_COLOR_rgb(255, 255, 255, 1)"},{"offset":0,"length":11,"style":"FONT_FAMILY_Poppins"},{"offset":0,"length":11,"style":"FONT_WEIGHT_400"},{"offset":0,"length":11,"style":"FONT_SIZE_26"},{"offset":0,"length":11,"style":"FONT_SPACING_1_5"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="color: rgb(255, 255, 255, 1); white-space: break-spaces; font-family: &quot;Poppins&quot;; font-weight: 400; font-size: 1.625em; line-height: 1.5">Get Started</span></p>',
            shape: '',
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
          },
        },
        {
          _id: '950cab3a-3560-4ab1-9a9c-0857a14f453e',
          temporaryId: 'e4d171af-0173-42c3-ad8a-e9ba0e4fa10d',
          position: 2,
          isTitleDirty: false,
          author: '625414473e664b00325ce891',
          title: 'Image Layer',
          type: 'image',
          settings: {
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
            generalSettings: {
              initialOffsetY: '0',
              initialOffsetX: '0',
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 307,
              offsetX: 213,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: 0,
              round: '0',
              shadow: '0',
              locked: false,
            },
            layerSettings: {
              width: 20,
              height: 18,
              fullscreen: false,
              originalWidth: 16,
              originalHeight: 14,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: 0,
                height: 0,
              },
              isLayerHidden: false,
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
          },
          content: {
            value: 'https://cutnut-web-prod.s3.eu-central-1.amazonaws.com/Gradients/Chevron-Right.svg',
            link: '',
            html: '',
            shape: '',
            image: {
              url: 'https://cutnut-web-prod.s3.eu-central-1.amazonaws.com/Gradients/Chevron-Right.svg',
              id: '62de9a0533acdb0033ad2a0e',
              name: 'Chevron-Right',
            },
            video: {
              url: '',
              name: '',
            },
            originalName: 'Chevron-Right',
          },
        },
      ],
    },
    styles: {
      justifyContent: 'center',
      fontFamily: 'Poppins',
      color: 'var(--white)',
      background: '#008037',
      borderRadius: '3px',
    },
    chevron: true,
  },
  {
    id: 'custom-preset-08',
    title: 'Discover',
    layer: {
      _id: '7ad9be82-8390-4664-b685-aa0000305ae6',
      position: 0,
      type: 'group',
      title: 'Group',
      isTitleDirty: false,
      content: {
        value: '',
        link: '',
        html: '',
        shape: '',
        image: {
          url: '',
          name: '',
        },
        video: {
          url: '',
          name: '',
        },
      },
      settings: {
        animateIn: '',
        animateInDuration: 2,
        animateInDelay: 0,
        animateOut: '',
        animateOutDuration: 2,
        animateOutDelay: 3,
        generalSettings: {
          initialOffsetY: '0',
          initialOffsetX: '0',
          innerOffsetY: '0',
          innerOffsetX: '0',
          offsetY: 296,
          offsetX: 115,
          margin: '0',
          opacity: '100',
          position: '0',
          rotate: 0,
          round: '0',
          shadow: '0',
          locked: false,
        },
        layerSettings: {
          width: 120,
          height: 34,
          fullscreen: false,
          originalWidth: 120,
          originalHeight: 34,
          mute: false,
          shapeStyles: {
            borderColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'borderColor',
            },
            fillColor: {
              type: 'solid',
              leftColor: 'rgba(255, 255, 255, 1)',
              leftColorPercent: 0,
              rightColor: 'rgba(255, 255, 255, 1)',
              rightColorPercent: 100,
              angle: 90,
              horizontalDirection: 50,
              verticalDirection: 50,
              colorType: 'fillColor',
            },
            thickness: 0,
            relativeThickness: 0,
            round: '25',
          },
          locked: true,
          permissionForFullScreen: false,
          fullScreenConfig: {},
          isLayerHidden: false,
        },
        linkSettings: {
          isEnabled: true,
          link: '',
          text: 'Click here',
          icon: null,
        },
      },
      childLayers: [
        {
          content: {
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
            compressed: false,
            value: 'square',
            link: '',
            html: '',
            shape: 'square',
          },
          settings: {
            generalSettings: {
              round: '30',
              locked: false,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 296,
              offsetX: 115,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: '0',
              shadow: '0',
            },
            layerSettings: {
              isUnsplash: false,
              isLayerHidden: false,
              width: 120,
              height: 34,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(0, 0, 0, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 2,
                relativeThickness: 1.84,
                round: '25',
              },
              locked: false,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: 0,
                height: 0,
              },
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
          },
          position: 0,
          isTitleDirty: false,
          _id: '2bfa42d6-cd1b-4b55-a3b1-196abfca8b9a',
          title: 'Shape Layer (copy)',
          type: 'shape',
          updatedAt: '2022-07-22T16:12:51.413Z',
          createdAt: '2022-07-22T16:12:51.413Z',
          childLayers: [],
        },
        {
          content: {
            image: {
              url: '',
              name: '',
            },
            video: {
              url: '',
              name: '',
            },
            compressed: false,
            value:
              '{"blocks":[{"key":"dfn85","text":"Discover","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":8,"style":"FONT_COLOR_rgba(255, 255, 255, 1)"},{"offset":0,"length":8,"style":"FONT_FAMILY_Roboto"},{"offset":0,"length":8,"style":"FONT_WEIGHT_400"},{"offset":0,"length":8,"style":"FONT_SIZE_26"},{"offset":0,"length":8,"style":"FONT_SPACING_1_5"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
            link: '',
            html:
              '<p style="text-align: center; font-size: inherit"><span style="color: rgba(255, 255, 255, 1); white-space: break-spaces; font-family: &quot;Roboto&quot;; font-weight: 400; font-size: 1.625em; line-height: 1.5">Discover</span></p>',
            shape: '',
          },
          settings: {
            generalSettings: {
              round: '0',
              locked: false,
              innerOffsetY: '0',
              innerOffsetX: '0',
              offsetY: 304,
              offsetX: 142,
              margin: '0',
              opacity: '100',
              position: '0',
              rotate: '0',
              shadow: '0',
            },
            layerSettings: {
              isUnsplash: false,
              isLayerHidden: false,
              width: 70.0388198757764,
              height: 22,
              fullscreen: false,
              originalWidth: 0,
              originalHeight: 0,
              mute: false,
              shapeStyles: {
                borderColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'borderColor',
                },
                fillColor: {
                  type: 'solid',
                  leftColor: 'rgba(255, 255, 255, 1)',
                  leftColorPercent: 0,
                  rightColor: 'rgba(255, 255, 255, 1)',
                  rightColorPercent: 100,
                  angle: 90,
                  horizontalDirection: 50,
                  verticalDirection: 50,
                  colorType: 'fillColor',
                },
                thickness: 0,
                relativeThickness: 0,
                round: '25',
              },
              locked: true,
              permissionForFullScreen: false,
              fullScreenConfig: {
                width: 0,
                height: 0,
              },
            },
            linkSettings: {
              isEnabled: false,
              link: '',
              text: 'Click here',
              icon: null,
            },
            animateIn: '',
            animateInDuration: 2,
            animateInDelay: 0,
            animateOut: '',
            animateOutDuration: 2,
            animateOutDelay: 3,
          },
          position: 1,
          isTitleDirty: false,
          _id: '42ca729c-d298-4b6e-841f-b328fe9de74b',
          title: 'Discover',
          type: 'html',
          updatedAt: '2022-07-22T16:12:51.413Z',
          createdAt: '2022-07-22T16:12:51.413Z',
          childLayers: [],
        },
      ],
    },
    styles: {
      justifyContent: 'center',
      fontFamily: 'Roboto',
      color: 'var(--white)',
      background: 'var(--shade-900-85)',
      borderRadius: '3px',
      border: '2px solid var(--white)',
    },
  },
];
