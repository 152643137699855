import { memo } from 'react';

interface IEmojiRocket {
  className?: string;
}

const EmojiRocket = ({ className }: IEmojiRocket): JSX.Element => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.40593 15.2188C4.65593 13.9688 4.78093 13.6563 5.56218 14.4375C6.34343 15.2188 6.03093 15.3125 4.78093 16.5938C3.84343 17.5313 2.65593 17.3438 2.65593 17.3438C2.65593 17.3438 2.46843 16.1563 3.40593 15.2188Z"
        fill="#FF9D27"
      />
      <path
        d="M5.78078 16.5C6.28078 15.1875 6.43703 15.0313 5.71828 14.625C4.99953 14.2188 4.99953 14.5 4.53078 15.8125C4.15578 16.7813 4.59328 17.6563 4.59328 17.6563C4.59328 17.6563 5.43703 17.5 5.78078 16.5Z"
        fill="#FF9D27"
      />
      <path
        d="M5.06227 15.2812C5.34352 14.5624 5.34352 14.4062 5.71852 14.6249C6.12477 14.8437 6.03102 14.9374 5.74977 15.6562C5.56227 16.1874 5.09352 16.3124 5.09352 16.3124C5.09352 16.3124 4.87477 15.8437 5.06227 15.2812Z"
        fill="#FDF516"
      />
      <path
        d="M5.34375 14.2812C4.9375 13.5624 4.78125 13.7187 3.46875 14.2187C2.5 14.5937 2.3125 15.4062 2.3125 15.4062C2.3125 15.4062 3.1875 15.8437 4.15625 15.4687C5.46875 14.9687 5.75 14.9687 5.34375 14.2812Z"
        fill="#FF9D27"
      />
      <path
        d="M4.6875 14.9375C5.40625 14.6563 5.5625 14.6563 5.34375 14.2813C5.125 13.875 5.03125 13.9688 4.3125 14.25C3.78125 14.4375 3.65625 14.9063 3.65625 14.9063C3.65625 14.9063 4.15625 15.125 4.6875 14.9375Z"
        fill="#FDF516"
      />
      <path
        d="M4.34332 14.875C5.03082 14.1875 5.09332 14 5.53082 14.4375C5.96832 14.875 5.78082 14.9375 5.09332 15.625C4.56207 16.1562 3.90582 16.0312 3.90582 16.0312C3.90582 16.0312 3.84332 15.4062 4.34332 14.875Z"
        fill="#FDF516"
      />
      <path
        d="M5.78125 11.8751C3.84375 8.62505 0.625 9.9688 0.625 9.9688C0.625 9.9688 5.21875 5.37505 8.3125 8.4688L5.78125 11.8751Z"
        fill="#3BAACF"
      />
      <path
        d="M7.28125 9.4688L8.28125 8.4688C5.21875 5.37505 0.625 9.9688 0.625 9.9688C0.625 9.9688 4.65625 7.0938 7.28125 9.4688Z"
        fill="#428BC1"
      />
      <path
        d="M8.125 14.2187C11.375 16.1562 10.0312 19.375 10.0312 19.375C10.0312 19.375 14.625 14.7813 11.5313 11.6875L8.125 14.2187Z"
        fill="#3BAACF"
      />
      <path
        d="M10.5313 12.7188L11.5313 11.7188C14.625 14.8125 10.0312 19.4062 10.0312 19.4062C10.0312 19.4062 12.9063 15.3437 10.5313 12.7188Z"
        fill="#428BC1"
      />
      <path
        d="M15.2503 9.65626C11.5941 13.2813 7.56283 15.25 6.15658 13.8438C4.75033 12.4375 6.71908 8.40626 10.3441 4.75001C14.5941 0.500012 19.3128 0.687512 19.3128 0.687512C19.3128 0.687512 19.4691 5.40626 15.2503 9.65626Z"
        fill="#C5D0D8"
      />
      <path
        d="M14.3122 8.625C10.6872 12.25 7.06221 14.625 6.21846 13.7812C5.37471 12.9375 7.74971 9.3125 11.3747 5.6875C15.6247 1.4375 19.3747 0.625 19.3747 0.625C19.3747 0.625 18.5622 4.375 14.3122 8.625Z"
        fill="#DAE3EA"
      />
      <path
        d="M7.59375 14.8438C7.4375 15 7.1875 15 7.03125 14.8438L5.15625 12.9688C5 12.8125 5 12.5312 5.15625 12.375L5.71875 11.8125L8.15625 14.25L7.59375 14.8438Z"
        fill="#C94747"
      />
      <path
        d="M7.0626 14.2813C6.90635 14.4375 6.71885 14.5 6.6251 14.4062L5.5626 13.3438C5.46885 13.25 5.53135 13.0625 5.6876 12.9062L6.2501 12.3438L7.6251 13.7188L7.0626 14.2813Z"
        fill="#F15744"
      />
      <path
        d="M6.53121 15.0625C6.43746 15.1563 6.21872 15.1563 6.12497 15.0625L4.90622 13.8437C4.81247 13.75 4.84372 13.5625 4.93747 13.4687L5.31247 13.0938L6.90622 14.6875L6.53121 15.0625Z"
        fill="#3E4347"
      />
      <path
        d="M6.28125 14.8125C6.1875 14.9063 6 14.9375 5.9375 14.875L5.09375 14.0313C5.03125 13.9688 5.0625 13.8125 5.1875 13.7188L5.5625 13.3438L6.65625 14.4375L6.28125 14.8125Z"
        fill="#62727A"
      />
      <path
        d="M19.3125 0.6875C19.3125 0.6875 17.625 0.625 15.3438 1.5L18.5 4.65625C19.375 2.375 19.3125 0.6875 19.3125 0.6875Z"
        fill="#C94747"
      />
      <path
        d="M19.3125 0.6875C19.3125 0.6875 17.9688 0.96875 15.9375 2.125L17.875 4.0625C19.0313 2.03125 19.3125 0.6875 19.3125 0.6875Z"
        fill="#F15744"
      />
      <path
        d="M13.5938 7.96875C14.4567 7.96875 15.1562 7.26919 15.1562 6.40625C15.1562 5.54331 14.4567 4.84375 13.5938 4.84375C12.7308 4.84375 12.0312 5.54331 12.0312 6.40625C12.0312 7.26919 12.7308 7.96875 13.5938 7.96875Z"
        fill="#EDF4F9"
      />
      <path
        d="M13.5938 7.4375C14.1633 7.4375 14.625 6.97579 14.625 6.40625C14.625 5.83671 14.1633 5.375 13.5938 5.375C13.0242 5.375 12.5625 5.83671 12.5625 6.40625C12.5625 6.97579 13.0242 7.4375 13.5938 7.4375Z"
        fill="#3BAACF"
      />
      <path
        d="M10.4688 11.0938C11.3317 11.0938 12.0312 10.3942 12.0312 9.53125C12.0312 8.66831 11.3317 7.96875 10.4688 7.96875C9.60581 7.96875 8.90625 8.66831 8.90625 9.53125C8.90625 10.3942 9.60581 11.0938 10.4688 11.0938Z"
        fill="#EDF4F9"
      />
      <path
        d="M10.4687 10.5625C11.0383 10.5625 11.5 10.1008 11.5 9.53125C11.5 8.96171 11.0383 8.5 10.4687 8.5C9.89921 8.5 9.4375 8.96171 9.4375 9.53125C9.4375 10.1008 9.89921 10.5625 10.4687 10.5625Z"
        fill="#3BAACF"
      />
      <path
        d="M15.2813 2.15625C15.1875 2.25 15 2.25 14.9062 2.15625C14.8125 2.0625 14.8125 1.875 14.9062 1.78125C15 1.6875 15.1875 1.6875 15.2813 1.78125C15.375 1.875 15.375 2.0625 15.2813 2.15625Z"
        fill="white"
      />
      <path
        d="M15.8125 2.9375C15.9506 2.9375 16.0625 2.82557 16.0625 2.6875C16.0625 2.54943 15.9506 2.4375 15.8125 2.4375C15.6744 2.4375 15.5625 2.54943 15.5625 2.6875C15.5625 2.82557 15.6744 2.9375 15.8125 2.9375Z"
        fill="white"
      />
      <path
        d="M16.5625 3.6875C16.7006 3.6875 16.8125 3.57557 16.8125 3.4375C16.8125 3.29943 16.7006 3.1875 16.5625 3.1875C16.4244 3.1875 16.3125 3.29943 16.3125 3.4375C16.3125 3.57557 16.4244 3.6875 16.5625 3.6875Z"
        fill="white"
      />
      <path
        d="M17.2812 4.4375C17.4193 4.4375 17.5312 4.32557 17.5312 4.1875C17.5312 4.04943 17.4193 3.9375 17.2812 3.9375C17.1432 3.9375 17.0312 4.04943 17.0312 4.1875C17.0312 4.32557 17.1432 4.4375 17.2812 4.4375Z"
        fill="white"
      />
      <path
        d="M18.0312 5.15625C18.1693 5.15625 18.2812 5.04432 18.2812 4.90625C18.2812 4.76818 18.1693 4.65625 18.0312 4.65625C17.8932 4.65625 17.7812 4.76818 17.7812 4.90625C17.7812 5.04432 17.8932 5.15625 18.0312 5.15625Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(EmojiRocket);
