import CreatableSelect from 'react-select/creatable';
import { ActionMeta, components, createFilter, OptionProps, OptionsType, StylesConfig } from 'react-select';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { setSelectedTags } from 'redux/features/tags/tagsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useCreateTagMutation } from 'redux/services/tags/tags';

type Option = {
  label: string;
  value: string;
  id: string;
};

interface IProps {
  options: Option[];
  workspaceId: string;
  isLoading: boolean;
}

function OptimizedOption(props: OptionProps<Option, true>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  delete props.innerProps.onMouseMove;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  delete props.innerProps.onMouseOver;

  return <components.Option {...props}>{props.children}</components.Option>;
}

const selectStyles: StylesConfig<Option, true> = {
  container: (styles) => ({ ...styles, minWidth: '253px', borderRadius: '6px' }),
  control: (styles, state) => ({
    ...styles,
    minHeight: '34px',
    background: 'var(--shade-700-85)',
    borderRadius: '6px',
    border: `1px solid ${
      state.menuIsOpen ? 'var(--primary)' : state.isFocused ? 'var(--shade-300-85)' : 'transparent'
    }`,
    boxShadow: 'unset',
    cursor: 'text',
    '&:hover': {
      borderColor: state.menuIsOpen ? 'var(--primary)' : 'var(--shade-300-85)',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--shade-300-85) transparent',
    },
    maxHeight: '90px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '2px',
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '12px',
      transition: 'background-color 0.12s ease',
    },
    '&:hover::-webkit-scrollbar': {
      width: '2px',
      borderRadius: '2px',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: state.menuIsOpen ? 'var(--primary)' : 'var(--shade-300-85)',
    },
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles) => ({ ...styles, display: 'none' }),
  valueContainer: (styles) => ({ ...styles, paddingLeft: '6px' }),
  clearIndicator: (styles) => ({ ...styles, display: 'none' }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: 'var(--shade-300)',
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: 'var(--white)',
  }),
  menu: (styles) => ({
    ...styles,
    padding: 0,
    background: 'var(--shade-700-85)',
    border: '1px solid var(--white-10)',
    boxShadow: '24px 32px 72px var(--black-18)',
    backdropFilter: 'blur(50px)',
    borderRadius: '12px',
    overflow: 'hidden',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '16px 12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    scrollbarWidth: 'none',
    borderRadius: '12px',
    '&::-webkit-scrollbar': {
      width: '2px',
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '12px',
      transition: 'background-color 0.12s ease',
    },
    '&:hover': {
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--shade-300-85) transparent',
    },
    '&:hover::-webkit-scrollbar': {
      width: '2px',
      borderRadius: '2px',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--shade-300-85)',
    },
  }),
  option: (styles) => ({
    ...styles,
    padding: '4px 8px',
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.01em',
    color: 'var(--shade-100)',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 225ms ease, color 225ms ease',
    '&:hover': {
      backgroundColor: 'var(--primary)',
      color: 'var(--shade-900)',
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    padding: '1px 8px',
    backgroundColor: 'var(--shade-300-85)',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    cursor: 'default',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: 'var(--white)',
    padding: 0,
    paddingLeft: 0,
    margin: 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0,
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--shade-100)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--shade-100)',
      '> svg': {
        color: 'var(--shade-900)',
      },
    },
    '> svg': {
      width: '10px',
      height: '10px',
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: 'var(--shade-300)',
    padding: 0,
  }),
};

const TagsDropdown: React.VFC<IProps> = ({ options, workspaceId, isLoading }) => {
  const dispatch = useAppDispatch();
  const selectedTags = useAppSelector((state) => state.tags.selectedTags);

  const [createNewTag, { isLoading: isCreateTagLoading }] = useCreateTagMutation();
  const filterOption = useMemo(() => createFilter({ ignoreAccents: false }), []);

  const handleChange = useCallback(
    (newValue: OptionsType<Option>, actionMeta: ActionMeta<Option>) => {
      // Todo if tag is not used anywere else remove it.
      dispatch(setSelectedTags(newValue));
    },
    [dispatch],
  );

  const handleCreate = useCallback(
    async (name: string) => {
      try {
        const newTag = await createNewTag({ name, workspaceId }).unwrap();
        dispatch(setSelectedTags([...selectedTags, { value: newTag?.name, label: newTag?.name, id: newTag?._id }]));
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch, createNewTag, workspaceId, selectedTags],
  );

  const handleCreateLabel = useCallback((input: string) => `${input} (New tag)`, []);

  const handleNoOptionsMessage = useCallback(() => `No tags available`, []);

  return (
    <CreatableSelect
      isMulti
      isLoading={isCreateTagLoading || isLoading}
      isDisabled={isCreateTagLoading || isLoading}
      placeholder="Enter tags"
      createOptionPosition="first"
      formatCreateLabel={handleCreateLabel}
      options={options}
      value={selectedTags}
      styles={selectStyles}
      onChange={handleChange}
      onCreateOption={handleCreate}
      closeMenuOnSelect={false}
      noOptionsMessage={handleNoOptionsMessage}
      filterOption={filterOption}
      components={{
        Option: OptimizedOption,
      }}
    />
  );
};

export default memo(TagsDropdown);
