import { memo } from 'react';

const DuplicateSlideSM = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.437 6.93747C10.7884 6.93747 10.273 6.93747 9.85142 6.96369C9.90743 6.68084 9.99424 6.45139 10.1288 6.24997C10.293 6.00429 10.5039 5.79335 10.7496 5.62919C11.3171 5.25 12.1071 5.25 13.6871 5.25C15.2672 5.25 16.0572 5.25 16.6247 5.62919C16.8703 5.79335 17.0813 6.00429 17.2454 6.24997C17.6246 6.81747 17.6246 7.60748 17.6246 9.1875V12C17.6246 13.58 17.6246 14.37 17.2454 14.9375C17.0813 15.1832 16.8703 15.3941 16.6247 15.5583C16.2901 15.7819 15.8782 15.8736 15.2727 15.9113C15.3745 15.3972 15.3745 14.7067 15.3745 13.6875V10.875C15.3745 9.29495 15.3745 8.50494 14.9953 7.93744C14.8311 7.69176 14.6202 7.48082 14.3745 7.31666C13.807 6.93747 13.017 6.93747 11.437 6.93747ZM6.75419 9.06247C6.375 9.62997 6.375 10.42 6.375 12V14.8125C6.375 16.3925 6.375 17.1825 6.75419 17.75C6.91835 17.9957 7.12929 18.2066 7.37497 18.3708C7.94247 18.75 8.73248 18.75 10.3125 18.75C11.8925 18.75 12.6825 18.75 13.25 18.3708C13.4957 18.2066 13.7066 17.9957 13.8708 17.75C14.25 17.1825 14.25 16.3925 14.25 14.8125V12C14.25 10.42 14.25 9.62997 13.8708 9.06247C13.7066 8.81679 13.4957 8.60585 13.25 8.44169C12.6825 8.0625 11.8925 8.0625 10.3125 8.0625C8.73248 8.0625 7.94247 8.0625 7.37497 8.44169C7.12929 8.60585 6.91835 8.81679 6.75419 9.06247Z"
      fill="#ABABBA"
    />
  </svg>
);

export default memo(DuplicateSlideSM);
