export const generateDate = (date: Date): string => {
  return date.toUTCString();
};

export const revertToJSDate = (date?: string): Date => {
  if (!date) {
    return new Date();
  }

  return new Date(date);
};

export const datesAreOnSameDay = (first: Date, second: Date): boolean =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();
