import { memo } from 'react';

const Insights = (): JSX.Element => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0431 5.14467C20.0431 3.593 21.3031 2.33301 22.8547 2.33301C24.4064 2.33301 25.6664 3.593 25.6664 5.14467C25.6664 6.69633 24.4064 7.95633 22.8547 7.95633C21.3031 7.95633 20.0431 6.69633 20.0431 5.14467ZM15.5513 17.2188L18.923 12.8683L18.8763 12.8917C19.063 12.635 19.098 12.3083 18.9696 12.0167C18.8425 11.725 18.5613 11.5267 18.2591 11.5033C17.943 11.4683 17.6291 11.6083 17.4413 11.865L14.6191 15.5167L11.3863 12.9733C11.188 12.8217 10.9547 12.7622 10.7213 12.7867C10.4892 12.8217 10.2792 12.9488 10.138 13.1355L6.68583 17.6283L6.61466 17.7333C6.41633 18.1055 6.50966 18.5838 6.85966 18.8416C7.023 18.9466 7.198 19.0167 7.39633 19.0167C7.66583 19.0283 7.92133 18.8871 8.08466 18.6667L11.013 14.8972L14.338 17.395L14.443 17.4638C14.8163 17.6622 15.283 17.57 15.5513 17.2188ZM18.0246 4.41002C17.978 4.70169 17.9546 4.99335 17.9546 5.28502C17.9546 7.91001 20.078 10.0322 22.6913 10.0322C22.983 10.0322 23.263 9.99834 23.5546 9.95167V19.3655C23.5546 23.3216 21.2213 25.6666 17.2546 25.6666H8.63416C4.66634 25.6666 2.33301 23.3216 2.33301 19.3655V10.7333C2.33301 6.76668 4.66634 4.41002 8.63416 4.41002H18.0246Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Insights);
