function intlFormat(num: number) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}

export function makeFriendly(num: number): string {
  if (num >= 1000000) {
    return intlFormat(num / 1000000) + 'M';
  }
  if (num >= 1000) {
    return intlFormat(num / 1000) + 'K';
  }
  return intlFormat(num);
}
