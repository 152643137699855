import getBrowser from './getBrowser';
import getOperatingSystem from './getOperatingSystem';

export default function (description: string) {
  return `
    ${description}

    Browser: ${getBrowser(window)}

    OS: ${getOperatingSystem(window)}
    `;
}
