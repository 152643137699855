import React from 'react';
import styled from 'styled-components';

const StyledGradientPickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

const GradientPickerWrapper: React.FC = ({ children }) => {
  return <StyledGradientPickerWrapper>{children}</StyledGradientPickerWrapper>;
};

export default GradientPickerWrapper;
