import { memo } from 'react';

interface IChevronupIcon {
  className?: string;
}

const ChevronUpIcon = ({ className }: IChevronupIcon): JSX.Element => (
  <svg className={className} width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9159 4.72479L10.1234 0.308123V0.308123C9.50758 -0.100337 8.50508 -0.103041 7.88422 0.302083C7.88115 0.304088 7.87809 0.306102 7.87504 0.308123L1.16171 4.72479V4.72479C0.540847 5.12992 0.536736 5.78946 1.15253 6.19791C1.15557 6.19994 1.15863 6.20195 1.16171 6.20395V6.20395C1.77927 6.60783 2.77664 6.60783 3.39421 6.20395L8.99921 2.51645L14.6042 6.20395V6.20395C14.8991 6.3964 15.2972 6.50489 15.7125 6.50604V6.50604C16.1334 6.50764 16.5379 6.39894 16.8367 6.20395V6.20395C17.4792 5.81372 17.5192 5.15469 16.9261 4.73199C16.9227 4.72958 16.9193 4.72717 16.9159 4.72479L16.9159 4.72479Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ChevronUpIcon);
