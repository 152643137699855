import { memo } from 'react';

interface IStoryShowcaseIcon {
  className?: string;
}

const StoryShowcaseIcon = ({ className }: IStoryShowcaseIcon): JSX.Element => {
  return (
    <svg
      className={className}
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6358 14.7698C20.6334 15.1321 20.3391 15.4244 19.9768 15.4244H6.03424C5.67085 15.4261 5.37471 15.1332 5.37231 14.7698V1.08848C5.37471 0.72621 5.66907 0.433844 6.03128 0.433844H19.9739C20.3373 0.432263 20.6334 0.725087 20.6358 1.08848V14.7698Z"
        fill="#ABABBA"
      />
      <path
        opacity="0.2"
        d="M20.6359 3.95043L15.4096 3.27825L20.6359 4.6813V3.95043ZM13.2565 1.1472L5.34155 4.6813L14.0887 1.49793V1.48916C13.9524 1.16592 13.5808 1.01321 13.2565 1.1472Z"
        fill="#505062"
      />
      <path
        opacity="0.2"
        d="M19.9768 0.433889H6.03424C5.67085 0.432256 5.37471 0.725081 5.37231 1.08847V1.23521C5.37471 0.872998 5.66907 0.580633 6.03128 0.580633H19.9739C20.3373 0.579 20.6334 0.871825 20.6358 1.23521V1.08847C20.6334 0.726203 20.3391 0.433838 19.9768 0.433838V0.433889Z"
        fill="white"
      />
      <path
        d="M8.75675 14.35C8.65337 14.6854 8.84022 15.0414 9.17504 15.1469L22.0903 18.6208C22.433 18.6966 22.7725 18.4815 22.8505 18.1394L25.893 6.7828C25.9967 6.44859 25.8122 6.09321 25.4791 5.98586L12.5638 2.51202C12.2211 2.43625 11.8816 2.65126 11.8036 2.99337L8.75675 14.35Z"
        fill="#505062"
      />
      <path
        opacity="0.2"
        d="M22.9841 10.8366L23.6636 14.9637L22.9841 17.4997V10.8366ZM15.4697 3.44702L16.0788 3.61142L16.4574 6.1578L15.4697 3.44702Z"
        fill="#21212C"
      />
      <path
        opacity="0.2"
        d="M25.4792 5.98586L12.5639 2.51202C12.2212 2.43625 11.8817 2.65126 11.8037 2.99337L8.75684 14.35C8.75684 14.369 8.75684 14.3881 8.74658 14.4072L11.767 3.13568C11.845 2.79357 12.1846 2.57855 12.5272 2.65432L25.4425 6.12821C25.592 6.17578 25.7185 6.27721 25.7974 6.41282C25.8763 6.54843 25.902 6.70853 25.8695 6.86203L25.893 6.77545C25.9921 6.44359 25.8085 6.09326 25.4792 5.98586H25.4792Z"
        fill="white"
      />
      <path
        d="M18.929 12.9513C19.0616 13.2763 18.9069 13.6474 18.5827 13.782L4.95261 18.7427C4.61799 18.8484 4.26073 18.6642 4.15276 18.3303L0.131434 7.28036C-2.48104e-06 6.95534 0.155313 6.58517 0.479262 6.45113L14.1093 1.49056C14.4444 1.38275 14.8035 1.56659 14.9121 1.9015L18.929 12.9514V12.9513Z"
        fill="#2E2E38"
      />
      <path
        opacity="0.2"
        d="M0.18276 7.41985C0.0512211 7.09524 0.20572 6.72522 0.529159 6.59062L14.1591 1.63C14.4891 1.52622 14.8416 1.7047 14.9532 2.03207L14.912 1.90145C14.8041 1.56755 14.4469 1.3833 14.1122 1.48907L0.479207 6.45117C0.155054 6.58577 0.000350595 6.95681 0.132859 7.28183L4.15423 18.3303V18.3406L0.18276 7.41985Z"
        fill="white"
      />
      <path
        d="M22.3237 6.15771H3.68462C3.31986 6.15771 3.02417 6.45341 3.02417 6.81816V20.9075C3.02417 21.2723 3.31986 21.568 3.68462 21.568H22.3237C22.6885 21.568 22.9842 21.2723 22.9842 20.9075V6.81816C22.9842 6.45341 22.6885 6.15771 22.3237 6.15771Z"
        fill="#14141F"
      />
      <path
        d="M13.848 10.2671H19.425C19.5028 10.2671 19.5775 10.298 19.6325 10.3531C19.6876 10.4081 19.7185 10.4828 19.7185 10.5606V11.4412C19.7185 11.519 19.6876 11.5937 19.6325 11.6488C19.5775 11.7038 19.5028 11.7347 19.425 11.7347H13.8479V10.2671H13.848ZM13.848 15.8441H19.425C19.5028 15.8441 19.5775 15.875 19.6325 15.9301C19.6876 15.9851 19.7185 16.0598 19.7185 16.1377V17.0183C19.7185 17.0961 19.6876 17.1708 19.6325 17.2258C19.5775 17.2808 19.5028 17.3118 19.425 17.3118H13.8479V15.8441H13.848ZM13.848 13.0556H20.3055C20.3833 13.0556 20.458 13.0866 20.513 13.1416C20.5681 13.1966 20.599 13.2713 20.599 13.3491V14.2297C20.599 14.3076 20.5681 14.3823 20.5131 14.4373C20.458 14.4923 20.3833 14.5233 20.3055 14.5233H13.848V13.0556Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M3.68462 6.30446H22.3238C22.6882 6.30528 22.9834 6.6005 22.9842 6.96491V6.81811C22.9834 6.4537 22.6882 6.15848 22.3238 6.15771H3.68462C3.32021 6.15848 3.02499 6.4537 3.02417 6.81811V6.96491C3.02499 6.6005 3.32021 6.30528 3.68462 6.30446Z"
        fill="white"
      />
      <path
        d="M8.85803 13.2025V14.5234H10.7571C10.6001 15.3306 9.89126 15.9147 8.85803 15.9147C7.68271 15.9147 6.72994 14.9619 6.72994 13.7866C6.72994 12.6113 7.68271 11.6585 8.85803 11.6585C9.36303 11.6489 9.85122 11.84 10.2156 12.1898L11.2209 11.1845C10.5824 10.5834 9.73492 10.2544 8.85803 10.2672C8.39435 10.2654 7.93487 10.3552 7.50597 10.5315C7.07707 10.7077 6.68719 10.9669 6.35869 11.2941C6.03018 11.6214 5.76953 12.0103 5.59167 12.4385C5.41382 12.8667 5.32227 13.3259 5.32227 13.7895C5.32227 14.2532 5.41382 14.7124 5.59167 15.1406C5.76953 15.5688 6.03018 15.9577 6.35869 16.285C6.68719 16.6122 7.07707 16.8714 7.50597 17.0476C7.93487 17.2238 8.39435 17.3136 8.85803 17.3119C10.8922 17.3119 12.2336 15.8824 12.2336 13.8703C12.2329 13.6466 12.2142 13.4232 12.1778 13.2025H8.85803Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(StoryShowcaseIcon);
