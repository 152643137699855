import styled from 'styled-components';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

export const WrappedToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }): JSX.Element => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export default styled(WrappedToastContainer)`
  .Toastify__toast-container {
    padding: 0px 0px;
    margin: 0px 0px;
    width: auto;
  }
  .Toastify__toast {
    padding: 0px 0px;
    margin: 0px 0px;
    min-height: 40px;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--info {
    background: #14141f;
    border-radius: 8px;
  }
  .Toastify__toast-body {
    margin: 9px 13px;

    color: #ffffff;
    font-family: Heebo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;

    font-family: Heebo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  .Toastify__close-button {
    display: none;
  }
  .Toastify__progress-bar {
  }
`;
