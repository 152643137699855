import styled from 'styled-components';
import WidgetEditor from '../components/widget-editor';
import WidgetEditorHeader from '../components/widget-editor-header';
import { ChangesContext } from 'context/widget/changes';
import debounce from 'lodash/debounce';
import { PreviewContext } from 'context/widget/is-preview';
import { pushCrisp } from '../components/crisp/utils/crispUtils';
import { useAppSelector, useSpinner } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetWidgetQuery, useUpdateWidgetMetadataMutation } from 'redux/services/widgets/widgets';
import { useParams } from 'react-router';
import { WidgetContext } from 'context/widget/widget';
import { WidgetDetails } from 'redux/services/widgets/interface';

const ResizeContainer = styled.div``;

const initialWidget = {
  _id: '',
  author: '',
  name: '',
  key: '',
  status: '',
  editorDetails: { style: {}, stories: [] },
  publishedDetails: {},
  createdAt: '',
  updatedAt: '',
  tags: [],
};

const WidgetsEditorPage: React.VFC = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { Spinner, spinnerProps } = useSpinner();
  const { id } = useParams<{ id: string }>();

  const [updateMetadata] = useUpdateWidgetMetadataMutation();
  const { data: dbWidget, isLoading } = useGetWidgetQuery(id);

  const userId = useAppSelector((state) => state.auth.user?._id);

  const [widgetCopy, setWidgetCopy] = useState<WidgetDetails>(initialWidget);
  const [isPreview, setPreview] = useState(false);
  const [isSaveNotificationVisible, setSaveNotificationVisible] = useState(false);
  const [changesCount, setChangesCount] = useState<number>(0);
  const [isExportModalOpen, setExportModalOpen] = useState(false);

  const handlePreview = useCallback(async () => {
    if (!isPreview && widgetCopy?._id) {
      try {
        await updateMetadata({
          id: widgetCopy?._id,
          name: widgetCopy?.name,
          modifiedBy: userId,
          editorDetails: widgetCopy?.editorDetails,
        }).unwrap();
        setChangesCount(0);
      } catch (error) {
        console.error(error);
      }
    }
    setPreview(!isPreview);
  }, [isPreview, updateMetadata, userId, widgetCopy?._id, widgetCopy?.editorDetails, widgetCopy?.name]);

  const handlePublishWidget = useCallback(async () => {
    try {
      if (changesCount > 0) {
        await updateMetadata({
          id: widgetCopy?._id,
          name: widgetCopy?.name,
          modifiedBy: userId,
          editorDetails: widgetCopy?.editorDetails,
        }).unwrap();
        setChangesCount(0);
      }

      setExportModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  }, [changesCount, updateMetadata, widgetCopy?._id, widgetCopy?.name, widgetCopy?.editorDetails, userId]);

  const handleAutosaveWidget = useCallback(
    async ({ id, name, editorDetails, modifiedBy }) => {
      try {
        await updateMetadata({
          id,
          name,
          editorDetails,
          modifiedBy,
        }).unwrap();

        setChangesCount(0);
      } catch (error) {
        console.error(error);
      }
    },
    [updateMetadata],
  );

  const debouncedAutosave = useCallback(
    debounce((input) => handleAutosaveWidget(input), 5000, {
      leading: false,
      trailing: true,
    }),
    [],
  );

  const handleSaveWidget = useCallback(
    async (showNotification = true) => {
      debouncedAutosave.cancel();
      try {
        await updateMetadata({
          id: widgetCopy?._id,
          name: widgetCopy?.name,
          editorDetails: widgetCopy?.editorDetails,
          modifiedBy: userId,
        }).unwrap();
        if (showNotification) {
          setSaveNotificationVisible(showNotification);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setChangesCount(0);
      }
    },
    [debouncedAutosave, updateMetadata, userId, widgetCopy?._id, widgetCopy?.editorDetails, widgetCopy?.name],
  );

  useEffect(() => {
    pushCrisp('do', 'chat:hide');

    return () => {
      pushCrisp('do', 'chat:show');
    };
  }, []);

  useEffect(() => {
    setWidgetCopy((prev) => {
      if (dbWidget) {
        return dbWidget;
      }

      return prev;
    });
  }, [dbWidget]);

  useEffect(() => {
    if (changesCount) {
      debouncedAutosave({
        id: widgetCopy?._id,
        name: widgetCopy?.name,
        editorDetails: widgetCopy?.editorDetails,
        modifiedBy: userId,
      });
    }

    return () => {
      debouncedAutosave.cancel();
    };
  }, [changesCount, debouncedAutosave, userId, widgetCopy?._id, widgetCopy?.editorDetails, widgetCopy?.name]);

  if (isLoading || !widgetCopy) {
    return <Spinner {...spinnerProps} isVisible />;
  }

  return (
    <ResizeContainer ref={containerRef}>
      <WidgetContext.Provider value={{ widget: widgetCopy, setWidget: setWidgetCopy }}>
        <PreviewContext.Provider value={{ isPreview, handlePreview }}>
          <ChangesContext.Provider value={{ changesCount, setChangesCount }}>
            <WidgetEditorHeader handlePublishWidget={handlePublishWidget} handleSaveWidget={handleSaveWidget} />
            <WidgetEditor
              isExportModalOpen={isExportModalOpen}
              setExportModalOpen={setExportModalOpen}
              isSaveNotificationVisible={isSaveNotificationVisible}
              setSaveNotificationVisible={setSaveNotificationVisible}
              saveWidget={handleSaveWidget}
            />
          </ChangesContext.Provider>
        </PreviewContext.Provider>
      </WidgetContext.Provider>
    </ResizeContainer>
  );
};

export default WidgetsEditorPage;
