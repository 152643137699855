import ChevronDown from '../icons/chevron-down';
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu';
import styled, { css } from 'styled-components';
import { useMemo, memo, VFC } from 'react';

const StyledMenu = styled(Menu)<{ $isDisabled?: boolean }>`
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
    `}

  &&& {
    .context-menu {
      background: red;
    }
  }
`;

const StyledSubMenu = styled(SubMenu)`
  &&& {
    border: none;
    border-radius: 8px;
    transition: background-color 200ms ease;
    &.rc-menu-submenu-selected {
      background-color: unset;
    }
    &.rc-menu-submenu-active {
      background-color: unset;
    }
    &.rc-menu-submenu-open {
      background-color: unset;
      backdrop-filter: blur(50px);
      svg,
      svg > * {
        fill: var(--primary);
      }
    }
    > div {
      background: none;
      padding: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: none;
    margin: 0 12px 4px 12px;
    padding: 3px 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--shade-100);
    cursor: pointer;
    transition: background-color 200ms ease, color 200ms ease;
    &.rc-menu-item-active {
      background-color: var(--primary);
      color: var(--shade-900);
      > span {
        color: var(--shade-900-85);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  padding: 14.5px 23px;
  background: var(--shade-700-85);
  border: 1px solid var(--shade-300);
  box-sizing: border-box;
  backdrop-filter: blur(50px);
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    transform: scale(1.4);
    fill: var(--white);
    > * {
      fill: var(--white);
    }
  }
`;

const Title = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: var(--white);
  margin-bottom: 1px;
`;

const Body = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: var(--shade-100);
`;

type TriggerProps = {
  periodLength: number;
  period: string;
};

const TriggerComponent: VFC<TriggerProps> = ({ periodLength, period }) => (
  <Trigger>
    <Row>
      <Column>
        <Title>Last {periodLength} days</Title>
        <Body>{period}</Body>
      </Column>
      <Column>
        <ChevronDown />
      </Column>
    </Row>
  </Trigger>
);

function buildPeriod(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const startYear = start.toLocaleString('en', { year: 'numeric' });
  const startMonth = start.toLocaleString('en', { month: 'long' });

  const endYear = end.toLocaleString('en', { year: 'numeric' });
  const endMonth = end.toLocaleString('en', { month: 'long' });

  const skipStartYear = startYear === endYear;

  return `${startMonth} ${start.getDate()} ${
    skipStartYear ? '' : startYear
  } - ${endMonth} ${end.getDate()}, ${endYear}`;
}

interface Props {
  startDate: string;
  endDate: string;
  periodLength: number;
  changePeriod: (newPeriod: number) => void;
  isDisabled?: boolean;
}

const Datepicker: VFC<Props> = ({ isDisabled, startDate, endDate, changePeriod, periodLength }) => {
  const triggerPeriod = useMemo(() => buildPeriod(startDate, endDate), [startDate, endDate]);
  const onChangePeriod = (period: number) => () => changePeriod(period);

  return (
    <StyledMenu $isDisabled={isDisabled} triggerSubMenuAction="click" mode="horizontal" openAnimation="slide-up">
      <StyledSubMenu
        popupClassName={'datepicker-menu'}
        popupOffset={[0, 10]}
        title={<TriggerComponent periodLength={periodLength} period={triggerPeriod} />}
        key="1"
      >
        <StyledMenuItem onClick={onChangePeriod(7)}>Last 7 days</StyledMenuItem>
        <StyledMenuItem onClick={onChangePeriod(30)}>Last 30 days</StyledMenuItem>
        <StyledMenuItem onClick={onChangePeriod(90)}>Last 90 days</StyledMenuItem>
      </StyledSubMenu>
    </StyledMenu>
  );
};

export default memo(Datepicker);
