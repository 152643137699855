import React from 'react';
import styled from 'styled-components';

interface IEditorSidebarLabel {
  text: string;
  highlighted?: boolean;
  paddingTop?: string;
}

const SidebarLabel = styled.p<{ highlighted?: boolean; paddingTop?: string }>`
  padding-top: ${({ paddingTop }) => paddingTop};
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ highlighted }) =>
    highlighted &&
    `
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
  
  `}
`;

const EditorSidebarLabel = ({ text, highlighted, paddingTop }: IEditorSidebarLabel): JSX.Element => {
  return (
    <SidebarLabel highlighted={highlighted} paddingTop={paddingTop}>
      {text}
    </SidebarLabel>
  );
};

export default EditorSidebarLabel;
