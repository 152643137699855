import { v4 } from 'uuid';
import { groupByActionTypes } from 'redux-undo';
import { Action } from '@reduxjs/toolkit';

export const batchGroupBy = {
  _group: null as null | string,
  start(group = v4()) {
    this._group = group;
  },
  end() {
    this._group = null;
  },
  init(rawActions: string[]) {
    const defaultGroupBy = groupByActionTypes(rawActions);
    return (action: Action, currentState: any, historyState: any) =>
      this._group || defaultGroupBy(action, currentState, historyState);
  },
};
