import { memo } from 'react';

const StepBack = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 11H6.40001L8.70002 8.70001C9.10002 8.30001 9.10002 7.70001 8.70002 7.30001C8.30001 6.90001 7.70001 6.90001 7.30001 7.30001L3.3 11.3C2.9 11.7 2.9 12.3 3.3 12.7L7.30001 16.7C7.50001 16.9 7.70001 17 8.00001 17C8.30001 17 8.50002 16.9 8.70002 16.7C9.10002 16.3 9.10002 15.7 8.70002 15.3L6.40001 13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11ZM21.0001 6C20.4001 6 20.0001 6.4 20.0001 7V17C20.0001 17.6 20.4001 18 21.0001 18C21.6001 18 22.0001 17.6 22.0001 17V7C22.0001 6.4 21.6001 6 21.0001 6Z"
      fill="#ABABBA"
    />
  </svg>
);

export default memo(StepBack);
