import Styled from './tutorial-dashboard-styled';
import TutorialDashboardVideoModal from './tutorial-dashboard-video-modal';
import { duration, fadeStyle, fadeTransitionStyles } from '../../../css/animations';
import { EmojiPartyPopper, EmojiRocket } from '../../icons';
import { features } from './constant';
import { Transition } from 'react-transition-group';
import { TutorialTypesPassed } from 'redux/services/auth/interface';
import { useAppSelector } from '../../../hooks';
import { useRef, useState } from 'react';
import { useUpdateTutorialFieldMutation } from 'redux/services/auth/auth';
import {
  Feature,
  SharedStyled,
  TutorialModalBody,
  TutorialModalBodyWrapper,
  TutorialModalFooter,
  TutorialModalHeader,
  TutorialModalWrapper,
  TutorialOuterWrapper,
} from '../shared';
import useResizeModalBodyHeight from '../hooks/useResizeModalBodyHeight';

const TutorialDashboard = (): JSX.Element => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [updateTutorialField] = useUpdateTutorialFieldMutation();
  const headerRef = useRef<null | HTMLDivElement>(null);
  const footerRef = useRef<null | HTMLDivElement>(null);
  const outerWrapperRef = useRef<null | HTMLDivElement>(null);
  const { bodyHeight } = useResizeModalBodyHeight({
    header: headerRef.current,
    footer: footerRef.current,
    modalWrapper: outerWrapperRef.current,
  });
  const userId = useAppSelector((state) => state.auth.user?._id);

  const onMainButtonClick = () => {
    if (userId) {
      updateTutorialField({ type: TutorialTypesPassed.Dashboard, userId });
    }
  };

  const toggleVideoModal = () => {
    setIsVideoModalOpen(!isVideoModalOpen);
  };

  return (
    <>
      <TutorialOuterWrapper propRef={outerWrapperRef}>
        <TutorialModalWrapper>
          <TutorialModalHeader
            title={
              <>
                Welcome to Zazu! <EmojiPartyPopper />
              </>
            }
            subTitle="Start building immersive Stories to captivate your audience"
            propRef={headerRef}
          />

          <TutorialModalBodyWrapper maxHeight={bodyHeight}>
            <Styled.VideoModalButton type="button" onClick={toggleVideoModal}>
              <Styled.VideoButtonText>Zazu Product Tour</Styled.VideoButtonText>
              <Styled.VideoButtonIcon />
            </Styled.VideoModalButton>

            <Styled.Splitter />

            <TutorialModalBody>
              <Styled.SecondaryTitle>
                Core features <EmojiRocket />
              </Styled.SecondaryTitle>

              <Styled.Row>
                {features.map((feature) => (
                  <Styled.Column key={feature.textInfo.text} flexBasis={feature.textInfo.maxWidth}>
                    <Feature textInfo={feature.textInfo} icon={feature.icon} iconBackground="var(--shade-500-85)" />
                  </Styled.Column>
                ))}
              </Styled.Row>
            </TutorialModalBody>
          </TutorialModalBodyWrapper>

          <TutorialModalFooter propRef={footerRef}>
            <SharedStyled.Link
              href="https://help.zazuapp.co/en/category/getting-started-7jo4o6/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get more help
            </SharedStyled.Link>

            <SharedStyled.MainButton text="Got it" onClick={onMainButtonClick} />
          </TutorialModalFooter>
        </TutorialModalWrapper>
      </TutorialOuterWrapper>

      <Transition in={isVideoModalOpen} timeout={duration} unmountOnExit>
        {(state) => (
          <TutorialDashboardVideoModal
            style={{
              ...fadeStyle,
              ...fadeTransitionStyles[state],
            }}
            onClose={toggleVideoModal}
          />
        )}
      </Transition>
    </>
  );
};

export default TutorialDashboard;
