import { memo } from 'react';
import styled from 'styled-components';

type IProps = {
  children: React.ReactNode;
  margin?: string;
  height?: string;
  width?: string;
  iconColor?: string;
  transform?: string;
};

const Container = styled.div`
  width: ${(props: IProps) => props.width};
  height: ${(props: IProps) => props.height};
  transform: ${(props: IProps) => props.transform};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props: IProps) => props.margin};
  cursor: pointer;
  svg > * {
    fill: ${(props: IProps) => props.iconColor};
  }
`;

const NormalIconWrapper = ({
  children,
  margin = '0',
  height = '24px',
  width = '24px',
  iconColor = 'var(--shade-100)',
  transform,
}: IProps): JSX.Element => (
  <Container margin={margin} height={height} width={width} iconColor={iconColor} transform={transform}>
    {children}
  </Container>
);

export default memo(NormalIconWrapper);
