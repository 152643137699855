import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IEditorSidebarButtonWrapper {
  children: ReactNode;
}

const ButtonWrapper = styled.div`
  margin: 0 4px;
`;

const EditorSidebarButtonWrapper = ({ children }: IEditorSidebarButtonWrapper) => {
  return <ButtonWrapper>{children}</ButtonWrapper>;
};

export default EditorSidebarButtonWrapper;
