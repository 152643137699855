import { memo } from 'react';
import styled from 'styled-components';
import { WorkspaceMembersTableRow } from '../../components/workspace-members';
import { IWorkspaceUser } from '../../interfaces/workspaces';

const StyledTableHeader = styled.th`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--white);
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

const StyledTableWrapper = styled.div`
  margin-top: 45px;
`;

interface IWorkspaceMembersTableProps {
  workspaceUsers: IWorkspaceUser[] | null;
  isDisabled?: boolean;
}

const WorkspaceMembersTable = ({ workspaceUsers, isDisabled }: IWorkspaceMembersTableProps): JSX.Element => {
  return (
    <StyledTableWrapper>
      <StyledTable>
        <thead>
          <tr>
            <StyledTableHeader>Name</StyledTableHeader>
            <StyledTableHeader>E-Mail</StyledTableHeader>
            <StyledTableHeader>Date added</StyledTableHeader>
            <StyledTableHeader>Role</StyledTableHeader>
          </tr>
        </thead>
        <tbody>
          {workspaceUsers?.map((user, index) => {
            return (
              <WorkspaceMembersTableRow
                key={`${user.email} - ${user.addedAt.toString()} - ${index}`}
                user={user}
                isDisabled={isDisabled}
              />
            );
          })}
        </tbody>
      </StyledTable>
    </StyledTableWrapper>
  );
};

export default memo(WorkspaceMembersTable);
