import { memo } from 'react';

interface IDuplicateSlide {
  color?: string;
  width?: string;
  height?: string;
}

const DuplicateSlide = ({ color = '#ABABBA', width = '24px', height = '24px' }: IDuplicateSlide): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2493 5.24996C10.3846 5.24996 9.69735 5.24996 9.13524 5.28492C9.20991 4.90779 9.32567 4.60185 9.50512 4.33329C9.72399 4.00572 10.0052 3.72447 10.3328 3.50559C11.0895 3 12.1428 3 14.2495 3C16.3562 3 17.4096 3 18.1662 3.50559C18.4938 3.72447 18.7751 4.00572 18.9939 4.33329C19.4995 5.08996 19.4995 6.14331 19.4995 8.25V12C19.4995 14.1067 19.4995 15.16 18.9939 15.9167C18.7751 16.2443 18.4938 16.5255 18.1662 16.7444C17.7201 17.0425 17.1709 17.1648 16.3636 17.215C16.4993 16.5296 16.4993 15.6089 16.4993 14.25V10.5C16.4993 8.39327 16.4993 7.33992 15.9937 6.58325C15.7748 6.25568 15.4936 5.97443 15.166 5.75555C14.4094 5.24996 13.356 5.24996 11.2493 5.24996ZM5.00559 8.08329C4.5 8.83996 4.5 9.89331 4.5 12V15.75C4.5 17.8567 4.5 18.91 5.00559 19.6667C5.22447 19.9943 5.50572 20.2755 5.83329 20.4944C6.58996 21 7.64331 21 9.75 21C11.8567 21 12.91 21 13.6667 20.4944C13.9943 20.2755 14.2755 19.9943 14.4944 19.6667C15 18.91 15 17.8567 15 15.75V12C15 9.89331 15 8.83996 14.4944 8.08329C14.2755 7.75572 13.9943 7.47447 13.6667 7.25559C12.91 6.75 11.8567 6.75 9.75 6.75C7.64331 6.75 6.58996 6.75 5.83329 7.25559C5.50572 7.47447 5.22447 7.75572 5.00559 8.08329Z"
      fill={color}
    />
  </svg>
);

export default memo(DuplicateSlide);
