import jwtDecode from 'jwt-decode';
import LoaderWrapper from '../components/media/shared/loader-wrapper';
import { Notification } from '../components/profile/shared/elements';
import { QueryKeys } from './login-page';
import { Redirect, useParams } from 'react-router-dom';
import { useAcceptInvitationMutation } from 'redux/services/workspaces/workspaces';
import { useAppSelector, useSpinner } from '../hooks';
import { useEffect } from 'react';
import { useSelectWorkspaceMutation } from 'redux/services/auth/auth';
import { useState } from 'react';

interface IDecodedToken {
  email: string;
  workspaceId: string;
}

const config = {
  disableOverlay: true,
  spinnerType: 'SyncLoader' as const,
  size: 20,
};

const AcceptInvitationPage = (): JSX.Element => {
  const loggedUserEmail = useAppSelector((state) => state.auth.user?.email);
  const { Spinner, spinnerProps } = useSpinner(config);

  const [isTokenConsumed, setTokenConsumed] = useState(false);
  const [isNotificationShown, showNotification] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState('');
  const [notificationText, setNotificationText] = useState(
    'The Workspace invitation was sent to an account with a different email address',
  );

  const [acceptInvitation] = useAcceptInvitationMutation();
  const [selectWorkspace] = useSelectWorkspaceMutation();

  const { token }: any = useParams();
  const { email, workspaceId }: IDecodedToken = jwtDecode(token);

  useEffect(() => {
    const onAccept = async () => {
      if (email === loggedUserEmail) {
        try {
          await acceptInvitation(token).unwrap();
          await selectWorkspace(workspaceId).unwrap();

          setNotificationText('Successfully accepted workspace invite!');
          showNotification(true);
          setTimeout(() => {
            showNotification(false);
            setRedirectRoute('/teams');
          }, 3000);
        } catch (err) {
          console.error(err);
          setRedirectRoute('/teams');
        }
        return;
      }

      setNotificationText('Invalid invitation.');
      showNotification(true);

      setTimeout(() => {
        showNotification(false);
        setRedirectRoute('/teams');
      }, 3000);
    };

    if (!loggedUserEmail) {
      setTokenConsumed(true);
      setRedirectRoute(`/login?${QueryKeys.WorkspaceToken}=${token}`);
      return;
    }

    if (!isTokenConsumed) {
      setTokenConsumed(true);
      onAccept();
    }
  }, [acceptInvitation, selectWorkspace, email, isTokenConsumed, loggedUserEmail, token, workspaceId]);

  if (redirectRoute) {
    return <Redirect to={redirectRoute} />;
  }

  return (
    <div style={{ transform: 'translate(0, 400px)' }}>
      <LoaderWrapper>
        <Spinner {...spinnerProps} isVisible={true} />
      </LoaderWrapper>
      {isNotificationShown && (
        <Notification
          text={notificationText}
          config={{
            position: 'absolute',
            top: '0',
            zIndex: '100',
            left: 'calc(50%)',
            transform: 'translate(-50%, -550%)',
          }}
        />
      )}
    </div>
  );
};

export default AcceptInvitationPage;
