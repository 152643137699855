import { DependencyList, EffectCallback, MutableRefObject, useEffect, useRef } from 'react';

type IUseDidUpdateEffect = (fn: EffectCallback, deps: DependencyList) => void;

const useDidUpdateEffect: IUseDidUpdateEffect = (fn, deps = []) => {
  const didMountRef: MutableRefObject<boolean> = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }

    didMountRef.current = true;
  }, deps);
};

export default useDidUpdateEffect;
