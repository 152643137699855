import api from '../api';

import type { ReportResult, ReportsInputType } from './interface';

export const analyticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    runReport: builder.query<ReportResult, ReportsInputType>({
      query: (input) => ({ url: 'analytics/run-report', body: input, method: 'POST' }),
      keepUnusedDataFor: 43200,
    }),
  }),
});

export const { useRunReportQuery } = analyticsApi;
