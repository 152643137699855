import FontOption from './font-option/font-option';
import InfiniteScroll from 'react-infinite-scroll-component';
import OutsideClickHandler from 'react-outside-click-handler';
import Search from '../../../shared/search';
import SelectStyled from './../../../shared/select-styled';
import styled from 'styled-components';
import { IFont } from '../../../../interfaces/fonts';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { setLoadedFontsCount } from 'redux/features/editor/helpers/helpersSlice';
import { useAppDispatch, useAppSelector } from 'hooks';

const SelectDropdownInnerContentWrapper = styled(InfiniteScroll)`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  max-width: 100%;
  overflow-x: hidden;
`;

interface IEditorSidebarFontSelect<Type> {
  isDisabled?: boolean;
  selectOption: string | number;
  optionsObject: { [key: string]: { title?: string; options: Type[] } };
  placeholder?: string;
  onSelect: (option: string) => void;
  searchValue: string;
  onSearchChange: (text: any) => void;
  widgetScrollIdentifier?: string;
}

export interface IFontOption {
  name: string;
  value: string;
  fontData: IFont;
}

const SearchWrapper = styled.div`
  padding: 12px 12px 0;
`;

const StyledSearch = styled(Search)`
  background: var(--shade-500);
`;

const StyledSelectDropdownWrapper = styled(SelectStyled.SelectDropdownWrapper)<{ isOpen: boolean }>`
  display: block;
  z-index: ${({ isOpen }) => (isOpen ? 1 : -10)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`;

const SelectDropdownContentWrapper = styled(SelectStyled.SelectDropdownContentWrapper)`
  height: 272px;
  flex: 0;
`;

const EditorSidebarFontSelect = <T,>({
  isDisabled,
  selectOption,
  optionsObject,
  placeholder,
  onSelect,
  searchValue,
  onSearchChange,
  widgetScrollIdentifier,
}: IEditorSidebarFontSelect<T>): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useAppDispatch();
  const loadedFontsNumber = useAppSelector((state) => state.helpers.loadedFontsCount);
  const optionsObjectKeys = Object.keys(optionsObject);
  const normalizedOptions = useMemo(
    () =>
      optionsObjectKeys.map((key) => {
        if (key === 'google') {
          return optionsObject[key]?.options?.slice(0, loadedFontsNumber);
        }
        return optionsObject[key]?.options;
      }),
    [optionsObject, optionsObjectKeys, loadedFontsNumber],
  );

  const optionsTitle = useMemo(
    () =>
      optionsObjectKeys.map((key) => {
        const title = optionsObject[key]?.title;
        if (title) {
          return title;
        }
        return '';
      }),
    [optionsObject, optionsObjectKeys],
  );

  const onScrollEnd = useCallback(async () => {
    dispatch(setLoadedFontsCount(loadedFontsNumber + 5));
  }, [loadedFontsNumber, dispatch]);

  const onOpenDropdown = () => {
    if (isDisabled) {
      return;
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onSelectFont = useCallback(
    (font: IFontOption) => () => {
      onSelect(font.value);
      setIsDropdownOpen(false);
    },
    [onSelect],
  );

  return (
    <SelectStyled.SelectWrapper>
      <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
        <SelectStyled.SelectDropdownTrigger isDisabled={isDisabled} isFocused={isDropdownOpen} onClick={onOpenDropdown}>
          <SelectStyled.SelectDropdownTriggerValue fontFamily={selectOption as string}>
            {selectOption}
          </SelectStyled.SelectDropdownTriggerValue>
          <SelectStyled.SelectDropdownIcon />
        </SelectStyled.SelectDropdownTrigger>

        <StyledSelectDropdownWrapper isOpen={isDropdownOpen} isLarge ref={dropdownRef}>
          <SearchWrapper>
            <StyledSearch placeholder={placeholder} value={searchValue} onChange={onSearchChange} />
          </SearchWrapper>
          <SelectDropdownContentWrapper
            id={widgetScrollIdentifier ? `scrollableDiv-${widgetScrollIdentifier}` : 'scrollableDiv'}
          >
            <SelectDropdownInnerContentWrapper
              next={onScrollEnd}
              hasMore={true}
              loader={<></>}
              dataLength={loadedFontsNumber}
              scrollableTarget={widgetScrollIdentifier ? `scrollableDiv-${widgetScrollIdentifier}` : 'scrollableDiv'}
            >
              {normalizedOptions?.map((option: any, index: number) => {
                if (option?.length > 0) {
                  const title = optionsTitle[index];
                  return (
                    <SelectStyled.OptionSection key={`section-${index}`}>
                      {title.length > 0 && <SelectStyled.OptionSectionTitle>{title}</SelectStyled.OptionSectionTitle>}
                      {option.map((subOption: any, innerIndex: number) => {
                        return (
                          <FontOption
                            key={`font-option-${subOption.name}-${innerIndex}`}
                            font={subOption}
                            isSelected={selectOption === subOption.name}
                            onClick={onSelectFont(subOption)}
                          />
                        );
                      })}
                    </SelectStyled.OptionSection>
                  );
                }
              })}
            </SelectDropdownInnerContentWrapper>
          </SelectDropdownContentWrapper>
        </StyledSelectDropdownWrapper>
      </OutsideClickHandler>
    </SelectStyled.SelectWrapper>
  );
};

export default memo(EditorSidebarFontSelect);
