import ColorPicker from '../../color-picker';
import EditorSidebarHalfColumn from '../../editor-sidebar/shared/structure/editor-sidebar-half-column';
import EditorSidebarLabel from '../../editor-sidebar/shared/elements/editor-sidebar-label';
import EditorSidebarRowWrapper from '../../editor-sidebar/shared/structure/editor-sidebar-row-wrapper';
import EditorSidebarSectionWrapper from '../../editor-sidebar/shared/structure/editor-sidebar-section-wrapper';
import EditorSidebarValuesWrapper from '../../editor-sidebar/shared/structure/editor-sidebar-values-wrapper';
import ToggleSwitch from '../../shared/toggle-switch';
import { ChangesContext, ChangesContextType } from 'context/widget/changes';
import { IColorType } from '../../../interfaces/colors';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useDebounce, useDidUpdateEffect } from 'hooks';
import { WidgetContext, WidgetContextType } from 'context/widget/widget';

const WidgetEditorSidebarCarouseSettings = (): JSX.Element => {
  const { widget, setWidget } = useContext(WidgetContext) as WidgetContextType;
  const { setChangesCount } = useContext(ChangesContext) as ChangesContextType;

  const {
    hasarrows: hasArrows,
    hasbullets: hasBullets,
    arrowscolor: defaultArrowsColor,
    bulletscolor: defaultBulletsColor,
    bulletsactivecolor: defaultBulletsActiveColor,
  } = widget.editorDetails.style;

  const [arrowsColor, setArrowsColor] = useState(defaultArrowsColor);
  const [bulletsColor, setBulletsColor] = useState(defaultBulletsColor);
  const [bulletsActiveColor, setBulletsActiveColor] = useState(defaultBulletsActiveColor);

  const debouncedArrowsColor = useDebounce<string>(arrowsColor, 50);
  const debouncedBulletsColor = useDebounce<string>(bulletsColor, 50);
  const debouncedActiveBulletsColor = useDebounce<string>(bulletsActiveColor, 50);

  const onArrowsColorChange = useCallback((_: any, value: any) => {
    setArrowsColor(value);
  }, []);

  const onBulletsActiveColorChange = useCallback((prop: string, value: any) => {
    setBulletsActiveColor(value);
  }, []);

  const onBulletsColorChange = useCallback((prop: string, value: any) => {
    setBulletsColor(value);
  }, []);

  const onHasArrowsChange = useCallback(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          hasarrows: prev.editorDetails.style.hasarrows ? 0 : 1,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [setWidget, setChangesCount]);

  const onHasBulletsChange = useCallback(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          hasbullets: prev.editorDetails.style.hasbullets ? 0 : 1,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [setWidget, setChangesCount]);

  useDidUpdateEffect(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          arrowscolor: debouncedArrowsColor,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [debouncedArrowsColor, setWidget, setChangesCount]);

  useDidUpdateEffect(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          bulletscolor: debouncedBulletsColor,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [debouncedBulletsColor, setWidget, setChangesCount]);

  useDidUpdateEffect(() => {
    setWidget((prev) => ({
      ...prev,
      editorDetails: {
        ...prev.editorDetails,
        style: {
          ...prev.editorDetails.style,
          bulletsactivecolor: debouncedActiveBulletsColor,
        },
      },
    }));
    setChangesCount((prev) => prev + 1);
  }, [debouncedActiveBulletsColor, setWidget, setChangesCount]);

  return (
    <EditorSidebarSectionWrapper>
      <EditorSidebarRowWrapper>
        <EditorSidebarLabel text={'Arrows'} highlighted />
        <EditorSidebarValuesWrapper>
          <EditorSidebarHalfColumn justifyContent={'flex-end'} padding="0 8px 0 4px">
            <ToggleSwitch isOn={hasArrows} onClick={onHasArrowsChange} />
          </EditorSidebarHalfColumn>
        </EditorSidebarValuesWrapper>
      </EditorSidebarRowWrapper>

      {hasArrows ? (
        <EditorSidebarRowWrapper>
          <EditorSidebarLabel text={'Color'} />
          <EditorSidebarValuesWrapper>
            <EditorSidebarHalfColumn justifyContent={'flex-end'}>
              <ColorPicker
                colorType={IColorType.FillColor}
                isDisabled={!hasArrows}
                defaultLeftColor={defaultArrowsColor}
                leftColor={arrowsColor}
                handleColorChange={onArrowsColorChange}
              />
            </EditorSidebarHalfColumn>
          </EditorSidebarValuesWrapper>
        </EditorSidebarRowWrapper>
      ) : null}

      <EditorSidebarRowWrapper>
        <EditorSidebarLabel text={'Bullets'} highlighted />
        <EditorSidebarValuesWrapper>
          <EditorSidebarHalfColumn justifyContent={'flex-end'} padding="0 8px 0 4px">
            <ToggleSwitch isOn={hasBullets} onClick={() => onHasBulletsChange()} />
          </EditorSidebarHalfColumn>
        </EditorSidebarValuesWrapper>
      </EditorSidebarRowWrapper>

      {hasBullets ? (
        <>
          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Focused'} />
            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <ColorPicker
                  bulletFocused={true}
                  colorType={IColorType.FillColor}
                  isDisabled={!hasBullets}
                  defaultLeftColor={defaultArrowsColor}
                  leftColor={bulletsActiveColor}
                  handleColorChange={onBulletsActiveColorChange}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>

          <EditorSidebarRowWrapper>
            <EditorSidebarLabel text={'Regular'} />
            <EditorSidebarValuesWrapper>
              <EditorSidebarHalfColumn justifyContent={'flex-end'}>
                <ColorPicker
                  colorType={IColorType.FillColor}
                  isDisabled={!hasBullets}
                  defaultLeftColor={defaultArrowsColor}
                  leftColor={bulletsColor}
                  handleColorChange={onBulletsColorChange}
                />
              </EditorSidebarHalfColumn>
            </EditorSidebarValuesWrapper>
          </EditorSidebarRowWrapper>
        </>
      ) : null}
    </EditorSidebarSectionWrapper>
  );
};

export default memo(WidgetEditorSidebarCarouseSettings);
