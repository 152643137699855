import { memo } from 'react';

interface IPipette {
  className?: string;
}

const Pipette = ({ className }: IPipette): JSX.Element => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7433 2.1067L14.8543 1.21775C14.6887 1.05353 14.4653 0.960943 14.2321 0.959961H13.2187C12.9855 0.960943 12.762 1.05353 12.5964 1.21775L9.50289 4.34683L8.24948 3.08453C8.16639 3.0031 8.05469 2.95748 7.93835 2.95748C7.82201 2.95748 7.7103 3.0031 7.62722 3.08453L6.99607 3.71568C6.95441 3.757 6.92134 3.80616 6.89878 3.86033C6.87621 3.91449 6.86459 3.97258 6.86459 4.03126C6.86459 4.08993 6.87621 4.14803 6.89878 4.20219C6.92134 4.25636 6.95441 4.30551 6.99607 4.34683L7.62722 4.96909L0.782355 11.814C0.547179 12.0488 0.382539 12.3449 0.307118 12.6685C0.231696 12.9922 0.248514 13.3306 0.355663 13.6452L0.800134 14.943L0.133427 15.6097C0.0917674 15.6511 0.0587012 15.7002 0.036136 15.7544C0.0135707 15.8085 0.00195312 15.8666 0.00195312 15.9253C0.00195312 15.984 0.0135707 16.0421 0.036136 16.0962C0.0587012 16.1504 0.0917674 16.1996 0.133427 16.2409L0.755687 16.8632C0.797006 16.9048 0.846165 16.9379 0.900328 16.9604C0.954491 16.983 1.01259 16.9946 1.07126 16.9946C1.12994 16.9946 1.18803 16.983 1.2422 16.9604C1.29636 16.9379 1.34552 16.9048 1.38684 16.8632L2.05354 16.1964L3.3514 16.6409C3.53464 16.7035 3.72669 16.7365 3.92032 16.7387C4.15493 16.7389 4.38725 16.6926 4.60389 16.6026C4.82054 16.5126 5.01723 16.3806 5.18262 16.2142L12.0275 9.36936L12.6497 10.0005C12.6911 10.0422 12.7402 10.0752 12.7944 10.0978C12.8485 10.1204 12.9066 10.132 12.9653 10.132C13.024 10.132 13.0821 10.1204 13.1363 10.0978C13.1904 10.0752 13.2396 10.0422 13.2809 10.0005L13.912 9.36936C13.9935 9.28628 14.0391 9.17457 14.0391 9.05823C14.0391 8.94189 13.9935 8.83019 13.912 8.7471L12.6497 7.49369L15.7433 4.40017C15.9075 4.2346 16.0001 4.01112 16.0011 3.77791V2.7734C16.0066 2.65049 15.9865 2.52776 15.9421 2.413C15.8978 2.29824 15.83 2.19393 15.7433 2.1067ZM4.027 14.863L2.13355 12.9696L8.88952 6.24917L10.7652 8.10706L4.027 14.863Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Pipette);
