import { isUserAuthenticated } from 'redux/features/auth/authSlice';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute: React.FC<RouteProps> = (props) => {
  const isAuthenticated = useSelector(isUserAuthenticated);
  return !isAuthenticated ? <Route {...props} /> : <Redirect to="/" />;
};

export default PublicRoute;
