import { authApi } from '../../services/auth/auth';
import { PayloadAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { dpaApi } from 'redux/services/dpa/dpa';
import { RootState } from 'redux/rootReducer';
import { RefreshResponse, User, UserToken } from '../../services/auth/interface';

type InitialState = {
  user: User | null;
  accessToken: UserToken | null;
  refreshToken: UserToken | null;
};

const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
} as InitialState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessToken:iat');
      localStorage.removeItem('accessToken:exp');

      localStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshToken:iat');
      localStorage.removeItem('refreshToken:exp');

      return initialState;
    },
    refreshTokens: (state, action: PayloadAction<RefreshResponse>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      // Set tokens
      localStorage.setItem('accessToken', action.payload.accessToken?.token);
      localStorage.setItem('accessToken:exp', action.payload.accessToken?.exp.toString());
      localStorage.setItem('accessToken:iat', action.payload.accessToken?.iat.toString());

      localStorage.setItem('refreshToken', action.payload.refreshToken?.token);
      localStorage.setItem('refreshToken:exp', action.payload.refreshToken?.exp.toString());
      localStorage.setItem('refreshToken:iat', action.payload.refreshToken?.iat.toString());
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApi.endpoints.login.matchFulfilled, dpaApi.endpoints.claimToken.matchFulfilled),
      (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.user = payload.user;
      },
    );
    builder.addMatcher(authApi.endpoints.authenticate.matchFulfilled, (state, { meta, payload }) => {
      const requestArgs = meta?.arg?.originalArgs;
      if (requestArgs && !state.accessToken) {
        state.accessToken = requestArgs.accessToken;
      }
      if (requestArgs?.refreshToken && !state.refreshToken) {
        // It could be null because of DPA
        state.refreshToken = requestArgs?.refreshToken ?? null;
      }

      state.user = payload;
    });
    builder.addMatcher(authApi.endpoints.selectWorkspace.matchFulfilled, (state, { meta }) => {
      const requestArgs = meta?.arg?.originalArgs;
      if (requestArgs && state.user) {
        state.user.selectedWorkspaceId = requestArgs;
      }
    });
    builder.addMatcher(authApi.endpoints.selectTeam.matchFulfilled, (state, { meta }) => {
      const requestArgs = meta?.arg?.originalArgs;
      if (state.user) {
        state.user.selectedTeamId = requestArgs;
      }
    });
    builder.addMatcher(authApi.endpoints.updateTutorialField.matchFulfilled, (state, { meta }) => {
      const requestArgs = meta?.arg?.originalArgs;
      if (requestArgs && state.user) {
        const { type } = requestArgs;
        state.user[type] = true;
      }
    });
    builder.addMatcher(authApi.endpoints.addFavouriteColor.matchFulfilled, (state, { payload }) => {
      if (state.user) {
        state.user.favouriteColors = payload;
      }
    });
    builder.addMatcher(authApi.endpoints.logout.matchPending, () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessToken:iat');
      localStorage.removeItem('accessToken:exp');

      localStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshToken:iat');
      localStorage.removeItem('refreshToken:exp');

      return initialState;
    });
  },
});

export const { logout, refreshTokens } = authSlice.actions;
export default authSlice.reducer;

export const isUserAuthenticated = createSelector(
  (state: RootState) => state.auth.user,
  (user) => !!user,
);

export const selectDPAProvider = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user?.provider === 'dpa',
);
