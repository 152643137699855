import React, { useMemo } from 'react';
import styled from 'styled-components';
import ToggleSwitch from '../../shared/toggle-switch';
import { useAppDispatch } from '../../../hooks';
import { makeSelectPropFromActiveSlide, setActiveSlideProps } from '../../../redux/features/amp-story/ampStorySlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  position: absolute;
  top: 3px;
  right: calc(100% + 8px);
  background: var(--shade-900-85);
  box-shadow: 24px 32px 72px var(--black-18);
  backdrop-filter: blur(50px);
  border-radius: 8px;
  min-width: 137px;
  z-index: 1;
`;

const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--shade-100);
  margin: 0 0 0 8px;
`;

const ToggleAutoAdvance = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectPropFromActiveSlide = useMemo(makeSelectPropFromActiveSlide, []);
  const isAutoAdvancedDisabled = useSelector((state: RootState) =>
    selectPropFromActiveSlide(state, 'isAutoAdvancedDisabled'),
  );

  const onToggle = () => {
    dispatch(
      setActiveSlideProps({
        field: 'isAutoAdvancedDisabled',
        value: !isAutoAdvancedDisabled,
      }),
    );
  };

  return (
    <Wrapper>
      <ToggleSwitch size="medium" isOn={!isAutoAdvancedDisabled} onClick={onToggle} />
      <Text>Auto-advance</Text>
    </Wrapper>
  );
};

export default ToggleAutoAdvance;
