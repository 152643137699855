import EditorModalBackTo from './shared/editor-modal-back-to/editor-modal-back-to';
import EditorModalHeaderWrapper from './shared/editor-modal-header-wrapper/editor-modal-header-wrapper';
import EditorModalLabel from '../shared/editor-modal-label/editor-modal-label';
import EditorModalLabelsWrapper from '../shared/editor-modal-labels-wrapper/editor-modal-labels-wrapper';
import EditorModalWrapper from '../shared/editor-modal-wrapper/editor-modal-wrapper';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import { layerTypes } from '../../../interfaces/layer-types';
import { memo, useCallback, useState } from 'react';
import { onOutsideClickModal } from '../../../redux/features/editor-modal/editorModalSlice';
import { TemplateSelector, TemplateSlidesSelector } from '../../templates';
import { TemplateDetails, TemplateType } from 'redux/services/templates/templates';
import { useAppDispatch } from '../../../hooks';
import {
  EDITOR_MODAL_TEMPLATES_LIBRARY,
  EDITOR_MODAL_TEMPLATES_PERSONAL,
  EDITOR_MODAL_TEMPLATES_TEAM,
} from '../constants/editor-modal-templates-tabs';
import { Transition } from 'react-transition-group';

const StyledEditorModalLabelsWrapper = styled(EditorModalLabelsWrapper)`
  width: 50%;
`;

const transitionStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const duration = 225;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  display: 'flex',
  overflow: 'auto',
  height: '100%',
};

interface IMapTabsToTemplateType {
  [key: string]: TemplateType;
}

const mapTabsToTemplateType: IMapTabsToTemplateType = {
  [EDITOR_MODAL_TEMPLATES_LIBRARY]: TemplateType.Global,
  [EDITOR_MODAL_TEMPLATES_PERSONAL]: TemplateType.Personal,
  [EDITOR_MODAL_TEMPLATES_TEAM]: TemplateType.Team,
};

const tabNames = [EDITOR_MODAL_TEMPLATES_LIBRARY, EDITOR_MODAL_TEMPLATES_PERSONAL, EDITOR_MODAL_TEMPLATES_TEAM];

const EditorModalTemplates = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [templateType, setTemplateType] = useState(TemplateType.Global);
  const [activeTab, setActiveTab] = useState<keyof IMapTabsToTemplateType>(tabNames[0]);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateDetails | null>(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const onTabClick = (tabName: keyof IMapTabsToTemplateType) => () => {
    setActiveTab(tabName);
    setTemplateType(mapTabsToTemplateType[tabName]);
  };

  const onOutsideClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => dispatch(onOutsideClickModal(event, layerTypes.TEMPLATES)),
    [dispatch],
  );

  const onBackToButtonClick = useCallback(() => setSelectedTemplate(null), []);

  return (
    <OutsideClickHandler disabled={isDeleteModalOpen} onOutsideClick={onOutsideClick}>
      <EditorModalWrapper>
        <EditorModalHeaderWrapper isActive={!!selectedTemplate}>
          <StyledEditorModalLabelsWrapper>
            {tabNames.map((tabName) => {
              return (
                <EditorModalLabel
                  key={tabName}
                  text={tabName}
                  isActive={tabName === activeTab}
                  onClick={onTabClick(tabName)}
                />
              );
            })}
          </StyledEditorModalLabelsWrapper>

          <EditorModalBackTo text={activeTab} onClick={onBackToButtonClick} />
        </EditorModalHeaderWrapper>

        {!selectedTemplate && (
          <TemplateSelector
            templateType={templateType}
            isDeleteModalOpen={isDeleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}

        {selectedTemplate !== null && (
          <Transition in={selectedTemplate !== null} timeout={duration}>
            {(state) => (
              <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
                {selectedTemplate && (
                  <TemplateSlidesSelector
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                  />
                )}
              </div>
            )}
          </Transition>
        )}
      </EditorModalWrapper>
    </OutsideClickHandler>
  );
};

export default memo(EditorModalTemplates);
