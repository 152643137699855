import api from 'utils/api';
import InlineEditor from '../../inline-editor';
import Layer from 'components/story-editor/layer';
import OutsideClickHandler from 'react-outside-click-handler';
import React, { memo, useMemo, useRef, useState } from 'react';
import store from 'redux/store';
import Styled from './template-modal-styles';
import { BackButton } from '../../export-modal/shared/elements';
import { batch } from 'react-redux';
import { falseFunction } from 'utils/commonUtils';
import { getNewBackground } from '../../../utils/colorUtils';
import { ICut } from '../../../interfaces/cut';
import { ILayer } from '../../../interfaces/layer';
import { ModalHeaderWrapper, ModalWrapper } from '../../shared/modal';
import { replaceTemporaryMediaInStory } from 'redux/features/media/mediaSlice';
import { ScaleWrapper } from 'components/editor-slide-list/editor-slide-list-element/editor-slide-list-element';
import { SecondaryButton } from '../../buttons';
import { selectTemplateType, toggleSaveTemplateModal } from 'redux/features/templates/templatesSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TemplateType, useSaveTemplateMutation } from 'redux/services/templates/templates';
import { toggleTemplateSavedNotificationVisibility } from 'redux/features/editor/helpers/helpersSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useCallback } from 'react';
import { useGetWorkspaceQuery } from 'redux/services/workspaces/workspaces';
import { updateStoryConfig } from 'redux/features/amp-story/ampStorySlice';

const miniEditorHeight = 248;

const getSaveButtonText = (type: TemplateType | null) => {
  if (type === TemplateType.Team) {
    return 'Save as team template';
  }

  if (type === TemplateType.Global) {
    return 'Save as global template';
  }

  return 'Save as template';
};

const getSelectButtonText = ({
  selectedSlides,
  ampStorySlides,
}: {
  selectedSlides: unknown[];
  ampStorySlides: unknown[];
}) => {
  if (selectedSlides?.length === ampStorySlides?.length) {
    return 'Deselect all artboards';
  }

  if (
    selectedSlides &&
    ampStorySlides &&
    selectedSlides?.length > 0 &&
    selectedSlides?.length !== ampStorySlides?.length
  ) {
    return `Deselect ${selectedSlides.length} artboards`;
  }

  return `Select all artboards`;
};

const TemplateModal: React.VFC = () => {
  const dispatch = useAppDispatch();

  const emptyRef = useRef<any>();
  const storyId = useAppSelector((state) => state.ampStory.present?._id);
  const storyTitle = useAppSelector((state) => state.ampStory.present?.title);
  const ampStorySlides = useAppSelector((state) => state.ampStory.present?.cuts);
  const ampStoryInitialWidth = useAppSelector((state) => state.ampStory.present?.initialWidth);
  const ampStoryInitialHeight = useAppSelector((state) => state.ampStory.present?.initialHeight);

  const selectedWorkspaceId = useAppSelector((state) => state.auth.user?.selectedWorkspaceId);

  const { team } = useGetWorkspaceQuery(selectedWorkspaceId ?? skipToken, {
    selectFromResult: ({ data: workspace }) => ({
      team: workspace?.teams?.[0],
    }),
  });

  const templateType = useAppSelector((state) => state.templates.templateType);

  const [templateName, setTemplateName] = useState<string | number>(storyTitle);
  const [selectedSlides, setSelectedSlides] = useState<number[]>([...Array(ampStorySlides?.length).keys()]);
  const [activeEditor, setActiveEditor] = useState(false);
  const [savingStarted, setSavingStarted] = useState(false);

  const dimensionsRatio = useMemo(() => (Number(ampStoryInitialWidth) / Number(ampStoryInitialHeight)).toFixed(2), [
    ampStoryInitialWidth,
    ampStoryInitialHeight,
  ]);
  const miniEditorWidth = useMemo(() => miniEditorHeight * Number(dimensionsRatio), [dimensionsRatio]);
  const saveButtonText = useMemo(() => getSaveButtonText(templateType), [templateType]);
  const selectButtonText = useMemo(
    () =>
      getSelectButtonText({
        selectedSlides,
        ampStorySlides,
      }),
    [selectedSlides, ampStorySlides],
  );

  const [saveTemplate] = useSaveTemplateMutation();

  const onSelectButtonClick = useCallback(() => {
    if (ampStorySlides) {
      if (selectedSlides?.length) {
        setSelectedSlides([]);
        return;
      }
      setSelectedSlides([...Array(ampStorySlides?.length).keys()]);
    }
  }, [ampStorySlides, selectedSlides?.length]);

  const onCloseModal = useCallback(() => {
    batch(() => {
      dispatch(toggleSaveTemplateModal(false));
      dispatch(selectTemplateType(null));
    });
  }, [dispatch]);

  const onSelectSlides = useCallback(
    (position: number) => () => {
      const index = selectedSlides.indexOf(position);

      if (index > -1) {
        const selectedSlidesCopy = [...selectedSlides];
        selectedSlidesCopy.splice(index, 1);
        setSelectedSlides(selectedSlidesCopy);
        return;
      }

      setSelectedSlides([...selectedSlides, position]);
    },
    [selectedSlides],
  );

  const onSave = useCallback(async () => {
    if (!templateType || !selectedSlides) {
      return;
    }

    setSavingStarted(true);

    dispatch(replaceTemporaryMediaInStory());
    await dispatch(updateStoryConfig());

    try {
      const teamId = templateType === TemplateType.Team ? team?._id : null;

      await saveTemplate({
        type: templateType,
        storyId,
        selectedSlides,
        title: templateName.toString(),
        teamId,
      }).unwrap();
    } catch (e) {
      console.error(e);
    } finally {
      setSavingStarted(false);
    }

    dispatch(toggleTemplateSavedNotificationVisibility(true));
    onCloseModal();
  }, [dispatch, onCloseModal, saveTemplate, storyId, selectedSlides, team?._id, templateName, templateType]);

  const onBlurCallback = useCallback(() => {
    if (templateName === '') {
      setTemplateName('Untitled');
    }
  }, [templateName]);

  const onTemplateTitleChange = useCallback((value: string | number) => setTemplateName(value), []);

  return (
    <Styled.StyledModalExportOuterWrapper>
      <OutsideClickHandler display="flex" onOutsideClick={onCloseModal}>
        <ModalWrapper>
          <ModalHeaderWrapper>
            <BackButton onClick={onCloseModal} />

            <Styled.TemplateName isActive={activeEditor}>
              <InlineEditor
                open={activeEditor}
                setOpen={setActiveEditor}
                onChange={onTemplateTitleChange}
                onBlurCallback={onBlurCallback}
                placeholder={templateName.toString()}
                value={templateName}
              />
            </Styled.TemplateName>

            <Styled.PublishButton
              isDisabled={selectedSlides.length === 0 || savingStarted}
              onClick={onSave}
              text={saveButtonText}
            />
          </ModalHeaderWrapper>

          <Styled.StyledModalBodyWrapper>
            <Styled.SlidesWrapper>
              {ampStorySlides?.map((slide: ICut) => {
                const isActiveSlide = selectedSlides?.includes(slide.position);
                return (
                  <Styled.ContainerBackgroundWrapper key={`slide-select-${slide.position}`}>
                    <Styled.ContainerBackground
                      activeSlide={isActiveSlide}
                      onClick={onSelectSlides(slide.position)}
                      cursor="pointer"
                    >
                      <Styled.Container
                        containerHeight={miniEditorHeight}
                        containerWidth={miniEditorWidth}
                        style={{
                          background: getNewBackground(slide.backgroundColor),
                        }}
                      >
                        <ScaleWrapper
                          width={ampStoryInitialWidth}
                          height={ampStoryInitialHeight}
                          containerWidth={miniEditorWidth}
                          containerHeight={miniEditorHeight}
                        >
                          {slide?.layers
                            ?.filter((layer) => !layer.settings.layerSettings.isLayerHidden)
                            ?.map((layer: ILayer) => {
                              return (
                                <Layer
                                  key={`${slide.position}-${layer._id}-${layer.position}`}
                                  layer={layer}
                                  activeLayer={false}
                                  keepRatio={false}
                                  editorWidth={ampStoryInitialWidth}
                                  editorHeight={ampStoryInitialHeight}
                                  slidePosition={slide.position}
                                  areAnimationsRunning={false}
                                  isActive={false}
                                  handleLayerClick={falseFunction}
                                  handleLayerChange={falseFunction}
                                  handleBatchLayerChange={falseFunction}
                                  ctaLayerRef={emptyRef}
                                  isTextReadOnly
                                />
                              );
                            })}
                        </ScaleWrapper>

                        {isActiveSlide && (
                          <>
                            <Styled.ActiveSlideMask />
                            <Styled.StyledChecked zIndex={1000} />
                          </>
                        )}
                      </Styled.Container>
                    </Styled.ContainerBackground>
                    <Styled.SlideNumbers>{slide.position + 1}</Styled.SlideNumbers>
                  </Styled.ContainerBackgroundWrapper>
                );
              })}
            </Styled.SlidesWrapper>

            <Styled.SelectButtonWrapper>
              <SecondaryButton text={selectButtonText} onClick={onSelectButtonClick} />
            </Styled.SelectButtonWrapper>
          </Styled.StyledModalBodyWrapper>
        </ModalWrapper>
      </OutsideClickHandler>
    </Styled.StyledModalExportOuterWrapper>
  );
};

export default memo(TemplateModal);
