import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'redux/base-query';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    'Articles',
    'Folder',
    'Gifs',
    'Workspaces',
    'GA',
    'Snippets',
    'Members',
    'Team',
    'WorkspaceFonts',
    'GoogleFonts',
    'Stories',
    'Tags',
    'Templates',
    'Widgets',
    'Presets',
  ],
});

export default api;
