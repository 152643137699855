import React from 'react';
import DashboardLayout from '../components/layouts';
import Profile from '../components/profile/profile';

const ProfilePage = (): JSX.Element => {
  return (
    <DashboardLayout>
      <Profile />
    </DashboardLayout>
  );
};

export default ProfilePage;
