import React, { ChangeEvent, useEffect, useState } from 'react';
import EditorSidebarHalfColumn from './structure/editor-sidebar-half-column';
import EditorSidebarSlider from './elements/editor-sidebar-slider';
import EditorSidebarSignInput from './elements/editor-sidebar-sign-input';
import EditorSidebarValuesWrapper from './structure/editor-sidebar-values-wrapper';

interface IEditorSidebarSliderWithInput {
  min: number;
  max: number;
  step: number;
  value: string | number;
  sign: string;
  isDisabled?: boolean;
  onChange: (params: number) => void;
  onInputArrowDown: (params: any) => void;
}

const EditorSidebarSliderWithInput = ({
  min,
  max,
  step,
  value,
  sign,
  isDisabled,
  onChange,
  onInputArrowDown,
}: IEditorSidebarSliderWithInput): JSX.Element => {
  const [localValue, setLocalValue] = useState<string | number>(+value);

  useEffect(() => {
    setLocalValue((v) => (v === value ? v : value));
  }, [value]);

  const getValue = (value: number, min: number, max: number) => {
    if (isNaN(value)) {
      return min;
    }
    if (+value > max) {
      return value % 10;
    }
    if (+value < min) {
      return min;
    }

    return +value;
  };

  const shouldArrowsFire = (event: any, min: number, max: number) => {
    if (isDisabled) {
      return false;
    }
    const value = +event.target.value;
    if (isNaN(value)) {
      return false;
    }
    if (event.key === 'ArrowUp') {
      return !(value > max - 1);
    }
    if (event.key === 'ArrowDown') {
      return !(value < min + 1);
    }

    return true;
  };

  const changeValue = (value: number) => {
    setLocalValue(value);
    onChange(value);
  };

  const onInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    const newValue = getValue(Number(event.target.value), min, max);

    changeValue(newValue);
  };

  const onSliderValueChange = (inputParam: number) => {
    if (isDisabled) {
      return;
    }

    const newValue = getValue(+inputParam, min, max);

    changeValue(newValue);
  };

  const onArrowDown = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }
    if (shouldArrowsFire(event, min, max)) {
      onInputArrowDown(event);
    }
  };

  return (
    <EditorSidebarValuesWrapper>
      <EditorSidebarHalfColumn>
        <EditorSidebarSlider
          isDisabled={isDisabled}
          min={min}
          max={max}
          step={step}
          value={+localValue}
          onChange={onSliderValueChange}
        />
      </EditorSidebarHalfColumn>

      <EditorSidebarHalfColumn>
        <EditorSidebarSignInput
          isDisabled={isDisabled}
          value={localValue}
          sign={sign}
          onKeyDown={onArrowDown}
          onChange={onInputValueChange}
        />
      </EditorSidebarHalfColumn>
    </EditorSidebarValuesWrapper>
  );
};

export default EditorSidebarSliderWithInput;
