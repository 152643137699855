import { memo } from 'react';

interface IToggleLockSize {
  className?: string;
}

const ToggleLockSize = ({ className }: IToggleLockSize): JSX.Element => (
  <svg
    className={className}
    width="23"
    height="24"
    viewBox="-8 -4 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 11C4.41421 11 4.75 10.6642 4.75 10.25L4.75 5.75C4.75 5.33579 4.41421 5 4 5C3.58579 5 3.25 5.33579 3.25 5.75V10.25C3.25 10.6642 3.58579 11 4 11ZM6.25 9.5V11.75C6.25 12.9926 5.24264 14 4 14C2.75736 14 1.75 12.9926 1.75 11.75L1.75 9.5C1.75 9.08579 1.41421 8.75 1 8.75C0.585786 8.75 0.25 9.08579 0.25 9.5L0.25 11.75C0.25 13.8211 1.92894 15.5 4 15.5C6.07106 15.5 7.75 13.8211 7.75 11.75V9.5C7.75 9.08579 7.41421 8.75 7 8.75C6.58579 8.75 6.25 9.08579 6.25 9.5ZM0.25 4.25L0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25C1.41421 7.25 1.75 6.91421 1.75 6.5L1.75 4.25C1.75 3.00736 2.75736 2 4 2C5.24264 2 6.25 3.00736 6.25 4.25V6.5C6.25 6.91421 6.58579 7.25 7 7.25C7.41421 7.25 7.75 6.91421 7.75 6.5V4.25C7.75 2.17894 6.07106 0.5 4 0.5C1.92894 0.5 0.25 2.17894 0.25 4.25Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ToggleLockSize);
