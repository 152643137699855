import { QueryKeys } from './login-page';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useQuery } from 'hooks';

const DPAAuthenticationPage: React.VFC = () => {
  const query = useQuery();
  const dpaCode = query.get(QueryKeys.Code);

  return <h2>Test</h2>;
};

export default DPAAuthenticationPage;
