import { useEffect, useState } from 'react';

interface IUseIsContentOverflowing {
  element: HTMLDivElement | null;
}

const useIsContentOverflowing = ({ element }: IUseIsContentOverflowing): boolean => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  // This will rerender when element prop is changed which is exactly what we need
  useEffect(() => {
    setIsOverflowing((element as HTMLDivElement)?.scrollHeight > (element as HTMLDivElement)?.clientHeight);
  });

  return isOverflowing;
};

export default useIsContentOverflowing;
