import { memo } from 'react';

interface IShare {
  className?: string;
}

const Share = ({ className }: IShare): JSX.Element => {
  return (
    <svg
      className={className}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.52124 20.4696C6.89836 20.4656 8.22338 19.9427 9.23227 19.0054L19.0884 24.6373C18.4058 27.3048 19.7851 30.069 22.3271 31.1275C24.869 32.186 27.8026 31.2178 29.215 28.8541C30.6274 26.4904 30.0903 23.4482 27.9539 21.7112C25.8174 19.9741 22.7296 20.0689 20.7038 21.9339L10.8477 16.302C10.951 15.9069 11.0081 15.5011 11.0177 15.0928L20.7023 9.55857C22.6245 11.3061 25.5082 11.4682 27.6142 9.94695C29.7201 8.42575 30.4725 5.63726 29.4175 3.26319C28.3626 0.889122 25.7888 -0.421356 23.2484 0.122089C20.708 0.665533 18.8956 2.9143 18.9042 5.51219C18.9099 5.96576 18.9717 6.41688 19.0884 6.85521L10.1392 11.9675C8.7056 9.74974 5.90025 8.8607 3.45099 9.84797C1.00172 10.8352 -0.402871 13.4213 0.102328 16.0132C0.607528 18.6052 2.88049 20.4745 5.52124 20.4696Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Share);
