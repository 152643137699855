import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IHeaderWrapper {
  children: ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 60px;
`;

const HeaderWrapper = ({ children }: IHeaderWrapper): JSX.Element => {
  return <Wrapper>{children}</Wrapper>;
};

export default HeaderWrapper;
