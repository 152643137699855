export const FONT_STYLE_BOLD = 'bold';
export const FONT_STYLE_ITALIC = 'italic';
export const FONT_STYLE_UNDERLINE = 'underline';

export const TITLE = 'Title';
export const HEADLINE = 'Headline';
export const SUBHEADLINE = 'Subheadline';
export const TEXT = 'Normal text';
export const SMALL_TEXT = 'Small text';

export const BOLD = 'BOLD';
export const ITALIC = 'ITALIC';
export const UNDERLINE = 'UNDERLINE';
