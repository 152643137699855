import { memo } from 'react';

interface IStoryLinkIcon {
  className?: string;
}

const StoryLinkIcon = ({ className }: IStoryLinkIcon): JSX.Element => {
  return (
    <svg
      className={className}
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6 6C6.6 6.66274 7.09249 7.2 7.7 7.2L14.3 7.2C14.9075 7.2 15.4 6.66274 15.4 6C15.4 5.33726 14.9075 4.8 14.3 4.8L7.7 4.8C7.09249 4.8 6.6 5.33726 6.6 6ZM8.8 9.6L5.5 9.6C3.67747 9.6 2.2 7.98822 2.2 6C2.2 4.01178 3.67747 2.4 5.5 2.4L8.8 2.4C9.40751 2.4 9.9 1.86274 9.9 1.2C9.9 0.537258 9.40751 0 8.8 0L5.5 0C2.46244 1.44847e-07 0 2.6863 0 6C1.32777e-07 9.3137 2.46244 12 5.5 12L8.8 12C9.40751 12 9.9 11.4627 9.9 10.8C9.9 10.1373 9.40751 9.6 8.8 9.6ZM16.5 0L13.2 0C12.5925 2.89692e-08 12.1 0.537258 12.1 1.2C12.1 1.86274 12.5925 2.4 13.2 2.4L16.5 2.4C18.3225 2.4 19.8 4.01178 19.8 6C19.8 7.98822 18.3225 9.6 16.5 9.6L13.2 9.6C12.5925 9.6 12.1 10.1373 12.1 10.8C12.1 11.4627 12.5925 12 13.2 12L16.5 12C19.5376 12 22 9.3137 22 6C22 2.6863 19.5376 0 16.5 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(StoryLinkIcon);
